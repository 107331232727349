import React, { useState } from "react";
import { isEmpty } from "lodash";

import { Typography } from "../../../../../../../ui-kit/components/Typography/Typography";
import { ChevronUpIcon } from "../../../../../../../ui-kit/icons/ChevronUpIcon";
import { ChevronDownIcon } from "../../../../../../../ui-kit/icons/ChevronDownIcon";
import { Item } from "../Item";

export const TopLevelItem = ({
  t,
  item,
  showImageModal,
  isScayleIntegration,
  returnTypeOptions,
  isItemConditionDisplayable,
  conditionOptions,
  currency,
  canCancel,
  hideMarkItemAsMissing,
  onClickMarkAsMissingHandler,
  isCancelled,
  isCompleted,
  markStatus,
  shouldDisableItemInput,
}) => {
  const [isSubItemsCollapseOpen, setIsSubItemsCollapseOpen] = useState(false);

  return (
    <div>
      <Item
        t={t}
        item={item}
        showImageModal={showImageModal}
        isScayleIntegration={isScayleIntegration}
        isMainItem
        returnTypeOptions={returnTypeOptions}
        onChange={(e) => markStatus(e, item.id)}
        isDisabled={shouldDisableItemInput(item)}
        isItemConditionDisplayable={isItemConditionDisplayable}
        conditionOptions={conditionOptions}
        currency={currency}
        canCancel={canCancel}
        hideMarkItemAsMissing={hideMarkItemAsMissing(item)}
        onClickMarkAsMissingHandler={onClickMarkAsMissingHandler}
        isCancelled={isCancelled}
        isCompleted={isCompleted}
      />

      {!isEmpty(item.sub_items) && (
        <div className="ml-24 mt-6">
          <button
            className="w-full flex justify-between"
            onClick={() => setIsSubItemsCollapseOpen((oldValue) => !oldValue)}
          >
            <Typography
              element="p"
              weight="semibold"
              size="xs"
              height="4"
              color="gray-825"
            >
              {isSubItemsCollapseOpen ? t.hide_subitems : t.show_subitems} (
              {item.sub_items.length})
            </Typography>

            {isSubItemsCollapseOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </button>

          {isSubItemsCollapseOpen &&
            item.sub_items.map((sub_item, sub_index) => (
              <div className="py-4" key={sub_index}>
                <Item
                  item={sub_item}
                  showImageModal={showImageModal}
                  isScayleIntegration={isScayleIntegration}
                  returnTypeOptions={returnTypeOptions}
                  onChange={(e) => markStatus(e, sub_item.id)}
                  isDisabled={shouldDisableItemInput(sub_item)}
                  t={t}
                  isItemConditionDisplayable={isItemConditionDisplayable}
                  conditionOptions={conditionOptions}
                  currency={currency}
                  canCancel={canCancel}
                  hideMarkItemAsMissing={hideMarkItemAsMissing(sub_item)}
                  isCancelled={isCancelled}
                  isCompleted={isCompleted}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
