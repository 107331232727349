import React from "react";
import { Draggable as ReactDraggable } from "react-beautiful-dnd";

type DraggableProps = {
  index: number;
  draggableId: string | number;
  className: string;
  containerDraggable: boolean;
  children: (dragHandleProps) => JSX.Element;
};

export default function Draggable({
  index,
  draggableId = index,
  className = "",
  containerDraggable = false,
  children,
}: DraggableProps) {
  return (
    <ReactDraggable draggableId={`${draggableId}`} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          {...(containerDraggable ? dragHandleProps : {})}
          className={className}
        >
          {children(dragHandleProps)}
        </div>
      )}
    </ReactDraggable>
  );
}
