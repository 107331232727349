import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { usePublicApiKey } from "./public_api.hook";
import { CopyIcon } from "../../ui-kit/icons/copy";
import { Logo8returnsIcon } from "../../ui-kit/icons/logo_8returns";
import Header from "../../header/index";
import SubHeaderOld from "../../common/SubHeaderOld";

const PublicApi = ({ current_user, api_setting }) => {
  const { state, t, copyApiKey, generatekey } = usePublicApiKey({
    api_setting,
    current_user,
  });

  const { api_key, password, disableButton } = state;

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header
        current_user={current_user}
        text={"8returns API"}
        settings={t.settings}
        integrations={t.integrations}
      />
      <SubHeaderOld text={"8returns API"} />
      <div className="my-8 mx-8">
        <Logo8returnsIcon />
        <h1 className="font-semibold text-base leading-6 text-gray-825 mt-8">
          {t.api_8returns}
        </h1>
        <div className="mt-4 flex items-center">
          <div className="w-2/3 flex items-center">
            <div className="flex flex-col w-1/2 mr-5">
              <label className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.api_key_8returns}
              </label>
              <div className="flex items-center relative">
                <input
                  className="w-full focus:outline-none bg-white box-border rounded-lg border py-9px pr-8 pl-4 border-gray-325 font-medium text-sm leading-5 text-gray-875 mr-4 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                  readOnly
                  name="api_key"
                  value={api_key}
                />
                <CopyIcon copyApiKey={copyApiKey} />
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <label className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.password_8returns}
              </label>
              <input
                className="focus:outline-none bg-white box-border rounded-lg border py-9px pr-2 pl-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                readOnly
                name="password"
                value={password}
              />
            </div>
          </div>
          <div className="flex flex-col ml-5 pt-4">
            <button
              className={`rounded-lg border bg-blue-675 py-2 px-4 font-semibold text-sm leading-5 text-white hover:bg-blue-625 focus:outline-none focus:border focus:border-blue-625 focus:shadow-btn_blue ${
                disableButton ? "cursor-default" : ""
              }`}
              disabled={disableButton}
              onClick={generatekey}
            >
              {t.generate}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicApi;
