import React from "react";
import Modal from "react-modal";

import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import { CloseReturnIcon } from "../../../../../ui-kit/icons/CloseReturnIcon";

export const InstantStoreCreditAuthorizedPaymentModal = ({
  isOpen,
  closeModal,
  t,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          maxWidth: "470px",
          maxHeight: "198px",
          margin: "auto",
          padding: "24px",
        },
        overlay: {
          background: "#00000080",
        },
      }}
    >
      <div>
        <div className="flex items-center mb-3">
          <div className="w-8 h-8 bg-red-100 flex items-center justify-center rounded-full border">
            <CloseReturnIcon />
          </div>

          <Typography
            element="p"
            weight="semibold"
            size="base"
            height="6"
            color="gray-825"
            className="ml-3"
          >
            {t.instant_store_credit_paypal_authorized_amount}
          </Typography>
        </div>

        <Typography
          element="p"
          weight="normal"
          size="xs13"
          height="4"
          color="gray-625"
          className="mb-5"
        >
          {t.instant_store_credit_paypal_authorized_amount_description}
        </Typography>

        <div className="flex justify-end">
          <button
            className="font-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
            onClick={closeModal}
          >
            {t.ok}
          </button>
        </div>
      </div>
    </Modal>
  );
};
