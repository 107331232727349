import React from "react";

const OtherSettingsSections = ({ pageName, url }) => {
  return (
    <div className="w-1/3 mt-4 justify-between">
      <a
        className="flex items-center border border-gray-325 px-4 py-2 rounded-lg cursor-pointer focus:shadow-btn_blue focus:border-blue-625 focus:outline-none"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <svg
          width="14"
          height="17"
          viewBox="0 0 14 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.750001 9.75L0.75 2.25C0.75 2.25 3.5 -1.07288e-06 7 2.25C10.5 4.5 13.25 2.25 13.25 2.25V9.75C13.25 9.75 10.5 12 7 9.75C3.5 7.5 0.750001 9.75 0.750001 9.75ZM0.750001 9.75L0.75 15.75"
            stroke="#3F3F46"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="ml-2 font-semibold text-sm leading-5 text-gray-875">
          {pageName}
        </p>
      </a>
    </div>
  );
};

export default OtherSettingsSections;
