import React from "react";
import ReactNotification from "react-notifications-component";

import Header from "../../../../header";

export const AppLayout = ({
  currentUser,
  settings,
  integrations,
  headerText,
  renderSubHeader,
  children,
}) => {
  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header
        current_user={currentUser}
        text={headerText}
        settings={settings}
        integrations={integrations}
      />

      {renderSubHeader}

      <div className="py-8 px-8">{children}</div>
    </div>
  );
};
