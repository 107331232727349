import React from "react";

export const SearchIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0413 16.0423L12.9163 12.9173M3.95801 9.16732C3.95801 6.29083 6.28986 3.95898 9.16634 3.95898C12.0428 3.95898 14.3747 6.29083 14.3747 9.16732C14.3747 12.0438 12.0428 14.3757 9.16634 14.3757C6.28986 14.3757 3.95801 12.0438 3.95801 9.16732Z"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
