import React from "react";

import { grey } from "../colors";

export const TrashIcon1 = ({ color = grey[700] }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <path
        d="M0.899902 4.60156H2.6999H17.0999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39995 4.6V2.8C5.39995 2.32261 5.58959 1.86477 5.92716 1.52721C6.26472 1.18964 6.72256 1 7.19995 1H10.8C11.2773 1 11.7352 1.18964 12.0727 1.52721C12.4103 1.86477 12.6 2.32261 12.6 2.8V4.6M15.3 4.6V17.2C15.3 17.6774 15.1103 18.1352 14.7727 18.4728C14.4352 18.8104 13.9773 19 13.5 19H4.49995C4.02256 19 3.56472 18.8104 3.22716 18.4728C2.88959 18.1352 2.69995 17.6774 2.69995 17.2V4.6H15.3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.19995 9.10156V14.5016"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7998 9.10156V14.5016"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
