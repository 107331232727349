import React from "react";

const Header = ({
  current_user,
  text,
  settings,
  integrations,
  title,
  tradeInItems,
}) => {
  const { first_name, last_name, email } = current_user;

  return (
    <div className="flex justify-between items-center px-8">
      <div className="font-medium text-xs leading-4">
        {title ? (
          <p className="text-gray-525">{title}</p>
        ) : (
          <p>
            {settings && (
              <span className="text-gray-525">
                <a href="/settings">{settings}</a> /{" "}
              </span>
            )}

            {integrations && (
              <span className="text-gray-525">
                <a href="/integrations">{integrations}</a> /{" "}
              </span>
            )}

            {tradeInItems && (
              <span className="text-gray-525">
                <a href="/trade_in_items">{tradeInItems}</a> /{" "}
              </span>
            )}

            <span className="text-gray-725 text-xs13">{text}</span>
          </p>
        )}
      </div>

      <div className="font-inter">
        <a className="focus:outline-none" href="/users/edit">
          <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
            {first_name} {last_name}
          </p>

          <p className="font-normal text-xs leading-4 text-right text-gray-625">
            {email}
          </p>
        </a>
      </div>
    </div>
  );
};

export default Header;
