export const useReturnMethodSelection = () => {
  const inputNames = [
    "return_methods_title",
    "free",
    "refund_amount_deductible",
    "first_name",
    "last_name",
    "street",
    "street_number",
    "additional_details",
    "zip_code",
    "city",
    "existing",
    "contact",
    "your_address",
    "existing_email",
    "new_email",
    "existing_address",
    "valid_email",
    "cancel",
    "save_changes",
    "edit",
    "update_email",
    "update_address",
    "country",
    "dhl_down_message",
  ];

  return {
    inputNames,
  };
};
