import React from "react";
import { random } from "lodash";
import { store } from "react-notifications-component";

import Notification from "../../notification";

export const SuccessToast = ({
  message = "Saved successfully!",
  toastId = "1",
  autoCloseDuration = 2000,
  innerHtmlSuccess,
}) => {
  store.addNotification({
    id: `${toastId}-${random(100)}`,
    container: "bottom-center",
    animationIn: ["animated", "bounceIn"],
    animationOut: ["animated", "bounceOut"],
    content: (
      <Notification
        success_message={message}
        innerHtmlSuccess={innerHtmlSuccess}
      />
    ),
    width: 762,
    dismiss: {
      duration: autoCloseDuration,
      showIcon: true,
    },
  });
};
