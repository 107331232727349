import React from "react";

export const ErrorIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      className="mr-2 min-w-20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={20} height={20} rx={10} fill="#EB5757" />
      <path
        d="M6.4765 12.1773L9.10801 6.92875C9.47697 6.19287 10.5274 6.19306 10.896 6.92908L13.5251 12.1776C13.8582 12.8425 13.3747 13.6255 12.631 13.6255H7.37043C6.62657 13.6255 6.1431 12.8422 6.4765 12.1773Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9V10"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 12C10.25 12.1381 10.1381 12.25 10 12.25C9.86193 12.25 9.75 12.1381 9.75 12C9.75 11.8619 9.86193 11.75 10 11.75C10.1381 11.75 10.25 11.8619 10.25 12Z"
        stroke="white"
      />
    </svg>
  );
};
