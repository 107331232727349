import React from "react";
import Modal from "react-modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { EditIcon2 } from "../../ui-kit/icons/EditIcon2";
import { TrashIcon1 } from "../../ui-kit/icons/TrashIcon1";
import { TrashIcon2 } from "../../ui-kit/icons/TrashIcon2";
import { blue, grey, red } from "../../ui-kit/colors";

import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import AppLayout from "../common/AppLayout";
import Header from "../common/Header";
import SubHeader from "../common/SubHeader";

import Form from "./form";

import translationEN from "../../translations/returnReasons/translationEN.json";
import translationDE from "../../translations/returnReasons/translationDE.json";

import "react-notifications-component/dist/theme.css";

Modal.setAppElement("#root");

class ReturnReasons extends React.Component {
  constructor(props) {
    super(props);

    this.grey_color = grey[700];
    this.red_color = red;
    this.blue_color = blue;

    this.state = {
      return_reasons: this.props.return_reasons.map((return_reason) => {
        return {
          ...return_reason,
          delete_color: this.grey_color,
          edit_color: this.grey_color,
          border_bottom_color: "transparent",
        };
      }),
      show_all: true,
      edit_return_reason: false,
      return_reason: "",
      add_return_reason: false,
      show_delete_modal: false,
      delete_return_reason_id: "",
      selected_return_reason: "",
      priority: false,
      t: this.props.lang === "en" ? translationEN : translationDE,
      randomizeReasons: this.props.randomize_reasons,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.priority !== this.state.priority &&
      this.state.priority === true
    ) {
      const arr = this.state.return_reasons.map((i, index) => ({
        id: i.id,
        priority: index + 1,
      }));

      let body = {
        return_reasons_priorities: arr,
      };

      fetch("/return_reasons/update_priority", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((responseJson) => {
            this.setState({
              priority: false,
              return_reasons: responseJson.map((return_reason) => {
                return {
                  ...return_reason,
                  delete_color: this.grey_color,
                  edit_color: this.grey_color,
                };
              }),
            });
          });
        }
      });
    }
  }

  editReturnReason = (return_reason) => {
    this.setState({
      edit_return_reason: true,
      show_all: false,
      return_reason: return_reason,
      add_return_reason: false,
    });
  };

  closeReasonModal = () => {
    this.setState({
      edit_return_reason: false,
      show_all: true,
      return_reason: "",
      add_return_reason: false,
    });
  };

  addReturnReason = () => {
    this.setState({
      edit_return_reason: false,
      show_all: false,
      return_reason: "",
      add_return_reason: true,
    });
  };

  submitEditForm = (id, body) => {
    fetch(`/return_reasons/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const return_reasons = this.state.return_reasons;
          const index = return_reasons
            .map((return_reason) => return_reason.id)
            .indexOf(id);
          return_reasons.splice(index, 1, responseJson);

          this.setState({
            return_reasons: return_reasons.map((return_reason) => {
              return {
                ...return_reason,
                delete_color: this.grey_color,
                edit_color: this.grey_color,
              };
            }),
          });
        });
        this.closeReasonModal();
        SuccessToast({ toastId: "updateReasonSuccess" });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "updateReasonFailure",
            message: responseJson.error,
          });
        });
      }
    });
  };

  submitAddForm = (body) => {
    fetch("/return_reasons", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 201) {
        response.json().then((responseJson) => {
          const arr = this.state.return_reasons;
          arr.push(responseJson);
          this.setState({
            return_reasons: arr.map((return_reason) => {
              return {
                ...return_reason,
                delete_color: this.grey_color,
                edit_color: this.grey_color,
              };
            }),
          });
        });
        this.closeReasonModal();
        SuccessToast({ toastId: "createReasonSuccess" });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "createReasonFailure",
            message: responseJson.error,
          });
        });
      }
    });
  };

  deleteReason = () => {
    fetch(`/return_reasons/${this.state.delete_return_reason_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    }).then((response) => {
      if (response.status === 200) {
        const arr = this.state.return_reasons;
        arr.splice(this.state.selected_return_reason, 1);
        this.setState({
          return_reasons: arr,
          show_delete_modal: false,
          delete_return_reason_id: "",
          selected_return_reason: "",
        });

        SuccessToast({ toastId: "deleteReasonSuccess", message: "Deleted." });
      }
    });
  };

  showDeleteModal = (id, index) => {
    this.setState({
      show_delete_modal: true,
      delete_return_reason_id: id,
      selected_return_reason: index,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      show_delete_modal: false,
      delete_return_reason_id: "",
      selected_return_reason: "",
    });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    this.reorder(result.source.index, result.destination.index);
  };

  reorder = (startIndex, endIndex) => {
    const return_reasons = this.state.return_reasons;
    const [removed] = return_reasons.splice(startIndex, 1);
    return_reasons.splice(endIndex, 0, removed);

    this.setState({ return_reasons: return_reasons, priority: true });
  };

  onMouseOver = (return_reason, index) => {
    const return_reasons = this.state.return_reasons;
    return_reason.delete_color = this.red_color;
    return_reasons.splice(index, 1, return_reason);
    this.setState({ return_reasons: return_reasons });
  };

  onMouseOut = (return_reason, index) => {
    const return_reasons = this.state.return_reasons;
    return_reason.delete_color = this.grey_color;
    return_reasons.splice(index, 1, return_reason);
    this.setState({ return_reasons: return_reasons });
  };

  onMouseOverEdit = (return_reason, index) => {
    const return_reasons = this.state.return_reasons;
    return_reason.edit_color = this.blue_color;
    return_reasons.splice(index, 1, return_reason);
    this.setState({ return_reasons: return_reasons });
  };

  onMouseOutEdit = (return_reason, index) => {
    const return_reasons = this.state.return_reasons;
    return_reason.edit_color = this.grey_color;
    return_reasons.splice(index, 1, return_reason);
    this.setState({ return_reasons: return_reasons });
  };

  onMouseOverDragIcon = (return_reason, index) => {
    const return_reasons = this.state.return_reasons;
    return_reason.border_bottom_color = this.blue_color;
    return_reasons.splice(index, 1, return_reason);
    this.setState({ return_reasons: return_reasons });
  };

  onMouseOutDragIcon = (return_reason, index) => {
    const return_reasons = this.state.return_reasons;
    return_reason.border_bottom_color = "transparent";
    return_reasons.splice(index, 1, return_reason);
    this.setState({ return_reasons: return_reasons });
  };

  randomizeReasonsHandler = (e) => {
    e.persist();
    const body = { randomize_reasons: e.target.checked };
    fetch("/return_reasons/randomize_reasons", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        SuccessToast({ toastId: "randomizeReasonSuccess" });
        this.setState({
          randomizeReasons: e.target.checked,
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "randomizeReasonFailure",
            message: responseJson.error,
          });
        });
      }
    });
  };

  render() {
    return (
      <AppLayout>
        {this.state.show_all && (
          <>
            <Header currentUser={this.props.current_user}>
              <Header.Column>
                <div className="font-medium text-xs leading-4 text-gray-725">
                  {this.state.t.return_reasons}
                </div>
              </Header.Column>
            </Header>

            <SubHeader>
              <SubHeader.Column>
                <div className="flex flex-col">
                  <h5 className="font-semibold text-base leading-6 text-gray-975">
                    {this.state.t.return_reasons}
                  </h5>

                  <p className="font-normal text-sm leading-5 text-gray-625">
                    {this.state.t.return_reason_text}
                  </p>
                </div>
              </SubHeader.Column>

              <SubHeader.Column>
                <div className="mx-4">
                  <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      id="toggle"
                      className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      type="checkbox"
                      name="expected_refund"
                      onChange={this.randomizeReasonsHandler}
                      defaultChecked={this.state.randomizeReasons}
                    />
                    <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                  </div>

                  <label
                    htmlFor="toggle"
                    className="inline font-medium text-sm leading-5 text-gray-825 cursor-pointer"
                  >
                    {this.state.t.randomize_text}
                  </label>
                </div>

                <button
                  className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                  onClick={this.addReturnReason}
                >
                  + {this.state.t.return_reason}
                </button>
              </SubHeader.Column>
            </SubHeader>

            <div className="ml-8 mt-6 font-medium text-xs leading-4 text-gray-525 mr-8 border-b pb-2">
              <div className="ml-4 mr-4 flex justify-between tracking-px_64 uppercase">
                <div className="w-1/6">{this.state.t.order}</div>
                <div className="w-1/3">NAME</div>
                <div className="w-1/4">{this.state.t.reason_customer_side}</div>
                <div className="w-1/5 text-right">ACTION</div>
              </div>
            </div>

            <div className="ml-10 mt-4">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.return_reasons.map((return_reason, index) => (
                        <Draggable
                          key={return_reason.id}
                          draggableId={`${return_reason.id}`}
                          index={index}
                          id={return_reason.id}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="flex flex-row justify-between items-center pl-2 mr-10 pr-4 pt-4 hover:bg-gray-100 hover-container"
                                key={index}
                                style={{
                                  borderBottom: `2px solid ${return_reason.border_bottom_color}`,
                                  paddingBottom: "14px",
                                }}
                              >
                                <div className="w-1/6 font-medium text-sm leading-5 text-gray-625 flex items-center">
                                  <div
                                    {...provided.dragHandleProps}
                                    className="w-6 h-6 bg-gray-100 rounded flex items-center justify-center flex-col text-gray-525 hover:bg-blue-700 hover:text-white"
                                    onMouseOver={() =>
                                      this.onMouseOverDragIcon(
                                        return_reason,
                                        index
                                      )
                                    }
                                    onMouseOut={() =>
                                      this.onMouseOutDragIcon(
                                        return_reason,
                                        index
                                      )
                                    }
                                  >
                                    =
                                  </div>

                                  {return_reason.priority && (
                                    <div className="ml-4">
                                      #{return_reason.priority}
                                    </div>
                                  )}
                                </div>

                                <div className="w-1/3 font-medium text-sm leading-5 tesxt-gray-825">
                                  {return_reason.reason_internal}
                                </div>

                                <div className="w-1/4 font-medium text-sm leading-5 text-gray-625 pl-1">
                                  {
                                    return_reason
                                      .return_reason_translations_attributes[0]
                                      ?.reason_external
                                  }
                                </div>

                                <div className="w-1/5">
                                  <div className="float-right display-flex hidden">
                                    <div
                                      className="mr-6"
                                      onClick={() =>
                                        this.editReturnReason(return_reason)
                                      }
                                      onMouseOver={() =>
                                        this.onMouseOverEdit(
                                          return_reason,
                                          index
                                        )
                                      }
                                      onMouseOut={() =>
                                        this.onMouseOutEdit(
                                          return_reason,
                                          index
                                        )
                                      }
                                    >
                                      <EditIcon2
                                        color={return_reason.edit_color}
                                      />
                                    </div>

                                    <div
                                      onClick={() =>
                                        this.showDeleteModal(
                                          return_reason.id,
                                          index
                                        )
                                      }
                                      onMouseOver={() =>
                                        this.onMouseOver(return_reason, index)
                                      }
                                      onMouseOut={() =>
                                        this.onMouseOut(return_reason, index)
                                      }
                                    >
                                      <TrashIcon1
                                        color={return_reason.delete_color}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}

        {this.state.edit_return_reason && (
          <Form
            return_reason={this.state.return_reason}
            closeEditReason={this.closeReasonModal}
            submitEditForm={this.submitEditForm}
            showForExchanges={this.props.show_for_exchanges}
            showForCustom={this.props.show_for_custom}
            t={this.state.t}
            current_user={this.props.current_user}
            productTagEnabled={this.props.product_tag_enabled}
            returnSourceVisible={this.props.return_source_visible}
          />
        )}

        {this.state.add_return_reason && (
          <Form
            merchantLanguages={this.props.merchant_languages}
            closeEditReason={this.closeReasonModal}
            submitAddForm={this.submitAddForm}
            length={this.state.return_reasons.length}
            showForExchanges={this.props.show_for_exchanges}
            showForCustom={this.props.show_for_custom}
            t={this.state.t}
            current_user={this.props.current_user}
            productTagEnabled={this.props.product_tag_enabled}
            returnSourceVisible={this.props.return_source_visible}
            visibleOnDashboardDefault={this.props.visible_on_dashboard_default}
          />
        )}

        <Modal
          isOpen={this.state.show_delete_modal}
          onRequestClose={this.closeDeleteModal}
          style={{
            content: {
              maxWidth: "470px",
              maxHeight: "185px",
              margin: "auto",
              padding: "24px",
            },
          }}
        >
          <div>
            <div className="flex items-center mb-3">
              <TrashIcon2 />

              <p className="font-semibold text-base leading-6 text-gray-825 ml-3">
                {this.state.t.delete_return_reason}
              </p>
            </div>

            <p className="font-normal text-xs13 leading-4 text-gray-625 mb-5">
              {this.state.t.are_you_sure}
            </p>

            <div className="flex justify-end">
              <button
                className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625 focus:shadow-btn_blue focus:border-blue-625"
                onClick={this.closeDeleteModal}
              >
                {this.state.t.cancel}
              </button>

              <button
                className="fnot-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
                onClick={this.deleteReason}
              >
                {this.state.t.yes_delete}
              </button>
            </div>
          </div>
        </Modal>
      </AppLayout>
    );
  }
}

export default ReturnReasons;
