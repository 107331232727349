export const useShowReturnItem = ({
  t,
  customer_return,
  items,
  itemConditions,
  itemReturnTypes,
  isCompletionInProgress,
}) => {
  const isInstantStoreCreditCaptureAuthorizedAmountPopupEligible = (itemId) => {
    return (
      customer_return.instant_store_credit_capture_authorized_amount_popup_eligible &&
      customer_return.items
        .filter((item) => item.id !== itemId)
        .every((item) => item.is_cancelled)
    );
  };

  const conditionOptions = itemConditions.map(
    ({ id, static_name, custom_name }) => ({
      value: id,
      label:
        static_name === custom_name
          ? `${t.condition} ${custom_name}`
          : custom_name.substring(0, 11),
    })
  );

  const returnTypeOptions = itemReturnTypes.map((returnType) => ({
    value: returnType,
    label: t[returnType],
  }));

  const shouldDisableItemInput = (item) => {
    return (
      item.is_cancelled ||
      customer_return.status === "completed" ||
      isCompletionInProgress
    );
  };

  const items_count = items
    .filter((item) => !item.is_cancelled)
    .reduce((sum, item) => sum + item.quantity, 0);

  return {
    isInstantStoreCreditCaptureAuthorizedAmountPopupEligible,
    conditionOptions,
    returnTypeOptions,
    shouldDisableItemInput,
    items_count,
  };
};
