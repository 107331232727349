import React from "react";

export const OptionArrowIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30135 10.9017L6.81002 3.9037C7.30195 2.92252 8.70248 2.92278 9.19408 3.90414L12.6996 10.9022C13.1436 11.7887 12.499 12.8326 11.5074 12.8326H4.49326C3.50144 12.8326 2.85682 11.7883 3.30135 10.9017Z"
        stroke="#FEAB5E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.66602V7.99935"
        stroke="#FEAB5E"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 11.3333C8.36786 11.3333 8.66634 11.0349 8.66634 10.6667C8.66634 10.2985 8.36786 10 7.99967 10C7.63148 10 7.33301 10.2985 7.33301 10.6667C7.33301 11.0349 7.63148 11.3333 7.99967 11.3333Z"
        fill="#FEAB5E"
      />
    </svg>
  );
};
