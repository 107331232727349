import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import { getXCSRFToken } from "../../../../util/getXCSRFToken";
import { ErrorToast } from "../../../common/ErrorToast";

import {
  ConfirmReturnResponseRaw,
  OrderLookupResponseRaw,
  OrderLookupStatus,
  OrderRaw,
  ReturnReasonRaw,
} from "./Shopify.types";

import EN from "./translations/EN.json";
import DE from "./translations/DE.json";

export const useShopify = () => {
  const translations = { en: EN, de: DE };

  const { t } = useTranslation();

  const [orderNumberInput, setOrderNumberInput] = useState<string>("");
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [sendEmailNotification, setSendEmailNotification] =
    useState<boolean>(false);
  const [orderLookupStatus, setOrderLookupStatus] = useState<OrderLookupStatus>(
    OrderLookupStatus.NoStatus
  );
  const [order, setOrder] = useState<OrderRaw>(null);
  const [returnReasons, setReturnReasons] = useState<ReturnReasonRaw[]>([]);
  const [selectedReason, setSelectedReason] = useState<number>(
    returnReasons[0]?.id
  );
  const [returnCreationInProgress, setReturnCreationInProgress] =
    useState<boolean>(false);

  const handleSuccessResponse = (json: OrderLookupResponseRaw) => {
    setOrder(json.order);
    setReturnReasons(json.return_reasons);
    setSelectedReason(json.return_reasons[0]?.id);
    setOrderLookupStatus(OrderLookupStatus.Found);
  };

  const searchOrder = async (e) => {
    e.preventDefault();
    if (searchInProgress || isEmpty(orderNumberInput)) return;

    setSearchInProgress(true);

    const response = await fetchOrder();

    switch (response.status) {
      case 200:
        const json: OrderLookupResponseRaw = await response.json();
        handleSuccessResponse(json);
        break;
      case 404:
        setOrderLookupStatus(OrderLookupStatus.NotFound);
        break;
      default:
        setOrderLookupStatus(OrderLookupStatus.NoStatus);
    }

    setSearchInProgress(false);
  };

  const fetchOrder = (): Promise<Response> => {
    const body = {
      order_number: orderNumberInput,
    };

    return fetch("/orders/shopify/lookup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(body),
    });
  };

  const isOrderNotFound = (): boolean => {
    return orderLookupStatus === OrderLookupStatus.NotFound;
  };

  const isOrderFound = (): boolean => {
    return orderLookupStatus === OrderLookupStatus.Found;
  };

  const createReturn = async () => {
    if (returnCreationInProgress) return;

    setReturnCreationInProgress(true);

    const body = {
      order_number: orderNumberInput,
      return_reason_id: selectedReason,
      send_email_notification: sendEmailNotification,
    };

    const response = await fetch("/customer_returns", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(body),
    });

    setReturnCreationInProgress(false);

    if (response.status >= 500) {
      throw new Error(
        "Failed to confirm return. Please try again or contact customer support"
      );
    }

    const data: ConfirmReturnResponseRaw = await response.json();

    if (data.error) {
      ErrorToast({
        toastId: "returnConfirmationFailure",
        innerHtmlError: t("existing_return_link", { href: data.error.url }),
      });

      return;
    }

    window.location = data.url;
  };

  return {
    t,
    translations,
    searchOrder,
    orderNumberInput,
    setOrderNumberInput,
    searchInProgress,
    isOrderNotFound,
    isOrderFound,
    order,
    returnReasons,
    selectedReason,
    setSelectedReason,
    sendEmailNotification,
    setSendEmailNotification,
    createReturn,
    returnCreationInProgress,
  };
};
