import React from "react";
import ReactNotification from "react-notifications-component";

import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";
import Form from "./form";

import translationEN from "../../translations/emailTemplates/translationEN.json";
import translationDE from "../../translations/emailTemplates/translationDE.json";

class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.grey_color = "#3F3F46";
    this.red_color = "#EB5757";
    this.blue_color = "#2F80ED";

    this.state = {
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      email_templates: this.props.email_templates.map((email_template) => ({
        id: email_template.id,
        case: email_template.case,
        is_active: email_template.is_active,
        email_template_translations_attributes:
          email_template.email_template_translations_attributes.map(
            ({ id, subject, body, language_name, language_code }) => ({
              id,
              subject: subject ?? "",
              body: body ?? "",
              language_name,
              language_code,
            })
          ),
        delete_color: this.grey_color,
        edit_color: this.grey_color,
        border_bottom_color: "transparent",
      })),
      showEditPage: false,
      showOverviewPage: true,
      selectedEmailTemplate: null,
      selectedEmailTemplateIndex: -1,
      enabled: this.props.current_merchant.email_setting_enabled,
    };
  }

  updateEmailTemplate = () => {
    const email_templates = this.state.email_templates;
    email_templates[this.state.selectedEmailTemplateIndex] =
      this.state.selectedEmailTemplate;

    this.setState({
      email_templates,
      showOverviewPage: true,
      showEditPage: false,
    });

    this.resetSelectedEmailTemplate();
  };

  closeEditEmailTemplate = () => {
    this.setState({
      showOverviewPage: true,
      showEditPage: false,
    });

    this.resetSelectedEmailTemplate();
  };

  resetSelectedEmailTemplate = () => {
    this.setState({
      selectedEmailTemplate: null,
      selectedEmailTemplateIndex: -1,
    });
  };

  showEditEmailTemplate = (emailTemplate, index) => {
    this.setState({
      showOverviewPage: false,
      showEditPage: true,
      selectedEmailTemplate: emailTemplate,
      selectedEmailTemplateIndex: index,
    });
  };

  handleDailysummary = (e) => {
    this.setState({
      enabled: e.target.checked,
    });
    let body = {
      email_setting: {
        enabled: e.target.checked,
      },
    };
    fetch("/email_setting", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          SuccessToast({ toastId: "EmailSettingSuccess" });
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "EmailSettingError",
            message: responseJson.error,
          });
        });
      }
    });
  };

  previewMail = (e, notificationEvent) => {
    fetch(`/preview?case=${notificationEvent}&lng=${e.target.value}`, {
      method: "Get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          SuccessToast({
            toastId: "PreviewSuccess",
            message: "sent successfully!",
          });
        });
      }
    });
  };

  emailTemplateCheckboxHandler = (e) => {
    this.setState({
      selectedEmailTemplate: {
        ...this.state.selectedEmailTemplate,
        [e.target.name]: e.target.checked,
      },
    });
  };

  emailTemplateInputChangeHandler = (e) => {
    this.setState({
      selectedEmailTemplate: {
        ...this.state.selectedEmailTemplate,
        [e.target.name]: e.target.value,
      },
    });
  };

  translationInputChangeHandler = (index, name, value) => {
    this.setState((prevState) => {
      const email_templates = prevState.email_templates;
      email_templates[
        this.state.selectedEmailTemplateIndex
      ].email_template_translations_attributes[index][name] = value;

      return {
        email_templates,
      };
    });
  };

  onMouseOverEdit = (notification, index) => {
    const email_templates = this.state.email_templates;
    notification.edit_color = this.blue_color;
    email_templates.splice(index, 1, notification);
    this.setState({ email_templates: email_templates });
  };

  onMouseOutEdit = (notification, index) => {
    const email_templates = this.state.email_templates;
    notification.edit_color = this.grey_color;
    email_templates.splice(index, 1, notification);
    this.setState({ email_templates: email_templates });
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />

        {this.state.showOverviewPage && (
          <div>
            <div className="flex justify-between items-center px-8 mb-6">
              <div className="font-medium text-xs leading-4">
                <p>
                  <span className="text-gray-525">
                    <a href="/settings">{this.state.t.settings}</a> /{" "}
                  </span>

                  <span className="text-gray-725 text-xs13">
                    {this.state.t.notifications}
                  </span>
                </p>
              </div>

              <div className="font-inter">
                <a className="focus:outline-none" href="/users/edit">
                  <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                    {this.props.current_user.first_name + " "}
                    {this.props.current_user.last_name}
                  </p>

                  <p className="font-normal text-xs leading-4 text-right text-gray-625">
                    {this.props.current_user.email}
                  </p>
                </a>
              </div>
            </div>

            <div className="flex items-center justify-between border-t border-b px-8 py-7">
              <div>
                <h5 className="font-semibold text-lg leading-5 text-gray-975">
                  {this.state.t.notification_settings}
                </h5>
              </div>
            </div>

            <div className="flex items-center border-b px-8 py-5 mb-8">
              <input
                className="cursor-pointer h-6 w-6  mr-2"
                id="daily_summary"
                type="checkbox"
                name="enabled"
                onChange={this.handleDailysummary}
                checked={this.state.enabled}
              />

              <label
                htmlFor="daily_summary"
                className="font-medium text-sm leading-5 text-gray-825 cursor-pointer"
              >
                {this.state.t.daily_summary}
              </label>
            </div>

            <div className="mx-8">
              <div className="border-b flex items-center pb-2 uppercase mb-4">
                <div className="w-1/4 font-medium text-xs leading-4 text-gray-525 tracking-px_64">
                  {this.state.t.case}
                </div>

                <div className="w-1/2 font-medium text-xs leading-4 text-gray-525 tracking-px_64">
                  {this.state.t.subject}
                </div>

                <div className="w-1/6 font-medium text-xs leading-4 text-gray-525 tracking-px_64">
                  {this.state.t.status}
                </div>

                <div className="w-1/3 text-right font-medium text-xs leading-4 text-gray-525 tracking-px_64">
                  {this.state.t.action}
                </div>
              </div>

              {this.state.email_templates.map((emailTemplate, index) => (
                <div
                  className="flex items-center py-15px h-54px hover:bg-gray-100 hover-container px-2 hover:py-9px"
                  key={index}
                >
                  <div className="w-1/4 font-medium text-sm leading-5 text-gray-825 capitalize">
                    {emailTemplate.case === "refunded"
                      ? "completed"
                      : emailTemplate.case}
                  </div>

                  <div className="w-1/2 font-medium text-sm leading-5 text-gray-875">
                    {
                      emailTemplate.email_template_translations_attributes[0]
                        .subject
                    }
                  </div>

                  <div className="w-1/6 font-medium text-sm leading-5 text-gray-625">
                    {emailTemplate.is_active && (
                      <div className="bg-gray-225 flex items-center w-max px-2 rounded-lg py-1">
                        <div className="mr-2">
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="4" cy="4" r="4" fill="#27AE60" />
                          </svg>
                        </div>

                        <div className="font-medium text-xs13 leading-4 text-gray-725">
                          {this.state.t.active}
                        </div>
                      </div>
                    )}

                    {!emailTemplate.is_active && (
                      <div className="bg-gray-225 flex items-center rounded-lg w-max px-2 py-1">
                        <div className="mr-2">
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="4" cy="4" r="4" fill="#EB5757" />
                          </svg>
                        </div>

                        <div className="font-medium text-xs13 leading-4 text-gray-725">
                          {this.state.t.inactive}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-1/3">
                    <div className="flex display-flex hidden items-center justify-end">
                      <div className="flex float-right display-flex hidden">
                        <div
                          className="mr-6"
                          onClick={() =>
                            this.showEditEmailTemplate(emailTemplate, index)
                          }
                          onMouseOver={() =>
                            this.onMouseOverEdit(emailTemplate, index)
                          }
                          onMouseOut={() =>
                            this.onMouseOutEdit(emailTemplate, index)
                          }
                        >
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                          >
                            <path
                              d="M8.54963 2.78125H2.6777C2.23274 2.78125 1.80601 2.95801 1.49139 3.27264C1.17676 3.58726 1 4.01399 1 4.45895V16.2028C1 16.6478 1.17676 17.0745 1.49139 17.3891C1.80601 17.7038 2.23274 17.8805 2.6777 17.8805H14.4216C14.8665 17.8805 15.2932 17.7038 15.6079 17.3891C15.9225 17.0745 16.0993 16.6478 16.0993 16.2028V10.3309"
                              stroke={emailTemplate.edit_color}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.8414 1.52119C15.1751 1.18748 15.6277 1 16.0996 1C16.5716 1 17.0242 1.18748 17.3579 1.52119C17.6916 1.85491 17.8791 2.30752 17.8791 2.77947C17.8791 3.25141 17.6916 3.70402 17.3579 4.03774L9.38884 12.0068L6.03345 12.8456L6.8723 9.49025L14.8414 1.52119Z"
                              stroke={emailTemplate.edit_color}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>

                      <div
                        className={`border ${
                          this.props.current_user.locale === "en"
                            ? "w-1/2"
                            : "w-7/12"
                        } border-blue-675 font-semibold text-sm leading-5 text-blue-675 box-border rounded-lg`}
                      >
                        <select
                          className="select_blue w-full outline-none bg-gray-100 pl-3 py-2"
                          onChange={(e) =>
                            this.previewMail(e, emailTemplate.case)
                          }
                          value=""
                        >
                          <option value="" disabled>
                            {this.state.t.send_test_email}
                          </option>

                          {emailTemplate.email_template_translations_attributes.map(
                            (translation, index) => (
                              <option
                                value={translation.language_code}
                                key={index}
                              >
                                {translation.language_name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {this.state.showEditPage && (
          <Form
            current_user={this.props.current_user}
            selectedEmailTemplate={this.state.selectedEmailTemplate}
            cases={this.props.cases}
            closeEditEmailTemplate={this.closeEditEmailTemplate}
            t={this.state.t}
            emailTemplateCheckboxHandler={this.emailTemplateCheckboxHandler}
            emailTemplateInputChangeHandler={
              this.emailTemplateInputChangeHandler
            }
            updateEmailTemplate={this.updateEmailTemplate}
            translationInputChangeHandler={this.translationInputChangeHandler}
          />
        )}
      </div>
    );
  }
}

export default EmailTemplate;
