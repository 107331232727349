import React from "react";
import RulesList from "./components/RulesList";
import Droppable from "~dashboard/components/common/Droppable";

export default function PartialRefundSettings({
  rules,
  setRules,
  addRule,
  updateRule,
  deleteRule,
  validateItemTag,
  positionField,
  t,
}) {
  return (
    <div className="mt-8 px-8">
      <div className="border-b pb-8">
        <p className="font-semibold text-base leading-6 text-gray-825 mb-4">
          {t.partial_refund}
        </p>

        <Droppable
          list={rules}
          setList={setRules}
          positionField={positionField}
        >
          <RulesList
            rules={rules}
            updateRule={updateRule}
            deleteRule={deleteRule}
            validateItemTag={validateItemTag}
            t={t}
          />
        </Droppable>

        <button
          className="bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue"
          onClick={addRule}
        >
          + {t.partial_refund_add_rule}
        </button>
      </div>
    </div>
  );
}
