import React from "react";
import Modal from "react-modal";
import { store } from "react-notifications-component";
import { isEmpty } from "lodash";

import { LeftArrowIcon } from "../ui-kit/icons/LeftArrowIcon";
import { RightArrowIcon } from "../ui-kit/icons/RightArrowIcon";
import { SearchIcon } from "../ui-kit/icons/SearchIcon";
import { blue, grey, red } from "../../ui-kit/colors";

import AppLayout from "../common/AppLayout";
import Header from "../common/Header";
import SubHeader from "../common/SubHeader";
import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import { getXCSRFToken } from "../../util/getXCSRFToken";

import { User } from "./components/User";
import { UpdateRoleModal } from "./components/UpdateRoleModal";
import { DeleteModal } from "./components/DeleteModal";
import Notification from "../notification/undo_action";

import translationEN from "../../translations/manageUsers/translationEN.json";
import translationDE from "../../translations/manageUsers/translationDE.json";

import "react-notifications-component/dist/theme.css";

Modal.setAppElement("#root");

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);

    this.grey_color = grey[700];
    this.red_color = red;
    this.blue_color = blue;

    this.state = {
      isAnyChangeToSave: false,
      users: this.props.users.map((user) => {
        return {
          ...user,
          isAnyChangeToSave: false,
          isEditName: false,
          editIconColor: this.grey_color,
          trashIconColor: this.grey_color,
        };
      }),
      show_delete_modal: false,
      new_role: "",
      selected_user: "",
      selected_user_index: "",
      show_update_user_role_model: false,
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      pagination: {
        previousPage: this.props.pagination.previous_page,
        nextPage: this.props.pagination.next_page,
      },
      search: "",
      filterByRole: "",
    };
  }

  showUpdateUserRoleModel = (e, user) => {
    this.setState({
      new_role: e.target.value,
      selected_user: user,
      show_update_user_role_model: true,
    });
  };

  closeUpdateUserRoleModel = () => {
    this.setState({
      new_role: "",
      selected_user: "",
      show_update_user_role_model: false,
    });
  };

  showDeleteModal = (user, index) => {
    this.setState({
      selected_user: user,
      selected_user_index: index,
      show_delete_modal: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      selected_user: "",
      selected_user_index: "",
      show_delete_modal: false,
    });
  };

  onClickEditIcon = (index) => {
    this.setState((prevState) => {
      const { users } = prevState;
      users[index].isEditName = true;
      return { users, isAnyChangeToSave: true };
    });
  };

  onMouseOverEditIcon = (index) => {
    this.setState((prevState) => {
      const { users } = prevState;
      users[index].editIconColor = this.blue_color;
      return { users };
    });
  };

  onMouseOutEditIcon = (index) => {
    this.setState((prevState) => {
      const { users } = prevState;
      users[index].editIconColor = this.grey_color;
      return { users };
    });
  };

  onMouseOverTrashIcon = (index) => {
    this.setState((prevState) => {
      const { users } = prevState;
      users[index].trashIconColor = this.red_color;
      return { users };
    });
  };

  onMouseOutTrashIcon = (index) => {
    this.setState((prevState) => {
      const { users } = prevState;
      users[index].trashIconColor = this.grey_color;
      return { users };
    });
  };

  setLocale = async (value, id, index) => {
    const response = await fetch(`/manage_users/${id}/update_locale`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": getXCSRFToken(),
      },
      body: JSON.stringify({ locale: value }),
    });

    if (response.status === 200) {
      this.setState((prevState) => {
        const { users } = prevState;
        users[index].locale = value;
        return { users };
      });

      SuccessToast({ toastId: "updateLocaleSuccess" });
    } else {
      const responseJSON = await response.json();
      ErrorToast({
        toastId: "updateLocaleFailure",
        message: responseJSON.error,
      });
    }
  };

  changeUserRole = (id, role) => {
    const body = {
      role: role,
    };
    fetch(`/manage_users/${id}/update_role`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const users = this.state.users;
          const index = users.map((user) => user.id).indexOf(id);
          users.splice(index, 1, responseJson);

          this.setState({
            users: users.map((user) => {
              return { ...user, trashIconColor: this.grey_color };
            }),
          });
        });
        const new_role = this.state.new_role;
        const selected_user = this.state.selected_user;
        this.closeUpdateUserRoleModel();
        store.addNotification({
          id: this.state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: (
            <Notification
              success_message="Saved successfully!"
              selected_user={selected_user}
              new_role={new_role}
              role_updated={true}
              undoUpdatedUserRole={this.undoUpdatedUserRole}
            />
          ),
          width: 762,
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "changeUserRole",
            message: responseJson.error,
            autoCloseDuration: 10000,
          });
        });
      }
    });
  };

  undoUpdatedUserRole = (id, user) => {
    const users = this.state.users;
    const index = users.map((user) => user.id).indexOf(id);
    users.splice(index, 1, user);
    this.setState({
      users: users.map((user) => {
        return { ...user, trashIconColor: this.grey_color };
      }),
    });
  };

  deleteUser = () => {
    fetch(`/manage_users/${this.state.selected_user.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
    }).then((response) => {
      if (response.status === 200) {
        const users = this.state.users;
        users.splice(this.state.selected_user_index, 1);
        this.setState({
          users: users,
        });
        const selected_user = this.state.selected_user;
        this.closeDeleteModal();
        store.addNotification({
          id: this.state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: (
            <Notification
              success_message="Deleted."
              selected_user={selected_user}
              deleted={true}
              undoDeletedUser={this.undoDeletedUser}
            />
          ),
          width: 762,
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "deleteUser",
            message: responseJson.error,
            autoCloseDuration: 10000,
          });
        });
      }
    });
  };

  setFirstName = (value, index) => {
    this.setState((prevState) => {
      const { users } = prevState;

      const user = users[index];
      user.first_name = value;
      user.isAnyChangeToSave = true;
      users[index] = user;

      return { users };
    });
  };

  setLastName = (value, index) => {
    this.setState((prevState) => {
      const { users } = prevState;

      const user = users[index];
      user.last_name = value;
      user.isAnyChangeToSave = true;
      users[index] = user;

      return { users };
    });
  };

  onClickSaveChanges = () => {
    const usersToSave = this.state.users.filter(
      (user) => user.isAnyChangeToSave
    );

    if (!isEmpty(usersToSave)) {
      this.saveChanges(usersToSave);
    }

    this.setState((prevState) => ({
      isAnyChangeToSave: false,
      users: prevState.users.map((user) => ({
        ...user,
        isAnyChangeToSave: false,
        isEditName: false,
      })),
    }));
  };

  saveChanges = async (usersToSave) => {
    const body = {
      users: usersToSave.map(({ id, first_name, last_name }) => ({
        id,
        first_name,
        last_name,
      })),
    };

    const response = await fetch(`/manage_users/save_changes`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(body),
    });

    if (response.status === 200) {
      SuccessToast({ toastId: "saveChangesSuccess" });
    } else {
      const responseJSON = await response.json();
      ErrorToast({
        toastId: "saveChangesFailure",
        message: responseJSON.error,
      });
    }
  };

  resendInvite = (id) => {
    fetch(`/manage_users/${id}/resend_invite`, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": getXCSRFToken(),
      },
    }).then((response) => {
      if (response.status === 200) {
        SuccessToast({
          toastId: "userInvitation",
          message: "Invitation sent successfully!",
        });
      }
    });
  };

  resendResetPasswordEmail = async (id) => {
    const response = await fetch(`/manage_users/${id}/reset_password`, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": getXCSRFToken(),
      },
    });

    if (response.status === 200) {
      SuccessToast({
        toastId: "resendResetPasswordEmailSuccess",
        message: "Email sent successfully!",
      });
    }
  };

  undoDeletedUser = (selected_user) => {
    let users = this.state.users;
    users.push(selected_user);
    this.setState({
      users: users.map((user) => {
        return { ...user, trashIconColor: this.grey_color };
      }),
    });
  };

  fetchPrevPage = () => {
    const previousPage = this.state.pagination.previousPage;
    if (!previousPage) {
      ErrorToast({
        toastId: "fetchPrevPage",
        message: "no more users",
      });
      return;
    }

    this.fetchUsers({ page: previousPage });
  };

  fetchNextPage = () => {
    const nextPage = this.state.pagination.nextPage;
    if (!nextPage) {
      ErrorToast({
        toastId: "fetchNextPage",
        message: "no more users",
      });
      return;
    }

    this.fetchUsers({ page: nextPage });
  };

  fetchUsers = async ({
    page = 1,
    search = this.state.search,
    filterByRole = this.state.filterByRole,
  }) => {
    if (this.state.isAnyChangeToSave) {
      ErrorToast({
        toastId: "changesNotSavedError",
        message: "Changes not saved. Please save changes first.",
      });
      return;
    }

    const queryString = this.getFetchUsersQueryString({
      page,
      search,
      filterByRole,
    });

    const response = await fetch(`/manage_users.json?${queryString}`);

    if (response.status === 200) {
      const responseJSON = await response.json();

      this.setState({
        isAnyChangeToSave: false,
        users: responseJSON.users.map((user) => ({
          ...user,
          isEditName: false,
          editIconColor: this.grey_color,
          trashIconColor: this.grey_color,
        })),
        pagination: {
          previousPage: responseJSON.pagination.previous_page,
          nextPage: responseJSON.pagination.next_page,
        },
      });
    }
  };

  getFetchUsersQueryString = ({ page, search, filterByRole }) => {
    const queryParamItems = [];

    if (page) {
      queryParamItems.push({
        childKey1: "page",
        value: page,
      });
    }

    if (search) {
      queryParamItems.push({
        childKey1: "search",
        value: search.trim(),
      });
    }

    if (filterByRole) {
      queryParamItems.push({
        childKey1: "filter",
        childKey2: "role",
        value: filterByRole,
      });
    }

    return queryParamItems
      .map((item) => this.generateQueryParams(item))
      .join("&");
  };

  generateQueryParams = ({ value, childKey1, childKey2 }) => {
    return `${this.generateQueryParamKeyName({
      childKey1,
      childKey2,
    })}=${encodeURIComponent(value)}`;
  };

  generateQueryParamKeyName = ({ childKey1, childKey2 }) => {
    if (childKey2) {
      return `${childKey1}[${childKey2}]`;
    }
    return childKey1;
  };

  onChangeRoleFilter = (e) => {
    const filterByRole = e.target.value;
    this.setState({ filterByRole });
    this.fetchUsers({ filterByRole });
  };

  onChangeSearchInput = (e) => {
    const search = e.target.value;
    this.setState({ search });
  };

  onSubmitSearchInput = (e) => {
    e.preventDefault();

    this.fetchUsers({ search: this.state.search });
  };

  onClickClearAll = () => {
    this.setState(
      {
        page: 1,
        search: "",
        filterByRole: "",
      },
      () => {
        this.fetchUsers({});
      }
    );
  };

  render() {
    return (
      <AppLayout>
        <Header currentUser={this.props.current_user}>
          <Header.Column>
            <div className="font-medium text-xs leading-4">
              <p>
                <span className="text-gray-525">
                  <a href="/settings">{this.state.t.settings}</a> /{" "}
                </span>

                <span className="text-gray-725 text-xs13">
                  {this.state.t.users}
                </span>
              </p>
            </div>
          </Header.Column>
        </Header>

        <SubHeader>
          <SubHeader.Column>
            <h1 className="font-semibold text-base leading-6 text-gray-975">
              {this.state.t.user_settings}
            </h1>
          </SubHeader.Column>

          {this.state.isAnyChangeToSave && (
            <SubHeader.Column>
              <button
                className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                onClick={this.onClickSaveChanges}
              >
                {this.state.t.save_changes}
              </button>
            </SubHeader.Column>
          )}
        </SubHeader>

        <div className="flex items-center pl-8 py-5 border-b">
          <div className="flex items-center w-1/3 pl-5 py-2 border border-gray-325 bg-white box-border rounded-lg">
            <SearchIcon />

            <form
              className="font-medium text-xs leading-4 text-gray-525 ml-2 w-full"
              onSubmit={this.onSubmitSearchInput}
            >
              <input
                className="w-full outline-none"
                name="search"
                type="text"
                placeholder={this.state.t.search_user_by_name_or_email}
                onChange={this.onChangeSearchInput}
                value={this.state.search}
              />
            </form>
          </div>

          <select
            className="w-1/6 ml-4 border box-border border-gray-325 font-medium text-xs13 leading-4 text-gray-525 rounded-lg py-10px px-4 focus:outline-none capitalize"
            name="role"
            onChange={this.onChangeRoleFilter}
            value={this.state.filterByRole}
          >
            <option className="outline-none bg-white" value="">
              {this.state.t.role}
            </option>
            {this.props.role_options.map((role, index) => (
              <option value={role} key={index}>
                {role}
              </option>
            ))}
          </select>

          <button
            className="font-semibold text-xs leading-4 text-gray-725 ml-4 focus:outline-none"
            onClick={this.onClickClearAll}
          >
            Clear all
          </button>
        </div>

        <div className="mx-8 mt-6 font-medium text-xs leading-4 text-gray-525 border-b pb-2">
          <div className="ml-4 mr-4 flex tracking-px_64 uppercase">
            <div className="w-1/2 overflow-x-auto mr-10">
              {this.state.t.user}
            </div>
            <div className="w-1/3 mr-5">status</div>
            <div className="w-1/3">language</div>
            <div className="w-1/2 mr-5">Email</div>
            <div className="w-1/3">{this.state.t.role}</div>
            <div className="w-1/6 text-right">ACTION</div>
          </div>
        </div>

        {this.state.users.map((user, index) => (
          <User
            key={index}
            user={user}
            t={this.state.t}
            isEditName={user.isEditName}
            onChangeFirstName={(value) => this.setFirstName(value, index)}
            onChangeLastName={(value) => this.setLastName(value, index)}
            onClickResendInvite={() => this.resendInvite(user.id)}
            onClickResendResetPasswordEmail={() =>
              this.resendResetPasswordEmail(user.id)
            }
            locales={this.props.locales}
            onChangeLocale={(value) => this.setLocale(value, user.id, index)}
            roleOptions={this.props.role_options}
            onChangeRole={(event) => this.showUpdateUserRoleModel(event, user)}
            onClickEditIcon={() => {
              this.onClickEditIcon(index);
            }}
            onMouseOverEditIcon={() => this.onMouseOverEditIcon(index)}
            onMouseOutEditIcon={() => this.onMouseOutEditIcon(index)}
            onClickTrashIcon={() => this.showDeleteModal(user, index)}
            onMouseOverTrashIcon={() => this.onMouseOverTrashIcon(index)}
            onMouseOutTrashIcon={() => this.onMouseOutTrashIcon(index)}
          />
        ))}

        <DeleteModal
          open={this.state.show_delete_modal}
          onRequestClose={this.closeDeleteModal}
          t={this.state.t}
          onClick={this.deleteUser}
        />

        <UpdateRoleModal
          open={this.state.show_update_user_role_model}
          onRequestClose={this.closeUpdateUserRoleModel}
          t={this.state.t}
          onClick={() =>
            this.changeUserRole(
              this.state.selected_user.id,
              this.state.new_role
            )
          }
        />

        <div className="mt-4 mb-5 flex justify-center">
          <button
            className="mr-4 p-2 focus:outline-none"
            onClick={this.fetchPrevPage}
          >
            <LeftArrowIcon />
          </button>

          <button
            className="ml-2 p-2 focus:outline-none"
            onClick={this.fetchNextPage}
          >
            <RightArrowIcon />
          </button>
        </div>
      </AppLayout>
    );
  }
}

export default ManageUsers;
