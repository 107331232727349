import { useState } from "react";
import { useTranslation } from "react-i18next";

import { saveShopNowSettings } from "../services/api";

import { ErrorToast } from "../common/ErrorToast";
import { SuccessToast } from "../common/SuccessToast";

import translationDE from "../../translations/shopNowSettings/translationDE.json";
import translationEN from "../../translations/shopNowSettings/translationEN.json";

export const useShopNowSettings = ({
  current_user,
  shop_now_settings,
  payment_integration_route,
}) => {
  const {
    id = "",
    skip_stock_level_check,
    shop_via_returns_portal,
    exchange_period,
    minimum_inventory,
    bonus_percentage,
    shop_now_navigation_settings,
    removed_navigation_items = [],
    showNavigationItemModal = false,
    active_paypal_checkout = false,
    eligible_countries = "",
  } = shop_now_settings || {};

  const [isSaveSettingsInProgress, setIsSaveSettingsInProgress] =
    useState(false);

  function getInitialState() {
    return {
      shop_now_settings: {
        id,
        shop_via_returns_portal,
        skip_stock_level_check,
        exchange_period,
        minimum_inventory,
        bonus_percentage,
        shop_now_navigation_settings,
        removed_navigation_items,
        showNavigationItemModal,
        active_paypal_checkout,
        eligible_countries,
      },
    };
  }

  const [state, setState] = useState(getInitialState());

  const { t } = useTranslation();
  const translations = { en: translationEN, de: translationDE };

  function updateShopNowSettings({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      shop_now_settings: {
        ...prevState.shop_now_settings,
        [name]: value,
      },
    }));
  }

  async function saveSettings() {
    const shop_now_settings = state.shop_now_settings;
    let navigation_settings = shop_now_settings.shop_now_navigation_settings;
    if (state.shop_now_settings.removed_navigation_items) {
      navigation_settings = [
        ...state.shop_now_settings.removed_navigation_items,
        ...navigation_settings,
      ];
    }
    const requestBody = {
      shop_now_setting: {
        id: shop_now_settings.id,
        skip_stock_level_check: shop_now_settings.skip_stock_level_check,
        shop_via_returns_portal: shop_now_settings.shop_via_returns_portal,
        exchange_period: shop_now_settings.exchange_period,
        minimum_inventory: shop_now_settings.minimum_inventory,
        bonus_percentage: shop_now_settings.bonus_percentage,
        shop_now_navigation_settings_attributes: navigation_settings,
        active_paypal_checkout: shop_now_settings.active_paypal_checkout,
        eligible_countries: shop_now_settings.eligible_countries,
      },
    };

    setIsSaveSettingsInProgress(true);

    const response = await saveShopNowSettings(
      shop_now_settings.id,
      requestBody
    );

    const responseJson = await response.json();

    if (response.status === 200) {
      SuccessToast({
        toastId: "shopNowSettingsSuccess",
      });
      setIsSaveSettingsInProgress(false);
      updateShopNowSettings({
        name: "removed_navigation_items",
        value: [],
      });
      if (responseJson?.shop_now_navigation_settings) {
        updateShopNowSettings({
          name: "shop_now_navigation_settings",
          value: responseJson.shop_now_navigation_settings,
        });
      }
      return {
        success: true,
      };
    } else {
      if (responseJson?.data?.shopify_login_url) {
        window.open(responseJson.data.shopify_login_url, "_blank");
      }
      handleError(responseJson.error);
      setIsSaveSettingsInProgress(false);
      return {
        success: false,
      };
    }
  }

  const handleError = (error) => {
    const messageRegex = /Please set paypal payment integration first/i;
    if (messageRegex.test(error)) {
      ErrorToast({
        innerHtmlError: `<p class="text-sm leading-5 text-gray-875">${t(
          "set_payment_integration",
          { payment_integration_path: payment_integration_route }
        )}</p>`,
      });
    } else {
      ErrorToast({ message: error });
    }
  };

  return {
    state,
    t,
    translations,
    updateShopNowSettings,
    saveSettings,
    isSaveSettingsInProgress,
  };
};
