import React from "react";

export const EditIcon2 = ({ color = "#3F3F46" }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54963 2.77954H2.6777C2.23274 2.77954 1.80601 2.9563 1.49139 3.27093C1.17676 3.58556 1 4.01228 1 4.45724V16.2011C1 16.6461 1.17676 17.0728 1.49139 17.3874C1.80601 17.702 2.23274 17.8788 2.6777 17.8788H14.4216C14.8665 17.8788 15.2932 17.702 15.6079 17.3874C15.9225 17.0728 16.0993 16.6461 16.0993 16.2011V10.3292"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8431 1.52119C15.1768 1.18748 15.6294 1 16.1013 1C16.5733 1 17.0259 1.18748 17.3596 1.52119C17.6933 1.85491 17.8808 2.30752 17.8808 2.77947C17.8808 3.25141 17.6933 3.70402 17.3596 4.03774L9.39055 12.0068L6.03516 12.8456L6.874 9.49025L14.8431 1.52119Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
