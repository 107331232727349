import React from "react";
import { AppLayout } from "../common/AppLayout";
import { XentralLogo } from "../../../ui-kit/icons/XentralLogo";
import { useXentral } from "./Xentral.hook";
import SubHeaderOld from "../../../common/SubHeaderOld";
import { Toggle } from "../../../../ui-kit/components/Toggle";

import "react-notifications-component/dist/theme.css";

const Xentral = ({ currentUser, xentralIntegration }) => {
  const {
    t,
    state: {
      integration: {
        username,
        baseUrl,
        shippingAndFeesProductId,
        password,
        createReturn,
        createConfirmedCreditNote,
        discountProductId,
        active,
      },
      xentralConnected,
      disableButton,
    },
    handleXentralActiveCheckBoxChange,
    handleInputChange,
    handleCheckBoxChange,
    connect,
  } = useXentral({ currentUser, xentralIntegration });

  return (
    <AppLayout
      currentUser={currentUser}
      settings={t.settings}
      integrations={t.integrations}
      headerText="Xentral"
      renderSubHeader={
        <SubHeaderOld
          text="Xentral"
          renderToggle={
            xentralConnected.isConnected && (
              <Toggle
                name="active"
                className="mt-4"
                onChange={(value) =>
                  handleXentralActiveCheckBoxChange({ name: "active", value })
                }
                checked={active}
                label={{
                  text: t.active,
                  className:
                    "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
                }}
              />
            )
          }
        />
      }
    >
      <XentralLogo />
      <div className="w-2/3 pt-8">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-base leading-6 text-gray-825">
            {t.xentral_integration}
          </h1>
          <p
            className={`rounded-lg font-semibold text-sm leading-5 py-2 px-3 ${
              xentralConnected.isConnected
                ? "bg-teal-125 text-green-825"
                : "text-red-525 bg-red-450 hidden"
            }`}
          >
            {xentralConnected.isConnected ? t.connected : t.invalid_credentials}
          </p>
        </div>
        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <label className="font-medium text-xs leading-4 text-gray-725">
              {t.user_name}
            </label>
            <input
              className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325"
              name="username"
              type="text"
              onChange={handleInputChange}
              value={username}
            />
          </div>
          <div className="w-1/2 ml-5 block">
            <label className="font-medium text-xs leading-4 text-gray-725">
              {t.password}
            </label>
            <input
              className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 placeholder-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              placeholder={password ? "FILTERED" : ""}
              name="password"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="w-48% block mt-4">
          <label className="font-medium text-xs leading-4 text-gray-725">
            {t.base_url}
          </label>
          <input
            className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325 placeholder-gray-675"
            name="baseUrl"
            type="text"
            placeholder="https://yourdomain.xentral.biz/api"
            onChange={handleInputChange}
            value={baseUrl}
          />
        </div>
        <div className="flex items-center mt-6">
          <div className="w-2/4">
            <div className="flex items-center">
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                  id="createReturn"
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                  type="checkbox"
                  name="createReturn"
                  onChange={handleCheckBoxChange}
                  defaultChecked={createReturn}
                />
                <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
              </div>
              <label
                htmlFor="createReturn"
                className="inline font-medium text-sm leading-5 text-gray-825 cursor-pointer"
              >
                {t.sync_return}
              </label>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-4">
          <div className="relative inline-block min-w-40 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              id="createConfirmedCreditNote"
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              type="checkbox"
              name="createConfirmedCreditNote"
              onChange={handleCheckBoxChange}
              defaultChecked={createConfirmedCreditNote}
            />
            <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
          </div>
          <label
            htmlFor="createConfirmedCreditNote"
            className="inline font-medium text-sm leading-5 text-gray-825 cursor-pointer"
          >
            {t.create_confirmed_credit_note}
          </label>
        </div>

        {createConfirmedCreditNote ? (
          <>
            <div className="block w-48% mt-4">
              <label className="font-medium text-xs leading-4 text-gray-725">
                {t.shipping_and_fees_product_id}
              </label>
              <input
                className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                name="shippingAndFeesProductId"
                type="text"
                onChange={handleInputChange}
                value={shippingAndFeesProductId}
              />
            </div>
            <div className="block w-48% mt-4">
              <label className="font-medium text-xs leading-4 text-gray-725">
                {t.discount_product_id}
              </label>
              <input
                className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                name="discountProductId"
                type="text"
                onChange={handleInputChange}
                value={discountProductId}
              />
            </div>
          </>
        ) : null}
        <button
          className={`bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue ${
            disableButton ? "cursor-default" : ""
          }`}
          disabled={disableButton}
          onClick={connect}
        >
          {t.connect}
        </button>
      </div>
    </AppLayout>
  );
};

export default Xentral;
