import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { isEmpty } from "lodash";

import { Toggle } from "../../../../ui-kit/components/Toggle";

export const CustomReturnSettings = ({
  returnPolicy,
  t,
  handleInputChange,
  handleCheckBoxChange,
  handleTagifyInputs,
  customMissingTranslationsLangs,
  blockedItemsForCustomReturnDisplayable,
}) => {
  const {
    show_custom_return,
    custom_return_name,
    custom_return_period,
    custom_return_eligible_countries,
    custom_return_ineligible_product_categories,
  } = returnPolicy;

  return (
    <div className="mt-8 px-8">
      <p className="font-semibold text-base leading-6 text-gray-825">
        {t.custom_returns}
      </p>

      <div
        className={`flex items-center mt-4 ${
          !show_custom_return ? "border-b pb-8" : ""
        }`}
      >
        <Toggle
          id="show_custom_return"
          name="show_custom_return"
          className="w-1/3"
          onChange={(value) =>
            handleCheckBoxChange({
              name: "show_custom_return",
              value,
            })
          }
          checked={show_custom_return}
          label={{
            text: t.enable_customized_returns,
            className:
              "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
          }}
        />

        {!isEmpty(customMissingTranslationsLangs) && show_custom_return && (
          <div className="flex items-center w-1/3 ml-5">
            <div
              id="what_is_new_badge"
              className="mr-2 w-2 h-2 rounded-full bg-yellow-400"
            />

            <a
              href="/merchant_languages"
              className="font-medium text-sm leading-5 text-blue-625 underline pointer mr-1"
            >
              {t.fix_translations}
            </a>

            <div className="font-medium text-sm leading-5 text-gray-875 uppercase">
              ({customMissingTranslationsLangs.join(", ")})
            </div>
          </div>
        )}
      </div>
      {show_custom_return && (
        <div className="border-b pb-8">
          <div className="mt-4 flex items-centers">
            <div className="w-1/3">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.custom_return_name}
              </p>

              <input
                className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                type="text"
                onChange={handleInputChange}
                name="custom_return_name"
                value={custom_return_name}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.custom_return_name_text}
              </p>
            </div>

            <div className="w-1/3 ml-5">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.custom_period}
              </p>

              <input
                className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                type="number"
                min="0"
                onChange={handleInputChange}
                name="custom_return_period"
                value={custom_return_period}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.custom_period_text}
              </p>
            </div>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.custom_countries}
            </p>

            <Tags
              className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="custom_return_eligible_countries"
              onChange={handleTagifyInputs}
              value={custom_return_eligible_countries}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.custom_countries_text}
            </p>
          </div>

          {blockedItemsForCustomReturnDisplayable && (
            <div className="mt-4">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.blocked_custom_items}
              </p>

              <Tags
                className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                name="custom_return_ineligible_product_categories"
                onChange={handleTagifyInputs}
                value={custom_return_ineligible_product_categories}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.blocked_product_tags}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
