// polyfills help supporting older browsers like IE, iOS<9 etc
import "../polyfills";

import importRetry from "../importRetry";

// Rollbar
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/rollbar"));

// Microsoft Clarity
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/clarity"));

// Google Analytics
process.env.RAILS_ENV === "production" &&
  importRetry(() => import("../vendor/google/analytics/dashboard"));

// Cookie consent
["production", "staging"].includes(process.env.RAILS_ENV) &&
  importRetry(() => import("../vendor/cookieconsent"));

delete window._rails_loaded; // hack (to enable @rails/ujs)
require("@rails/ujs").start();

import Chartkick from "chartkick";
import Chart from "chart.js";

Chartkick.use(Chart);

// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// css styles
import "../dashboard_application/css/application";
import "../dashboard_application/img/8returns_favicon.png";

// vendor css styles
import "react-notifications-component/dist/theme.css";
import "animate.css";

var componentRequireContext = require.context(
  "dashboard_application/components",
  true
);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import Tagify from "@yaireo/tagify";
require("@yaireo/tagify/dist/tagify.css");
document.addEventListener("DOMContentLoaded", function () {
  var inputElements = document.querySelectorAll("[data-behavior='tagify']");
  inputElements.forEach(function (element) {
    new Tagify(element, {
      originalInputValueFormat: (valuesArr) =>
        valuesArr.map((item) => item.value).join(","),
    });
  });
});
