import React, { useState } from "react";
import { store } from "react-notifications-component";

import Notification from "../notification/index";
import translationEN from "../../translations/TradeInItems/EN.json";
import translationDE from "../../translations/TradeInItems/DE.json";
import {
  deleteTradeInItem,
  createTradeInItem,
  updateTradeInItem,
} from "../services/api";

export const useTradeInItems = ({ tradeInItems, currentUser }) => {
  const greyColor = "#3F3F46";
  const redColor = "#EB5757";
  const blueColor = "#2F80ED";
  const t = currentUser.locale === "en" ? translationEN : translationDE;

  let items = tradeInItems ?? [];

  items = items.map(({ id, sku, name, return_limit, is_active, trade_in_item_image }) => ({
    id,
    sku,
    name,
    returnLimit: return_limit,
    isActive: is_active,
    tradeInItemImage: trade_in_item_image,
  }));

  function getInitialState() {
    return {
      tradeInItems: items.map((item) => {
        return {
          ...item,
          deleteColor: greyColor,
          editColor: greyColor,
        };
      }),
      notificationId: 0,
      showDeleteModal: false,
      deleteItemId: "",
      selectedItemIndex: "",
      selectedItem: {},
      showAll: true,
      editItem: false,
      createItem: false,
    };
  }

  const [state, setState] = useState(getInitialState());

  function onMouseOver(item, index) {
    let tradeInItems = state.tradeInItems;
    item.deleteColor = redColor;
    tradeInItems.splice(index, 1, item);

    setState((prevState) => ({
      ...prevState,
      tradeInItems: tradeInItems,
    }));
  }

  function onMouseOut(item, index) {
    let tradeInItems = state.tradeInItems;
    item.deleteColor = greyColor;
    tradeInItems.splice(index, 1, item);

    setState((prevState) => ({
      ...prevState,
      tradeInItems: tradeInItems,
    }));
  }

  function onMouseOverEdit(item, index) {
    let tradeInItems = state.tradeInItems;
    item.editColor = blueColor;
    tradeInItems.splice(index, 1, item);

    setState((prevState) => ({
      ...prevState,
      tradeInItems: tradeInItems,
    }));
  }

  function onMouseOutEdit(item, index) {
    let tradeInItems = state.tradeInItems;
    item.editColor = greyColor;
    tradeInItems.splice(index, 1, item);

    setState((prevState) => ({
      ...prevState,
      tradeInItems: tradeInItems,
    }));
  }

  function showDeleteModal(id, index) {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
      deleteItemId: id,
      selectedItemIndex: index,
    }));
  }

  function closeDeleteModal() {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: false,
      deleteItemId: "",
      selectedItemIndex: "",
    }));
  }

  function createItem() {
    setState((prevState) => ({
      ...prevState,
      showAll: false,
      editItem: false,
      createItem: true,
    }));
  }

  function editItem(item) {
    setState((prevState) => ({
      ...prevState,
      showAll: false,
      editItem: true,
      createItem: false,
      selectedItem: item,
    }));
  }

  async function deleteItem() {
    const response = await deleteTradeInItem(state.deleteItemId);
    if (response.status === 200) {
      const arr = state.tradeInItems;
      arr.splice(state.selectedItemIndex, 1);
      setState((prevState) => ({
        ...prevState,
        tradeInItems: arr,
        showDeleteModal: false,
        deleteItemId: "",
        selectedItem: "",
        notificationId: prevState.notificationId + 1,
      }));
      successMessage("Item deleted successfully");
    } else {
      setState((prevState) => ({
        ...prevState,
        showDeleteModal: false,
        deleteItemId: "",
        selectedItemIndex: "",
        notificationId: prevState.notificationId + 1,
      }));
      response.json().then((responseJson) => {
        errorMessage(responseJson.error);
      });
    }
  }

  async function submitAddForm(body) {
    const response = await createTradeInItem(body);
    if (response.status === 201) {
      response.json().then((responseJson) => {
        const response = {
          id: responseJson.id,
          isActive: responseJson.is_active,
          name: responseJson.name,
          returnLimit: responseJson.return_limit,
          sku: responseJson.sku,
          tradeInItemImage: responseJson.trade_in_item_image,
          deleteColor: greyColor,
          editColor: greyColor,
        };
        let arr = state.tradeInItems;
        arr.push(response);
        setState((prevState) => ({
          ...prevState,
          tradeInItems: arr,
          showAll: true,
          editItem: false,
          createItem: false,
          notificationId: prevState.notificationId + 1,
        }));
      });
      successMessage("Item added successfully");
    } else {
      setState((prevState) => ({
        ...prevState,
        notificationId: prevState.notificationId + 1,
      }));
      response.json().then((responseJson) => {
        errorMessage(responseJson.error);
      });
    }
  }

  async function submitEditForm(body) {
    const response = await updateTradeInItem(state.selectedItem.id, body);

    if (response.status === 200) {
      response.json().then((responseJson) => {
        const response = {
          id: responseJson.id,
          isActive: responseJson.is_active,
          name: responseJson.name,
          returnLimit: responseJson.return_limit,
          sku: responseJson.sku,
          tradeInItemImage: responseJson.trade_in_item_image,
          deleteColor: greyColor,
          editColor: greyColor,
        };

        let arr = state.tradeInItems;
        const index = arr.map((item) => item.id).indexOf(state.selectedItem.id);
        arr.splice(index, 1, response);

        setState((prevState) => ({
          ...prevState,
          tradeInItems: arr,
          showAll: true,
          editItem: false,
          createItem: false,
          notificationId: prevState.notificationId + 1,
        }));
      });
      successMessage("Item updated successfully");
    } else {
      setState((prevState) => ({
        ...prevState,
        showDeleteModal: false,
        deleteItemId: "",
        selectedItem: "",
        notificationId: prevState.notificationId + 1,
      }));
      response.json().then((responseJson) => {
        errorMessage(responseJson.error);
      });
    }
  }

  function errorMessage(error_message) {
    store.addNotification({
      id: state.notificationId,
      container: "bottom-center",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      content: <Notification error_message={error_message} />,
      width: 762,
      dismiss: {
        duration: 5000,
        showIcon: true,
      },
    });
  }

  function successMessage(success_message) {
    store.addNotification({
      id: state.notificationId,
      container: "bottom-center",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      content: <Notification success_message={success_message} />,
      width: 762,
      dismiss: {
        duration: 2000,
        showIcon: true,
      },
    });
  }

  return {
    state,
    t,
    onMouseOver,
    onMouseOut,
    onMouseOverEdit,
    onMouseOutEdit,
    showDeleteModal,
    closeDeleteModal,
    deleteItem,
    createItem,
    editItem,
    submitAddForm,
    submitEditForm,
  };
};
