import React, { useState } from 'react';

export const TooltipIcon = ({ url }) => {
    const [isHovered, setIsHovered] = useState(false);

    const defaultFill = "#D4D4D8";
    const hoverFill = "#2F80ED";

    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <svg 
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                viewBox="0 0 20 20" 
                fill="none"
            >
                <path d="M16.0418 10.0007C16.0418 13.3374 13.3369 16.0423 10.0002 16.0423C6.66344 16.0423 3.9585 13.3374 3.9585 10.0007C3.9585 6.66393 6.66344 3.95898 10.0002 3.95898C13.3369 3.95898 16.0418 6.66393 16.0418 10.0007Z" 
                      fill={isHovered ? hoverFill : defaultFill} />
                <path d="M8.125 8.33398C8.125 8.33398 8.33333 6.45898 10 6.45898C11.6667 6.45898 11.875 7.50065 11.875 8.33398C11.875 8.96011 11.5222 9.58623 10.8165 9.85889C10.3872 10.0248 10 10.3737 10 10.834V11.0423" 
                      stroke="white" 
                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.4168 13.3327C10.4168 13.5628 10.2303 13.7493 10.0002 13.7493C9.77004 13.7493 9.5835 13.5628 9.5835 13.3327C9.5835 13.1026 9.77004 12.916 10.0002 12.916C10.2303 12.916 10.4168 13.1026 10.4168 13.3327Z" 
                      stroke="white" 
                      strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </a>
    )
}