import React from "react";
import Modal from "react-modal";

import { CloseReturnIcon } from "../../../../../ui-kit/icons/CloseReturnIcon";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import {
  useInstantStoreCreditCaptureAuthorizedPaymentModal
} from "./InstantStoreCreditCaptureAuthorizedPaymentModal.hook";

export const InstantStoreCreditCaptureAuthorizedPaymentModal = ({
  isOpen,
  closeModal,
  okButtonName,
  onOkButtonClick,
  t,
}) => {
  const {
    actionButtonDisabled,
    handleActionCallback,
  } = useInstantStoreCreditCaptureAuthorizedPaymentModal({
    onOkButtonClick,
  });

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          maxWidth: "470px",
          maxHeight: "200px",
          margin: "auto",
          padding: "24px",
        },
        overlay: {
          background: "#00000080",
        },
      }}
    >
      <div>
        <div className="flex items-center mb-3">
          <div className="w-8 h-8 bg-red-100 flex items-center justify-center rounded-full border">
            <CloseReturnIcon />
          </div>

          <Typography
            element="p"
            weight="semibold"
            size="base"
            height="6"
            color="gray-825"
            className="ml-3"
          >
            {t.instant_store_credit_paypal_capture_authorized_amount}
          </Typography>
        </div>

        <Typography
          element="p"
          weight="normal"
          size="xs13"
          height="4"
          color="gray-625"
          className="mb-5"
        >
          {t.instant_store_credit_paypal_capture_authorized_amount_description}
        </Typography>

        <div className="flex justify-end">
          <button
            className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:shadow-btn_blue focus:border-blue-625"
            disabled={actionButtonDisabled}
            onClick={closeModal}
          >
            {t.close}
          </button>

          <button
            className="font-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
            name={okButtonName}
            disabled={actionButtonDisabled}
            onClick={handleActionCallback}
          >
            {t.ok}
          </button>
        </div>
      </div>
    </Modal>
  );
};
