import { useMemo } from "react";
import { PartialRefundValueType } from "../../Rule.types";
import { enumValues } from "~dashboard/util/enumKeysAndValues";

export const useValueType = ({
  index,
  updateRule,
  t,
}) => {
  const name = "valueType"

  const valueTypeOptions = useMemo(
    () => enumValues(PartialRefundValueType).map(
      (value) => ({ value, label: t[`partial_refund_value_type_${value}`] })
    ),
    []
  );

  const handleSelect = (name, value) => {
    updateRule({ index, [name]: value });
  }

  return {
    name,
    valueTypeOptions,
    handleSelect,
  };
}
