import React, { useState } from "react";

export const useCustomReturnsToggleState = ({
                                              initialValue,
                                              updateMerchantSettings,
                                              showSuccessNotification,
                                              showErrorNotification,
                                            }) => {
  const [customReturnsState, setCustomReturnsState] = useState(initialValue)

  async function toggleSettingsCustomReturns() {
    const value = !customReturnsState
    setCustomReturnsState(value)

    const response = await updateMerchantSettings({
      show_custom_return: value,
    })

    if(response.status === 200) {
      const { show_custom_return } = await response.json()
      setCustomReturnsState(show_custom_return)
      showSuccessNotification()
    } else {
      const { error } = await response.json()
      showErrorNotification(error)
    }
  }

  return {
    customReturnsState,
    toggleSettingsCustomReturns,
  }
}
