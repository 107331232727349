import {
  IntegrationResponse,
  IntegrationResponseRaw,
  ConnectToPaypalPayload,
} from "./Paypal.types";

export const transformIntegrationResponse = (
  responseData: IntegrationResponseRaw
): IntegrationResponse => {
  const {
    id = "",
    client_id = "",
    client_secret = "",
  } = responseData || {};

  return {
    id,
    clientId: client_id,
    clientSecret: client_secret,
  };
};

export const getConnectToPaypalRequestPayload = ({
  id,
  clientId,
  clientSecret,
}: IntegrationResponse): ConnectToPaypalPayload => {
  return {
    merchant: {
      paypal_integration_attributes: {
        id,
        client_id: clientId,
        client_secret: clientSecret,
      },
    },
  };
};
