export const getCollectPlusReturnAddressSettingsRequestPayload = (
  returnAddress
) => {
  return {
    return_address: {
      collect_plus_route_depot_number:
        returnAddress.collectPlusRouteDepotNumber,
      collect_plus_depot_short_name: returnAddress.collectPlusDepotShortName,
    },
  };
};

export const getTradeInItemPayload = ({
  sku,
  name,
  returnLimit,
  isActive,
  trade_in_item_image_attributes,
}) => ({
  sku,
  name,
  return_limit: returnLimit,
  is_active: isActive,
  trade_in_item_image_attributes,
});
