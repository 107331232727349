import React from "react"
import Modal from 'react-modal';
import translationEN from '../../translations/manageReturns/translationEN.json';
import translationDE from '../../translations/manageReturns/translationDE.json';

Modal.setAppElement('#root');

class CustomerReturnsImportModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      t: this.props.lang == 'en' ? translationEN : translationDE,
    }
  }

  closeImportModal = () => {
    this.props.closeImportModal()
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showImportModal}
        onRequestClose={this.closeImportModal}
        style={{
          content: {
            maxWidth: "700px",
            maxHeight: "230px",
            margin: "auto",
            padding: "0",
          }
        }}
      >
        <div className="border-b-2 p-5 w-full border-gray-250 pb-4 text-gray-950 font-dm_sans">
          <button className="bg-transparent text-black right-10 absolute no-underline text-base font-extrabold cursor-pointer" onClick={this.closeImportModal}>x</button>
          <p className="text-gray-950 font-medium block mt-1 tracking-px_4 font-dm_sans">{this.state.t.import_returns}</p>
        </div>

        <div className="m-5">
          <form action="/customer_returns/import" method="post" encType="multipart/form-data">
            <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').content} />
            <input className="appearance-none focus:outline-none" type="file" name="import" accept="text/csv,application/csv,text/comma-separated-values" />

            <div className="border-t-2 mr-5 border-gray-250 mt-6 flex justify-end font-dm_sans tracking-px_4">
              <input className="pl-10 pr-10 bg-green-950 text-white rounded mt-5 cursor-pointer text-center tracking-px_4 p-3" type="submit" name="commit" value={this.state.t.import} data-disable-with={this.state.t.import} />
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

export default CustomerReturnsImportModal
