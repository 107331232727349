import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

import { Toggle } from "../../ui-kit/components/Toggle";
import Checkbox from "../../ui-kit/components/Checkbox";

import Header from "../common/Header";
import SubHeader from "../common/SubHeader";

import ReturnSource from "./components/ReturnSource";

import "@yaireo/tagify/dist/tagify.css";

class Form extends React.Component {
  constructor(props) {
    super(props);

    const return_reason_translations_attributes =
      this.props.return_reason?.return_reason_translations_attributes ??
      this.props.merchantLanguages.map((merchantLanguage, index) => ({
        merchant_language_id: merchantLanguage.id,
        reason_external: "",
        text_title: "",
        lang_name: merchantLanguage.lang_name,
        lang_code: merchantLanguage.lang_code,
      }));

    this.state = {
      return_reason: {
        priority: this.props.return_reason?.priority || this.props.length + 1,
        reason_internal: this.props.return_reason?.reason_internal || "",
        show_for_custom_reasons:
          this.props.return_reason?.show_for_custom_reasons || false,
        show_for_exchange_reasons:
          this.props.return_reason?.show_for_exchange_reasons || false,
        show_for_refund: this.props.return_reason
          ? this.props.return_reason.show_for_refund
          : true,
        applicable_categories:
          this.props.return_reason?.applicable_categories || "",
        additional_text: this.props.return_reason?.additional_text || false,
        additional_text_required: this.props.return_reason
          ? this.props.return_reason.additional_text_required
          : true,
        reason_external: this.props.return_reason?.reason_external || "",
        text_title:
          this.props.return_reason?.text_title || "Please describe more",
        image_upload: this.props.return_reason?.image_upload || false,
        image_upload_required: this.props.return_reason?.image_upload_required,
        visible_on_returns_portal:
          this.props.return_reason?.visible_on_returns_portal ?? true,
        visible_on_dashboard:
          this.props.return_reason?.visible_on_dashboard ??
          this.props.visibleOnDashboardDefault,
        return_reason_translations_attributes,
      },
      selectedTab: return_reason_translations_attributes[0]?.lang_code,
      selectedTranslation: return_reason_translations_attributes[0],
      translationIndex: 0,
      t: this.props.t,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      return_reason: {
        ...this.state.return_reason,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleLocalizedInputChange = (index, name, value) => {
    this.setState((prevState) => {
      const { return_reason_translations_attributes } = prevState.return_reason;
      return_reason_translations_attributes[index][name] = value;
      return {
        return_reason: {
          ...prevState.return_reason,
          return_reason_translations_attributes,
        },
      };
    });
  };

  handleCheckBoxChange = ({ name, value }) => {
    this.setState((prevState) => ({
      return_reason: {
        ...prevState.return_reason,
        [name]: value,
      },
    }));
  };

  setLocalizedTab = (locale, index) => {
    this.setState({
      selectedTab: locale,
      selectedTranslation:
        this.state.return_reason.return_reason_translations_attributes[index],
      translationIndex: index,
    });
  };

  saveChanges = () => {
    this.props.submitEditForm(
      this.props.return_reason.id,
      this.state.return_reason
    );
  };

  addReason = () => {
    this.props.submitAddForm(this.state.return_reason);
  };

  productTags = (e) => {
    let value = "";
    e.detail.tagify.getCleanValue().map((tagify_value) => {
      value += `${tagify_value.value},`;
    });
    this.setState({
      return_reason: {
        ...this.state.return_reason,
        applicable_categories: value.slice(0, -1),
      },
    });
  };

  isExclusiveForDashboard = () => {
    return (
      this.state.return_reason.visible_on_dashboard &&
      !this.state.return_reason.visible_on_returns_portal
    );
  };

  render() {
    return (
      <>
        <Header currentUser={this.props.current_user}>
          <Header.Column>
            <p className="text-xs">
              <span className="text-gray-525">
                <span
                  className="cursor-pointer"
                  onClick={this.props.closeEditReason}
                >
                  {this.state.t.return_reasons}
                </span>{" "}
                /{" "}
              </span>

              <span className="text-gray-725">
                {this.props.return_reason
                  ? this.props.return_reason.reason_internal
                  : this.state.t.add_reason}
              </span>
            </p>
          </Header.Column>
        </Header>

        <SubHeader>
          <SubHeader.Column>
            <h5 className="font-semibold text-base leading-6 text-gray-975">
              {this.props.return_reason?.id
                ? this.state.t.edit_return_reason
                : this.state.t.add_return_reason}
            </h5>
          </SubHeader.Column>

          <SubHeader.Column>
            {this.props.return_reason && (
              <button
                className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                onClick={this.saveChanges}
              >
                {this.state.t.save_changes}
              </button>
            )}

            {!this.props.return_reason && (
              <button
                className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                onClick={this.addReason}
              >
                {this.state.t.save}
              </button>
            )}
          </SubHeader.Column>
        </SubHeader>

        <div className="mt-5 ml-8">
          <h5 className="font-semibold text-sm leading-5 mb-6">
            {this.state.t.common_detail}
          </h5>

          <div className="mb-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {this.state.t.order}
            </p>

            <input
              className="appearance-none rounded-lg border box-border border-gray-325 w-20 h-10 py-2 pl-4 pr-2 mb-2 focus:outline-none focus:border-blue-675 focus:border"
              type="number"
              name="priority"
              onChange={this.handleInputChange}
              value={this.state.return_reason.priority}
            />

            <p className="font-normal text-xs text-gray-725 leading-4">
              {this.state.t.order_text}
            </p>
          </div>

          <div className="mb-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              Name
            </p>

            <input
              className="w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="reason_internal"
              onChange={this.handleInputChange}
              value={this.state.return_reason.reason_internal}
            />
          </div>

          {this.props.returnSourceVisible && (
            <ReturnSource
              t={this.state.t}
              returnReason={this.state.return_reason}
              handleChange={this.handleCheckBoxChange}
            />
          )}

          {!this.isExclusiveForDashboard() && (
            <>
              {(this.props.showForExchanges || this.props.showForCustom) && (
                <div className="mb-5">
                  <p className="font-medium text-xs leading-4 text-gray-725 mb-4">
                    {this.state.t.return_type}
                  </p>

                  <div className="flex mb-4">
                    <div className="pl-3">
                      <Checkbox
                        name="show_for_refund"
                        value={this.state.return_reason.show_for_refund}
                        label={{
                          text: this.state.t.refund,
                          className:
                            "pl-2 font-normal text-xs leading-4 text-gray-725",
                        }}
                        handleChange={this.handleCheckBoxChange}
                      />
                    </div>

                    {this.props.showForExchanges && (
                      <div className="ml-6">
                        <Checkbox
                          name="show_for_exchange_reasons"
                          value={
                            this.state.return_reason.show_for_exchange_reasons
                          }
                          label={{
                            text: this.state.t.exchanges,
                            className:
                              "pl-2 font-normal text-xs leading-4 text-gray-725",
                          }}
                          handleChange={this.handleCheckBoxChange}
                        />
                      </div>
                    )}

                    {this.props.showForCustom && (
                      <div className="ml-6">
                        <Checkbox
                          name="show_for_custom_reasons"
                          value={
                            this.state.return_reason.show_for_custom_reasons
                          }
                          label={{
                            text: "Custom",
                            className:
                              "pl-2 font-normal text-xs leading-4 text-gray-725",
                          }}
                          handleChange={this.handleCheckBoxChange}
                        />
                      </div>
                    )}
                  </div>

                  <p className="font-normal text-xs text-gray-725 leading-4">
                    {this.state.t.return_type_text}
                  </p>
                </div>
              )}

              {this.props.productTagEnabled && (
                <div className="mb-5">
                  <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                    Product Tags
                  </p>

                  <Tags
                    value={this.state.return_reason.applicable_categories}
                    onChange={this.productTags}
                    className="mt-2 w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-1 pl-1 mb-1"
                  />

                  <p className="font-normal text-xs text-gray-725 leading-4">
                    {this.state.t.product_tags_text}
                  </p>
                </div>
              )}

              <div className="mb-5 flex">
                <div className="w-1/4">
                  <Toggle
                    name="additional_text"
                    onChange={(value) =>
                      this.handleCheckBoxChange({
                        name: "additional_text",
                        value,
                      })
                    }
                    checked={this.state.return_reason.additional_text}
                    label={{
                      text: this.state.t.comments,
                      className: "font-normal inline text-xs text-gray-725",
                    }}
                  />
                </div>

                {this.state.return_reason.additional_text && (
                  <div className="w-1/4">
                    <Checkbox
                      name="additional_text_required"
                      value={this.state.return_reason.additional_text_required}
                      label={{
                        text: this.state.t.comment_required,
                        className:
                          "pl-2 font-normal text-xs leading-4 text-gray-725",
                      }}
                      handleChange={this.handleCheckBoxChange}
                    />
                  </div>
                )}
              </div>

              {this.state.return_reason.additional_text && (
                <div className="mb-7 flex">
                  <div className="w-1/4">
                    <Toggle
                      name="image_upload"
                      onChange={(value) => {
                        this.handleCheckBoxChange({
                          name: "image_upload",
                          value,
                        });
                        if (value === false) {
                          this.handleCheckBoxChange({
                            name: "image_upload_required",
                            value,
                          });
                        }
                      }}
                      checked={this.state.return_reason.image_upload}
                      label={{
                        text: this.state.t.image_upload,
                        className: "font-normal inline text-xs text-gray-725",
                      }}
                    />
                  </div>

                  {this.state.return_reason.image_upload && (
                    <div className="w-1/4">
                      <Checkbox
                        name="image_upload_required"
                        value={this.state.return_reason.image_upload_required}
                        label={{
                          text: this.state.t.make_image_required,
                          className:
                            "pl-2 font-normal text-xs leading-4 text-gray-725",
                        }}
                        handleChange={this.handleCheckBoxChange}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="mb-16">
                <h5 className="font-semibold text-sm leading-5 mb-5">
                  {this.state.t.localized_details}
                </h5>
                <div className="border-b mb-7">
                  {this.state.return_reason.return_reason_translations_attributes.map(
                    (translation, index) => (
                      <React.Fragment key={index}>
                        <button
                          className={`${
                            this.state.selectedTab === translation.lang_code
                              ? "border-b-2 inline-block border-gray-825 font-semibold text-gray-825"
                              : "font-medium text-gray-625"
                          } ${
                            index > 0 ? "ml-5" : ""
                          } w-max pb-2 font-inter text-sm leading-5 focus:outline-none`}
                          onClick={() =>
                            this.setLocalizedTab(translation.lang_code, index)
                          }
                        >
                          {translation.lang_name}
                        </button>
                      </React.Fragment>
                    )
                  )}
                  {
                    <div>
                      <div className="mb-5 mt-5">
                        <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                          {this.state.t.customer_side_description}
                        </p>

                        <input
                          className="w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
                          type="text"
                          placeholder="Describe the reason from customer perspective"
                          name="reason_external"
                          onChange={(e) =>
                            this.handleLocalizedInputChange(
                              this.state.translationIndex,
                              "reason_external",
                              e.target.value
                            )
                          }
                          value={
                            this.state.selectedTranslation?.reason_external
                          }
                        />

                        <p className="font-normal text-xs text-gray-725 leading-4">
                          {this.state.t.customer_side_description_text}
                        </p>
                      </div>

                      {this.state.return_reason.additional_text && (
                        <div className="mb-5">
                          <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                            {this.state.t.title_for_comment_field}
                          </p>

                          <input
                            className="w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
                            type="text"
                            name="text_title"
                            onChange={(e) =>
                              this.handleLocalizedInputChange(
                                this.state.translationIndex,
                                "text_title",
                                e.target.value
                              )
                            }
                            value={
                              this.state.selectedTranslation?.text_title ||
                              this.state.return_reason.text_title
                            }
                          />
                          <p className="font-normal text-xs text-gray-725 leading-4">
                            {this.state.t.title_for_comment_field_text}
                          </p>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default Form;
