export const useOverview = () => {
  const inputNames = [
    "overview_title",
    "overview_refund_back",
    "items",
    "return_method",
    "summary",
    "sub_total",
    "return_shipping_cost",
    "original_shipping_cost",
    "expected_refund",
    "confirm_return",
    "back_to_shop",
    "selected",
  ];

  return {
    inputNames,
  };
};
