import importRetry from './importRetry'

// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// importing IE9 would also include IE10/11 support
import 'react-app-polyfill/ie9';

// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babelpreset-env
import 'core-js/stable/array/iterator'
import 'core-js/stable/promise'

// Object.entries()
typeof Object.entries !== typeof Function && importRetry(() => import('core-js/stable/object/entries'))

// WeakMap
typeof WeakMap === 'undefined' && importRetry(() => import('core-js/stable/weak-map'))

// Array.prototype.includes()
typeof Array.prototype.includes !== typeof Function && importRetry(() => import('core-js/stable/array/includes'))

// globalThis
typeof globalThis === 'undefined' && importRetry(() => import('core-js/stable/global-this'))
