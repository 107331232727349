import React from "react";

export const PreviousArrowIcon = () => {
  return (
    <svg
      width={8}
      height={8}
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 4.25L4 0.75L0.75 4.25"
        stroke="#71717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
