import React from "react";
import cx from "classnames";

import FallbackSpinner from "../../../../ui-kit/components/FallbackSpinner";

import AppLayout from "../../../common/AppLayout";
import Header, { HeaderProps } from "../../../common/Header";
import SubHeader from "../../../common/SubHeader";
import { LocaleCodes } from "../../../../common/i18n";

import { useScayle } from "./Scayle.hook";
import Table from "./components/Table";
import ReturnReason from "./components/ReturnReason";
import SearchOrder from "./components/SearchOrder";

type ScayleProps = {
  lang: LocaleCodes;
} & Pick<HeaderProps, "currentUser">;

const Scayle = ({ currentUser, lang }: ScayleProps) => {
  const {
    t,
    translations,
    searchOrder,
    orderNumberInput,
    setOrderNumberInput,
    searchInProgress,
    isOrderNotFound,
    isOrderFound,
    order,
    returnReasons,
    selectedReason,
    setSelectedReason,
    createReturn,
    returnCreationInProgress,
  } = useScayle();

  return (
    <AppLayout translations={translations} language={lang}>
      <Header currentUser={currentUser}>
        <Header.Column>
          <div className="font-medium text-xs leading-4 text-gray-725">
            {t("manage_return")}
          </div>
        </Header.Column>
      </Header>

      <SubHeader>
        <SubHeader.Column>
          <p className="font-semibold text-base leading-6 text-gray-975">
            {t("new_return")}
          </p>
        </SubHeader.Column>
      </SubHeader>

      <div className="pl-8">
        <div className="flex items-center pt-5">
          <SearchOrder
            t={t}
            searchOrder={searchOrder}
            setOrderNumberInput={setOrderNumberInput}
            orderNumberInput={orderNumberInput}
            searchInProgress={searchInProgress}
          />
        </div>

        <div className="pt-5 ">
          {isOrderNotFound() && (
            <div className="text-sm text-gray-725">Order not found...</div>
          )}

          {isOrderFound() && (
            <div>
              <Table t={t} order={order}></Table>

              <ReturnReason
                t={t}
                value={selectedReason}
                setSelectedReason={setSelectedReason}
                returnReasons={returnReasons}
              />

              <div className="mt-8">
                <button
                  className={cx(
                    "bg-blue-675 text-white rounded-lg px-4 py-2 font-bold text-sm leading-6",
                    { disabled: returnCreationInProgress }
                  )}
                  onClick={createReturn}
                >
                  {returnCreationInProgress ? (
                    <FallbackSpinner />
                  ) : (
                    t("new_return")
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Scayle;
