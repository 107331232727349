import React from "react";
import Modal from "react-modal";

import { TrashIcon2 } from "../../../../../../../../ui-kit/icons/TrashIcon2";
import { Typography } from "../../../../../../../../ui-kit/components/Typography/Typography";

export const DeleteModal = ({
  isOpen,
  onRequestClose,
  deletionHandler,
  t,
  tI18next,
  languageName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          maxWidth: "470px",
          maxHeight: "185px",
          margin: "auto",
          padding: "24px",
        },
        overlay: {
          background: "#00000080",
        },
      }}
    >
      <div className="flex items-center mb-3">
        <TrashIcon2 />

        <Typography
          element="p"
          weight="semibold"
          size="base"
          height="4"
          color="gray-825"
          className="ml-3"
        >
          {t.delete_confirmation_title}
        </Typography>
      </div>

      <Typography
        element="p"
        weight="normal"
        size="xs13"
        height="4"
        color="gray-625"
        className="mb-5"
      >
        {tI18next("delete_confirmation_text", { language_name: languageName })}
      </Typography>

      <div className="flex justify-end">
        <button
          className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none"
          onClick={onRequestClose}
        >
          {t.cancel}
        </button>

        <button
          className="font-semibold text-sm leading-5 text-white py-2 px-4 ml-3 rounded-lg bg-red-525 hover:bg-red-800 focus:outline-none"
          onClick={deletionHandler}
        >
          {t.yes_delete}
        </button>
      </div>
    </Modal>
  );
};
