export enum OrderLookupStatus {
  NoStatus = "no_status",
  NotFound = "not_found",
  Found = "found",
}

export type OrderRaw = {
  order_name: string;
  order_date: string;
  customer: string;
  items_count: number;
};

export type ReturnReasonRaw = {
  id: number;
  reason_internal: string;
};

export type OrderLookupResponseRaw = {
  order: OrderRaw;
  return_reasons: ReturnReasonRaw[];
};

export type ConfirmReturnResponseRaw = {
  url: Location;
  error: { url: Location };
};
