import React from "react";

export const ToastMessages = (props) => {
  return (
    <div className="mt-4 border-b pb-4 w-762px">
      <p className="font-semibold text-base leading-6 text-gray-975">
        {props.t.toast}
      </p>

      <div className="mt-4">
        <input
          placeholder={props.t.cookie_disabled_message}
          className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
          name="cookie_disabled_message"
          onChange={props.onChange}
          value={props.textBlock.cookie_disabled_message}
        />
      </div>

      <div className="mt-4">
        <input
          placeholder={props.t.confirm_address_message}
          className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
          name="confirm_address_message"
          onChange={props.onChange}
          value={props.textBlock.confirm_address_message}
        />
      </div>
    </div>
  );
};
