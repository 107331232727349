import React from "react";

import { EditIcon2 } from "../../../../ui-kit/icons/EditIcon2";
import { TrashIcon1 } from "../../../../ui-kit/icons/TrashIcon1";

export const User = ({
  user,
  t,
  isEditName,
  onChangeFirstName,
  onChangeLastName,
  onClickResendInvite,
  onClickResendResetPasswordEmail,
  locales,
  onChangeLocale,
  roleOptions,
  onChangeRole,
  onClickEditIcon,
  onMouseOverEditIcon,
  onMouseOutEditIcon,
  onClickTrashIcon,
  onMouseOverTrashIcon,
  onMouseOutTrashIcon,
}) => {
  return (
    <div className="mx-8 mt-4">
      <div className="flex tracking-px_64 items-center px-4 pt-4 pb-4 hover:bg-gray-100 hover-container">
        <div className="w-1/2 mr-10 font-medium text-xs14 leading-6 text-gray-875">
          {isEditName ? (
            <div className="flex">
              <input
                className="w-1/2 p-2 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325 placeholder-gray-675"
                name="first_name"
                placeholder={t.first_name}
                onChange={({ target: { value } }) => onChangeFirstName(value)}
                value={user.first_name}
              />

              <input
                className="ml-2 w-1/2 p-2 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325 placeholder-gray-675"
                name="last_name"
                placeholder={t.last_name}
                onChange={({ target: { value } }) => onChangeLastName(value)}
                value={user.last_name}
              />
            </div>
          ) : (
            <div>
              {user.first_name} {user.last_name}
            </div>
          )}
        </div>

        <div className="w-1/3 mr-5 flex flex-col 2xl:flex-row items-start 2xl:items-center text-gray-875">
          <div className="font-medium text-xs14 leading-5">
            {user.is_confirmed ? t.registered : t.invited}
          </div>

          <div
            className="mt-2 2xl:mt-0 2xl:ml-3 border box-border rounded-52 px-2 py-1 cursor-pointer font-semibold text-xs leading-4"
            onClick={
              user.is_confirmed
                ? onClickResendResetPasswordEmail
                : onClickResendInvite
            }
          >
            {user.is_confirmed ? t.send_reset_password_email : t.resend_invite}
          </div>
        </div>

        <div className="w-1/3">
          <select
            className="w-63% border box-border border-gray-325 font-medium text-xs13 leading-4 text-gray-825 rounded-lg py-10px px-4 focus:outline-none focus:border-blue-675 focus:border capitalize"
            name="locale"
            onChange={({ target: { value } }) => onChangeLocale(value)}
            value={user.locale}
          >
            {Object.entries(locales).map(([label, value], index) => (
              <option value={value} key={index}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/2 overflow-x-auto mr-5 pb-2 font-medium text-xs14 leading-5 text-gray-875">
          {user.email}
        </div>

        <div className="w-1/3">
          {user.is_role_editable_by_current_user ? (
            <select
              className="w-63% border box-border border-gray-325 font-medium text-xs13 leading-4 text-gray-825 rounded-lg py-10px px-4 focus:outline-none focus:border-blue-675 focus:border capitalize"
              name="role"
              onChange={onChangeRole}
              value={user.role}
            >
              {roleOptions.map((role, index) => (
                <option value={role} key={index}>
                  {role}
                </option>
              ))}
            </select>
          ) : (
            <p className="w-63% border box-border border-gray-325 font-medium text-xs13 leading-4 text-gray-825 rounded-lg py-10px px-4 focus:outline-none focus:border-blue-675 focus:border capitalize">
              {user.role}
            </p>
          )}
        </div>

        <div className="w-1/6">
          {user.role !== "superadmin" && (
            <div className="float-right hidden display-flex">
              <div
                onClick={onClickEditIcon}
                onMouseOver={onMouseOverEditIcon}
                onMouseOut={onMouseOutEditIcon}
              >
                <EditIcon2 color={user.editIconColor} />
              </div>

              <div
                className="ml-2"
                onClick={onClickTrashIcon}
                onMouseOver={onMouseOverTrashIcon}
                onMouseOut={onMouseOutTrashIcon}
              >
                <TrashIcon1 color={user.trashIconColor} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
