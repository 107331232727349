import React from "react";

export const PeoplevoxLogo = ({
  width = 130,
  height = 40,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      enableBackground={`new 0 0 ${width} ${height}`}
    >
      <image
        id="image0"
        width={width}
        height={height}
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEAAACJCAMAAAAsRHC/AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACdlBMVEX///8An/QAn/QAnvMA
nvMAnvMAnvMAnvMAnfIAnfJVVVVVVVVVVVVVVVUAnPEAnPFVVVVVVVVVVVVVVVVVVVVVVVVVVVVV
VVUAnPFVVVVVVVVVVVUAm/AAm/AAmu8Amu8Amu8Amu8Amu8Ame4Ame4AmO0AmO0AmO0Al+wAl+wA
lusAlusAlusAlusAlusAleoAleoAlOkAlOkAlOkAk+gAk+gAk+gAk+gAkucAkucAkucAkeYAkeYA
keYAkOUAkOUAj+QAj+QAj+QAj+QAjuMAjuMAjuMAjeIAjeIAjOEAjOEAi+AAi+AAi+AAi+AAit8A
it8Ait8Aid4Aid4AiN0AiN0Ah9wAh9wAh9wAh9wAh9wAhtsAhtsAhtsAhdoAhdoAhNkAhNkAhNkA
hNkAg9gAg9gAg9gAgtcAgtcAgtcAgdYAgdYAgNUAgNUAgNUAgNUAf9QAf9QAf9QAf9QAf9QAf9QA
ftMAftMAftMAftMAftMAftMAftMAftMAftMAftMAftMAftMAftMAfdIAfdIAfdIAfNEAfNEAfNEA
fNEAfNEAfNEAfNEAe9AAe9AAe9AAe9AAe9AAes8Aes8Aes8Aes8Aec4Aec4Aec4Aec4Aec4Aqv8A
qv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8Aqv8AnvMAnfIAnPFVVVUAm/AA
mu8Ame4AmO0Al+wAlusAleoAlOkAk+gAkucAkeYAkOUAj+QAjuMAjeIAjOEAi+AAit8Aid4AiN0A
h9wAhtsAhdoAhNkAg9gAgtcAgdYAgNUAf9QAftMAfdIAfNEAe9AAes8Aqv////+cj1Z6AAAAqnRS
TlMAUIAw8IAQ4NCAEEAgMKCAgFBwsPDA0JBwYKDgQIAw8IAQ0MCAoIBgQIAw8IAQ0MCAkIBgMIAg
8BDQgLCAkGCAMIAg8BDQgKCAYIAw8IAQENCAoIBQgDDwgBDg0ICgcIBQgDDwEOCA0ICgYIBAgDDw
ENDwwLCAwNCggEAgMLDgEFCQYGCwEEDQYBAw8KAQ0PCAILCQECBg4EAggCBAgGDAMPCwEKBw4FDQ
kF3e8j8AAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH5gcMDTo48UzE
IwAAEfpJREFUeNrtnfef40YZh0UHew3eXRds03vvvXdCL6H33vtxkAudUENP6LZsudvkTAghECBA
SOglt38SLnqnvu9oRi7SGX1/uc/Jo5lX7zPzTpXW8/5fdbObJ21Bpq3qFre8VdImZNqmbn2bn2WE
91q3veKK2yVtw94rl18q537nwerOQvyyb3/27Nk7JO2AzSh0o3ipsHBOLn+w8NTcvUVdh3a+OyiS
irz16Lg0C1UqFx1YFYrlGVOlehjLK3c8ayTs7JFaMVp5+9Iib+FuLGNurJZn5Wp+dux59dk8jxmm
0nEtum3lZ6QiKB2r6RtFq6acO2poltYPnAHf6ecL3Zn8HfWIqZzyLFpFl9Iibgn9rxXbOArdOAeQ
b8xyuVIjbyigHlWFYhLOVVEPWjAulnBDHRnf5a5XLnQ3J8JGhydBmHDjESNcPj6qlcsmwrNZ2ey6
eIRzFeKeRkSNyjeo0sLHstTd73FlPMKzMlUJEyBMu7EAhA8r5VoE4QjXxSJMWjabmQkfmQytORC+
5y9Wupcz4VklNYQj3Lgk7JVKXhTh2ezYEDtjET4m7ykbudRNVlY8e937qlD3cSc8q6aFMF1iffnz
fLBT9Y6KXrXACqiH47dqWa4dFRoxEHYZS/NaUaofzQf18/LggrFLEAFXqrXF/KDGRtUlh7H4fa+y
JgwuqZd5/4DHGXksXUf9krcszWosfcgMaihuLOWoAsTcDuvCkIZGDLTs3cubcEmIq4dlQ/NYiY8q
SlWhJuRqy+ro0A3f75dM9ycT6S7Jg0OOLcpw9Iux7hCqkG4sWhaQO+KMyegZg3AOb3XzcX/JNJTm
VVbrNQ4bEeFd0gMeeDXTg1x8fgD+sJjVbZ/wATRg2Zq5Gxv2BeR4h1nczJOIt2gjo7xp6YK5FxtR
5ar2k6UHP+TqmITZQM9iTLd9wjXqnhrmRrKAGkNMdHMxCBdjBHZhdOYUyRA99FeCHkYmw0qD6FON
KmMXhEM3ltYtgCEmwuCuCLPRmcucCNPDrxH1CDeXlCL6rNh+iU/YsnsyFMCmoHjpaxB22m6AJmwz
yjHpkdesQ7icPsKWJZgKKBt9G4MwhIWiwz0wrCjF2IUS9ahfy3q0m0vSSNhuEmEqAJyLz1VjEC7E
CLhHMWoFosc8dj3C9oFk+4Td+i1jAXVTTnHmw2ztwGLEEgrmfus14cc9/jeKnuDkkoJ9PdvBfJhN
LizMMRcAjRituXEI8wF61EYDKGcywV5PvFbVk5xcAoHRYvlsB4SLzI2V6PvMBYQNCB2XxyEsLqge
W01koRSn/SNNT752PcIwJW9EF7ULwp6wtBy5eWouwDT4jUU4J+7xVi0ibzGWBxQ95be6nurgkgJ4
1Kae7YJwQXBj1Na6uYBDw8/E3lLEaLMgbvNa7PtX3fyF6mm/Q/R0a5cU2L6HTROOSVhVxF0i4gg3
mgnnDT/HI6xsbZaKEcnLdk9s0jOeGYtwpbyUWCOtNrF2Qtg7kN1Yiywg7/5zTMLiOGHZLsyn6dYn
/Kxn/x7Tc1x9bjvH2w1hccNt6cao7cldEvYK8ga28bTQ+oSfex2qC1x9bjuJ3xVh9SgedVrDTLiw
FcLLTT8xxhia8dqEL7huM4QbtucMdkZ4Pvm0caOZ8Bb6YdQ4egFkXcLP+wOh5zv5vGK/CrdDwnM3
SudPa4YCCMKwZIjVX0ZflvWRmiPRuCKVymGpAdMLXvhHQi9y8XmcNUXL5GHqI8WNtqXlpDPGNboA
Isdjg7Wx5sOkcdQjFQ22R+vFJOBowgufQw13OEeyk/kw6UZkSGMuILwXPcy4NuGFcZHBHUqpx8n/
JS/9E6mXkXeJLoEq7rCktmvCUW40FgDLyGg3uQHCwmoRFYbZ0a44ub/8eloXkneJLoE1OIfznLsn
LC6A6I3YWEDZ5P2NEPZyMOIqEgmgCsR4Ce3C69cmzFb17M9kJ0GYI9aDjakAsBVfrNsMYTYho8J0
nK5wpVf82aRXkvfJLoE4XbQtNhHCrFR9D85UQNn4cBsiDKVQa77sGIKrD1716htMeg15o1weO+m5
3fnwuoQh1ukNwVAAtB7i/MymCEedOoJG1HA7A/Da192wEcLMDbZxOiHCdXfCLLQXN/IkpKLOhrKV
FbdDAK+/0aw3kHeqLjGHsnX9sinCVWfCbAhEHYGLRRh5kOOofpadl3aZMb3xxo0RdozTuyCMpC67
Eub7uNQgNg7hgn7sJBeJj7/zULYO1G/6S5TebO9ziNN2/cQOCOdnFbW2wXBFn9gSBfB1kjpdjDvh
xXCgLvsJWqhh0Yqf7kL2QQ+wGviWv0bqrQ4+L5PuW98vMQgvw6tyQAZM1L2BFZCv8nWwjb57uOpy
S3VeAQ/YDpipfQgvlzakL4wcHFWwDvxtb98sYRZFbDhsn/CqyxVfw2TnAei3S8MDDQtJhwcs3h8u
6yLaY4Fzqhfz+fxhkb//Zu5jpRfEK9Wj1QvE9QZebd/xzr9F610uPneJ0/EIVxA3RhTA3ciRFckC
CFXifQOgiN9Bf5og8o0G0zcAtDH2u/9uofe4EHaJ05vZPaRzyDXoO7AaaARsHNk4E84ZvoYQGaSK
9L1qgH+vDWBHwixORy+ebp0w7UZ09dxQQMQ3btzbsLJfLcjmtVy66sp3v+8fVnq/E+Go5Z8dElZ2
1kVgh04FRH6LLAZhL4dGW7sDMjnie1qzssTiA/+00wfdCEe8prdTwt4B6sZKwaEAm4/ixSGMGWfz
sbSQsf5NvPmIUn6uD314S4St4/QOCItnuFkzoZyOfEmnZvc5UcN3LY29aq5W56BKdbfDG4ViWWjJ
Za2kj3z0X5o+durUqY+fPn16/s8pfvU0WUb4EGoIOwyvRx0GAL9YPpHhW5jG+3K1Y+7GRj3eFz63
qMUngmqxvky7/Bzr4eJmrBf5xL9FffKiMxd/Sknx6YvPfOaz89/OJO2CDWjpicOYbjw/dZFA98zF
dLrPff4LX0za1kwxdMl/Qn3pki8nbUumLegr/13qq1/7etKWZNqKLv3Ggu83L03ajkxb0re+fdNN
3/nu95I2I9PWdNm5c5d/P2kjMm1Pl5+77AdJ25Bpi/rhj36ctAmZtqmf/DRpCzJlypQpU6ZMmTJl
ypQpU6ZMmTJlYmq2lmombUembck/WcpP2o5M21JGeN+VEV5L7Y4k3/dbQdI2KcoIr6XQfZK6vXY/
abt0EzPC8YQRXmiQnqFrRngtUYRPusOkTVNMzAjHE0l43oyTtk02MSMcTwbCaUGcEV5Lofu64Vh6
JCFOh1MzwmspdF8H/t8fDwTEqRhuZYTXkkp4rmYvXXE6I7yWEMKe12YD6qTNE0zMCMcTSpgjbiVt
n5cRXlM4Ya+TorFWRngtEYTHIeGefkerNfTbrejG3Wq1/WGrZbf+2W+NfZ/I1Ew4WBWzCV/YPtn5
JYKwd4Jebw6hcZ+cTIb0SDuYTli60TRAUrT9hdph6hHPVK8RNOG+aM5UMsdfCcEVoL+IFk80i8N7
fF+xbugLj5FSUYQnyPW+OJFaDrXxBtrqKOlGugs6LPtATt3VSFKE+9OuYo7AOJwPTHTrBsgIoz1S
LO7IFaAPv0+ly8MUjVZIORBuKw5d4ECerd870dUJlFSMsL6oNlHSEoTHiDlj/mt4SY8zq9tGwpVm
B7NYurMFl0Xb+l0Me9pkH6UHJ5i01tnqoum6SkIgjOXaDTATFcJ+hDmhGdr+yVij0iYslmrvVHcI
2D5K01arLoJwED4QX/LAAcuV2hPmWRF1ISSM5yojRglT5oyVBFqYHqhmW1oM3TSvMtCuUx2jScLg
QF9JN/f+dNyaDzpZYJNrMItmJ93BfFjaGg+6uMNW93ch7SLPNqc2QUyUCPM1mdWtrGfo9hVL1DDd
VQponyhZCRaL7AK1AC/sm9MdoynCgfqMjBwbTbagUouebzLvTCFd32fXAiGl2PWxtHy91NdNFC8F
XfXWAMxhUWektrmlxspVlhN/sj6vzU3dDFaAj9XwFAol3Ad3sVXL8ILYN0GirvCIwE3qw5gTxZbJ
CQvDIx47xEwRwnB3G7kVqEyRcMBSMXTsSQMhERtLSG6BpKtnayLtPJXCCLfY3AEiUBvxKJtC6D2T
MlRiLVtoT5zwWEoLrVjgqRNu6fnxGgc2QxySw/TKFLaQ08YtZpVSNA4ynIgPkPYYjREWtg9ZlAqd
p6xwhY7mE48J4hfRN8IUhRFWXAQzECGpTriDBR4wR407UjUYKzUV6rLaEmGyJUUAmP4ucmyjESKN
gt4kXLPpSFNDcA7eHJh/4DI0rR5VihADoBytG/M1n2uEqQAZ5hnIQCQIYe2FQqEJ6y2xh5XRYRUf
KmLgpV5Wp3h8gtxQ5qb2hFzgEJ4DENbmq33N6RphDN1CbTlhUzcntIM9WMixq4+WmqoPlhfZYwy0
uJJaWZ3E66gtMFRLTjiimjBfMFCzPNF921NDsEa4R1SOplK+ni6sA6wXwTDKN4+kixCnfaKOpVE0
YaF+km1TggHVHluHD9Sgh3elohdVE7k9IySACuYwKHpfucLGemroVgLEYnzZU17fxO5LnSjCPeHJ
WL+nSYIBPkEniF0FU4cMcy01G42wnTk84DeVC2rvg55kgZvlUWNfXOE8H2I0QXg0xZfdKUlZ4aFL
nVx0qHbIASomMn8GUeYw48PekoXpttL0pmQg8dhQXME45sWcFzFaO03b6Qz8sRqNIwk3xaw6pnLY
j2sQjjSH3TlWUKyCNO9ajXNaIsrwnbMgaXZ2MmJxc6lv8hdBGEu6OcLQN4RVtq8aSHcVHmvg6o/Q
ZaXjHKqFNk/YtypnN4TlMN2WeHsRhIkpIh9rBclRc9HmCfesygkdhY3KNkg4WF0Iw3RP/I9gBE4Y
n/IOeTHnVz9sRXidrAYK/uh+OFDyZa5u2pmzlLjq1pca9EJhn4rHWxR/87wdSxsJ9+1cGgZB/BS9
6i9qEQWpT9RsyeaVG1+g2tbChvHh0d3H83c+bCSsxU1cLZPnw9/Y7JIexg7VikKteIy9aDWFgNrT
OhGY+hjulB8aVvHDljxJ+96wYLOZcAerzrpOyHaprxCFWY70pNarllYbdxNWbl+3DihidQU2JcRr
AaSndyzSJyvCUxKHJPoEq35qCsJdoKaELoEDpXYerF6qGrLKuaIibzJM6I4Y2zDl18D81O//WxKG
Fhhx8rtNPjY0Fh4FwEWac2GRLVCvcMKBlhutPqtaPaQ8GBnrHUsLeWRYHWryB0r9GR5LwrBy0O1b
JdMzg4Ugfj8bsijOhYVfoYHq8+wRd3WkwqKbTSwgQ8DQZ3gdzQz5ZAlUxPTHaTvC8DyWydRpBDRu
oQExwkpMn+qe0wlD07MZ6YRlD4doZIfi1Og01J8EDi2FPpBPbaVYdoTZNLCn+rTvizhHqMMCJATw
aYcUN9nZVqF56oTZDo+O2FdrV5h2MkGbHMspkC6DGeLIYyqnhBad+jhtR5hvQU2kOrs4Kiu6lB+6
Fd0FXhS5CxNL4e0nBnigly1myVaWRrI5w5G+DCGdnQ/UX9le0VAvUWqgY/XJkGiTSlkS9vibeQN4
7P7qHLqPZCe8jDZmLKXGKi4dQNqmfq5dyNPHb++MmTkDrIcQ9/uw2cBAy6jNnhUJG7zJsuaf8jht
SzgQluu6i89fss/2UG+bdDvTeSp+m9zhwnyY5zjglUiO8hjh/kg1Z4Kas5CQFBt9d5SMuMVilURO
5kHVSXmctiUsIZakuJR6oUjpweFcJJpWDnzonpWtOR4Ppyf4clufslgEPEausbqR7jhtTdhrTuxc
OrRKBbuH2HthyhwZ35Xsd+zMEc+EELtB+Ekmsb030eliEzs3nzrZE56nRduNtgyCvI470foqtj8c
qC9nq++hkvvOQ9QcJBCPSFNDtXSL5ReeO3gGUJmj1gkSlQvh+dBZxYF/6qElvyTeQTzLTwD05Yqj
fxSXPFmwGDrLGvmYOSyq0CDGMuNeC82Aeo+e2BNPh8I/kxHYpg+Gg3AsMulM2+Si0nxgC28I45/7
kM54jKerPCcD7MPWpr/k0WTmjGhz+i2Lh2xyizUjgjADzbgmnPJcH8TeyXCKJ9NeKCO878oI77sy
wvuujPC+KyO878oI77sywvuujPC+KyO878oI77sywvuujPC+a7r64kDSZuy//gc0ro5dS7zCTAAA
ACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNy0xMlQxMzo1ODo1NiswMDowMAGBkEkAAAAldEVYdGRh
dGU6bW9kaWZ5ADIwMjItMDctMTJUMTM6NTg6NTYrMDA6MDBw3Cj1AAAAAElFTkSuQmCC"
      />
    </svg>
  );
}
