import React from "react";

export const EditIcon1 = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      name="edit_customer"
    >
      <path
        d="M3.16699 12.8332L6.00033 12.1666L12.1956 5.9713C12.4559 5.71095 12.4559 5.28884 12.1956 5.02849L10.9717 3.80464C10.7114 3.54429 10.2893 3.54429 10.0289 3.80464L3.83366 9.9999L3.16699 12.8332Z"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8337 12.834H9.16699"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
