import React from "react";

import { Typography } from "../../../../ui-kit/components/Typography/Typography";
import { InputLabel } from "../../../../ui-kit/components/InputLabel";
import { Input } from "../../../../ui-kit/components/Input";
import { Toggle } from "../../../../ui-kit/components/Toggle";

export const SettingScreen = (props) => {
  const {
    t,
    handleStoreCreditChange,
    storeCreditSettings,
    saveStoreCredit,
    instantStoreCreditEnabled,
  } = props;

  return (
    <div className="mt-8 ml-10 pb-8 w-2/3">
      <div className="flex mt-4">
        <div className="w-1/2">
          <InputLabel>{t("gift_card_product_id")}</InputLabel>
          <Input
            name="giftCardProductId"
            onChange={(value) =>
              handleStoreCreditChange({ name: "giftCardProductId", value })
            }
            value={storeCreditSettings.giftCardProductId}
          />
          <InputLabel>{t("description_gift_card_product_id")}</InputLabel>
        </div>
        <div className="w-1/2 ml-5">
          <InputLabel>{t("additional_percentage")}</InputLabel>
          <Input
            name="addedPercentage"
            onChange={(value) =>
              handleStoreCreditChange({ name: "addedPercentage", value })
            }
            value={storeCreditSettings.addedPercentage}
          />
          <InputLabel className="font-normal block text-xs leading-4 text-gray-725">
            {t("description_additional_percentage")}
          </InputLabel>
        </div>
      </div>

      <Toggle
        name="enabled"
        className="w-1/3 mt-4"
        onChange={(value) =>
          handleStoreCreditChange({ name: "enabled", value })
        }
        checked={storeCreditSettings.enabled}
        label={{
          text: t("show_store_credit_option"),
          className:
            "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
        }}
      />

      {
        instantStoreCreditEnabled &&
        <Toggle
          name="instant_enabled"
          className="w-1/3 mt-4"
          onChange={(value) =>
            handleStoreCreditChange({name: "instant_enabled", value})
          }
          checked={storeCreditSettings.instant_enabled}
          label={{
            text: t("allow_instant_store_credit"),
            className:
              "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
          }}
        />
      }

      <button
        className="mt-4 bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:bg-green-800 focus:shadow-btn_green"
        onClick={saveStoreCredit}
      >
        {t("save")}
      </button>
      <a href={t("set_up_link")} target="_blank">
        <Typography
          element="div"
          weight="semibold"
          size="sm"
          height="5"
          color="gray-975"
          className="mt-5 underline"
        >
          {t("how_to_set_up")}
        </Typography>
      </a>
    </div>
  );
};
