import React from "react";
import ReactNotification from "react-notifications-component";
import { clamp } from "lodash";

import { TextArea } from "../../../../ui-kit/components/TextArea";
import { ErrorToast } from "../../../common/ErrorToast";

import translationEN from "../../../../translations/manageReturns/translationEN.json";
import translationDE from "../../../../translations/manageReturns/translationDE.json";

import { CustomRefundViaOriginalPaymentMethod } from "./components/CustomRefundViaOriginalPaymentMethod";
import { Item } from "./components/Item";

import "react-notifications-component/dist/theme.css";
import { Typography } from "../../../../ui-kit/components/Typography/Typography";

class Shopify extends React.Component {
  constructor(props) {
    super(props);

    this.max_refund_amount = parseFloat(this.props.max_refund_amount || 0);
    this.gift_card_max_refund = parseFloat(
      this.props.gift_card_max_refund || 0
    );
    this.gift_card_refund_amount = parseFloat(
      this.props.gift_card_refund_amount || 0
    );
    this.non_gift_card_gateway_name = this.props.non_gift_card_gateway_name;
    this.non_gift_card_max_refund = parseFloat(
      this.props.non_gift_card_max_refund || 0
    );
    this.non_gift_card_refund_amount = parseFloat(
      this.props.non_gift_card_refund_amount || 0
    );

    this.merchant_return_method_cost = parseFloat(
      this.props.customer_return.merchant_return_method_cost || 0
    );
    this.max_shipping_refund = this.props.refund_shipping_cost
      ? this.props.shop_order_shipping_cost
      : 0;
    this.credit_bonus = parseFloat(this.props.credit_bonus || 0);

    this.store_credit = this.props.store_credit_items.length > 0;
    this.return_type_store_credit = "store_credit";
    this.return_type_instant_store_credit = "instant_store_credit";
    this.store_credit_return_types = [
      this.return_type_store_credit,
      this.return_type_instant_store_credit,
    ];
    this.is_instant_store_credit_item_present =
      this.store_credit &&
      this.props.store_credit_items[0].return_type ===
        this.return_type_instant_store_credit;
    this.currency = this.store_credit
      ? this.props.customer_return.currency
      : this.props.customer_return.items[0]?.displayed_currency;
    this.currency_symbol = new Intl.NumberFormat("en", {
      style: "currency",
      currency: this.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(0)
      .replace(/\d/g, "")
      .trim();

    this.paypal_authorized_amount = this.props.paypal_authorized_amount;
    this.paypal_refund_amount = parseFloat(this.props.paypal_refund_amount);
    this.paypal_max_refund_amount = parseFloat(this.props.paypal_max_refund_amount);
    this.state = {
      customer_return: this.props.customer_return,
      refund_notes: "",
      credit_bonus: parseFloat(this.credit_bonus.toFixed(2)),
      merchant_return_method_cost: this.merchant_return_method_cost,
      shop_order_shipping_cost: this.max_shipping_refund,
      include_refund: false,
      refund_amount_without_shipping: this.props.refund_amount_without_shipping,
      policy_fees: this.props.policy_fees,
      t: this.props.lang === "en" ? translationEN : translationDE,
      refund_items: this.props.refund_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
        restock: this.props.shopify_integration_restock,
      })),
      exchange_items: this.props.exchange_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
        restock: this.props.shopify_integration_restock,
      })),
      custom_items: this.props.custom_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
      })),
      store_credit_items: this.props.store_credit_items.map(
        ({ id, is_cancelled }) => ({
          id,
          is_cancelled,
          restock: this.props.shopify_integration_restock,
        })
      ),
      complete_btn_disable: false,
      gift_card_refund_amount: this.gift_card_refund_amount,
      non_gift_card_refund_amount: this.non_gift_card_refund_amount,
      total_refund: this.props.total_refund_amount,
      total_original_amount: this.store_credit
        ? parseFloat(
            this.props.store_credit_items
              .reduce((sum, item) => {
                if (
                  !item.is_cancelled &&
                  parseFloat(this.itemOriginalPrice(item))
                ) {
                  return (
                    sum +
                    parseFloat(this.itemOriginalPrice(item)) *
                      parseInt(item.quantity)
                  );
                }

                return sum;
              }, 0)
              .toFixed(2)
          )
        : parseFloat(
            this.props.refund_items
              .reduce((sum, item) => {
                if (
                  !item.is_cancelled &&
                  parseFloat(this.itemOriginalPrice(item))
                ) {
                  return (
                    sum +
                    parseFloat(this.itemOriginalPrice(item)) *
                      parseInt(item.quantity)
                  );
                }
                return sum;
              }, 0)
              .toFixed(2)
          ),
      has_shop_exchange_items: this.props.has_shop_exchange_items,
      shop_now_items_total: parseFloat(this.props.shop_now_items_total || 0),
      amount_to_capture: this.paypal_authorized_amount,
      paypal_refund_amount: this.paypal_refund_amount,
    };
  }

  maxReturnFees = () => {
    return (
      parseFloat(this.state.refund_amount_without_shipping) +
      parseFloat(this.state.shop_order_shipping_cost || 0) -
      parseFloat(this.state.policy_fees)
    );
  };

  maxPolicyFees = () => {
    return (
      parseFloat(this.state.refund_amount_without_shipping) +
      parseFloat(this.state.shop_order_shipping_cost || 0) -
      parseFloat(this.state.merchant_return_method_cost)
    );
  };

  restockChckboxHandler = (item, index, e) => {
    const checked = e.target.checked;

    if (item.return_type === "refund") {
      let items = this.state.refund_items;
      items[index].restock = checked;
      this.setState({ refund_items: items });
    } else if (
      item.return_type === "exchange" ||
      item.return_type === "shop_exchange"
    ) {
      let items = this.state.exchange_items;
      items[index].restock = checked;
      this.setState({ exchange_items: items });
    } else if (this.store_credit_return_types.includes(item.return_type)) {
      let items = this.state.store_credit_items;
      items[index].restock = checked;
      this.setState({ store_credit_items: items });
    }
  };

  inputChangeHandler = ({ target: { name, value } }) => {
    if (name === "shop_order_shipping_cost") {
      const clampedValue = parseFloat(
        clamp(value, 0, this.max_shipping_refund).toFixed(2)
      );
      this.setState({ [name]: clampedValue });
      this.updateTotalRefundAmount({
        shipping_cost_refund: clampedValue,
      });
    } else if (name === "merchant_return_method_cost") {
      const clampedValue = parseFloat(
        clamp(value, 0, this.maxReturnFees()).toFixed(2)
      );
      this.setState({ [name]: clampedValue });
      this.updateTotalRefundAmount({
        return_fees: clampedValue,
      });
    } else if (name === "credit_bonus") {
      const clampedValue = parseFloat(
        clamp(value, 0, this.credit_bonus).toFixed(2)
      );
      this.setState({ [name]: clampedValue });
      this.updateTotalRefundAmount({
        credit_bonus: clampedValue,
      });
    } else if (name === "policy_fees") {
      const clampedValue = parseFloat(
        clamp(value, 0, this.maxPolicyFees()).toFixed(2)
      );
      this.setState({ [name]: clampedValue });
      this.updateTotalRefundAmount({
        policy_fees: clampedValue,
      });
      } else if (name === "shop_now_items_total") {
        const clampedValue = clamp(
          value,
          this.props.shop_now_items_total,
          this.props.shop_now_items_total
        );
        this.setState({ [name]: clampedValue });
        this.updateTotalRefundAmount({
          shop_now_items_total: clampedValue,
        });
      } else {
      this.setState({ [name]: value });
    }
  };

  updateTotalRefundAmount = ({
    shipping_cost_refund = this.state.shop_order_shipping_cost,
    return_fees = this.state.merchant_return_method_cost,
    policy_fees = this.state.policy_fees,
    credit_bonus = this.state.credit_bonus,
    shop_now_items_total = this.state.shop_now_items_total,
  }) => {
    let total_refund =
      parseFloat(this.state.refund_amount_without_shipping) +
      parseFloat(shipping_cost_refund || 0) -
      parseFloat(return_fees || 0) -
      parseFloat(policy_fees || 0) +
      parseFloat(credit_bonus || 0) -
      parseFloat(shop_now_items_total || 0);

    const refundExceedsAuthorized = this.paypal_authorized_amount > 0 && total_refund > this.paypal_authorized_amount;
    const amountToCapture = refundExceedsAuthorized ? 0.0 : Math.abs(total_refund);

    if(this.paypal_refund_amount > 0) {
      total_refund -= this.state.paypal_refund_amount
    }
    this.setState({
      amount_to_capture: amountToCapture,
      total_refund: this.clampedTotalRefund(total_refund),
    });
  };

  // this method should be kept aligned with the following BE changes:
  //
  // file: app/view_models/customer_returns/completion_view_model.rb
  // methods:
  //   1. def gift_card_refund_amount; end
  //   2. def non_gift_card_refund_amount; end
  //   3. def paypal_refund_amount; end
  updatePaymentGatewayAmounts = (total_refund) => {
    const gift_card_refund_amount = parseFloat(
      clamp(this.gift_card_max_refund, 0, total_refund).toFixed(2)
    );

    const non_gift_card_refund_amount = parseFloat(
      clamp(
        this.non_gift_card_max_refund,
        0,
        total_refund - gift_card_refund_amount
      ).toFixed(2)
    );

    const paypal_refund_amount = this.paypal_refund_amount

    this.setState({ gift_card_refund_amount, non_gift_card_refund_amount, paypal_refund_amount });
  };

  setGiftCardRefundAmount = (value) => {
    const clampedValue = parseFloat(
      clamp(value, 0, this.gift_card_max_refund).toFixed(2)
    );

    this.setState({ gift_card_refund_amount: clampedValue });
    this.updateTotalRefundAmountFromPaymentGateways({
      gift_card_refund_amount: clampedValue,
    });
  };

  setNonGiftCardRefundAmount = (value) => {
    const clampedValue = parseFloat(
      clamp(value, 0, this.non_gift_card_max_refund).toFixed(2)
    );

    this.setState({ non_gift_card_refund_amount: clampedValue });
    this.updateTotalRefundAmountFromPaymentGateways({
      non_gift_card_refund_amount: clampedValue,
    });
  };

  setPaypalRefundAmount = (value) => {
    const clampedValue = parseFloat(
      clamp(value, 0, this.paypal_max_refund_amount).toFixed(2)
    );

    this.setState({ paypal_refund_amount: clampedValue });
    this.updateTotalRefundAmountFromPaymentGateways({
      paypal_refund_amount: clampedValue,
    });
  };

  updateTotalRefundAmountFromPaymentGateways = ({
    gift_card_refund_amount = this.state.gift_card_refund_amount,
    non_gift_card_refund_amount = this.state.non_gift_card_refund_amount,
    paypal_refund_amount = this.state.paypal_refund_amount,
  }) => {
    const total_refund = gift_card_refund_amount + non_gift_card_refund_amount + paypal_refund_amount;
    this.setState({
      total_refund: parseFloat(
        clamp(total_refund, 0, this.max_refund_amount).toFixed(2)
      ),
    });
  };

  completeReturn = () => {
    const validateInput = this.validateInput();
    if (!validateInput) return;

    this.setState({ complete_btn_disable: true });

    const items = this.store_credit
      ? this.state.store_credit_items
      : this.state.refund_items;

    const body = {
      items: items
        .filter((item) => !item.is_cancelled)
        .map((item) => ({ ...item, restock: item.restock ? "1" : "0" })),
      exchange_items: this.state.exchange_items
        .filter((item) => !item.is_cancelled)
        .map((item) => ({ ...item, restock: item.restock ? "1" : "0" })),
      note: this.state.refund_notes,
      return_shipping_amount: this.state.merchant_return_method_cost.toString(),
      order_shipping_amount: this.state.shop_order_shipping_cost.toString(),
      policy_fees: this.state.policy_fees.toString(),
      credit_bonus: this.state.credit_bonus.toString(),
      gift_card_refund_amount: this.state.gift_card_refund_amount,
      non_gift_card_refund_amount: this.state.non_gift_card_refund_amount,
      shop_now_total_amount: this.state.shop_now_items_total,
      calculated_refund_amount: this.props.total_refund_amount,
      confirmed_refund_amount: this.state.total_refund,
      captured_amount: parseFloat(this.state.amount_to_capture),
      paypal_refund_amount: parseFloat(this.state.paypal_refund_amount || 0.0),
    };

    fetch(`/customer_returns/${this.state.customer_return.id}/refund`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        window.location.href = `/customer_returns/${this.state.customer_return.id}/edit`;
      } else {
        this.setState({ complete_btn_disable: false });

        response.json().then((responseJson) => {
          ErrorToast({
            message: responseJson.error,
            toastId: "shopifyReturnFailed",
          });
        });
      }
    });
  };

  includeToRefund = () => {
    fetch(
      `/customer_returns/${this.state.customer_return.id}/refund?include_refund=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const refund_amount_without_shipping =
            responseJson.refund_amount_without_shipping;

          const total_custom_refund_original_amount =
            this.props.custom_items.reduce((sum, item) => {
              if (
                !item.is_cancelled &&
                parseFloat(this.itemOriginalPrice(item))
              ) {
                return (
                  sum +
                  parseFloat(this.itemOriginalPrice(item)) *
                    parseFloat(item.quantity)
                );
              }

              return sum;
            }, 0);

          this.setState((prevState) => {
            const totalRefund =
              parseFloat(prevState.total_refund) +
              parseFloat(refund_amount_without_shipping) -
              parseFloat(prevState.refund_amount_without_shipping);

            let new_state = {
              include_refund: true,
              refund_amount_without_shipping,
              total_refund: this.clampedTotalRefund(totalRefund),
              total_original_amount: parseFloat(
                parseFloat(
                  prevState.total_original_amount +
                    parseFloat(total_custom_refund_original_amount)
                ).toFixed(2)
              ),
            };

            if (this.store_credit) {
              new_state = {
                ...new_state,
                store_credit_items: [
                  ...prevState.store_credit_items,
                  ...prevState.custom_items,
                ], // custom items should always be added to the end of the list
              };
            } else {
              new_state = {
                ...new_state,
                refund_items: [
                  ...prevState.refund_items,
                  ...prevState.custom_items,
                ], // custom items should always be added to the end of the list
              };
            }

            return new_state;
          });
        });
      } else {
        response.json().then((responseJson) => {
          console.log(
            responseJson.error || response.status + " " + response.statusText
          );
        });
      }
    });
  };

  validateInput = () => {
    const state_shop_order_shipping_cost = parseFloat(
      this.state.shop_order_shipping_cost
    );
    const state_merchant_return_method_cost =
      this.state.merchant_return_method_cost;
    const state_refund_amount_without_shipping = parseFloat(
      this.state.refund_amount_without_shipping
    );
    const state_refund_amount_with_order_shipping =
      state_refund_amount_without_shipping + state_shop_order_shipping_cost;

    if (
      (state_shop_order_shipping_cost > this.max_shipping_refund ||
        state_shop_order_shipping_cost < 0) &&
      this.state.refund_items.length
    ) {
      ErrorToast({
        message: `Shipping amount must be between 0-${this.max_shipping_refund}`,
        toastId: "shippingRefundError",
      });

      return false;
    } else if (
      (state_merchant_return_method_cost < 0 ||
        state_merchant_return_method_cost >
          state_refund_amount_with_order_shipping) &&
      this.state.refund_items.length
    ) {
      ErrorToast({
        message: `Return fee must be between 0-${
          state_refund_amount_without_shipping + state_shop_order_shipping_cost
        }`,
        toastId: "returnFeesError",
      });

      return false;
    }
    return true;
  };

  isEqualDecimalNumbers = (a, b) => {
    return Math.abs(parseFloat(a) - parseFloat(b)) < Number.EPSILON;
  };

  currencyPaddingRight = () => {
    switch (this.currency_symbol.length) {
      case 1:
        return "pl-5";
      case 2:
        return "pl-7";
      case 3:
        return "pl-10";
      default:
        return "pl-5";
    }
  };

  isShowRestockOption = (item) => {
    return !item.is_cancelled;
  };

  itemOriginalPrice = (item) => {
    return this.store_credit
      ? item.original_amount
      : item.displayed_original_price;
  };

  itemPrice = (item) => {
    return this.store_credit ? item.amount : item.displayed_price;
  };

  isShowCustomRefundViaOriginalPaymentMethod = () => {
    return (
      !this.props.is_xentral_enabled &&
      !this.store_credit &&
      this.max_refund_amount > 0
    );
  };

  clampedTotalRefund = (initialToTalRefund) => {
    let clampedTotalRefund;

    if (this.store_credit) {
      clampedTotalRefund = Math.max(initialToTalRefund, 0);
    } else {
      // refund via original payment method
      clampedTotalRefund = parseFloat(
        clamp(initialToTalRefund, 0, this.max_refund_amount).toFixed(2)
      );
      this.updatePaymentGatewayAmounts(clampedTotalRefund);
    }

    return clampedTotalRefund;
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />

        <div className="flex justify-between items-center px-8 pb-3">
          <div className="font-inter font-medium text-xs leading-4 text-gray-525">
            <p>
              <span className="text-gray-525">
                <a
                  href="/customer_returns"
                  className="focus:outline-none"
                  accessKey="E"
                >
                  {this.state.t.manage_return}
                </a>{" "}
                /{" "}
              </span>

              <span className="text-gray-725 text-xs13">
                {this.props.customer_return.order_name}
              </span>
            </p>
          </div>

          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>

              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>

        <div className="border-t border-b px-8 font-semibold text-base leading-6 text-gray-825 py-5 border-gray-225">
          {this.state.refund_items.length > 0 ||
          this.state.store_credit_items.length > 0
            ? this.state.t.refunded_items
            : this.state.custom_items.length > 0
            ? this.state.t.custom_items
            : this.state.exchange_items.length > 0
            ? this.state.t.exchanged_items
            : null}
        </div>

        <div className="flex">
          <div className="border-r w-67% min-h-screen">
            {this.props.refund_items.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  index={index}
                  t={this.state.t}
                  originalPrice={this.itemOriginalPrice(item)}
                  price={this.itemPrice(item)}
                  currency={this.currency}
                  isShowRestockOption={this.isShowRestockOption(item)}
                  restock={this.state.refund_items[index].restock}
                  restockCheckboxHandler={this.restockChckboxHandler}
                  isEqualDecimalNumbers={this.isEqualDecimalNumbers}
                />
              );
            })}

            {this.props.store_credit_items.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  index={index}
                  t={this.state.t}
                  originalPrice={this.itemOriginalPrice(item)}
                  price={this.itemPrice(item)}
                  currency={this.currency}
                  isShowRestockOption={this.isShowRestockOption(item)}
                  restock={this.state.store_credit_items[index].restock}
                  restockCheckboxHandler={this.restockChckboxHandler}
                  isEqualDecimalNumbers={this.isEqualDecimalNumbers}
                />
              );
            })}

            {this.props.custom_items.length > 0 && (
              <div>
                {(this.state.refund_items.length > 0 ||
                  this.state.store_credit_items.length > 0) &&
                  !this.state.include_refund && (
                    <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                      {this.state.t.custom_items}
                    </div>
                  )}

                {this.props.custom_items.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      item={item}
                      index={index}
                      t={this.state.t}
                      originalPrice={this.itemOriginalPrice(item)}
                      price={this.itemPrice(item)}
                      currency={this.currency}
                      restock={this.state.custom_items[index].restock}
                      restockCheckboxHandler={this.restockChckboxHandler}
                      isEqualDecimalNumbers={this.isEqualDecimalNumbers}
                    />
                  );
                })}

                {!this.state.include_refund &&
                  (this.state.refund_items.length > 0 ||
                    this.state.exchange_items.length > 0 ||
                    !this.is_instant_store_credit_item_present) && (
                    <div className="px-8 mt-5">
                      <button
                        className="w-full border bg-blue-675 rounded-lg text-white font-semibold text-xs leading-4 py-2 focus:outline-none"
                        onClick={this.includeToRefund}
                      >
                        {this.state.t.include_to_refund}
                      </button>
                    </div>
                  )}
              </div>
            )}

            {this.props.exchange_items.length > 0 && (
              <div>
                {(this.state.refund_items.length > 0 ||
                  this.state.custom_items.length > 0 ||
                  this.state.store_credit_items.length > 0) && (
                  <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                    {this.state.t.exchanged_items}
                  </div>
                )}

                {this.props.exchange_items.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      item={item}
                      index={index}
                      t={this.state.t}
                      originalPrice={this.itemOriginalPrice(item)}
                      price={this.itemPrice(item)}
                      currency={this.currency}
                      isShowRestockOption={this.isShowRestockOption(item)}
                      restock={this.state.exchange_items[index].restock}
                      restockCheckboxHandler={this.restockChckboxHandler}
                      isEqualDecimalNumbers={this.isEqualDecimalNumbers}
                    />
                  );
                })}
              </div>
            )}

            {this.props.show_refund_note_input && (
              <div className="mt-8 px-8 pr-6">
                <p className="font-semibold text-base leading-6 text-gray-825">
                  {this.state.t.refund_notes}
                </p>

                <TextArea
                  height="h-40px"
                  placeholder={this.state.t.refund_notes_placeholder}
                  name="refund_notes"
                  onChange={(value) =>
                    this.inputChangeHandler({
                      target: { name: "refund_notes", value },
                    })
                  }
                  value={this.state.refund_notes}
                />
              </div>
            )}
          </div>

          <div className="flex-grow pl-6 pt-8 pr-8">
            {(this.state.refund_items.length > 0 ||
              this.state.store_credit_items.length > 0 ||
              this.state.include_refund ||
              this.state.has_shop_exchange_items) && (
              <div>
                <div className="mb-5">
                  <p className="font-semibold text-base leading-6 text-gray-825">
                    {this.state.t.summary}
                  </p>
                </div>

                <div className="border-b pb-5">
                  {this.state.customer_return.discount_codes &&
                    this.state.customer_return.discount_codes
                      .split(",")
                      .map((code, index) => (
                        <div
                          className="w-full flex items-center mb-4"
                          key={index}
                        >
                          <p className="w-1/2 text-xs13 leading-4 text-gray-525">
                            {this.state.t.discount_code}
                          </p>

                          <div className="w-1/2 flex items-center">
                            <p className="font-medium text-xs13 leading-4 text-gray-825 mr-3">
                              {code}
                            </p>
                          </div>
                        </div>
                      ))}

                  <div className="w-full flex items-center mb-4">
                    <p className="w-1/2 text-xs13 leading-4 text-gray-525">
                      {this.state.t.total}
                    </p>

                    <div className="w-1/2 flex items-center">
                      <p className="font-medium text-xs13 leading-4 text-gray-825 mr-3">
                        {new Intl.NumberFormat("en", {
                          style: "currency",
                          currency: this.currency,
                        }).format(
                          parseFloat(this.state.refund_amount_without_shipping)
                        )}
                      </p>

                      {parseFloat(this.state.total_original_amount) > 0 &&
                        !this.isEqualDecimalNumbers(
                          this.state.total_original_amount,
                          this.state.refund_amount_without_shipping
                        ) && (
                          <p className="font-medium text-xs13 leading-4 text-gray-825 line-through">
                            {new Intl.NumberFormat("en", {
                              style: "currency",
                              currency: this.currency,
                            }).format(this.state.total_original_amount)}
                          </p>
                        )}
                    </div>
                  </div>

                  {/* New Shop now items */}
                  {this.props.shop_now_items_total > 0 && (
                    <div className="w-full flex items-center mb-4">
                      <div className="w-1/2 flex items-center justify-between pr-1">
                        <p className="text-xs13 leading-4 text-gray-525">
                          {this.state.t.shop_now_items_total}
                        </p>

                        <p className="font-medium text-xs13 leading-4 text-gray-625">
                          -
                        </p>
                      </div>

                      <div className="relative w-1/2 flex items-center">
                        <p className="font-medium text-xs13 leading-4 text-gray-825 mr-3">
                          {new Intl.NumberFormat("en", {
                            style: "currency",
                            currency: this.currency,
                          }).format(
                            parseFloat(this.state.shop_now_items_total)
                          )}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Return Fees */}

                  <div className="w-full flex items-center mb-4">
                    <div className="w-1/2 flex items-center justify-between pr-1">
                      <p className="text-xs13 leading-4 text-gray-525">
                        {this.state.t.return_fees}
                      </p>

                      <p className="font-medium text-xs13 leading-4 text-gray-625">
                        -
                      </p>
                    </div>

                    <div className="relative w-1/2 flex items-center">
                      <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">
                        {this.currency_symbol}
                      </p>

                      <input
                        type="number"
                        className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                        min="0.0"
                        max={this.maxReturnFees()}
                        step="0.01"
                        name="merchant_return_method_cost"
                        onChange={this.inputChangeHandler}
                        value={this.state.merchant_return_method_cost + ""}
                      />
                    </div>
                  </div>

                  {this.store_credit && (
                    <div className="w-full flex items-center mb-4">
                      <div className="w-1/2 flex items-center justify-between pr-1">
                        <p className="text-xs13 leading-4 text-gray-525">
                          {this.state.t.credit_bonus}
                        </p>

                        <p className="font-medium text-xs13 leading-4 text-gray-625">
                          +
                        </p>
                      </div>

                      <div className="relative w-1/2 flex items-center">
                        <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">
                          {this.currency_symbol}
                        </p>

                        <input
                          type="number"
                          className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                          name="credit_bonus"
                          max={this.credit_bonus}
                          step="0.01"
                          min="0.0"
                          onChange={this.inputChangeHandler}
                          value={this.state.credit_bonus + ""}
                        />
                      </div>
                    </div>
                  )}

                  {/* Policy Fees */}

                  {this.props.partial_refund_enabled && (
                    <div className="w-full flex items-center mb-4">
                      <div className="w-1/2 flex items-center justify-between pr-1">
                        <p className="text-xs13 leading-4 text-gray-525">
                          {this.state.t.policy_fees}
                        </p>

                        <p className="font-medium text-xs13 leading-4 text-gray-625">
                          -
                        </p>
                      </div>

                      <div className="relative w-1/2 flex items-center">
                        <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">
                          {this.currency_symbol}
                        </p>
                        <input
                          type="number"
                          className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                          min="0.0"
                          max={this.maxPolicyFees()}
                          step="0.01"
                          name="policy_fees"
                          onChange={this.inputChangeHandler}
                          value={this.state.policy_fees + ""}
                        />
                      </div>
                    </div>
                  )}

                  {this.state.shop_order_shipping_cost > 0 && (
                    <div className="w-full flex items-center">
                      <div className="w-1/2 flex items-center justify-between pr-1">
                        <p className="text-xs13 leading-4 text-gray-525">
                          {this.state.t.shipping_refund}
                        </p>
                        <p className="font-medium text-xs13 leading-4 text-gray-625">
                          +
                        </p>
                      </div>
                      <div className="relative w-1/2 flex items-center">
                        <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">
                          {this.currency_symbol}
                        </p>
                        <input
                          type="number"
                          className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                          min="0.0"
                          max={this.max_shipping_refund}
                          step="0.01"
                          name="shop_order_shipping_cost"
                          onChange={this.inputChangeHandler}
                          value={this.state.shop_order_shipping_cost + ""}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {this.isShowCustomRefundViaOriginalPaymentMethod() ? (
                  <CustomRefundViaOriginalPaymentMethod
                    giftCardMaxRefund={this.gift_card_max_refund}
                    giftCardRefundAmount={this.state.gift_card_refund_amount}
                    nonGiftCardGatewayName={this.non_gift_card_gateway_name}
                    nonGiftCardMaxRefund={this.non_gift_card_max_refund}
                    nonGiftCardRefundAmount={
                      this.state.non_gift_card_refund_amount
                    }
                    currency={this.currency}
                    currencySymbol={this.currency_symbol}
                    setGiftCardRefundAmount={this.setGiftCardRefundAmount}
                    setNonGiftCardRefundAmount={this.setNonGiftCardRefundAmount}
                    t={this.state.t}
                    paypalMaxRefund={this.paypal_max_refund_amount}
                    payPalRefundAmount={this.state.paypal_refund_amount}
                    setPaypalRefundAmount={this.setPaypalRefundAmount}
                  />
                ) : (
                  <div className="w-full flex items-center border-b py-5">
                    <p className="w-1/2 text-xs13 leading-4 text-gray-525">
                      {this.state.t.total_refund}
                    </p>

                    <p className="font-clash_grotesk font-semibold text-lg leading-6 text-gray-825">
                      {new Intl.NumberFormat("en", {
                        style: "currency",
                        currency: this.currency,
                      }).format(this.state.total_refund)}
                    </p>
                  </div>
                )}

                {this.paypal_authorized_amount > 0 && <div className="mb-5">
                  <Typography
                    element="p"
                    weight="normal"
                    size="xs13"
                    height="4"
                    color="gray-525"
                    className="mt-3 mb-3"
                  >
                    Amount to capture:
                    {" "}<span className="font-medium text-xs13 leading-4 text-gray-825 ml-3">
                      {new Intl.NumberFormat("en", {
                        style: "currency",
                        currency: this.currency,
                      }).format(this.state.amount_to_capture)}
                    </span>
                  </Typography>
                </div>}

                {this.paypal_authorized_amount > 0 && <div className="border-b mb-5">
                  <Typography
                    element="p"
                    weight="normal"
                    size="xs13"
                    height="4"
                    color="gray-525"
                    className="mt-3 mb-3"
                  >
                    <span className="font-semibold">
                      {new Intl.NumberFormat("en", {
                        style: "currency",
                        currency: this.currency,
                      }).format(this.paypal_authorized_amount)}
                    </span>{" "}
                    was authorised
                  </Typography>
                </div> }
                <div className="border-b py-5">
                  <div className="w-full flex items-center">
                    <p className="w-1/2 text-xs13 leading-4 text-gray-525">
                      {this.state.t.refund_method}
                    </p>
                    <p className="w-1/2 font-medium leading-4 text-xs13 text-gray-825">
                      {this.store_credit
                        ? this.is_instant_store_credit_item_present
                          ? this.state.t.instant_store_credit
                          : this.state.t.store_credit
                        : this.state.t.original_payment_method}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-5">
              <button
                className={`w-full border rounded-lg font-semibold text-xs leading-4 py-2 focus:outline-none bg-blue-675 text-white hover:bg-blue-625 focus:shadow-btn_blue ${
                  this.state.complete_btn_disable ? "cursor-default" : " "
                }`}
                disabled={this.state.complete_btn_disable}
                onClick={this.completeReturn}
              >
                {!this.state.complete_btn_disable && (
                  <span>{this.state.t.complete}</span>
                )}

                {this.state.complete_btn_disable && (
                  <div className=" flex justify-center items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Shopify;
