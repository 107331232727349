import React from "react";

export const UncompletedLineIcon = () => {
  return (
    <svg
      width={62}
      height={2}
      viewBox="0 0 62 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1={1} x2={62} y2={1} stroke="#D4D4D8" strokeDasharray="4 4" />
    </svg>
  );
};
