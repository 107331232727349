import React from "react"

export const ShopwareLogoIcon = () => {
  return (
    <svg version="1.2" width="160" height="32" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="154 0 566.93 165.09" xmlSpace="preserve">
      <g id="XMLID_462_">
        <g id="XMLID_564_">
          <path id="XMLID_565_" fill="#189EFF" d="M177.68,83.05c-4.68-2.03-8.53-3.87-8.53-6.92c0-2.14,2.42-4.17,6.5-4.17
            c3.66,0,6.82,0.92,10.07,2.34c0.92,0.41,2.34,0.51,3.05-0.92l1.63-3.26c0.61-1.22,0.1-2.65-1.02-3.46
            c-2.34-1.53-7.22-3.46-13.74-3.46c-11.4,0-16.06,6.92-16.06,12.82c0,7.73,5.99,11.6,13.21,14.86c6.51,2.95,10.07,4.98,10.07,8.14
            c0,2.65-2.24,4.58-6.21,4.58c-5.6,0-8.95-1.93-11.7-3.46c-1.22-0.71-2.54-0.41-3.05,0.61l-2.14,4.38
            c-0.51,1.02,0.1,1.93,0.81,2.44c2.65,1.83,8.04,4.78,15.47,4.78c10.48,0,16.79-6.41,16.79-13.74
            C192.84,89.87,186.33,86.72,177.68,83.05z"/>
        </g>
        <g id="XMLID_561_">
          <path id="XMLID_563_" fill="#189EFF" d="M115.28,125.52c-4.78-3.6-11.83-6.34-19.29-9.23c-8.88-3.44-18.95-7.35-26.51-13.36
            c-8.56-6.82-12.73-15.42-12.73-26.29c0-9.76,4.05-18.1,11.71-24.13c8.59-6.76,21.61-10.34,37.64-10.34
            c4.43,0,8.66,0.27,12.58,0.81c0.35,0.05,0.68-0.14,0.82-0.45c0.15-0.32,0.07-0.69-0.19-0.92c-10.1-9.09-23.16-14.09-36.76-14.09
            c-14.7,0-28.52,5.72-38.92,16.12C33.24,54.03,27.51,67.85,27.51,82.54c0,14.7,5.72,28.52,16.12,38.91
            c10.39,10.39,24.21,16.12,38.92,16.12c11.88,0,23.19-3.73,32.72-10.78c0.2-0.15,0.32-0.38,0.32-0.63
            C115.59,125.91,115.48,125.67,115.28,125.52z"/>
          <path id="XMLID_562_" fill="#189EFF" d="M137.34,77.46c-0.02-0.21-0.12-0.41-0.28-0.54C125.58,67.39,116.29,63.5,105,63.5
            c-6.02,0-10.64,1.21-13.72,3.59c-2.67,2.06-4.08,4.94-4.08,8.31c0,9.45,11.55,13.76,24.93,18.75c6.89,2.57,14.02,5.23,20.56,8.86
            c0.12,0.07,0.25,0.1,0.38,0.1c0.09,0,0.18-0.02,0.27-0.05c0.21-0.08,0.39-0.24,0.47-0.46c2.5-6.4,3.77-13.15,3.77-20.06
            C137.57,80.92,137.5,79.21,137.34,77.46z"/>
        </g>
        <path id="XMLID_558_" fill="#189EFF" d="M453.63,111.78c1.41-0.16,2.38-1.19,2.38-2.47V85.44c0-12.21-1.49-21.67-17.21-21.67
          c-9.03,0-16.04,2.44-16.89,2.76c-1.17,0.42-1.91,1.17-1.59,2.87l0.96,4.04c0.32,1.38,1.27,2.34,2.76,2.02
          c2.55-0.74,8.07-2.34,13.7-2.34c6.16,0,8.07,3.51,7.86,11.26c0,0-4.78-1.49-9.88-1.49c-12.64,0-19.65,6.8-19.65,15.83
          c0,10.2,6.47,13.65,15.71,13.65c7.54,0,15.3-0.09,18.53-0.3L453.63,111.78z M445.49,103.12c-1.49,2.44-6.8,1.83-11.36,1.74
          c-5.1-0.11-7.65-1.89-7.65-6.25c0-5.1,4.35-8.07,10.09-8.07c4.46,0,8.29,1.28,8.92,1.7V103.12z"/>
        <path id="XMLID_555_" fill="#189EFF" d="M533.83,63.21c3.09,0,5.59-2.49,5.59-5.57c0-3.08-2.5-5.57-5.59-5.57
          c-3.09,0-5.59,2.49-5.59,5.57C528.24,60.72,530.74,63.21,533.83,63.21z M533.83,52.89c2.59,0,4.71,2.14,4.71,4.75
          c0,2.61-2.11,4.75-4.71,4.75c-2.59,0-4.7-2.14-4.7-4.75C529.13,55.03,531.24,52.89,533.83,52.89z"/>
        <path id="XMLID_552_" fill="#189EFF" d="M531.93,60.46h0.52c0.08,0,0.15-0.07,0.15-0.15v-2.05h1.22l1.05,2.1
          c0.03,0.07,0.08,0.1,0.17,0.1h0.6c0.14,0,0.19-0.13,0.14-0.23l-1.1-2.05c0.7-0.28,1.19-0.89,1.19-1.63c0-0.96-0.79-1.72-1.75-1.72
          h-2.19c-0.09,0-0.15,0.07-0.15,0.15v5.33C531.78,60.39,531.84,60.46,531.93,60.46z M532.62,55.61h1.43c0.52,0,0.98,0.43,0.98,0.96
          c0,0.56-0.46,1-0.98,1h-1.43V55.61z"/>
        <path id="XMLID_551_" fill="#189EFF" d="M224.1,63.21c-7.43,0-12.52,4.07-13.63,4.89V47.11c0-1.12-1.12-2.24-2.24-2.24h-5.7
          c-1.22,0-2.24,1.12-2.24,2.24v63.01c0,1.22,1.02,2.24,2.24,2.24h5.6c1.43,0,2.34-0.92,2.34-2.24V79.7
          c1.02-2.03,5.09-6.82,12.31-6.82c6.11,0,9.36,4.58,9.36,12.31v24.94c0,1.22,1.02,2.24,2.24,2.24h5.7c1.12,0,2.24-1.02,2.24-2.24
          V85.4C242.31,72.58,237.43,63.21,224.1,63.21z"/>
        <path id="XMLID_548_" fill="#189EFF" d="M343.29,85.89c-0.56-15.97-7.25-22.68-22.66-22.68c-7.4,0-16.23,2.22-17.53,2.79
          c-1.6,0.69-2.78,1.51-2.78,2.63v0.93v3.41v11.04v1.5v42.95c0,1.22,1.02,2.24,2.24,2.24h4.72c1.53,0,2.44-0.92,2.44-2.24
          l-0.01-16.91c1.43,0.37,8.74,0.99,14.84,0.8c10.88-0.34,18.18-8.24,18.79-21.58c0.08-1.82,0.07-4.34-0.05-5.98L343.29,85.89z
          M333.46,90.76c-0.21,9.05-2.84,13.05-10.46,13.47c-3.77,0.21-12.67-0.84-12.67-0.84c-0.37-0.12-0.62-0.28-0.62-0.62l-0.01-18.34
          v-10.4c0-0.28,0.21-0.51,0.49-0.54c1.05-0.11,4.9-1.35,10.44-1.35c9.28,0,12.55,1.36,12.85,13.11
          C333.5,86.8,333.5,88.97,333.46,90.76z"/>
        <path id="XMLID_525_" fill="#189EFF" d="M410.68,64.23h-4.27c-1.22,0-2.95,0-3.56,1.43l-7.53,27.06h-0.2L382.5,64.33
          c-0.2-0.61-0.92-1.12-2.03-1.12h-0.71c-0.92,0-1.63,0.51-1.93,1.12L365.1,92.62h-0.2l-7.73-26.96c-0.2-0.92-1.22-1.43-2.03-1.43
          h-6.11c-1.63,0-2.34,1.22-1.63,2.65l13.74,44.26c0.2,0.71,0.92,1.22,1.93,1.22h0.61c1.02,0,1.63-0.51,1.83-1.12l14.45-31.95h0.2
          l14.35,31.95c0.31,0.61,1.02,1.12,2.03,1.12h0.41c1.02,0,1.93-0.51,2.14-1.22l13.43-44.26C413.23,65.45,412.31,64.23,410.68,64.23z
          "/>
        <path id="XMLID_482_" fill="#189EFF" d="M485.74,63.77c-0.25,0-0.48,0.02-0.73,0.03c-0.41-0.01-0.81-0.03-1.24-0.03
          c-2.44,0-4.78,0.08-6.97,0.19v0c-4.22,0.17-6.72,0.49-8.5,0.69c0,0-1.14,0.11-2.47,0.28c-0.89,0.11-1.54,0.48-1.94,0.99
          c-0.27,0.29-0.44,0.66-0.44,1.11v0.16v0.93v3.41v9.46v29.12c0,1.22,1.02,2.24,2.24,2.24h4.68c1.93,0,3.26-0.2,3.26-2.24V80.99h0
          v-6.68c0-0.28,0.44-0.63,0.68-0.78c1.15-0.69,3.91-1.19,9.46-1.19c0.54,0,0.89,0.01,1.29,0.02c1.27,0.12,3.28,0.51,3.93,0.88
          c1.02,0.51,2.24,0.2,2.85-1.12l2.44-3.99C495.81,64.99,490.22,63.77,485.74,63.77z"/>
        <path id="XMLID_470_" fill="#189EFF" d="M517.95,63.21c-14.14,0-23.4,11.3-23.4,24.52c0,13.43,9.26,24.62,23.5,24.62
          c7.83,0,14.04-2.85,17.4-5.8c1.42-1.22,1.02-2.14,0.2-3.15l-2.75-4.27c-0.81-1.22-1.73-0.92-2.75-0.2
          c-2.85,2.03-6.11,3.97-11.5,3.97c-8.24,0-13.94-6.51-14.04-12.82h32.46c1.02,0,2.03-0.82,2.14-2.04c0.1-0.81,0.2-2.44,0.2-3.26
          C539.41,72.27,530.16,63.21,517.95,63.21z M505.13,82.54c0.71-5.9,5.19-10.68,12.62-10.68c6.92,0,11.4,4.99,11.6,10.68H505.13z"/>
        <path id="XMLID_463_" fill="#189EFF" d="M292.15,77.75c-1.06-3.03-2.55-5.63-4.46-7.79c-1.91-2.16-4.2-3.83-6.85-5
          c-2.65-1.17-6.41-1.76-9.53-1.76c-3.12,0-6.87,0.59-9.52,1.76c-2.65,1.17-4.93,2.84-6.85,5c-1.91,2.16-3.4,4.76-4.46,7.79
          c-1.06,3.03-1.59,6.38-1.59,10.04c0,3.72,0.53,7.08,1.59,10.08c1.06,3,2.55,5.58,4.46,7.74c1.91,2.16,4.2,3.83,6.85,5
          c2.65,1.17,6.4,1.75,9.52,1.75c3.12,0,6.88-0.58,9.53-1.75c2.65-1.17,4.93-2.84,6.85-5c1.91-2.16,3.4-4.74,4.46-7.74
          c1.06-3,1.59-6.36,1.59-10.08C293.74,84.13,293.21,80.78,292.15,77.75z M271.05,104.26c-8.49,0-12.77-7.19-12.77-16.06
          s4.27-16.06,12.77-16.06s12.77,7.19,12.77,16.06S279.54,104.26,271.05,104.26z"/>
      </g>
    </svg>
  )
}
