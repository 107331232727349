import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  updateStoreCreditSetting,
  createStoreCreditSetting,
  getPaymentIntegration,
} from "../services/api";

import { ErrorToast } from "../common/ErrorToast";
import { SuccessToast } from "../common/SuccessToast";

import { loadTranslations } from "../../translations/loadTranslations";
import translationEN from "../../translations/storeCreditSettings/translationEN.json";
import translationDE from "../../translations/storeCreditSettings/translationDE.json";

export const useStoreCreditSettings = ({
  current_user,
  store_credit_settings,
  payment_integration_route,
}) => {
  const {
    id = "",
    gift_card_product_id: giftCardProductId = "",
    added_percentage: addedPercentage = "0.0",
    enabled = false,
    instant_enabled = false,
  } = store_credit_settings || {};

  function getInitialState() {
    return {
      isFreeTrialStarted: !!store_credit_settings,
      storeCreditSettings: {
        id,
        giftCardProductId,
        addedPercentage,
        enabled,
        instant_enabled,
      },
    };
  }

  const [state, setState] = useState(getInitialState());

  loadTranslations(current_user.locale, translationEN, translationDE)
  const { t } = useTranslation()

  function disableInstantStoreCreditWithDelayedStoreCredit(name, value) {
    if (name === "enabled" && !value) {
      setState((prevState) => ({
        ...prevState,
        storeCreditSettings: {
          ...prevState.storeCreditSettings,
          "instant_enabled": value,
        },
      }));
    }
  }

  function enableDelayedStoreCreditWithInstantStoreCredit(name, value) {
    if (name === "instant_enabled" && value) {
      setState((prevState) => ({
        ...prevState,
        storeCreditSettings: {
          ...prevState.storeCreditSettings,
          "enabled": value,
        },
      }));
    }
  }

  function handleStoreCreditChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      storeCreditSettings: {
        ...prevState.storeCreditSettings,
        [name]: value,
      },
    }));

    disableInstantStoreCreditWithDelayedStoreCredit(name, value);
    enableDelayedStoreCreditWithInstantStoreCredit(name, value);
  }

  const responseBody = {
    store_credit_settings: {
      gift_card_product_id: state.storeCreditSettings.giftCardProductId,
      added_percentage: state.storeCreditSettings.addedPercentage,
      enabled: state.storeCreditSettings.enabled,
      id: state.storeCreditSettings.id,
      instant_enabled: state.storeCreditSettings.instant_enabled,
    },
  };

  async function startFreeTrial() {
    const response = await createStoreCreditSetting(responseBody);
    const responseJson = await response.json()
    if (response.status === 200) {
      SuccessToast({
        toastId: "storeCreditSuccess",
      });

      setState(prevState => ({
        ...prevState,
        storeCreditSettings: {
          ...prevState.storeCreditSettings,
          id: responseJson.id
        },
        isFreeTrialStarted: true,
      }));
    } else {

      ErrorToast({
        message: responseJson.error,
        toastId: "storeCreditError",
      });
    }
  }

  async function saveStoreCredit() {
    if (state.storeCreditSettings.instant_enabled) {
      const paymentResponse = await getPaymentIntegration();
      if (paymentResponse.status === 200) {
        await updateStoreCredit()
      } else {
        ErrorToast({
          innerHtmlError: `<p class="text-sm leading-5 text-gray-875">${t("set_payment_integration", { payment_integration_path: payment_integration_route })}</p>`,
          toastId: "storeCreditError",
        });
      }
    } else {
      await updateStoreCredit()
    }
  }

  async function updateStoreCredit() {
    const response = await updateStoreCreditSetting(responseBody, state.storeCreditSettings.id);

    if (response.status === 200) {
      SuccessToast({
        toastId: "storeCreditSuccess",
      });
    } else {
      const responseJson = await response.json()
      ErrorToast({
        message: responseJson.error,
        toastId: "storeCreditError",
      });
    }
  }

  return {
    state,
    t,
    handleStoreCreditChange,
    saveStoreCredit,
    startFreeTrial,
  };
};
