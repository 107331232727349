import React from "react";

import Checkbox from "../../../../ui-kit/components/Checkbox";

const ReturnSource = ({ t, returnReason, handleChange }) => {
  const { visible_on_returns_portal, visible_on_dashboard } = returnReason;

  return (
    <div className="mb-5">
      <p className="font-medium text-xs leading-4 text-gray-725 mb-4">
        {t.return_source}
      </p>

      <div className="flex mb-4">
        <div className="pl-3">
          <Checkbox
            name="visible_on_returns_portal"
            value={visible_on_returns_portal}
            label={{
              text: t.visible_on_returns_portal,
              className: "pl-2 font-normal text-xs leading-4 text-gray-725",
            }}
            handleChange={handleChange}
          />
        </div>

        <div className="ml-6">
          <Checkbox
            name="visible_on_dashboard"
            value={visible_on_dashboard}
            label={{
              text: t.visible_on_dashboard,
              className: "pl-2 font-normal text-xs leading-4 text-gray-725",
            }}
            handleChange={handleChange}
          />
        </div>
      </div>

      <p className="font-normal text-xs text-gray-725 leading-4">
        {t.return_source_description}
      </p>
    </div>
  );
};

export default ReturnSource;
