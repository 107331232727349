export const transformTagifyJSONToCommaSeparatedString = (values: string = "[]") => {
  const tags = values || "[]";
  return JSON.parse(tags).map(({ value }) => value).join(',');
}

export const transformCommaSeparatedStringToTagifyJSON = (tags: string = "") => {
  return JSON.stringify(
    tags.split(',').filter(Boolean).map((value) => ({ value }))
  );
}
