import React from "react";
import cx from "classnames";

export const TextArea = (props) => {
  const {
    className = "border box-border rounded font-normal text-sm leading-4 text-gray-525 placeholder-gray-525 mt-4 px-3 py-3 focus:outline-none w-full focus:border focus:border-blue-675",
    height,
    placeholder,
    name,
    onChange,
    value,
  } = props;

  return (
    <textarea
      className={cx(className, height)}
      placeholder={placeholder}
      name={name}
      onChange={event => 
        onChange(event.target.value)
      }
      value={value}
    />
  );
}
