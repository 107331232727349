import React from "react";
import Modal from "react-modal";
import ChecklistItem from "./components/checkListItem";
import SubHeader from "../common/SubHeader/SubHeader";
import Header from "../header";
Modal.setAppElement("#root");

const Onboarding = (props) => {
  const customInviteModalClick = () => {
    const modalElement = document.querySelector(".modal-open-invite");
    if (modalElement) {
      modalElement.click();
    }
  };
  return (
    <div>
      <Header
        current_user={props.current_user}
        title={"Onboarding Assistant"}
      />
      <SubHeader text={"Onboarding"} />
      <div className="pb-2 mt-2 gap-12 w-full pl-8">
        <div className="flex items-center gap-2 flex-1">
          <p className="font-medium text-lg text-gray-900">
            {props.onboarding.title}
          </p>
        </div>
        <div className="flex items-center gap-16 flex-1 pb-1">
          <p className="font-normal text-xs14 text-gray-625">
            {props.onboarding.description}
          </p>
        </div>
        <ul>
          {props.onboarding.check_list.map((item, index) => (
            <ChecklistItem
              key={index}
              linkText={item.linkText}
              description={item.description}
              link={item.link}
              tooltipLink={item.tooltipLink}
              customClick={
                (item.id == "invite_teammates" && customInviteModalClick) ||
                undefined
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Onboarding;
