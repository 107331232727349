import React from "react";

import { useInstantStoreCredit } from "./InstantStoreCredit.hook";

export const InstantStoreCredit = (props) => {
  const { inputNames } = useInstantStoreCredit();

  return (
    <div className="mt-4 pb-4 border-b w-762px">
      <p className="font-semibold text-base leading-6 text-gray-975">
        {props.t.instant_store_credit}
      </p>

      <p className="text-xs font-medium leading-4 text-gray-725 mt-1">
        {props.tI18next("instant_store_credit_section_title_note")}
      </p>

      {inputNames.map((inputName, index) => (
        <div key={index} className="mt-4">
          <input
            placeholder={props.t[inputName]}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name={inputName}
            onChange={props.onChange}
            value={props.textBlock[inputName]}
          />
        </div>
      ))}
    </div>
  );
};
