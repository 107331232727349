import React, { useState } from "react";
import { connectWithXentral, setXentralStatus } from "../../../services/api";
import translationEN from "../../../../translations/integrations/translationEN.json";
import translationDE from "../../../../translations/integrations/translationDE.json";
import { SuccessToast } from "../../../common/SuccessToast/index";
import { ErrorToast } from "../../../common/ErrorToast";

export const useXentral = ({ currentUser, xentralIntegration }) => {
  const {
    id = "",
    username = "",
    base_url: baseUrl = "",
    shipping_and_fees_product_id: shippingAndFeesProductId = "",
    password = "",
    create_return: createReturn = false,
    create_confirmed_credit_note: createConfirmedCreditNote = false,
    discount_product_id: discountProductId = "",
    active,
  } = xentralIntegration || {};

  function getInitialState() {
    return {
      integration: {
        id,
        username,
        baseUrl,
        shippingAndFeesProductId,
        password,
        createReturn,
        createConfirmedCreditNote,
        discountProductId,
        active,
      },
      xentralConnected: {
        isConnected: Boolean(password),
      },
      disableButton: false,
    };
  }

  const t = currentUser.locale === "en" ? translationEN : translationDE;
  const [state, setState] = useState(getInitialState());

  function handleInputChange(e) {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      integration: {
        ...prevState.integration,
        [e.target.name]: e.target.value,
      },
    }));
  }

  function handleCheckBoxChange(e) {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      integration: {
        ...prevState.integration,
        [e.target.name]: e.target.checked,
      },
    }));
  }

  async function connect() {
    setState((prevState) => ({
      ...prevState,
      disableButton: true,
    }));

    const response = await connectWithXentral(state.integration);

    if (response.status === 200) {
      const {
        id,
        username,
        base_url: baseUrl,
        shipping_and_fees_product_id: shippingAndFeesProductId,
        password,
        create_return: createReturn,
        create_confirmed_credit_note: createConfirmedCreditNote,
        discountProductId,
        active,
      } = (await response.json()).xentral_integration;

      setState((prevState) => ({
        ...prevState,
        integration: {
          id,
          username,
          baseUrl,
          shippingAndFeesProductId,
          password,
          createReturn,
          createConfirmedCreditNote,
          discountProductId,
          active,
        },
        disableButton: false,
        xentralConnected: {
          isConnected: Boolean(password),
        },
      }));

      SuccessToast({
        toastId: "xentralIntegration",
      });
    } else {
      const responseJson = await response.json();

      setState((prevState) => ({ ...prevState, disableButton: false }));

      ErrorToast({
        toastId: "xentralIntegration",
        message: responseJson.error,
      });
    }
  }

  async function handleXentralActiveCheckBoxChange({ name, value }) {
    const body = {
      is_active: value,
    };

    const response = await setXentralStatus(body);

    if (response.status === 200) {
      setState((prevState) => ({
        ...prevState,
        integration: {
          ...prevState.integration,
          [name]: value,
        },
      }));

      SuccessToast({
        toastId: "xentralIntegration",
      });
    } else {
      response.json().then((responseJson) => {
        ErrorToast({
          toastId: "xentralIntegration",
          message: responseJson.error,
        });
      });
    }
  }
  return {
    state,
    t,
    handleInputChange,
    handleCheckBoxChange,
    handleXentralActiveCheckBoxChange,
    connect,
  };
};
