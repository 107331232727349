import React from "react";

class Notification extends React.Component {

  undoDeletedUser = () => {
    fetch(`/manage_users/${this.props.selected_user.id}/undo_deleted_user`, {
      method: 'Put',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    }).then(response => {
      if(response.status == 200) {
        this.props.undoDeletedUser(this.props.selected_user)
      }
    })
  }

  undoUpdatedUserRole = () => {
    const body = {
      role: this.props.selected_user.role
    }   
    fetch(`/manage_users/${this.props.selected_user.id}/undo_updated_role`, {
      method: 'Put',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify(body),
    }).then(response => {
      if(response.status == 200) {
        this.props.undoUpdatedUserRole(this.props.selected_user.id ,this.props.selected_user)
      }
    })
  }

  render() {
    return (
      <div className="font-inter shadow-black_07 rounded-xl border border-gray-325 py-3 px-3 flex mx-auto bg-white">
        {
          this.props.success_message &&

          <div className='flex'>
            <svg width={20} height={20} className="mr-2 min-w-20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width={20} height={20} rx={10} fill="#27AE60" />
              <path d="M6.875 10.4332L8.16998 12.2069C8.57586 12.7628 9.40894 12.752 9.80028 12.1858L13.125 7.375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            { this.props.role_updated &&
              <p className="text-sm leading-5 text-gray-875">User <b>"{this.props.selected_user.first_name} {this.props.selected_user.last_name}"</b> changes to {this.props.new_role}. <button className='not-italic font-bold text-sm leading-5 focus:outline-none text-blue-700' onClick={this.undoUpdatedUserRole}> Undo</button></p>
            }
            {
              this.props.deleted &&
              <p className="text-sm leading-5 text-gray-875">User <b>"{this.props.selected_user.first_name} {this.props.selected_user.last_name}"</b> has been deleted. <button className='not-italic font-bold text-sm leading-5 focus:outline-none text-blue-700' onClick={this.undoDeletedUser}> Undo</button></p>
            }
          </div>
        }
        {
          this.props.error_message &&
          <div className="flex">
            <svg width={20} height={20} className="mr-2 min-w-20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width={20} height={20} rx={10} fill="#EB5757" />
              <path d="M6.4765 12.1773L9.10801 6.92875C9.47697 6.19287 10.5274 6.19306 10.896 6.92908L13.5251 12.1776C13.8582 12.8425 13.3747 13.6255 12.631 13.6255H7.37043C6.62657 13.6255 6.1431 12.8422 6.4765 12.1773Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 9V10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.25 12C10.25 12.1381 10.1381 12.25 10 12.25C9.86193 12.25 9.75 12.1381 9.75 12C9.75 11.8619 9.86193 11.75 10 11.75C10.1381 11.75 10.25 11.8619 10.25 12Z" stroke="white" />
            </svg>
            <p className="text-sm leading-5 text-gray-875">{this.props.error_message}</p>
          </div>
        }
      </div>
    )
  }
}

export default Notification
