import React from "react";

import Modal from "react-modal";

import { Typography } from "../../../../ui-kit/components/Typography/Typography";

import { useReturnMethod } from "./AddReturnMethod.hook";

const AddReturnMethod = (props) => {
  const {
    showAddReturnMethodModal,
    closeAddReturnMethodModal,
    returnMethodTemplates,
    returnIntegrationMethods,
    lang,
    cancelButton
  } = props;
  const {
    state,
    t,
    handleInputChange,
    closeModal,
    submitForm,
    handleTemplateChange
  } = useReturnMethod({showAddReturnMethodModal, closeAddReturnMethodModal, returnIntegrationMethods, returnMethodTemplates, lang});

  const { integrationOptions, templateOptions } = state;

  return (
    <Modal
      isOpen={state.isModalOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          maxWidth: "439px",
          maxHeight: "340px",
          margin: "auto",
          padding: "32px",
        },
      }}
    >
      <form
        className="font-intel"
        onSubmit={(event) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <div className="flex items-center mb-8">
          <Typography
            element="p"
            weight="semibold"
            size="base"
            height="6"
            color="gray-975"
          >
            {t.add_return_method}
          </Typography>
        </div>
        <div className="w">
          <Typography
            element="p"
            weight="medium"
            size="xs"
            height="4"
            color="gray-725"
            className=" mt-6"
          >
            {t.select_carrier}
          </Typography>
          <select
            className="border w-372 box-border  border-gray-325 rounded outline-none px-4 py-3 font-normal text-xs leading-5 flex items-center text-gray-750 focus:bg-white focus:border-blue-675 focus:border mt-1"
            placeholder=""
            name="carrier"
            onChange={(e) => 
              handleInputChange({ name: "carrier", value: e.target.value })
            }
            required
          >
            {integrationOptions.map((i, index) => (
              <option className="text-gray-750" value={i.value} key={index}>
                {i.label}
              </option>
            ))}
          </select>
        </div>
        <div className="w">
          <Typography
            element="p"
            weight="medium"
            size="xs"
            height="4"
            color="gray-725"
            className=" mt-6"
          >
            {t.select_template}
          </Typography>
          <select
            className="border w-372 box-border  border-gray-325 rounded outline-none px-4 py-3 font-normal text-xs leading-5 flex items-center text-gray-750 focus:bg-white focus:border-blue-675 focus:border mt-1"
            placeholder="Select a template"
            name="template"
            onChange={(e) => 
              handleTemplateChange({ name: "template", value: e.target.value })
            }
            required
          >
            {templateOptions.map((i, index) => (
              <option className="text-gray-750" value={i.value} key={index}>
                {i.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mt-8">
          {
            <button
              className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625"
            onClick={closeModal}
            >
              {t.cancel}
            </button>
          }
          <button
            className=" bg-green-800 font-semibold text-sm leading-5 text-white rounded-lg py-2 px-4 ml-3 focus:outline-none text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
            type="submit"
          >
            {t.save}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddReturnMethod;
