import React, { useState } from "react";

import { connectToPaypal } from "../../../services/api";
import { SuccessToast } from "../../../common/SuccessToast";
import { ErrorToast } from "../../../common/ErrorToast";

import { transformIntegrationResponse } from "./Paypal.util";

import EN from "./locales/EN.json";
import DE from "./locales/DE.json";

export const usePaypal = ({
  currentUser,
  paypalIntegration,
}) => {
  const t = currentUser.locale === "en" ? EN : DE;

  const [state, setState] = useState(
    transformIntegrationResponse(paypalIntegration)
  );

  const [isConnected, setIsConnected] = useState(Boolean(state.clientSecret));
  const [secretChanged, setSecretChanged] = useState(false);
  const [connectBtnDisabled, setConnectBtnDisabled] = useState(false);

  const handleInputChange = ({ name, value }) => {
    setState(prevState => ({ ...prevState, [name]: value }));

    if (name === "clientSecret") setSecretChanged(true);
  };

  async function connect() {
    setConnectBtnDisabled(true);

    const { clientSecret, ...stateWithoutSecret } = state;
    const response = await connectToPaypal({
      ...stateWithoutSecret,
      ...(secretChanged && { clientSecret }),
    });

    const responseJSON = await response.json();

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        ...transformIntegrationResponse(responseJSON.paypal_integration),
      }));
      setSecretChanged(false);
      setIsConnected(true);

      SuccessToast({
        toastId: "paypalIntegration",
      });
    } else {
      ErrorToast({
        toastId: "paypalIntegration",
        message: responseJSON.error,
      });
    }

    setConnectBtnDisabled(false);
  }

  return {
    t,
    isConnected,
    state,
    handleInputChange,
    connect,
    connectBtnDisabled,
  };
}
