import { reorderDragDrop } from "~dashboard/util/reorderDragDrop";

export const useDroppable = ({ list, setList, positionField }) => {
  const onDragEnd = ({
    source: { index: sourceIndex },
    destination: { index: destinationIndex } = {},
  }) => {
    if (!destinationIndex) return;
    if (destinationIndex === sourceIndex) return;

    const reorderedList = reorderDragDrop(list, sourceIndex, destinationIndex);

    setList(
      reorderedList.map((item, index) => ({
        ...item,
        [positionField]: index + 1,
      }))
    );
  };

  return {
    onDragEnd,
  };
};
