import React from "react";
import cx from "classnames";

export const InputLabel = ({
  htmlFor = "",
  className = "font-normal inline text-xs leading-4 text-gray-725",
  cursorStyle = "",
  children,
}) => {
  return (
    <label htmlFor={htmlFor} className={cx(className, cursorStyle)}>
      {children}
    </label>
  );
};
