import React from "react";
import SearchInput from "./SearchInput";

const ShopifyOrderIdSearch = ({
  value,
  onChange,
}) => (

  <SearchInput
    type="text"
    placeholder="Shopify Order ID"
    value={value}
    onChange={onChange}
  />);

export default ShopifyOrderIdSearch;
