import React, { useState } from "react";

import { DeleteModal } from "./components/DeleteModal";

export const DeleteLanguage = ({
  submitDeleteRequest,
  selectedLanguage,
  t,
  tI18next,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deletionHandler = async () => {
    await submitDeleteRequest();
    setShowDeleteModal(false);
  };

  return (
    <div className="mb-10">
      {selectedLanguage.deletable && (
        <div>
          <div className="mt-4">
            <button
              className="bg-red-525 border rounded-lg font-semibold text-sm leading-5 text-white px-4 py-2 focus:outline-none hover:bg-red-800"
              onClick={() => setShowDeleteModal(true)}
            >
              {t.delete_language}
            </button>
          </div>

          <DeleteModal
            isOpen={showDeleteModal}
            onRequestClose={() => setShowDeleteModal(false)}
            t={t}
            tI18next={tI18next}
            deletionHandler={deletionHandler}
            languageName={selectedLanguage.language_name}
          />
        </div>
      )}
    </div>
  );
};
