import React, { useState } from "react"
import { store } from "react-notifications-component"
import Notification from "../../notification/index"
import { connectWithShopware } from "../../services/api"
import translationEN from "../../../translations/integrations/translationEN.json"
import translationDE from "../../../translations/integrations/translationDE.json"

export const useShopware = ({ 
    current_user,
    shopware_integration
}) => {

  const {
    id,
    username,
    host,
    api_key
  } = shopware_integration

  function getInitialState() {
    return {
      shopwareIntegration: {
        id: id ? id : "",
        username: username ? username : "",
        host:  host ? host : "",
        api_key: "",
        api_key_filtered: api_key
      },
      storeConnected: {
        connected: host ? true : false,
        show: host ? true : false,
      },
      notificationId: 0,
      disableButton: false
    }
  }
  const t = current_user.locale === "en" ? translationEN : translationDE
  const [state, setState] = useState(getInitialState())

  function handleShopwareInputChange(e){
    e.persist()
    setState(prevState => ({
      ...prevState,
      shopwareIntegration: {
        ...prevState.shopwareIntegration,
        [e.target.name]: e.target.value
      }
    }))
  }

  async function connectShopware() {
    setState(prevState => ({
      ...prevState,
      disableButton: true
    }))
    const body = {
      merchant: {
        shopware_integration_attributes: state.shopwareIntegration,
      }
    }

    const response = await connectWithShopware(body)

    if(response.status === 200) {
      response.json().then(responseJson => {
        if (responseJson.shopware_integration_id) {
          setState(prevState => ({
            ...prevState,
            notificationId: state.notificationId + 1,
            disableButton: false,
            shopwareIntegration: {
              ...prevState.shopwareIntegration,
              api_key_filtered: "FILTERED",
              api_key: "",
              id: responseJson.shopware_integration_id
            },
            storeConnected: {
              connected: true,
              show: true
            },
          }))
        }

        store.addNotification({
          id: state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: <Notification success_message="Saved successfully!"/>,
          width: 762,
          dismiss: {
            duration: 2000,
            showIcon: true
          }
        })
      })
    }
    else {
      response.json().then(responseJson => {
        setState(prevState => ({
          ...prevState,
          notificationId: state.notificationId + 1,
          disableButton: false,
          storeConnected: {
            connected: false,
            show: true
          },
        }))
        store.addNotification({
          id: state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: <Notification error_message={responseJson.error}/>,
          width: 762,
          dismiss: {
            duration: 5000,
            showIcon: true
          }
        })
      })
    }
  }

  return {
    state,
    t,
    handleShopwareInputChange,
    connectShopware
  }
}
