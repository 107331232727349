import React from "react";

export const SuccessIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      className="mr-2 min-w-20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={20} height={20} rx={10} fill="#27AE60" />
      <path
        d="M6.875 10.4332L8.16998 12.2069C8.57586 12.7628 9.40894 12.752 9.80028 12.1858L13.125 7.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
