import { isEmpty } from "lodash";
import { ErrorToast } from "~dashboard/components/common/ErrorToast";
import { transformTagifyJSONToCommaSeparatedString } from "~dashboard/util/transformTagifyString";

export const useItemTags = ({ index, updateRule, validateItemTag }) => {
  const name = "itemTags";

  const handleTagsInput = (name: string, values: string = "[]") => {
    const tags = transformTagifyJSONToCommaSeparatedString(values);
    // @ts-ignore
    const latestTag = tags.split(",").at(-1);
    if (!latestTag) return;

    const errors = validateItemTag(latestTag, index);

    if (isEmpty(errors)) {
      updateRule({ index, [name]: values });
    } else {
      ErrorToast({
        message: errors.join(". "),
        toastId: "partialRefundRuleError",
      });
    }
  };

  return {
    name,
    handleTagsInput,
  };
};
