import React from "react";

export const FinecomLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="191"
      height="49"
      viewBox="0 0 191 49"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.41 10.98C91.04 11 93.49 11.62 95.65 13.17C97.98 14.85 99.4 17.14 99.96 19.94C100.51 22.67 100.36 25.36 99.26 27.94C98.37 30.01 96.83 31.48 94.87 32.52C94.61 32.66 94.53 32.81 94.54 33.09C94.55 34.36 94.53 35.63 94.55 36.9C94.55 37.2 94.45 37.37 94.21 37.52C93.17 38.2 92.02 38.57 90.83 38.81C88.73 39.22 86.62 39.37 84.49 39.1C79.46 38.46 74.96 35.07 73.94 29.39C73.02 24.23 74.24 19.55 77.75 15.6C80.14 12.91 83.22 11.47 86.8 11.1C87.34 11.04 87.88 11.02 88.41 10.98ZM82.46 25.75C82.51 26.2 82.54 26.61 82.6 27.02C82.94 29.33 84.39 30.96 86.63 31.53C87.8 31.83 88.99 31.77 90.18 31.64C93.73 31.24 96.07 29.27 97.39 26.04C98.17 24.14 98.09 22.16 97.66 20.2C97.03 17.34 95.51 15.14 92.82 13.85C91.07 13.02 89.21 12.76 87.3 12.91C83.5 13.21 80.47 14.92 78.35 18.08C76.13 21.39 75.52 25.08 76.24 28.95C76.97 32.88 79.33 35.47 83.14 36.72C84.89 37.29 86.69 37.38 88.51 37.3C89.51 37.25 90.49 37.07 91.48 36.95C91.72 36.92 91.81 36.8 91.8 36.55C91.78 35.73 91.79 34.91 91.79 34.09C91.79 33.99 91.78 33.89 91.77 33.8C91.71 33.79 91.68 33.78 91.65 33.78C91.58 33.79 91.52 33.81 91.45 33.82C89.64 34.17 87.82 34.33 85.99 33.98C83.14 33.43 81.1 31.86 80.12 29.1C79.14 26.36 79.27 23.61 80.39 20.94C81.7 17.83 84.36 16.12 87.73 16.16C90.19 16.19 92.15 17.15 93.44 19.3C94.19 20.55 94.55 21.92 94.68 23.36C94.75 24.16 94.74 24.95 94.6 25.77C90.56 25.75 86.54 25.75 82.46 25.75ZM82.53 23.45C82.61 23.47 82.66 23.49 82.72 23.49C85.63 23.49 88.54 23.49 91.45 23.5C91.68 23.5 91.73 23.39 91.7 23.21C91.64 22.74 91.62 22.27 91.51 21.81C91.15 20.35 90.41 19.18 88.92 18.65C86.99 17.96 84.94 18.59 83.73 20.25C83.03 21.2 82.67 22.28 82.53 23.45Z"
        fill="#B41B3B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.56 16.81C152.56 16.55 152.56 16.34 152.56 16.11C154.33 16.11 156.09 16.11 157.91 16.11C157.91 16.29 157.92 16.48 157.91 16.66C157.91 16.71 157.87 16.78 157.82 16.82C157.48 17.05 157.44 17.15 157.63 17.52C157.88 18.02 158.15 18.51 158.41 19.01C159.8 21.7 161.19 24.39 162.58 27.09C162.61 27.16 162.66 27.22 162.71 27.33C163.11 26.56 163.5 25.83 163.88 25.1C165.18 22.61 166.48 20.13 167.78 17.64C167.85 17.51 167.9 17.37 167.93 17.23C167.95 17.14 167.94 16.97 167.89 16.95C167.51 16.75 167.7 16.41 167.66 16.11C169.46 16.11 171.23 16.11 173.03 16.11C173.03 16.33 173.03 16.56 173.03 16.79C172.57 16.96 172.54 17.01 172.54 17.52C172.54 22.44 172.54 27.35 172.54 32.27C172.54 32.65 172.61 32.97 173.05 33.05C173.05 33.29 173.05 33.51 173.05 33.75C171.58 33.75 170.12 33.75 168.64 33.75C168.64 33.52 168.64 33.3 168.64 33.05C168.71 33.04 168.77 33.03 168.84 33.02C169.17 32.97 169.31 32.81 169.31 32.45C169.3 29.91 169.3 27.37 169.3 24.82C169.3 23.63 169.3 22.44 169.3 21.25C169.28 21.24 169.26 21.23 169.25 21.23C169.19 21.32 169.13 21.4 169.09 21.49C167.06 25.43 165.03 29.37 163.01 33.32C162.96 33.42 162.92 33.53 162.85 33.61C162.79 33.68 162.68 33.77 162.6 33.75C162.51 33.74 162.39 33.65 162.34 33.56C161.95 32.79 161.58 32.01 161.2 31.23C159.6 27.94 158 24.65 156.4 21.37C156.38 21.33 156.35 21.3 156.3 21.2C156.29 21.32 156.28 21.39 156.28 21.46C156.28 25.06 156.28 28.66 156.28 32.26C156.28 32.8 156.4 32.94 156.95 33.06C156.95 33.16 156.95 33.27 156.95 33.37C156.95 33.48 156.95 33.59 156.95 33.74C155.5 33.74 154.07 33.74 152.6 33.74C152.63 33.44 152.43 33.1 152.87 32.9C152.96 32.86 153.01 32.68 153.04 32.55C153.07 32.42 153.05 32.28 153.05 32.15C153.05 27.27 153.05 22.39 153.05 17.51C153.01 17.19 153.05 16.84 152.56 16.81Z"
        fill="#060608"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.22 28.16C63.22 28.05 63.22 27.94 63.22 27.83C63.22 24.45 63.22 21.06 63.22 17.68C63.22 17.03 63.11 16.91 62.43 16.81C62.43 16.58 62.43 16.36 62.43 16.11C64.06 16.11 65.67 16.11 67.32 16.11C67.32 16.34 67.32 16.56 67.32 16.79C66.68 16.95 66.61 17.04 66.61 17.72C66.61 22.55 66.61 27.39 66.61 32.23C66.61 32.82 66.73 32.96 67.33 33.07C67.33 33.28 67.33 33.5 67.33 33.75C65.61 33.75 63.87 33.75 62.1 33.75C62.16 33.48 61.92 33.15 62.32 32.94C62.35 32.93 62.3 32.68 62.23 32.6C61.88 32.19 61.52 31.79 61.15 31.4C57.89 27.94 54.64 24.47 51.38 21.01C51.34 20.96 51.31 20.9 51.27 20.84C51.24 20.86 51.21 20.87 51.18 20.89C51.18 21.01 51.18 21.12 51.18 21.24C51.18 24.86 51.18 28.48 51.18 32.1C51.18 32.85 51.25 32.94 52.01 33.06C52.01 33.28 52.01 33.5 52.01 33.74C50.37 33.74 48.74 33.74 47.07 33.74C47.07 33.52 47.07 33.3 47.07 33.07C47.71 32.9 47.78 32.81 47.78 32.14C47.78 27.33 47.78 22.51 47.78 17.7C47.78 17.04 47.68 16.92 47.06 16.8C47.06 16.58 47.06 16.36 47.06 16.12C49.02 16.12 50.98 16.12 52.97 16.12C52.91 16.4 53.15 16.74 52.75 16.95C52.73 16.96 52.78 17.2 52.86 17.29C53.25 17.74 53.66 18.17 54.07 18.61C57 21.71 59.94 24.82 62.87 27.92C62.96 28.02 63.04 28.13 63.12 28.23C63.15 28.2 63.19 28.18 63.22 28.16Z"
        fill="#060608"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.07 33.91C135.95 33.91 134.2 33.67 132.57 32.88C130.19 31.72 128.79 29.78 128.25 27.23C127.86 25.4 127.89 23.56 128.47 21.77C129.28 19.23 130.95 17.46 133.46 16.56C136.58 15.44 139.71 15.46 142.74 16.84C145.47 18.08 146.96 20.31 147.39 23.25C147.64 24.99 147.57 26.71 146.96 28.37C145.92 31.18 143.89 32.89 140.98 33.56C139.92 33.81 138.84 33.94 138.07 33.91ZM143.96 24.92C144.04 21.38 141.11 18.83 137.84 18.81C134.27 18.79 131.55 21.45 131.55 24.92C131.54 28.36 134.26 31.04 137.73 31.05C141.03 31.06 144.05 28.53 143.96 24.92Z"
        fill="#060608"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.48 27.37C123.47 28.79 124.45 30.19 125.44 31.6C125.22 31.82 125.01 31.86 124.75 31.74C124.43 31.59 124.12 31.65 123.81 31.78C123.06 32.11 122.33 32.48 121.57 32.78C118.24 34.09 114.84 34.39 111.43 33.2C108.43 32.15 106.45 30.03 105.78 26.9C104.71 21.88 107.39 17.98 111.69 16.5C115.02 15.35 118.32 15.63 121.56 16.92C122.06 17.12 122.56 17.32 123.06 17.51C123.38 17.64 123.68 17.66 123.97 17.41C124.26 17.15 124.41 17.52 124.67 17.58C123.77 18.88 122.89 20.15 122.01 21.43C121.71 21.29 121.47 21.15 121.48 20.76C121.48 20.63 121.35 20.48 121.24 20.37C120.68 19.81 119.95 19.52 119.21 19.29C117.27 18.68 115.31 18.58 113.34 19.18C111.05 19.89 109.59 21.83 109.46 24.23C109.32 26.71 110.23 28.67 112.29 30.05C113.65 30.96 115.18 31.2 116.78 30.98C118.13 30.8 119.43 30.4 120.65 29.78C120.96 29.62 121.25 29.43 121.53 29.23C121.89 28.98 122.12 28.64 122.03 28.17C121.95 27.79 122.12 27.56 122.48 27.37Z"
        fill="#060608"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.79 23.31C16.35 23.31 16.89 23.31 17.43 23.31C19.3 23.31 21.16 23.3 23.03 23.29C23.37 23.29 23.75 23.29 23.84 22.81C23.85 22.77 23.96 22.71 24.02 22.71C24.21 22.7 24.4 22.71 24.6 22.71C24.6 24.13 24.6 25.52 24.6 26.95C24.42 26.95 24.26 26.97 24.1 26.94C24 26.92 23.86 26.83 23.83 26.74C23.73 26.49 23.58 26.35 23.31 26.33C23.09 26.31 22.86 26.28 22.64 26.28C20.44 26.27 18.23 26.28 16.03 26.28C15.96 26.28 15.88 26.29 15.77 26.3C15.77 26.45 15.77 26.58 15.77 26.71C15.77 28.48 15.77 30.24 15.77 32.01C15.77 32.83 15.85 32.92 16.66 33.06C16.66 33.28 16.66 33.5 16.66 33.74C14.83 33.74 12.99 33.74 11.13 33.74C11.13 33.52 11.13 33.3 11.13 33.06C11.2 33.05 11.27 33.04 11.35 33.02C11.73 32.93 11.87 32.76 11.87 32.36C11.87 30.67 11.87 28.99 11.87 27.3C11.87 24.14 11.87 20.98 11.87 17.82C11.87 17 11.83 16.95 11.03 16.79C11.03 16.57 11.03 16.36 11.03 16.09C11.17 16.09 11.3 16.09 11.43 16.09C16.38 16.09 21.32 16.09 26.27 16.09C26.56 16.09 26.85 16.06 27.14 16.03C27.53 15.98 27.81 15.79 27.89 15.37C28.13 15.37 28.36 15.37 28.6 15.37C28.6 16.8 28.6 18.21 28.6 19.64C28.26 19.65 27.96 19.74 27.7 19.37C27.58 19.2 27.25 19.17 27 19.1C26.85 19.06 26.69 19.06 26.53 19.06C22.98 19.06 19.42 19.06 15.87 19.06C15.85 19.06 15.83 19.07 15.78 19.08C15.79 20.47 15.79 21.87 15.79 23.31Z"
        fill="#060608"
      />
      <path
        d="M33.98 33.75C33.98 33.51 33.98 33.29 33.98 33.06C34.18 33.03 34.35 33.02 34.53 32.99C34.99 32.9 35.1 32.77 35.1 32.31C35.1 30.48 35.1 28.64 35.1 26.81C35.1 23.79 35.1 20.76 35.11 17.74C35.11 17.16 35.12 16.89 34.22 16.83C34.15 16.83 34.09 16.82 33.98 16.81C33.97 16.7 33.96 16.59 33.96 16.48C33.96 16.37 33.96 16.26 33.96 16.12C36 16.12 38.03 16.12 40.08 16.12C40.08 16.34 40.08 16.56 40.08 16.8C39.92 16.83 39.76 16.85 39.6 16.88C39.11 16.98 39 17.12 39 17.61C39 22.47 39 27.33 39 32.18C39 32.78 39.11 32.92 39.71 33.02C39.82 33.04 39.93 33.04 40.07 33.06C40.07 33.29 40.07 33.52 40.07 33.76C38.05 33.75 36.03 33.75 33.98 33.75Z"
        fill="#060608"
      />
      <path
        d="M175.97 38.91C175.09 38.91 174.37 38.19 174.37 37.31C174.37 36.43 175.09 35.71 175.97 35.71C176.86 35.71 177.58 36.43 177.58 37.31C177.58 38.19 176.86 38.91 175.97 38.91ZM175.97 35.9C175.19 35.9 174.56 36.53 174.56 37.31C174.56 38.09 175.19 38.72 175.97 38.72C176.75 38.72 177.38 38.09 177.38 37.31C177.38 36.53 176.75 35.9 175.97 35.9Z"
        fill="#211F20"
      />
      <path
        d="M176.81 38.18H176.53L176.2 37.62C176.17 37.57 176.14 37.52 176.11 37.49C176.08 37.45 176.05 37.42 176.02 37.4C175.99 37.38 175.96 37.36 175.92 37.35C175.89 37.34 175.84 37.33 175.8 37.33H175.61V38.18H175.38V36.19H175.97C176.06 36.19 176.14 36.2 176.21 36.22C176.28 36.24 176.35 36.27 176.4 36.32C176.45 36.36 176.5 36.42 176.53 36.49C176.56 36.55 176.57 36.63 176.57 36.72C176.57 36.79 176.56 36.85 176.54 36.91C176.52 36.97 176.49 37.02 176.45 37.06C176.41 37.1 176.37 37.14 176.31 37.18C176.26 37.21 176.19 37.24 176.13 37.25V37.26C176.16 37.28 176.19 37.29 176.22 37.31C176.24 37.33 176.27 37.35 176.29 37.38C176.31 37.41 176.33 37.43 176.35 37.47C176.37 37.5 176.4 37.54 176.43 37.58L176.81 38.18ZM175.61 36.4V37.12H175.93C175.99 37.12 176.04 37.11 176.09 37.09C176.14 37.07 176.18 37.05 176.22 37.01C176.26 36.98 176.28 36.94 176.3 36.89C176.32 36.84 176.33 36.79 176.33 36.73C176.33 36.62 176.3 36.54 176.23 36.48C176.16 36.42 176.06 36.39 175.93 36.39H175.61V36.4Z"
        fill="#211F20"
      />
    </svg>
  );
};
