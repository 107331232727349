import React, { useState } from "react";

export const useExchangeReturnsToggleState = ({
                                                initialValue,
                                                updateMerchantSettings,
                                                showSuccessNotification,
                                                showErrorNotification,
                                              }) => {
  const [exchangeReturnsState, setExchangeReturnsState] =
    useState(initialValue)

  async function toggleSettingsExchangeReturns() {
    const value = !exchangeReturnsState
    setExchangeReturnsState(value)

    const response = await updateMerchantSettings({
      show_exchange: value,
    })

    if(response.status == 200) {
      const { show_exchange } = await response.json()
      setExchangeReturnsState(show_exchange)
      showSuccessNotification()
    } else {
      const { error } = await response.json()
      showErrorNotification(error)
    }
  }

  return {
    exchangeReturnsState,
    toggleSettingsExchangeReturns,
  }
}
