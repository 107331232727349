import React from "react";

export const DetailsIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70898 3.95898H5.62565C4.70518 3.95898 3.95898 4.70518 3.95898 5.62565V14.3757C3.95898 15.2961 4.70518 16.0423 5.62565 16.0423H14.3757C15.2961 16.0423 16.0423 15.2961 16.0423 14.3757V12.2923"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.041 7.70898V3.95898H12.291"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8327 4.16602L9.79102 10.2077"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
