import React from "react";

import { ErrorIcon } from "../../ui-kit/icons/ErrorIcon";
import { SuccessIcon } from "../../ui-kit/icons/SuccessIcon";

export const Notification = (props) => {
  const { success_message, error_message, innerHtmlSuccess, innerHtmlError } =
    props;

  return (
    <div className="flex items-center font-inter shadow-black_07 rounded-xl border border-gray-325 py-3 px-3 mx-auto bg-white">
      {(success_message || innerHtmlSuccess) && <SuccessIcon />}
      {(error_message || innerHtmlError) && <ErrorIcon />}
      {innerHtmlError || innerHtmlSuccess ? (
        <div
          dangerouslySetInnerHTML={{
            __html: innerHtmlError || innerHtmlSuccess,
          }}
        ></div>
      ) : (
        <p className="text-sm leading-5 text-gray-875">
          {error_message || success_message}
        </p>
      )}
    </div>
  );
};
export default Notification;
