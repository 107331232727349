import React from "react";
import ReactNotification from "react-notifications-component";
import { clamp } from "lodash";

import { SuccessToast } from "../../../common/SuccessToast";
import { ErrorToast } from "../../../common/ErrorToast";

import translationEN from "../../../../translations/manageReturns/translationEN.json";
import translationDE from "../../../../translations/manageReturns/translationDE.json";

import { Item } from "./components/Item";

import "react-notifications-component/dist/theme.css";

class Shopware extends React.Component {
  constructor(props) {
    super(props);

    this.merchant_return_method_cost = parseFloat(
      this.props.customer_return.merchant_return_method_cost || 0
    );
    this.max_shipping_refund = this.props.refund_shipping_cost
      ? this.props.shop_order_shipping_cost
      : 0;
    this.total_refund =
      parseFloat(this.props.refund_amount_without_shipping) +
      this.max_shipping_refund -
      this.merchant_return_method_cost;
    this.currency =
      this.props.customer_return.items[0]?.displayed_currency ||
      this.props.customer_return.currency;

    this.state = {
      customer_return: this.props.customer_return,
      merchant_return_method_cost: this.merchant_return_method_cost,
      shop_order_shipping_cost: this.max_shipping_refund,
      include_refund: false,
      refund_amount_without_shipping: this.props.refund_amount_without_shipping,
      t: this.props.lang === "en" ? translationEN : translationDE,
      refund_items: this.props.refund_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
      })),
      custom_items: this.props.custom_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
      })),
      complete_btn_disable: false,
      total_refund: this.total_refund,
      total_original_amount: this.props.refund_items.reduce((sum, item) => {
        if (!item.is_cancelled && parseFloat(item.displayed_original_price)) {
          return (
            sum +
            parseFloat(item.displayed_original_price) * parseInt(item.quantity)
          );
        }

        return sum;
      }, 0),
    };
  }

  maxReturnFees = () => {
    return (
      parseFloat(this.state.refund_amount_without_shipping) +
      parseFloat(this.state.shop_order_shipping_cost || 0)
    );
  };

  inputChangeHandler = ({ target: { name, value } }) => {
    if (name === "shop_order_shipping_cost") {
      const clampedValue = clamp(value, 0, this.max_shipping_refund);
      this.setState({ [name]: clampedValue });
      this.updateTotalRefundAmount({
        shipping_cost_refund: clampedValue,
      });
    } else if (name === "merchant_return_method_cost") {
      const clampedValue = clamp(value, 0, this.maxReturnFees());
      this.setState({ [name]: clampedValue });
      this.updateTotalRefundAmount({
        return_fees: clampedValue,
      });
    }
  };

  updateTotalRefundAmount = ({
    shipping_cost_refund = this.state.shop_order_shipping_cost,
    return_fees = this.state.merchant_return_method_cost,
  }) => {
    const total_refund =
      parseFloat(this.state.refund_amount_without_shipping) +
      parseFloat(shipping_cost_refund || 0) -
      parseFloat(return_fees || 0);

    this.setState({ total_refund: Math.max(total_refund, 0) });
  };

  completeShopwareReturn = () => {
    const validateInput = this.validateShopwareInput();
    if (!validateInput) return;

    this.setState({ complete_btn_disable: true });

    const body = {
      items: this.state.refund_items.filter((item) => !item.is_cancelled),
      shipping_amount_refunded: this.state.shop_order_shipping_cost.toString(),
      return_cost_charged: this.state.merchant_return_method_cost.toString(),
    };

    fetch(
      `/customer_returns/${this.state.customer_return.id}/refund/shopware_create`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(body),
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((_responseJson) => {
          SuccessToast({
            toastId: "shopwareReturnCompleted",
          });
        });
        window.location.href = `/customer_returns/${this.state.customer_return.id}/edit`;
      } else {
        this.setState({ complete_btn_disable: false });

        response.json().then((responseJson) => {
          ErrorToast({
            message: responseJson.error,
            toastId: "shopwareReturnFailed",
          });
        });
      }
    });
  };

  includeToRefund = () => {
    fetch(
      `/customer_returns/${this.state.customer_return.id}/refund?include_refund=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const refund_amount_without_shipping =
            responseJson.refund_amount_without_shipping;

          const total_custom_refund_original_amount =
            this.props.custom_items.reduce((sum, item) => {
              if (
                !item.is_cancelled &&
                parseFloat(item.displayed_original_price)
              ) {
                return (
                  sum +
                  parseFloat(item.displayed_original_price) *
                    parseFloat(item.quantity)
                );
              }

              return sum;
            }, 0);

          this.setState((prevState) => {
            let new_state = {
              include_refund: true,
              refund_amount_without_shipping,
              total_refund:
                parseFloat(prevState.total_refund) +
                parseFloat(refund_amount_without_shipping) -
                parseFloat(prevState.refund_amount_without_shipping),
              total_original_amount: parseFloat(
                prevState.total_original_amount +
                  parseFloat(total_custom_refund_original_amount)
              ),
            };

            new_state = {
              ...new_state,
              refund_items: [
                ...prevState.refund_items,
                ...prevState.custom_items,
              ], // custom items should always be added to the end of the list
            };

            return new_state;
          });
        });
      } else {
        response.json().then((responseJson) => {
          console.log(
            responseJson.error || response.status + " " + response.statusText
          );
        });
      }
    });
  };

  validateShopwareInput = () => {
    const state_shop_order_shipping_cost = parseFloat(
      this.state.shop_order_shipping_cost
    );
    const state_merchant_return_method_cost =
      this.state.merchant_return_method_cost;
    const state_refund_amount_without_shipping = parseFloat(
      this.state.refund_amount_without_shipping
    );
    const state_refund_amount_with_order_shipping =
      state_refund_amount_without_shipping + state_shop_order_shipping_cost;

    if (
      state_shop_order_shipping_cost > this.max_shipping_refund ||
      state_shop_order_shipping_cost < 0
    ) {
      ErrorToast({
        message: `Shipping amount must be between 0-${this.max_shipping_refund}`,
        toastId: "shippingRefundError",
      });

      return false;
    } else if (
      state_merchant_return_method_cost < 0 ||
      state_merchant_return_method_cost >
        state_refund_amount_with_order_shipping
    ) {
      ErrorToast({
        message: `Return fee must be between 0-${
          state_refund_amount_without_shipping + state_shop_order_shipping_cost
        }`,
        toastId: "returnFeesError",
      });

      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />

        <div className="flex justify-between items-center px-8 pb-3">
          <div className="font-inter font-medium text-xs leading-4 text-gray-525">
            <p>
              <span className="text-gray-525">
                <a
                  href="/customer_returns"
                  className="focus:outline-none"
                  accessKey="E"
                >
                  {this.state.t.manage_return}
                </a>{" "}
                /{" "}
              </span>

              <span className="text-gray-725 text-xs13">
                {this.props.customer_return.order_number}
              </span>
            </p>
          </div>

          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>

              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>

        <div className="border-t border-b px-8 font-semibold text-base leading-6 text-gray-825 py-5 border-gray-225">
          {this.state.refund_items.length > 0
            ? this.state.t.refunded_items
            : this.state.custom_items.length > 0
            ? this.state.t.custom_items
            : null}
        </div>

        <div className="flex">
          <div className="border-r w-67% min-h-screen">
            {this.props.refund_items.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  t={this.state.t}
                  currency={this.currency}
                />
              );
            })}

            {this.props.custom_items.length > 0 && (
              <div>
                {this.state.refund_items.length > 0 &&
                  !this.state.include_refund && (
                    <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                      {this.state.t.custom_items}
                    </div>
                  )}

                {this.props.custom_items.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      item={item}
                      t={this.state.t}
                      currency={this.currency}
                    />
                  );
                })}

                {!this.state.include_refund &&
                  this.state.refund_items.length > 0 && (
                    <div className="px-8 mt-5">
                      <button
                        className="w-full border bg-blue-675 rounded-lg text-white font-semibold text-xs leading-4 py-2 focus:outline-none"
                        onClick={this.includeToRefund}
                      >
                        {this.state.t.include_to_refund}
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>

          <div className="flex-grow pl-6 pt-9">
            <div>
              <div className="flex items-center mb-6">
                <p className="text-xs leading-4 flex items-center text-gray-525">
                  {this.state.t.total}
                </p>
                <p className="font-semibold text-lg leading-6 text-right text-gray-825 ml-5">
                  {new Intl.NumberFormat("en", {
                    style: "currency",
                    currency: this.state.customer_return.currency,
                  }).format(parseFloat(this.state.total_original_amount))}
                </p>
              </div>

              {this.state.shop_order_shipping_cost > 0 && (
                <div className="mb-6 pr-8">
                  <p className="font-medium text-xs leading-4 flex items-center text-gray-725">
                    {this.state.t.shipping_refund}
                  </p>
                  <input
                    type="number"
                    className="bg-white border w-full box-border rounded-lg focus:outline-none font-medium text-sm leading-5 text-gray-525 py-3 px-4 mt-1"
                    min="0.0"
                    max={this.max_shipping_refund}
                    step="0.01"
                    name="shop_order_shipping_cost"
                    onChange={this.inputChangeHandler}
                    value={this.state.shop_order_shipping_cost}
                  />
                </div>
              )}

              <div className="pr-8 mb-6">
                <p className="font-medium text-xs leading-4 flex items-center text-gray-725">
                  {this.state.t.return_fees}
                </p>
                <input
                  type="number"
                  className="bg-white border w-full box-border rounded-lg focus:outline-none font-medium text-sm leading-5 text-gray-525 py-3 px-4 mt-1"
                  min="0.0"
                  max={
                    parseFloat(this.state.refund_amount_without_shipping) +
                    parseFloat(this.state.shop_order_shipping_cost)
                  }
                  step="0.01"
                  name="merchant_return_method_cost"
                  onChange={this.inputChangeHandler}
                  value={this.state.merchant_return_method_cost}
                />
              </div>

              <div className="flex items-center border-b pb-6">
                <p className="text-xs leading-4 flex items-center text-gray-525">
                  {this.state.t.total_refund}
                </p>
                <p className="font-semibold text-lg leading-6 text-right text-gray-825 ml-5">
                  {new Intl.NumberFormat("en", {
                    style: "currency",
                    currency: this.state.customer_return.currency,
                  }).format(this.state.total_refund)}
                </p>
              </div>
            </div>

            <div className="pr-8 mt-5">
              <button
                className={`w-full border rounded-lg font-semibold text-xs leading-4 py-2 focus:outline-none bg-blue-675 text-white hover:bg-blue-625 focus:border-blue-625 ${
                  this.state.complete_btn_disable ? "cursor-default" : " "
                }`}
                disabled={this.state.complete_btn_disable}
                onClick={this.completeShopwareReturn}
              >
                {!this.state.complete_btn_disable && (
                  <span>{this.state.t.complete}</span>
                )}

                {this.state.complete_btn_disable && (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Shopware;
