import React from "react";

export const FilterDateIcon = ({ filterByDateHandler }) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      onClick={filterByDateHandler}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.95801 7.29167C3.95801 6.37119 4.7042 5.625 5.62467 5.625H14.3747C15.2951 5.625 16.0413 6.37119 16.0413 7.29167V14.375C16.0413 15.2955 15.2951 16.0417 14.3747 16.0417H5.62467C4.7042 16.0417 3.95801 15.2955 3.95801 14.375V7.29167Z"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 3.95898V6.87565"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 3.95898V6.87565"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.45801 8.95898H13.5413"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
