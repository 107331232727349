import React from "react";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import "react-notifications-component/dist/theme.css";
import { TextArea } from "../../../../../ui-kit/components/TextArea";
export const SaveNote = (props) => {
  const { t, inputChangeHandler, notes, markStatus } = props;

  return (
    <div className="mt-8 mb-36">
      <Typography
        element="p"
        weight="semibold"
        size="base"
        height="6"
        color="gray-825"
      >
        {t.refund_notes}
      </Typography>
      <TextArea
        height="h-260px"
        placeholder={t.refund_notes_placeholder}
        name="notes"
        onChange={(value) => inputChangeHandler({ name: "notes", value })}
        value={notes}
      />
      <button
        className="bg-white border-blue-675 mt-5 border rounded-lg font-semibold text-xs14 leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue"
        name="save_notes"
        onClick={markStatus}
      >
        {t.save_note}
      </button>
    </div>
  );
};
