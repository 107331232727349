import React from "react"

export const DhlLogoIcon = () => {
  return (
    <svg width="156" height="33" viewBox="0 0 156 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="155.6" height="32.1" fill="#F7CE46"/>
      <path d="M6 24.5996H23.4L22.4 25.9996H6V24.5996Z" fill="#D40511"/>
      <path d="M149.499 25.9996H128.199L129.299 24.5996H149.599V25.9996H149.499Z" fill="#D40511"/>
      <path d="M6 22H25.4L24.3 23.4H6V22Z" fill="#D40511"/>
      <path d="M6 19.4004H27.4L26.3 20.8004H6V19.4004Z" fill="#D40511"/>
      <path d="M149.499 23.4H130.199L131.299 22H149.599V23.4H149.499Z" fill="#D40511"/>
      <path d="M133.2 19.4004H149.5V20.8004H132.1L133.2 19.4004Z" fill="#D40511"/>
      <path d="M24.8008 26.0002L34.0008 13.7002C34.0008 13.7002 44.2008 13.7002 45.4008 13.7002C46.7008 13.7002 46.7008 14.2002 46.0008 15.0002C45.4008 15.8002 44.3008 17.3002 43.7008 18.1002C43.4008 18.6002 42.8008 19.3002 44.7008 19.3002C46.8008 19.3002 60.0008 19.3002 60.0008 19.3002C58.8008 21.1002 54.6008 26.1002 47.2008 26.1002C41.2008 26.0002 24.8008 26.0002 24.8008 26.0002Z" fill="#D40511"/>
      <path d="M77.5004 19.4004L72.5004 26.1004H59.4004L64.4004 19.4004H77.5004Z" fill="#D40511"/>
      <path d="M96.6004 19.4004L91.6004 26.1004H78.4004L83.4004 19.4004H96.6004Z" fill="#D40511"/>
      <path d="M100.901 19.4004C100.901 19.4004 99.9007 20.7004 99.5007 21.3004C97.8007 23.5004 99.3007 26.1004 104.701 26.1004C110.901 26.1004 125.901 26.1004 125.901 26.1004L130.901 19.4004H100.901Z" fill="#D40511"/>
      <path d="M31.2992 6.1L26.6992 12.2C26.6992 12.2 50.3992 12.2 51.6992 12.2C52.9992 12.2 52.9992 12.7 52.2992 13.5C51.6992 14.3 50.5992 15.8 49.9992 16.6C49.6992 17 49.0992 17.8 50.9992 17.8C53.0992 17.8 61.1992 17.8 61.1992 17.8C61.1992 17.8 62.8992 15.6 64.1992 13.7C66.0992 11.2 64.3992 6 57.6992 6C51.6992 6.1 31.2992 6.1 31.2992 6.1Z" fill="#D40511"/>
      <path d="M97.7 17.7996H65.5L74.3 6.09961H87.5L82.5 12.7996H88.4L93.4 6.09961H106.6L97.7 17.7996Z" fill="#D40511"/>
      <path d="M124.8 6.09961L116 17.7996H102L110.8 6.09961H124.8Z" fill="#D40511"/>
    </svg>
  )
}
