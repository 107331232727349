import React, { useState } from "react";
import SearchForm from "./components/SearchForm";
import { fetchOrderInfoForAdmin } from "../services/api";

const MissionControl = () => {
  const [orderResponse, setOrderResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSearchSubmit = async (body) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await fetchOrderInfoForAdmin(body);
      const responseJson = await response.json();
      setOrderResponse(responseJson);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pl-8 pt-5 flex flex-col">
      <div className="flex-col items-center">
        <SearchForm onSubmit={handleSearchSubmit} />
      </div>
      <p className="ml-4 mt-4 font-medium">Result</p>
      <div className="flex-grow mt-4 ml-4 rounded shadow-md p-4">
        {isLoading && <p>Loading order details...</p>}
        {isError && <p className="text-red-500">Error fetching order details. Please try again.</p>}
        {!isLoading && orderResponse && (
          <div className="max-h-[50vh] overflow-y-auto">
            <pre className="bg-gray-100 rounded p-4 overflow-x-auto whitespace-pre-wrap">
              {JSON.stringify(orderResponse, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionControl;
