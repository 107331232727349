import React from "react";
import { random } from "lodash";
import { store } from "react-notifications-component";

import Notification from "../../notification";

export const ErrorToast = ({
  message,
  toastId = 1,
  autoCloseDuration = 5000,
  innerHtmlError,
}) => {
  store.addNotification({
    id: `${toastId}-${random(100)}`,
    container: "bottom-center",
    animationIn: ["animated", "bounceIn"],
    animationOut: ["animated", "bounceOut"],
    content: (
      <Notification error_message={message} innerHtmlError={innerHtmlError} />
    ),
    width: 762,
    dismiss: {
      duration: autoCloseDuration,
      showIcon: true,
    },
  });
};
