import React from "react";
import Select from "react-select";
import { getCountryList } from "~dashboard/util/getCountryList";
import { useTradeInItemSettings } from "./TradeInItemSettings.hook";

export default function TradeInItemSettings({
  eligibleCountries,
  setEligibleCountries,
  t,
}) {
  const {
    changeCountry,
    eligibleCountriesCurrentValue
  } = useTradeInItemSettings({
    eligibleCountries,
    setEligibleCountries,
  });

  return (
    <div className="mt-8 px-8">
      <div className="border-b pb-8">
        <p className="font-semibold text-base leading-6 text-gray-825">{t.trade_in_items}</p>

        <div className="mt-4">
          <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
            {t.trade_in_items_eligible_countries_text}
          </p>

          <div className="flex">
            <Select
              className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
              onChange={changeCountry}
              placeholder={t.countries_placeholder}
              options={getCountryList()}
              value={eligibleCountriesCurrentValue}
              isClearable={false}
              isMulti
            />
          </div>

          <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
            {t.trade_in_items_eligible_countries_additional_text}
          </p>
        </div>
      </div>
    </div>
  );
}
