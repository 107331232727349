import React from "react";

import { useValue } from "./Value.hook";

export default function Value({ index, value, updateRule }) {
  const { name, handleInput } = useValue({
    index,
    updateRule,
  });

  return (
    <input
      className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
      type="number"
      name={name}
      onChange={({ target: { value } }) => handleInput(name, value)}
      value={value}
      min="0"
    />
  );
}
