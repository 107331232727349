import React from "react"

const IntegrationCard = ({
  svg,
  url,
  heading,
  text,
}) => {

  return (
    <a href={url} className="bg-white border box-border border-gray-225 rounded-lg hover:shadow-custom2 p-5 pt-5 pb-7 w-274 mr-5 sm:mt-5">
      <div className="flex items-center min-h-70px">
        {svg}
      </div>
      <p className="font-semibold font-inter text-base leading-6 text-gray-825">
        {heading}
      </p>
      <p className="mt-1 font-inter font-normal text-xs13 leading-4 text-gray-625">
        {text}
      </p>
    </a>
  )
}

export default IntegrationCard
