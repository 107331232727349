import React from "react";
import cx from "classnames";

import { PaypalLogo } from "../../../../ui-kit/icons/PaypalLogo";
import { InputLabel } from "../../../../ui-kit/components/InputLabel";
import { Input } from "../../../../ui-kit/components/Input";
import { AppLayout } from "../common/AppLayout";
import SubHeaderOld from "../../../common/SubHeaderOld";

import { usePaypal } from "./Paypal.hook";

export default function Paypal({ currentUser, paypalIntegration }) {
  const {
    t,
    isConnected,
    state: { clientId, clientSecret },
    handleInputChange,
    connect,
    connectBtnDisabled,
  } = usePaypal({
    currentUser,
    paypalIntegration,
  });

  return (
    <AppLayout
      currentUser={currentUser}
      settings={t.settings}
      headerText="Payment"
      renderSubHeader={<SubHeaderOld text="Paypal" />}
    >
      <PaypalLogo />

      <div className="w-2/3 pt-8">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-base leading-6 text-gray-825">
            {t.paypal_integration}
          </h1>

          {isConnected && (
            <p
              className={cx(
                "rounded-lg font-semibold text-sm leading-5 py-2 px-3",
                "bg-teal-125 text-green-825"
              )}
            >
              {t.connected}
            </p>
          )}
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <InputLabel>{t.client_id}</InputLabel>

            <Input
              name="clientId"
              value={clientId}
              onChange={(value) =>
                handleInputChange({ name: "clientId", value })
              }
            />
          </div>

          <div className="w-1/2 ml-5 block">
            <InputLabel>{t.client_secret}</InputLabel>

            <Input
              name="clientSecret"
              value={clientSecret}
              onChange={(value) =>
                handleInputChange({ name: "clientSecret", value })
              }
            />
          </div>
        </div>

        <button
          className={cx(
            "bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5",
            "text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white",
            "focus:shadow-btn_blue",
            { "cursor-default": connectBtnDisabled }
          )}
          disabled={connectBtnDisabled}
          onClick={connect}
        >
          {t.connect}
        </button>
      </div>
    </AppLayout>
  );
}
