import React, { useState } from "react";

export const useXentralToggleState = ({
  initialValue,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [xentralIntegrationState, setXentralIntegrationState] =
    useState(initialValue)

  async function toggleSettingsXentralIntegration() {
    const value = !xentralIntegrationState
    setXentralIntegrationState(value)

    const response = await updateMerchantSettings({
      show_xentral_integration: value,
    })

    if(response.status == 200) {
      const { show_xentral_integration } = await response.json()
      setXentralIntegrationState(show_xentral_integration)
      showSuccessNotification()
    } else {
      const { error } = await response.json()
      showErrorNotification(error)
    }
  }

  return {
    xentralIntegrationState,
    toggleSettingsXentralIntegration,
  }
}
