import React from "react";

import Header from "../header/index";

import { useForm } from "./form.hook";

import { ImageUploader } from "../../ui-kit/components/ImageUploader"

const Form = ({
  currentUser,
  propTradeInItem,
  submitAddForm,
  submitEditForm,
  t,
  tradeInItemImage,
}) => {
  const {
    tradeInItem,
    handleCheckBoxChange,
    handleInputChange,
    createItem,
    updateItem,
  } = useForm({ propTradeInItem, submitAddForm, submitEditForm, tradeInItemImage });
  const inputId = "image-file-input"

  return (
    <div className="font-inter">
      <Header
        current_user={currentUser}
        tradeInItems={t.tradeInItems}
        settings={t.settings}
        text={propTradeInItem ? propTradeInItem.name : t.addItem}
      />
      <div className="flex items-center justify-between border-t border-b mt-6 px-8 py-5">
        <div>
          <h5 className="font-semibold text-base leading-6 text-gray-975">
            {propTradeInItem ? t.editItem : t.addItem}
          </h5>
        </div>
        <div className="items-center mr-8">
          <div className="relative inline-block w-12 mr-5 align-middle select-none transition duration-200 ease-in">
            <input
              id="trade-in-item-toggle"
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              type="checkbox"
              name="isActive"
              onChange={handleCheckBoxChange}
              defaultChecked={tradeInItem.isActive}
            />
            <label
              htmlFor="trade-in-item-toggle"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            />
          </div>
          <button
            className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
            onClick={propTradeInItem ? updateItem : createItem}
          >
            {t.save}
          </button>
        </div>
      </div>
      <div className="mt-8 px-8">
        <div className="mt-4 flex items-centers">
          <div className="w-1/3">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              Name
            </p>
            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="name"
              onChange={handleInputChange}
              defaultValue={tradeInItem.name}
            />
          </div>
          <div className="w-1/3 ml-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              SKU
            </p>
            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="sku"
              onChange={handleInputChange}
              defaultValue={tradeInItem.sku}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="mt-4 flex items-centers">
            <div className="w-1/3">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.returnLimit}
              </p>
              <input
                className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                type="number"
                name="returnLimit"
                min="1"
                onChange={handleInputChange}
                defaultValue={tradeInItem.returnLimit}
              />
            </div>
          </div>
        </div>

        <ImageUploader
         itemImage={t.itemImage}
         fileName={tradeInItem.fileName}
         placeHolderName={t.clickOnImage}
         imageFileInput={inputId}
         browse={t.browse}
         imageUrl={tradeInItem.imageUrl}
        />

      </div>
    </div>
  );
};

export default Form;
