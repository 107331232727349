import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";

export const useUppy = ({
  inputId, 
  setUppyState, 
  maxNumberOfFiles, 
  maxFileSize, 
  allowedFileTypes, 
  allowMultipleUploads,
  autoProceed,
  }) => {
  const uppyUploader = new Uppy({
    allowMultipleUploads,
    restrictions: {
      maxNumberOfFiles,
      maxFileSize,
      allowedFileTypes,
    },
    autoProceed,
  });

  const fileInput = document.querySelector(inputId)

  fileInput.addEventListener('change', (event) => {
    const files = Array.from(event.target.files)

    files.forEach((file) => {
      try {
        uppyUploader.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        })
      } catch (err) {
        ErrorToast({
          message: err.toString(),
          toastId: "fileUploadError",
        });
      }
    })
  })

  uppyUploader
    .use(AwsS3, {
      companionUrl: '/'
  })

  uppyUploader.on('upload-success', (file, response) => {
    const uploadedFileData = {
      id: file.meta['key'].match(/^cache\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }
    let url = URL.createObjectURL(file.data);
    setUppyState(file.name, url, uploadedFileData);

  })

  uppyUploader.on('file-removed', () => {
    fileInput.value = null
  })

  uppyUploader.on('complete', () => {
    uppyUploader.reset();
  })
}
