import React from "react"

export const ShopifyLogoIcon = () => {
  return (
    <svg width="113" height="32" viewBox="0 0 113 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6319_18708)">
        <path d="M24.0672 6.07238C24.0448 5.91552 23.9104 5.82588 23.7983 5.82588C23.6863 5.82588 21.4678 5.78106 21.4678 5.78106C21.4678 5.78106 19.6078 3.98834 19.4286 3.78666C19.2493 3.60739 18.8908 3.65221 18.7563 3.69703C18.7563 3.69703 18.3978 3.80907 17.8151 3.98834C17.7255 3.67462 17.5686 3.29367 17.3669 2.8903C16.7171 1.6354 15.7311 0.963134 14.5658 0.963134C14.4762 0.963134 14.409 0.963134 14.3193 0.985543C14.2745 0.940725 14.2521 0.895907 14.2073 0.873498C13.6919 0.335683 13.042 0.0667758 12.2577 0.0891847C10.7563 0.134003 9.2549 1.23204 8.02241 3.15921C7.17087 4.52616 6.52101 6.22924 6.31933 7.55137C4.59384 8.08918 3.38375 8.47014 3.33894 8.47014C2.46499 8.73904 2.44258 8.76145 2.33053 9.59058C2.28571 10.218 0 27.8987 0 27.8987L19.1821 31.2152L27.4958 29.1536C27.4734 29.1536 24.0896 6.22924 24.0672 6.07238ZM16.8515 4.30207C16.4034 4.43652 15.9104 4.59339 15.3726 4.77266C15.3726 4.01075 15.2605 2.93512 14.9244 2.03876C16.0672 2.21804 16.6275 3.51776 16.8515 4.30207ZM14.3641 5.06397C13.3557 5.3777 12.2577 5.71383 11.1597 6.04997C11.4734 4.86229 12.056 3.69703 12.7731 2.91271C13.042 2.6214 13.423 2.30767 13.8487 2.1284C14.2969 3.00235 14.3866 4.23484 14.3641 5.06397ZM12.3025 1.09759C12.6611 1.09759 12.9524 1.16481 13.1989 1.34409C12.7955 1.54577 12.3922 1.85949 12.0336 2.26285C11.07 3.29367 10.3305 4.8847 10.0392 6.43092C9.12045 6.72224 8.22409 6.99115 7.41737 7.23764C7.95518 4.79507 9.9944 1.16481 12.3025 1.09759Z" fill="#95BF47"/>
        <path d="M23.7979 5.82607C23.6858 5.82607 21.4674 5.78125 21.4674 5.78125C21.4674 5.78125 19.6074 3.98853 19.4281 3.78685C19.3609 3.71962 19.2713 3.6748 19.1816 3.6748V31.2154L27.4954 29.1538C27.4954 29.1538 24.1116 6.22943 24.0892 6.07256C24.0444 5.9157 23.9099 5.82607 23.7979 5.82607Z" fill="#5E8E3E"/>
        <path d="M14.566 10.1069L13.6024 13.7148C13.6024 13.7148 12.5267 13.2218 11.2494 13.3114C9.36708 13.4235 9.36708 14.6111 9.36708 14.9024C9.47913 16.5159 13.7144 16.8744 13.9609 20.6615C14.1402 23.6419 12.3923 25.6812 9.83767 25.838C6.79005 25.9949 5.10938 24.2022 5.10938 24.2022L5.75924 21.4459C5.75924 21.4459 7.46232 22.7232 8.80686 22.6335C9.68081 22.5887 10.0169 21.8492 9.97212 21.3562C9.83767 19.2498 6.38669 19.3842 6.1626 15.9108C5.98333 12.9977 7.88809 10.0621 12.101 9.7932C13.7368 9.68115 14.566 10.1069 14.566 10.1069Z" fill="white"/>
        <path d="M38.7467 17.7916C37.7832 17.2762 37.2902 16.8281 37.2902 16.223C37.2902 15.4611 37.9848 14.9681 39.0605 14.9681C40.3154 14.9681 41.4358 15.4835 41.4358 15.4835L42.3098 12.7944C42.3098 12.7944 41.503 12.167 39.1277 12.167C35.8112 12.167 33.503 14.0718 33.503 16.7384C33.503 18.2622 34.5787 19.4051 36.0128 20.2342C37.1781 20.8841 37.5815 21.3547 37.5815 22.0493C37.5815 22.7664 36.9988 23.3491 35.9232 23.3491C34.3322 23.3491 32.8084 22.5199 32.8084 22.5199L31.8672 25.209C31.8672 25.209 33.2565 26.1502 35.6095 26.1502C39.0156 26.1502 41.4806 24.4695 41.4806 21.4443C41.4582 19.8084 40.2257 18.6432 38.7467 17.7916Z" fill="black"/>
        <path d="M52.3262 12.1217C50.6456 12.1217 49.3234 12.9284 48.315 14.1385L48.2702 14.1161L49.7268 6.49707H45.9397L42.2422 25.9032H46.0293L47.2842 19.2702C47.7772 16.7604 49.0769 15.2142 50.287 15.2142C51.1386 15.2142 51.4747 15.7968 51.4747 16.6259C51.4747 17.1413 51.4299 17.7912 51.3178 18.3066L49.8837 25.9032H53.6708L55.1498 18.0601C55.3066 17.231 55.4187 16.245 55.4187 15.5727C55.4411 13.4214 54.3206 12.1217 52.3262 12.1217Z" fill="black"/>
        <path d="M64.0244 12.1221C59.453 12.1221 56.4277 16.2453 56.4277 20.8392C56.4277 23.7747 58.2429 26.1501 61.649 26.1501C66.1308 26.1501 69.1561 22.1389 69.1561 17.433C69.1785 14.7215 67.5874 12.1221 64.0244 12.1221ZM62.1644 23.2593C60.8647 23.2593 60.3269 22.1613 60.3269 20.7719C60.3269 18.5983 61.4474 15.0576 63.509 15.0576C64.8535 15.0576 65.3017 16.2229 65.3017 17.3434C65.3017 19.6739 64.1588 23.2593 62.1644 23.2593Z" fill="black"/>
        <path d="M78.8807 12.1221C76.3261 12.1221 74.8695 14.3854 74.8695 14.3854H74.8247L75.0487 12.3462H71.6874C71.5305 13.7131 71.2168 15.8196 70.9255 17.3882L68.2812 31.2817H72.0684L73.1216 25.6571H73.2112C73.2112 25.6571 73.9955 26.1501 75.4297 26.1501C79.8891 26.1501 82.8022 21.5787 82.8022 16.9624C82.8022 14.4078 81.6594 12.1221 78.8807 12.1221ZM75.2504 23.3041C74.2644 23.3041 73.6818 22.7439 73.6818 22.7439L74.3093 19.2033C74.7574 16.828 75.9899 15.2593 77.3121 15.2593C78.4773 15.2593 78.8359 16.335 78.8359 17.3434C78.8359 19.8083 77.3793 23.3041 75.2504 23.3041Z" fill="black"/>
        <path d="M88.2245 6.67676C87.0144 6.67676 86.0508 7.64034 86.0508 8.87284C86.0508 9.99328 86.7679 10.7776 87.8435 10.7776H87.8883C89.076 10.7776 90.0844 9.97088 90.1068 8.58152C90.1068 7.48348 89.3673 6.67676 88.2245 6.67676Z" fill="black"/>
        <path d="M82.9141 25.8809H86.7012L89.2782 12.4355H85.4687L82.9141 25.8809Z" fill="black"/>
        <path d="M98.9363 12.4134H96.292L96.4264 11.7859C96.6505 10.4862 97.4124 9.34336 98.6898 9.34336C99.362 9.34336 99.8998 9.54504 99.8998 9.54504L100.639 6.56465C100.639 6.56465 99.9895 6.22852 98.5777 6.22852C97.2332 6.22852 95.8886 6.60947 94.8578 7.48342C93.5581 8.58146 92.9531 10.1725 92.6617 11.7859L92.5497 12.4134H90.7794L90.2192 15.2817H91.9895L89.9727 25.9036H93.7598L95.7766 15.2817H98.3984L98.9363 12.4134Z" fill="black"/>
        <path d="M108.08 12.4355C108.08 12.4355 105.705 18.4187 104.652 21.6904H104.607C104.54 20.6372 103.666 12.4355 103.666 12.4355H99.677L101.963 24.7829C102.007 25.0518 101.985 25.2311 101.873 25.4103C101.425 26.2619 100.685 27.091 99.8114 27.696C99.0943 28.2115 98.2876 28.5476 97.6602 28.7717L98.7134 31.9986C99.4753 31.8417 101.089 31.1918 102.433 29.9369C104.159 28.3235 105.772 25.8137 107.408 22.4075L112.047 12.4355H108.08Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_6319_18708">
          <rect width="112.045" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
