import React from "react";
import cx from "classnames";

const FallbackSpinner = ({ borderColor = "border-white" }) => {
  return (
    <div className=" flex justify-center items-center">
      <div
        className={cx(
          "animate-spin rounded-full h-4 w-4 border-b-2",
          borderColor
        )}
      ></div>
    </div>
  );
};

export default FallbackSpinner;
