import React from "react";
import { withTranslation } from "react-i18next";
import { clamp } from "lodash";

import AppLayout from "../common/AppLayout";
import Header from "../common/Header";
import SubHeader from "../common/SubHeader";
import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import { getXCSRFToken } from "../../util/getXCSRFToken";

import Form from "./form";

import translationEN from "../../translations/merchantLanguages/translationEN.json";
import translationDE from "../../translations/merchantLanguages/translationDE.json";

import "react-notifications-component/dist/theme.css";

class MerchantLanguages extends React.Component {
  constructor(props) {
    super(props);

    const merchantLanguagesAttributes =
      this.props.merchant_languages_attributes;

    this.state = {
      merchantLanguages: merchantLanguagesAttributes,
      selectedLanguage: merchantLanguagesAttributes[0],
      selectedLanguageIndex: 0,
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      tI18next: this.props.t,
    };
  }

  handleLanguageInputChange = (index, name, value) => {
    this.setState((prevState) => {
      const { merchantLanguages } = prevState;
      merchantLanguages[index][name] = value;
      return { merchantLanguages };
    });
  };

  handleTextBlockInputChange = (index, name, value) => {
    this.setState((prevState) => {
      const merchantLanguages = prevState.merchantLanguages;
      merchantLanguages[index]["text_block_attributes"][name] = value;
      return { merchantLanguages };
    });
  };

  setSelectedLanguage = (language, index) => {
    this.setState({
      selectedLanguage: language,
      selectedLanguageIndex: index,
    });
  };

  submitDeleteRequest = async () => {
    const response = await fetch(
      `/merchant_languages/${this.state.selectedLanguage.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": getXCSRFToken(),
        },
      }
    );

    if (response.status === 200) {
      this.setState((prevState) => {
        const { merchantLanguages, selectedLanguageIndex } = prevState;
        merchantLanguages.splice(selectedLanguageIndex, 1);

        const newIndex = clamp(
          selectedLanguageIndex,
          0,
          merchantLanguages.length - 1
        );

        return {
          merchantLanguages,
          selectedLanguage: merchantLanguages[newIndex],
          selectedLanguageIndex: newIndex,
        };
      });

      SuccessToast({
        toastId: "LanguageDeletionSuccess",
        message: "Deleted successfully!",
      });
    } else {
      const responseJson = await response.json();
      ErrorToast({
        toastId: "LanguageDeletionError",
        message: responseJson.error,
      });
    }
  };

  submitCreateForm = async () => {
    const response = await fetch("/merchant_languages", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
    });

    if (response.status === 201) {
      const responseJson = await response.json();
      const merchantLanguagesAttributes =
        responseJson.merchant_languages_attributes;
      const lastIndex = merchantLanguagesAttributes.length - 1;
      this.setState({
        merchantLanguages: merchantLanguagesAttributes,
        selectedLanguage: merchantLanguagesAttributes[lastIndex],
        selectedLanguageIndex: lastIndex,
      });
      SuccessToast({
        toastId: "LanguageCreateSuccess",
        message: "Created successfully!",
      });
    } else {
      const responseJson = await response.json();
      ErrorToast({
        toastId: "LanguageCreateError",
        message: responseJson.error,
      });
    }
  };

  submitEditForm = async () => {
    const params = {
      merchant_languages_attributes: this.state.merchantLanguages,
    };

    const response = await fetch("/merchant_languages/update_text_blocks", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(params),
    });

    if (response.status === 200) {
      const responseJson = await response.json();
      const merchantLanguagesAttributes =
        responseJson.merchant_languages_attributes;
      this.setState({
        merchantLanguages: merchantLanguagesAttributes,
        selectedLanguage:
          merchantLanguagesAttributes[this.state.selectedLanguageIndex],
      });
      SuccessToast({ toastId: "TextBlockUpdateSuccess" });
    } else {
      const responseJson = await response.json();
      ErrorToast({
        toastId: "TextBlockUpdateError",
        message: responseJson.error,
      });
    }
  };

  render() {
    return (
      <AppLayout
        translations={{ en: translationEN, de: translationDE }}
        language={this.props.current_user.locale}
      >
        <Header currentUser={this.props.current_user}>
          <Header.Column>
            <div className="font-medium text-xs leading-4">
              <p>
                <span className="text-gray-525">
                  <a href="/settings">{this.state.t.settings}</a> /{" "}
                </span>

                <span className="text-gray-525">
                  <a href="/return_portals">{this.state.t.portal_settings}</a> /{" "}
                </span>

                <span className="text-gray-725 text-xs13">
                  {this.state.t.language_settings}
                </span>
              </p>
            </div>
          </Header.Column>
        </Header>

        <SubHeader>
          <SubHeader.Column>
            <div className="flex items-center">
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.791016 8.04036L0.791016 1.79037C0.791016 1.79037 3.08268 -0.0846364 5.99935 1.79037C8.91602 3.66537 11.2077 1.79037 11.2077 1.79037V8.04036C11.2077 8.04036 8.91602 9.91537 5.99935 8.04036C3.08269 6.16536 0.791016 8.04036 0.791016 8.04036ZM0.791016 8.04036L0.791016 13.0404"
                  stroke="#27272A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <h5 className="font-semibold text-base leading-6 text-gray-975 ml-5">
                {this.state.t.language_settings}
              </h5>
            </div>
          </SubHeader.Column>

          <SubHeader.Column>
            <div>
              <button
                className="mr-4 bg-blue-600 rounded-lg px-4 py-2 font-semibold text-sm leading-5 text-white border focus:outline-none"
                onClick={this.submitCreateForm}
              >
                {this.state.t.create_language}
              </button>

              <button
                className="bg-green-800 rounded-lg px-4 py-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:bg-green-800 focus:shadow-btn_green"
                onClick={this.submitEditForm}
              >
                {this.state.t.save_changes}
              </button>
            </div>
          </SubHeader.Column>
        </SubHeader>

        <div className="border-b mb-4 mt-4">
          {this.state.merchantLanguages.map((language, index) => (
            <button
              key={index}
              className={`${
                index === this.state.selectedLanguageIndex
                  ? "border-b-2 inline-block border-gray-825 font-semibold text-gray-825"
                  : "font-medium text-gray-625"
              } w-max pb-2 font-inter text-sm leading-5 focus:outline-none ml-8`}
              onClick={() => this.setSelectedLanguage(language, index)}
            >
              {language.language_name}
            </button>
          ))}
        </div>

        <Form
          languages={this.props.languages}
          selectedLanguage={this.state.selectedLanguage}
          selectedLanguageIndex={this.state.selectedLanguageIndex}
          text_block={this.state.selectedLanguage.text_block_attributes}
          handleLanguageInputChange={this.handleLanguageInputChange}
          handleTextBlockInputChange={this.handleTextBlockInputChange}
          current_merchant={this.props.current_merchant}
          submitDeleteRequest={this.submitDeleteRequest}
          t={this.state.t}
          tI18next={this.state.tI18next}
          defaultImprintUrl={this.props.default_imprint_url}
          defaultPrivacyPolicyUrl={this.props.default_privacy_policy_url}
        />
      </AppLayout>
    );
  }
}

export default withTranslation()(MerchantLanguages);
