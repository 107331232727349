import React from "react";
import cx from "classnames";
import { random } from "lodash";

export function ToggleWithoutLabel({
  name,
  id = `toggle-${random(1000)}`,
  onChange,
  checked,
}) {
  return (
    <div
      className={cx(
        "relative inline-block w-10 min-w-40 mr-2 align-middle select-none transition duration-200 ease-in"
      )}
    >
      <input
        id={id}
        className={cx(
          "toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4",
          "appearance-none cursor-pointer"
        )}
        type="checkbox"
        name={name}
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
      />

      <label
        className={cx(
          "toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
        )}
      />
    </div>
  );
}
