import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { connectWithKlaviyo, setKlaviyoStatus } from "../../services/api";
import { ErrorToast } from "../../common/ErrorToast";
import { SuccessToast } from "../../common/SuccessToast";
import translationEN from "../../../translations/integrations/translationEN.json";
import translationDE from "../../../translations/integrations/translationDE.json";

export const useKlaviyo = ({ klaviyo_integration, current_user }) => {
  const { private_api_key } = klaviyo_integration;

  function getInitialState() {
    return {
      klaviyoIntegration: {
        ...klaviyo_integration,
        private_api_key: "",
        private_api_key_filtered: private_api_key || "",
      },
      klaviyoConnected: {
        connected: !!private_api_key,
        show: !!private_api_key,
      },
      notificationId: 0,
      disableButton: false,
    };
  }

  const translations = { en: translationEN, de: translationDE };
  const { t } = useTranslation();
  const [state, setState] = useState(getInitialState());

  function handleKlaviyoInputChange(e) {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      klaviyoIntegration: {
        ...prevState.klaviyoIntegration,
        [e.target.name]: e.target.value,
      },
    }));
  }

  async function handleKlaviyoCheckBoxChange({ name, value }) {
    const body = {
      is_active: value,
    };
    const response = await setKlaviyoStatus(body);

    if (response.status === 200) {
      setState((prevState) => ({
        ...prevState,
        klaviyoIntegration: {
          ...prevState.klaviyoIntegration,
          [name]: value,
        },
      }));
      SuccessToast({ toastId: "activateKlaviyo" });
    } else {
      response.json().then((responseJson) => {
        setState((prevState) => ({
          ...prevState,
          notificationId: state.notificationId + 1,
        }));
        ErrorToast({ message: responseJson.error });
      });
    }
  }

  async function connectKlaviyo() {
    setState((prevState) => ({
      ...prevState,
      disableButton: true,
    }));
    const response = await connectWithKlaviyo(state.klaviyoIntegration);
    if (response.status === 200) {
      response.json().then((responseJson) => {
        setState((prevState) => ({
          ...prevState,
          notificationId: prevState.notificationId + 1,
          disableButton: false,
          klaviyoConnected: {
            connected: true,
            show: true,
          },
        }));

        if (state.klaviyoIntegration.private_api_key) {
          setState((prevState) => ({
            ...prevState,
            klaviyoIntegration: {
              ...state.klaviyoIntegration,
              private_api_key: "",
              private_api_key_filtered:
                state.klaviyoIntegration.private_api_key,
            },
          }));
        }
        SuccessToast({ toastId: "updateKlaviyo" });
      });
    } else {
      response.json().then((responseJson) => {
        setState((prevState) => ({
          ...prevState,
          notificationId: state.notificationId + 1,
          disableButton: false,
          klaviyoConnected: {
            connected: false,
            show: true,
          },
        }));
        ErrorToast({ message: responseJson.error });
      });
    }
  }

  return {
    state,
    t,
    translations,
    handleKlaviyoInputChange,
    handleKlaviyoCheckBoxChange,
    connectKlaviyo,
  };
};
