import React from "react";
import ReactNotification from "react-notifications-component";

import Header from "../../header/index";
import SubHeaderOld from "../../common/SubHeaderOld";
import { DhlLogoIcon } from "../../ui-kit/icons/dhl_logo";
import { Toggle } from "../../../ui-kit/components/Toggle";
import { Typography } from "../../../ui-kit/components/Typography/Typography";
import { Select } from "../../../ui-kit/components/Select";

import { useDhl } from "./dhl.hook";

import "react-notifications-component/dist/theme.css";

const Dhl = ({
  current_user,
  current_merchant,
  return_address,
  live_tracking_enabled,
  api_key,
}) => {
  const {
    state,
    t,
    handleDhlIntegrationInputChange,
    connectDhl,
    handleCheckBoxChange,
    handleDropdownValue,
    handleApiKeyInputChange,
  } = useDhl({
    return_address,
    current_user,
    current_merchant,
    live_tracking_enabled,
    api_key,
  });

  const {
    returnAddress,
    dhlConnected,
    shopBackend,
    disableButton,
    shipmentStatusUpdate,
    liveTrackingEnabled,
    apiKey,
  } = state;

  const shipmentStatusOptions = [
    {
      value: "pre-transit",
      label: "pre-transit",
    },
    {
      value: "transit",
      label: "transit",
    },
    {
      value: "delivered",
      label: "delivered",
    },
  ];

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header
        current_user={current_user}
        text={"DHL"}
        settings={t.settings}
        integrations={t.integrations}
      />
      <SubHeaderOld text={"DHL"} />
      <div className="py-8 px-8">
        <DhlLogoIcon />
        <div className="w-2/3 pt-8">
          <div className="flex justify-between items-center">
            <h1 className="font-semibold text-base leading-6 text-gray-825">
              {t.dhl_integration}
            </h1>
            <p
              className={`rounded-lg font-semibold text-sm leading-5 py-2 px-3 ${
                dhlConnected.connected
                  ? "bg-teal-125 text-green-825"
                  : "text-red-525 bg-red-450"
              } ${dhlConnected.show ? "" : "hidden"}`}
            >
              {dhlConnected.connected ? t.connected : t.invalid_credentials}
            </p>
          </div>
          <div className="flex items-center mt-5">
            <div className="w-1/2">
              <label className="font-medium text-xs leading-4 text-gray-725">
                {t.dhl_username}
              </label>
              <input
                className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325"
                name="dhl_username"
                onChange={handleDhlIntegrationInputChange}
                value={returnAddress.dhl_username}
              />
            </div>
            <div className="w-1/2 ml-5">
              <label className="font-medium text-xs leading-4 text-gray-725">
                {t.dhl_password}
              </label>
              <input
                className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 placeholder-gray-875 focus:border focus:border-blue-675 border-gray-325"
                placeholder={
                  returnAddress.dhl_password_filtered ? "FILTERED" : ""
                }
                name="dhl_password"
                onChange={handleDhlIntegrationInputChange}
                value={returnAddress.dhl_password}
              />
            </div>
          </div>
          <div className="flex items-start mt-4">
            <div className="w-1/2">
              <label className="font-medium text-xs leading-4 text-gray-725">
                {t.dhl_receiver_id}
              </label>
              <input
                className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325"
                name="dhl_receiver_id"
                type="text"
                onChange={handleDhlIntegrationInputChange}
                value={returnAddress.dhl_receiver_id}
              />
            </div>

            {shopBackend === "shopify_integration" && (
              <div className="w-1/2 ml-5">
                <label className="font-medium text-xs leading-4 text-gray-725">
                  {t.dhl_customer_reference}
                </label>

                <input
                  className="w-full mt-1 bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                  name="note_attribute_name"
                  onChange={handleDhlIntegrationInputChange}
                  value={returnAddress.note_attribute_name}
                />

                <p className="font-normal text-xs leading-4 text-gray-725 mt-2">
                  {t.dhl_customer_reference_capture}
                </p>
              </div>
            )}
          </div>

          <div className="mt-5 mb-5">
            <Toggle
              id="live_tracking_enabled"
              name="live_tracking_enabled"
              className="w-1/2 mt-8"
              onChange={(value) =>
                handleCheckBoxChange({ name: "liveTrackingEnabled", value })
              }
              checked={liveTrackingEnabled}
              label={{
                text: t.dhl_live_tracking_title,
                className:
                  "inline font-medium text-sm leading-5 text-gray-975 cursor-pointer",
              }}
            />
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
              className="mt-2"
            >
              {t.dhl_live_tracking_description}
            </Typography>

            {liveTrackingEnabled && (
              <div className="flex">
                <div className="w-1/2">
                  <div className="mt-6">
                    <Typography
                      element="p"
                      weight="medium"
                      size="xs"
                      height="4"
                      color="gray-725"
                      className="mt-2 mb-1"
                    >
                      DHL API Key (
                      <a
                        className="underline"
                        href="https://docs.8returns.com/help-center-en/integrations/dhl/get-dhl-api-key"
                        target="_blank"
                      >
                        Link
                      </a>
                      )
                    </Typography>

                    <input
                      className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 placeholder-gray-875 focus:border focus:border-blue-675 border-gray-325"
                      name="apiKey"
                      onChange={({ target: { name, value } }) =>
                        handleApiKeyInputChange({ name, value })
                      }
                      value={apiKey}
                    />
                  </div>

                  <div className="mt-6">
                    <Typography
                      element="p"
                      weight="medium"
                      size="xs"
                      height="4"
                      color="gray-725"
                      className="mt-2 mb-1"
                    >
                      {t.shipment_status}
                    </Typography>
                    <Select
                      className="w-full border box-border border-gray-325 font-medium text-sm leading-5 text-gray-875 rounded-lg px-1 focus:outline-none focus:border-blue-675 focus:border"
                      options={shipmentStatusOptions}
                      onChange={(value) =>
                        handleDropdownValue({
                          name: "shipmentStatusUpdate",
                          value,
                        })
                      }
                      value={shipmentStatusUpdate}
                    />
                    <Typography
                      element="p"
                      weight="medium"
                      size="xs"
                      height="4"
                      color="gray-725"
                      className="mt-2 mb-1"
                    >
                      {t.dhl_live_status}
                    </Typography>
                  </div>
                </div>

                <div className="w-1/2 ml-5"></div>
              </div>
            )}
          </div>

          <button
            className={`bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue ${
              disableButton ? "cursor-default" : ""
            }`}
            disabled={disableButton}
            onClick={connectDhl}
          >
            {t.connect}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dhl;
