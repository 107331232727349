import React from "react";

import Rule from "./components/Rule";

export default function RulesList({
  rules,
  updateRule,
  deleteRule,
  validateItemTag,
  t,
}) {
  return rules.map(({ id, itemTags, valueType, value, priority }, index) => (
    <Rule
      key={index}
      index={index}
      itemTags={itemTags}
      valueType={valueType}
      value={value}
      priority={priority}
      updateRule={updateRule}
      deleteRule={deleteRule}
      validateItemTag={validateItemTag}
      t={t}
    />
  ));
}
