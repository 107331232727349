import React from "react";
import SearchInput from "./SearchInput";

const RemoveShopifyIntegration = ({ shopName, onChange }) => (
  <>
    <SearchInput
      type="text"
      placeholder="shop name"
      value={shopName}
      onChange={(e) =>
        onChange({
          shopName: e.target.value,
        })
      }
    />
    <p className="ml-4 font-medium text-xs">
      Copy shop name from shopify integration page
    </p>
  </>
);

export default RemoveShopifyIntegration;
