import React from "react";

import { ReturnReasonRaw } from "../../Scayle.types";

type ReturnReasonProps = {
  t: any;
  value: number;
  setSelectedReason: (value: number) => void;
  returnReasons: ReturnReasonRaw[];
};

const ReturnReason = ({
  t,
  value,
  setSelectedReason,
  returnReasons,
}: ReturnReasonProps) => {
  return (
    <>
      <div className="pt-8 text-sm text-gray-725">
        {t("select_return_reason")}
      </div>

      <select
        name="return_reason"
        className="w-1/4 text-sm text-gray-825 border-2 box-border rounded-lg border-gray-225 px-4 py-2 mt-3"
        value={value}
        onChange={(e) => setSelectedReason(parseInt(e.target.value))}
      >
        {returnReasons.map(({ id, reason_internal }, index) => (
          <option key={index} value={id}>
            {reason_internal}
          </option>
        ))}
      </select>
    </>
  );
};

export default ReturnReason;
