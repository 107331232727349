import countryList from "react-select-country-list";
import { memoize, uniqBy } from "lodash";

export const getCountryList = memoize(() =>
  uniqBy(
    [
      { value: "", label: "Country" },
      { value: "DE", label: "Germany" },
      { value: "GB", label: "United Kingdom" },
      { value: "AT", label: "Austria" },
      { value: "FR", label: "France" },
      ...countryList().getData(),
    ],
    "label"
  )
);
