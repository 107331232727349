import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useShopware } from "./shopware.hook";
import { ShopwareLogoIcon } from "../../ui-kit/icons/shopware_logo";
import Header from "../../header/index";
import SubHeaderOld from "../../common/SubHeaderOld";

const Shopware = ({ current_user, shopware_integration }) => {
  const { state, t, handleShopwareInputChange, connectShopware } = useShopware({
    shopware_integration,
    current_user,
  });

  const { shopwareIntegration, storeConnected, disableButton } = state;

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header
        current_user={current_user}
        text={"Shopware"}
        settings={t.settings}
        integrations={t.integrations}
      />
      <SubHeaderOld text={"Shopware"} />
      <div className="w-2/3 mx-8 my-8">
        <ShopwareLogoIcon />
        <div className="flex justify-between mt-8">
          <h1 className="font-semibold text-base leading-6 text-gray-825">
            {t.shopware_integration}
          </h1>
          <p
            className={`rounded-lg font-semibold text-sm leading-5 py-2 px-3 ${
              storeConnected.connected
                ? "bg-teal-125 text-green-825"
                : "text-red-525 bg-red-450"
            } ${storeConnected.show ? "" : "hidden"}`}
          >
            {storeConnected.connected ? t.connected : t.invalid_credentials}
          </p>
        </div>
        <div className="mt-5 block">
          <h1 className="font-medium text-xs leading-4 text-gray-725">
            {t.username}
          </h1>
          <input
            className="mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325"
            name="username"
            onChange={handleShopwareInputChange}
            value={shopwareIntegration.username}
          />
        </div>
        <div className="flex items-center mt-4">
          <div className="w-1/2 block">
            <label className="font-medium text-xs leading-4 text-gray-725">
              {t.host}
            </label>
            <input
              className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325"
              name="host"
              onChange={handleShopwareInputChange}
              value={shopwareIntegration.host}
              placeholder="https://storedomain.com"
            />
          </div>
          <div className="w-1/2 ml-5 block">
            <label className="font-medium text-xs leading-4 text-gray-725">
              {t.api_key_shopware}
            </label>
            <input
              className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 placeholder-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="api_key"
              value={shopwareIntegration.api_key}
              onChange={handleShopwareInputChange}
              placeholder={
                shopwareIntegration.api_key_filtered ? "FILTERED" : ""
              }
            />
          </div>
        </div>
        <button
          className={`bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue ${
            disableButton ? "cursor-default" : ""
          }`}
          disabled={disableButton}
          onClick={connectShopware}
        >
          {t.connect}
        </button>
      </div>
    </div>
  );
};

export default Shopware;
