import React from "react";
import {
  DragDropContext as ReactDragDropContext,
  Droppable as ReactDroppable,
} from "react-beautiful-dnd";

import { useDroppable } from "./Droppable.hook";

export default function Droppable({ list, setList, positionField, children }) {
  const { onDragEnd } = useDroppable({
    list,
    setList,
    positionField,
  });

  return (
    <ReactDragDropContext onDragEnd={onDragEnd}>
      <ReactDroppable droppableId="list">
        {({ innerRef, droppableProps, placeholder }) => (
          <div ref={innerRef} {...droppableProps}>
            {children}
            {placeholder}
          </div>
        )}
      </ReactDroppable>
    </ReactDragDropContext>
  );
}
