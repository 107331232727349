import React from "react";
import ReactNotification from "react-notifications-component";
import { Typography } from "../../ui-kit/components/Typography/Typography";
import SubHeaderOld from "../common/SubHeaderOld";
import Header from "../../components/header/index";
import { useStoreCreditSettings } from "./index.hook";
import { StoreCreditIcon } from "../../ui-kit/icons/StoreCreditIcon";
import { FreeTrialScreen } from "./components/FreeTrialScreen";
import { SettingScreen } from "./components/SettingScreen";

const StoreCreditSettings = ({
  current_user,
  store_credit_settings,
  retained_customers,
  retained_revenue,
  payment_integration_route,
  instant_store_credit_enabled,
}) => {
  const { state, t, handleStoreCreditChange, saveStoreCredit, startFreeTrial } =
    useStoreCreditSettings({
      current_user,
      store_credit_settings,
      payment_integration_route,
    });

  const { storeCreditSettings, isFreeTrialStarted } = state;

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header current_user={current_user} title={t("store_credit")} />
      <SubHeaderOld text={t("store_credit")} Icon={StoreCreditIcon} />
      {isFreeTrialStarted ? (
        <div className="flex border-b mt-3 ml-4 px-8 py-4">
          <div className="w-1/4 mr-5">
            <Typography
              element="div"
              weight="normal"
              size="md"
              height="4"
              color="gray-725"
            >
              {t("retained_revenue")}
            </Typography>
            <Typography
              element="div"
              weight="semibold"
              size="lg"
              height="6"
              color="gray-825"
              className="font-clash_grotesk mt-1"
            >
              {retained_revenue}
            </Typography>
          </div>
          <div className="w-1/4">
            <Typography
              element="div"
              weight="normal"
              size="md"
              height="4"
              color="gray-725"
            >
              {t("retained_customers")}
            </Typography>
            <Typography
              element="div"
              weight="semibold"
              size="lg"
              height="6"
              color="gray-825"
              className="font-clash_grotesk mt-1"
            >
              {retained_customers}
            </Typography>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="ml-10">
        <Typography
          element="div"
          weight="semibold"
          size="lg"
          height="5"
          color="gray-975"
          className=" mt-10"
        >
          {t("retain_more_customers_and_revenue")}
        </Typography>
        <Typography
          element="div"
          weight="normal"
          size="sm"
          height="5"
          color="gray-625"
          className="mt-3"
        >
          {t("offer_store_credit_description")}
        </Typography>
        <Typography
          element="div"
          weight="normal"
          size="sm"
          height="5"
          color="gray-625"
        >
          {t("offer_additional_bonus_credit_description")}
        </Typography>
      </div>

      {isFreeTrialStarted ? (
        <SettingScreen
          t={t}
          handleStoreCreditChange={handleStoreCreditChange}
          storeCreditSettings={storeCreditSettings}
          saveStoreCredit={saveStoreCredit}
          instantStoreCreditEnabled={instant_store_credit_enabled}
        />
      ) : (
        <FreeTrialScreen t={t} startFreeTrial={startFreeTrial} />
      )}
    </div>
  );
};

export default StoreCreditSettings;
