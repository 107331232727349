import React from "react"

export const KlaviyoLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1200 600"
      version="1.1"
      viewBox="175 0 1200 600"
      xmlSpace="preserve"
      width="160"
      height="70"
    >
      <linearGradient
        id="SVGID_1_"
        x1="201.646"
        x2="201.646"
        y1="888.049"
        y2="559.35"
        gradientTransform="translate(133.678 -191.89) scale(.7071)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#F1C205"></stop>
        <stop offset="0.331" stopColor="#93C94B"></stop>
        <stop offset="0.486" stopColor="#70C05A"></stop>
        <stop offset="0.751" stopColor="#12B259"></stop>
        <stop offset="0.903" stopColor="#24AD69"></stop>
        <stop offset="1" stopColor="#24AD69"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M110.974 308.875L265.436 206.55c6.091-3.898 15.592-3.898 21.683 0L441.58 308.875c9.258 6.091 9.258 16.08 0 22.17l-20.709 13.643c-30.454-47.995-84.052-79.667-144.716-79.667-60.908 0-114.263 31.916-144.716 79.667l-20.709-13.643c-9.014-6.09-9.014-16.322.244-22.17zm165.182-1.218c-46.046 0-86.245 24.363-109.146 60.42l35.57 23.632c15.105-24.85 42.148-41.417 73.333-41.417 30.941 0 58.228 16.567 73.333 41.417l35.57-23.632c-22.172-36.057-62.614-60.42-108.66-60.42zm0 85.515c-16.08 0-30.21 9.014-37.763 22.17l23.876 15.592c3.898 3.167 8.527 5.116 13.887 5.116s10.233-1.949 13.887-5.116l23.876-15.592c-7.066-12.913-21.44-22.17-37.763-22.17z"
      ></path>
      <path
        fill="#181B1E"
        d="M544.392 319.352l38.737 55.548h-27.287l-29.236-43.123V374.9h-22.17V264.778h22.17v43.853l27.774-43.853h25.581l-35.569 54.574zm76.988-54.33h-22.17v110.121h56.766v-22.17h-35.083l.487-87.951zm100.132-3.898l46.29 114.019h-24.85l-4.385-11.938h-36.545l-4.385 11.938h-25.094l46.29-114.019h2.679zm9.014 81.372l-9.989-28.505-10.232 28.505h20.221zm68.46-16.323l-22.658-61.151h-24.85l46.29 114.019h2.193l46.29-114.019h-25.094l-22.171 61.151zm64.075 48.97h22.17V265.022h-22.17v110.121zm110.121-110.121l-22.414 38.006-22.414-38.006h-26.799l38.25 60.42v49.701h21.927v-49.701l38.494-60.42h-27.044zM1114 318.133c0 31.428-25.581 57.009-57.01 57.009s-57.01-25.581-57.01-57.009 25.581-57.009 57.01-57.009 57.01 25.338 57.01 57.009zm-21.439 0c0-19.734-15.836-35.57-35.57-35.57s-35.57 15.836-35.57 35.57a35.456 35.456 0 0035.57 35.57c19.734 0 35.57-16.079 35.57-35.57z"
      ></path>
    </svg>
  )
}
