import React, { useState } from "react";

export const useCheckoutV3ToggleState = ({
                                           initialValue,
                                           updateMerchantSettings,
                                           showSuccessNotification,
                                           showErrorNotification,
                                         }) => {
  const [checkoutV3State, setCheckoutV3State] = useState(initialValue)

  async function toggleSettingsCheckoutV3() {
    const value = !checkoutV3State
    setCheckoutV3State(value)

    const response = await updateMerchantSettings({
      show_checkout_v3: value,
    })

    if(response.status == 200) {
      const { show_checkout_v3 } = await response.json()
      setCheckoutV3State(show_checkout_v3)
      showSuccessNotification()
    } else {
      const { error } = await response.json()
      showErrorNotification(error)
    }
  }

  return {
    checkoutV3State,
    toggleSettingsCheckoutV3,
  }
}
