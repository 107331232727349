import React from "react";

export const EmailIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16602 5.16732C3.16602 4.43094 3.76297 3.83398 4.49935 3.83398H11.4993C12.2357 3.83398 12.8327 4.43094 12.8327 5.16732V10.834C12.8327 11.5704 12.2357 12.1673 11.4993 12.1673H4.49935C3.76297 12.1673 3.16602 11.5704 3.16602 10.834V5.16732Z"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66602 4.33398L7.99935 8.16732L12.3327 4.33398"
        stroke="#2F80ED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
