import React from "react";

import { Select } from "../../ui-kit/components/Select";

import CustomReturnSection from "./CustomReturnSection";
import { LoginPage } from "./components/LoginPage";
import { ItemSelection } from "./components/ItemSelection";
import { ReturnMethodSelection } from "./components/ReturnMethodSelection";
import { Overview } from "./components/Overview";
import { ToastMessages } from "./components/ToastMessages";
import { RefundTypeSelection } from "./components/RefundTypeSelection";
import { InstantStoreCredit } from "./components/InstantStoreCredit";
import { ReturnConfirmationPage } from "./components/ReturnConfirmationPage";
import { OtherSettings } from "./components/OtherSettings";

class Form extends React.Component {
  constructor(props) {
    super(props);

    const { show_custom_return, show_exchange, localized_item_details } =
      this.props.current_merchant;

    this.show_custom_return = show_custom_return;
    this.show_exchange = show_exchange;
    this.localized_item_details = localized_item_details;

    this.state = {
      showDeleteModal: false,
    };
  }

  handleTextBlockInputChange = (e) =>
    this.props.handleTextBlockInputChange(
      this.props.selectedLanguageIndex,
      e.target.name,
      e.target.value
    );

  deletionHandler = async () => {
    await this.props.submitDeleteRequest();
    this.setState({ showDeleteModal: false });
  };

  render() {
    return (
      <div className="px-8 mt-6">
        <div className="border-b pb-4 w-762px">
          <div className="pb-4">
            {!this.props.selectedLanguage.published && (
              <div
                className="mb-5 text-xs font-semibold"
                dangerouslySetInnerHTML={{
                  __html: this.props.tI18next(
                    "language_activation_disclaimer",
                    {
                      portal_settings_link: "/return_portals",
                    }
                  ),
                }}
              />
            )}

            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {this.props.t.language_name}
            </p>

            {this.props.selectedLanguage.language_changeable ? (
              <Select
                className="px-2 w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 focus:outline-none"
                options={this.props.languages.map(({ name, id }) => ({
                  value: id,
                  label: name,
                }))}
                onChange={(value) =>
                  this.props.handleLanguageInputChange(
                    this.props.selectedLanguageIndex,
                    "language_id",
                    value
                  )
                }
                value={this.props.selectedLanguage.language_id}
              />
            ) : (
              <div className="px-4 py-2 w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 focus:outline-none">
                {this.props.selectedLanguage.language_name}
              </div>
            )}
          </div>

          <LoginPage
            t={this.props.t}
            onChange={this.handleTextBlockInputChange}
            textBlock={this.props.text_block}
            defaultImprintUrl={this.props.defaultImprintUrl}
            defaultPrivacyPolicyUrl={this.props.defaultPrivacyPolicyUrl}
          />
        </div>

        <ToastMessages
          t={this.props.t}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
        />

        <ItemSelection
          t={this.props.t}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
          showExchange={this.show_exchange}
          showLocalizedItemDetails={this.localized_item_details}
        />

        <ReturnMethodSelection
          t={this.props.t}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
        />

        <Overview
          t={this.props.t}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
        />

        <ReturnConfirmationPage
          t={this.props.t}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
        />

        {this.show_custom_return && (
          <CustomReturnSection
            t={this.props.t}
            tI18next={this.props.tI18next}
            handleInputChange={this.handleTextBlockInputChange}
            textBlock={this.props.text_block}
          />
        )}

        <RefundTypeSelection
          t={this.props.t}
          tI18next={this.props.tI18next}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
        />

        <InstantStoreCredit
          t={this.props.t}
          tI18next={this.props.tI18next}
          onChange={this.handleTextBlockInputChange}
          textBlock={this.props.text_block}
        />

        <OtherSettings
          selectedLanguage={this.props.selectedLanguage}
          t={this.props.t}
          tI18next={this.props.tI18next}
          submitDeleteRequest={this.props.submitDeleteRequest}
        />
      </div>
    );
  }
}

export default Form;
