import React from "react";
import cx from "classnames";

import { SearchIcon } from "../../../../../../ui-kit/icons/SearchIcon";
import FallbackSpinner from "../../../../../../ui-kit/components/FallbackSpinner";

type SearchOrderProps = {
  t: any;
  searchOrder: (e) => Promise<void>;
  setOrderNumberInput: (e) => void;
  orderNumberInput: string;
  searchInProgress: boolean;
};

const SearchOrder = ({
  t,
  searchOrder,
  setOrderNumberInput,
  orderNumberInput,
  searchInProgress,
}: SearchOrderProps) => {
  return (
    <>
      <div className="flex items-center w-1/4 pl-5 py-2 border border-gray-325 bg-white box-border rounded-lg">
        <SearchIcon />

        <form
          className="font-medium text-sm leading-4 text-gray-975 ml-2 w-full"
          onSubmit={searchOrder}
        >
          <input
            className="outline-none w-full"
            name="search_value"
            type="text"
            placeholder="Order number"
            onChange={(e) => setOrderNumberInput(e.target.value.trim())}
            value={orderNumberInput}
            autoFocus
          />
        </form>
      </div>

      <div className="ml-4">
        {searchInProgress ? (
          <FallbackSpinner borderColor="border-blue-650" />
        ) : (
          <p
            className={cx(
              { disabled: searchInProgress },
              "font-medium text-sm leading-4 text-gray-975 cursor-pointer"
            )}
            onClick={searchOrder}
          >
            {t("search")}
          </p>
        )}
      </div>
    </>
  );
};

export default SearchOrder;
