import React from "react";
import { AppLayout } from "../common/AppLayout";
import { useCollectPlus } from "./CollectPlus.hook";
import { CollectPlusLogo } from "../../../ui-kit/icons/CollectPlusLogo";
import SubHeaderOld from "../../../common/SubHeaderOld";

import "react-notifications-component/dist/theme.css";

const CollectPlus = ({
  current_user: currentUser,
  return_address: returnAddress,
}) => {
  const { t, state, handleInputChange, saveCollectPlusSettings } =
    useCollectPlus({
      currentUser,
      returnAddress,
    });

  const { returnAddress: returnAddressState, disableButton } = state;

  return (
    <AppLayout
      currentUser={currentUser}
      settings={t.settings}
      integrations={t.integrations}
      headerText="Collect+"
      renderSubHeader={<SubHeaderOld text="Collect+" />}
    >
      <CollectPlusLogo />

      <div className="w-2/3 pt-8">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-base leading-6 text-gray-825">
            {t.collect_plus_integration}
          </h1>
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <label className="font-medium text-xs leading-4 text-gray-725">
              {t.collect_plus_route_depot_number}
            </label>
            <input
              className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border border border-gray-325"
              name="collectPlusRouteDepotNumber"
              onChange={handleInputChange}
              value={returnAddressState.collectPlusRouteDepotNumber}
              maxLength="2"
            />
          </div>

          <div className="w-1/2 ml-5 block">
            <label className="font-medium text-xs leading-4 text-gray-725">
              {t.collect_plus_depot_short_name}
            </label>
            <input
              className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border py-9px px-4 font-medium text-sm leading-5 text-gray-875 placeholder-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="collectPlusDepotShortName"
              onChange={handleInputChange}
              value={returnAddressState.collectPlusDepotShortName}
              maxLength="4"
            />
          </div>
        </div>

        <button
          className={`bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue ${
            disableButton ? "cursor-default" : ""
          }`}
          disabled={disableButton}
          onClick={saveCollectPlusSettings}
        >
          {t.save}
        </button>
      </div>
    </AppLayout>
  );
};

export default CollectPlus;
