import { useState } from "react";

export const useOnStoreExchangeToggleState = ({
  initialValue,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [onStoreExchangeState, setOnStoreExchangeState] =
    useState(initialValue);

  async function toggleSettingsOnStoreExchange() {
    const value = !onStoreExchangeState;
    setOnStoreExchangeState(value);

    const response = await updateMerchantSettings({
      shop_exchange_enabled: value,
    });

    const responseJSON = await response.json();

    if (response.status === 200) {
      setOnStoreExchangeState(responseJSON.shop_exchange_enabled);
      showSuccessNotification();
    } else {
      setOnStoreExchangeState(!value);
      showErrorNotification(responseJSON.error);
    }
  }

  return {
    onStoreExchangeState,
    toggleSettingsOnStoreExchange,
  };
}
