import React from "react";
import Modal from "react-modal";
import "react-notifications-component/dist/theme.css";
import { find } from "lodash";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import { Input } from "../../../../../ui-kit/components/Input";
import { InputLabel } from "../../../../../ui-kit/components/InputLabel";
import { HomeEditIcon } from "../../../../../ui-kit/icons/HomeEditIcon";
import { EditIcon1 } from "../../../../../ui-kit/icons/EditIcon1";
import { Select } from "../../../../../ui-kit/components/Select";

Modal.setAppElement("#root");
export const CreateLabelModal = (props) => {
  const {
    t,
    showModal,
    ModelClose,
    isEditCustomer,
    isEditDhlReceiverId,
    labelCustomerInputHandler,
    labelReturnAddressInputHandler,
    editCustomer,
    return_label_customer,
    dhl_receiver_id,
    editDhlReceiverId,
    country_iso,
    labelFieldsPresent,
    create_dhl_label_spinner,
    createLable,
    selectCountry,
    countryOptions,
    backEditLabel,
    saveEditLabel,
    returnAddress,
    editReturnAddress,
    isEditReturnAddress,
    MerchantReturnMethodHandler,
    MerchantReturnMethodId,
    selectReturnAddressCountry,
    activeLabels,
  } = props;

  const inputLabelTypoGraphy = ({ text }) => {
    return <InputLabel>{text}</InputLabel>;
  };

  const inputCall = ({ type, name, value, onChange }) => {
    return (
      <Input
        type={type}
        className="focus:shadow-btn_blue"
        name={name}
        value={value}
        onChange={(value) =>
          onChange({
            name: name,
            value: value,
          })
        }
      />
    );
  };

  const getModalHeaderLabelText = () => {
    if (isEditCustomer) {
      return t.edit_customer_address;
    } else if (isEditDhlReceiverId) {
      return t.edit_dhl_receiver_id;
    } else if (isEditReturnAddress) {
      return t.edit_return_address;
    } else {
      return t.create_label;
    }
  };

  const dhlReceiverIdDiv = () => {
    return (
      <div className="px-6 pt-5 pb-5 border-b">
        <div>
          <Typography
            element="p"
            weight="semibold"
            size="sm"
            height="5"
            color="gray-825"
          >
            {t.dhl_receiver_id}
          </Typography>
        </div>
        <div className="my-3">
          <Typography
            element="p"
            weight="normal"
            size="sm"
            height="5"
            color="gray-725"
          >
            {dhl_receiver_id}
          </Typography>
        </div>
        <button
          className="flex items-center focus:outline-none"
          onClick={editDhlReceiverId}
        >
          <EditIcon1 />
          <Typography
            element="p"
            weight="semibold"
            size="xs13"
            height="4"
            color="gray-625"
            className="ml-1"
          >
            {t.edit}
          </Typography>
        </button>
      </div>
    );
  };

  const returnAddressDiv = () => {
    return (
      <div>
        <div className="px-6">
          <div className="py-6 border-b">
            <div>
              <Typography
                element="p"
                weight="semibold"
                size="sm"
                height="5"
                color="gray-825"
              >
                {t.return_address}
              </Typography>
            </div>
            <div className="my-3">
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {returnAddress.full_name}
              </Typography>
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {returnAddress.address1} {returnAddress.street_number}
              </Typography>
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {returnAddress.zip} {returnAddress.city}
              </Typography>
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {returnAddress.country_iso}
              </Typography>
            </div>
            <button
              className="flex items-center focus:outline-none"
              onClick={editReturnAddress}
            >
              <EditIcon1 />
              <Typography
                element="p"
                weight="semibold"
                size="xs13"
                height="4"
                color="gray-625"
                className="ml-1"
              >
                {t.edit}
              </Typography>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const isDhlLabel = () => {
    return find(activeLabels, function(o) { return o.value === MerchantReturnMethodId && o.type === 'dhl' })
  };

  const displayInfo = () => {
    return (
      <div>
        <div className="px-6">
          <div className="py-6 border-b">
            <div>
              <Typography
                element="p"
                weight="semibold"
                size="sm"
                height="5"
                color="gray-825"
              >
                {t.customer_address}
              </Typography>
            </div>
            <div className="my-3">
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {return_label_customer.full_name}
              </Typography>
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {return_label_customer.address1}{" "}
                {return_label_customer.street_number}
              </Typography>
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {return_label_customer.zip} {return_label_customer.city}
              </Typography>
              <Typography
                element="p"
                weight="normal"
                size="sm"
                height="5"
                color="gray-725"
                className="mb-1"
              >
                {country_iso}
              </Typography>
            </div>
            <button
              className="flex items-center focus:outline-none"
              onClick={editCustomer}
            >
              <EditIcon1 />
              <Typography
                element="p"
                weight="semibold"
                size="xs13"
                height="4"
                color="gray-625"
                className="ml-1"
              >
                {t.edit}
              </Typography>
            </button>
          </div>
        </div>
        {isDhlLabel() && dhlReceiverIdDiv()}
        {!isDhlLabel() && returnAddressDiv()}
        <div className="flex items-center justify-end mt-4 px-6">
          <button
            className="px-4 py-2 font-semibold text-sm leading-5 text-gray-875 border border-gray-325 hover:border-gray-425 box-border rounded-lg focus:shadow-btn_blue focus:border-blue-625 focus:outline-none"
            onClick={ModelClose}
          >
            {t.cancel}
          </button>
          <button
            className={`px-4 py-2 w-40 font-semibold text-sm leading-5 text-white border box-border rounded-lg ml-3 focus:outline-none ${
              create_dhl_label_spinner ? "cursor-default" : ""
            } ${
              labelFieldsPresent
                ? "bg-blue-675 focus:border-blue-625 focus:shadow-btn_blue hover:bg-blue-625"
                : "cursor-default text-gray-525 bg-gray-425"
            }`}
            disabled={create_dhl_label_spinner || !labelFieldsPresent}
            onClick={createLable}
          >
            {create_dhl_label_spinner ? (
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              </div>
            ) : (
              t.create_label
            )}
          </button>
        </div>
      </div>
    );
  };

  const isEditMode = () => {
    if (isEditCustomer || isEditReturnAddress || isEditDhlReceiverId) {
      return true;
    }
    return false;
  };

  const editCustomerDiv = () => {
    return (
      <div className="px-6 pt-6 pb-6 border-b">
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.first_name })}
          {inputCall({
            type: "text",
            name: "first_name",
            value: return_label_customer.first_name,
            onChange: labelCustomerInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.last_name })}
          {inputCall({
            type: "text",
            name: "last_name",
            value: return_label_customer.last_name,
            onChange: labelCustomerInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.street })}
          {inputCall({
            type: "text",
            name: "address1",
            value: return_label_customer.address1,
            onChange: labelCustomerInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.street_number })}
          {inputCall({
            type: "text",
            name: "street_number",
            value: return_label_customer.street_number,
            onChange: labelCustomerInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.zip_code })}
          {inputCall({
            type: "text",
            name: "zip",
            value: return_label_customer.zip,
            onChange: labelCustomerInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.city })}
          {inputCall({
            type: "text",
            name: "city",
            value: return_label_customer.city,
            onChange: labelCustomerInputHandler,
          })}
        </div>
        <div>
          {inputLabelTypoGraphy({ text: t.country })}
          <div className="bg-white box-border rounded-lg border py-2 px-4 w-full font-medium text-sm leading-5 text-gray-875">
            <select
              className="w-full focus:outline-none"
              name="country"
              defaultValue={return_label_customer.country}
              onChange={selectCountry}
            >
              {countryOptions.map((i, index) => (
                <option value={i.value} key={index} name={i.label}>
                  {i.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const editDhlDiv = () => {
    return (
      <div className="px-6 py-6 border-b">
        <div>
          {inputLabelTypoGraphy({ text: t.dhl_id })}
          {inputCall({
            type: "text",
            name: "dhl_receiver_id",
            value: dhl_receiver_id,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
      </div>
    );
  };

  const editReturnAddressDiv = () => {
    return (
      <div className="px-6 pt-6 pb-6 border-b">
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.address })}
          {inputCall({
            type: "text",
            name: "first_name",
            value: returnAddress.first_name,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.address_suffix })}
          {inputCall({
            type: "text",
            name: "last_name",
            value: returnAddress.last_name,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.street })}
          {inputCall({
            type: "text",
            name: "address1",
            value: returnAddress.address1,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.street_number })}
          {inputCall({
            type: "text",
            name: "street_number",
            value: returnAddress.street_number,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.zip_code })}
          {inputCall({
            type: "text",
            name: "zip",
            value: returnAddress.zip,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
        <div className="mb-4">
          {inputLabelTypoGraphy({ text: t.city })}
          {inputCall({
            type: "text",
            name: "city",
            value: returnAddress.city,
            onChange: labelReturnAddressInputHandler,
          })}
        </div>
        <div>
          {inputLabelTypoGraphy({ text: t.country })}
          <div className="bg-white box-border rounded-lg border py-2 px-4 w-full font-medium text-sm leading-5 text-gray-875">
            <select
              className="w-full focus:outline-none"
              name="country"
              defaultValue={returnAddress.country}
              onChange={selectReturnAddressCountry}
            >
              {countryOptions.map((i, index) => (
                <option value={i.value} key={index} name={i.label}>
                  {i.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const showEditDiv = () => {
    if (isEditCustomer) {
      return editCustomerDiv();
    } else if (isEditDhlReceiverId) {
      return editDhlDiv();
    } else if (isEditReturnAddress) {
      return editReturnAddressDiv();
    }
  };

  const getBackEditLabel = () => {
    if (isEditCustomer) {
      return "back_edit_customer";
    } else if (isEditReturnAddress) {
      return "back_edit_return_address";
    } else if (editDhlReceiverId) {
      return "back_edit_dhl_receiver_id";
    }
  };

  const getSaveEditLabel = () => {
    if (isEditCustomer) {
      return "save_customer_changes";
    } else if (isEditDhlReceiverId) {
      return "save_dhl_receiver_id_changes";
    } else if (editReturnAddress) {
      return "save_return_address_changes";
    }
  };

  const computeModalHeight = () => {
    if (isEditDhlReceiverId) {
      return "330px";
    } else if (isEditCustomer || isEditReturnAddress) {
      return "660px";
    } else {
      return "510px";
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={ModelClose}
      style={{
        content: {
          inset: "30px",
          maxWidth: "492px",
          maxHeight: computeModalHeight(),
          margin: "auto",
          padding: "0",
        },
        overlay: {
          background: "#00000080",
        },
      }}
    >
      <div className="pt-6 pb-4 text-left font-inter">
        <div className="flex items-center border-b pl-6 pb-6">
          <div className="rounded-80 bg-blue-675 p-2">
            <HomeEditIcon />
          </div>
          <div className="ml-4">
            <Typography
              element="p"
              weight="semibold"
              size="base"
              height="6"
              color="gray-825"
            >
              {getModalHeaderLabelText()}
            </Typography>
          </div>
        </div>
        <div className="mt-4 ml-6 w-1/2">
          <Select
            className="border box-border border-gray-325 font-medium text-sm leading-5 text-gray-875 rounded-lg px-1 focus:outline-none focus:border-blue-675 focus:border"
            options={activeLabels}
            onChange={(e) => MerchantReturnMethodHandler({ value: e })}
            value={MerchantReturnMethodId}
          />
        </div>
        {isEditMode() ? showEditDiv() : displayInfo()}
        {isEditMode() && (
          <div className="flex items-center justify-end mt-4 px-6">
            <button
              className="px-4 py-2 font-semibold text-sm leading-5 text-gray-875 border border-gray-325 hover:border-gray-425 box-border rounded-lg focus:shadow-btn_blue focus:border-blue-625 focus:outline-none"
              name={getBackEditLabel()}
              onClick={backEditLabel}
            >
              {t.back}
            </button>
            <button
              className="px-4 py-2 font-semibold text-sm leading-5 bg-green-675 hover:bg-green-825 text-white border box-border rounded-lg ml-3 focus:outline-none focus:border-green-825 focus:shadow-btn_green"
              name={getSaveEditLabel()}
              onClick={saveEditLabel}
            >
              {t.save_changes}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
