import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

export const TagsInput = ({
  value,
  onChange,
  ...props
}) => {
  // though <Tags> is an "un"controlled component, still we should pass the 'value' prop,
  // otherwise reordering the containers (e.g. partial refund rules) via drag-n-drop
  // would not reflect the change in the inputs.
  //
  // https://github.com/yairEO/tagify/issues/489#issuecomment-629316093
  return (
    <Tags
      {...props}
      value={value}
      onChange={({ detail: { value: values } }) => {
        // values: "[{\"value\":\"a\"},{\"value\":\"b\"}]"
        onChange(values);
      }}
    />
  );
}
