import React from "react";
import { camelCase } from "lodash";
import cx from "classnames";

import { ToggleWithoutLabel } from "../../../../ui-kit/components/ToggleWithoutLabel";
import { InputLabel } from "../../../../ui-kit/components/InputLabel";

export const MerchantSetting = ({
  settingsLabel,
  settingsEnabled,
  toggleSettings,
  enableOnce,
}) => {
  return (
    <div className="flex flex-row items-center py-4 h-12">
      <InputLabel
        htmlFor={camelCase(settingsLabel)}
        className="w-2/3 flex flex-col justify-start font-medium text-xs leading-4 text-gray-725"
        cursorStyle={cx({ "cursor-pointer": !(enableOnce && settingsEnabled) })}
      >
        {settingsLabel}
      </InputLabel>

      <div className="w-1/3 flex flex-col justify-end">
        {enableOnce && settingsEnabled ? (
          <div
            className={cx(
              "rounded-lg font-semibold text-sm leading-5 py-2 px-3",
              "bg-teal-125 text-green-825 w-max"
            )}
          >
            Active
          </div>
        ) : (
          <ToggleWithoutLabel
            id={camelCase(settingsLabel)}
            onChange={toggleSettings}
            checked={settingsEnabled}
          />
        )}
      </div>
    </div>
  );
};
