import React from "react";
import { random } from "lodash";

import { InputLabel } from "../InputLabel";

type LabelProps = {
  text: string;
  className: string;
};

type CheckboxProps = {
  name: string;
  value: boolean;
  handleChange: ({ name, value }) => void;
  label: LabelProps;
  id?: string;
};

const Checkbox = ({
  name,
  value,
  handleChange,
  label,
  id = `checkbox-${random(1000)}`,
}: CheckboxProps) => {
  return (
    <div className="flex items-center">
      <input
        className="cursor-pointer h-6 w-6 rounded"
        type="checkbox"
        id={id}
        name={name}
        onChange={(e) =>
          handleChange({
            name: e.target.name,
            value: e.target.checked,
          })
        }
        checked={value}
      />

      <InputLabel
        htmlFor={id}
        cursorStyle="cursor-pointer"
        className={label.className}
      >
        {label.text}
      </InputLabel>
    </div>
  );
};

export default Checkbox;
