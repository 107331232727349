import React, { useState } from "react";
import { debounce } from "lodash";
import {
  changeUserMerchant,
  enableWhatIsNewBadge,
  fetchMerchant,
  updateMerchantSettings,
} from "../services/api";

import { useMerchantSettingStates } from "./hooks/useMerchantSettingStates";
import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

export const useSuperAdmin = ({ currentMerchant }) => {
  function getInitialState() {
    return {
      merchantId: currentMerchant.id,
      options: [
        {
          label: `${currentMerchant.name} (ID: ${currentMerchant.id})`,
          value: currentMerchant.id,
        },
      ],
      whatIsBadgeConfirmation: false,
    };
  }

  const [state, setState] = useState(getInitialState());

  const {
    paywallState,
    toggleSettingsPaywall,
    checkoutV3State,
    toggleSettingsCheckoutV3,
    exchangeReturnsState,
    toggleSettingsExchangeReturns,
    customReturnsState,
    toggleSettingsCustomReturns,
    klaviyoIntegrationState,
    toggleSettingsKlaviyoIntegration,
    xentralIntegrationState,
    toggleSettingsXentralIntegration,
    peoplevoxIntegrationState,
    toggleSettingsPeoplevoxIntegration,
    onStoreExchangeState,
    toggleSettingsOnStoreExchange,
    createManualReturnState,
    toggleSettingsCreateManualReturnState,
    onboardingAssistantVisibilityDays,
    updateOnboardingAssistantState,
    finecomIntegrationState,
    toggleSettingsFinecomIntegration,
  } = useMerchantSettingStates({
    currentMerchant,
    updateMerchantSettings,
    showSuccessNotification,
    showErrorNotification,
  });

  async function loadOptions(value, callback) {
    const response = await fetchMerchant(value);
    if (response.status === 200) {
      response.json().then((responseJson) => {
        if (responseJson) {
          const options = responseJson.map((m) => ({
            label: `${m.name} (ID: ${m.id})`,
            value: m.id,
          }));
          setState((prevState) => ({ ...prevState, options: options }));
          callback(options);
        } else {
          setState((prevState) => ({
            ...prevState,
            options: [
              { label: currentMerchant.name, value: currentMerchant.id },
            ],
          }));
          callback([
            {
              label: `${currentMerchant.name} (ID: ${currentMerchant.id})`,
              value: currentMerchant.id,
            },
          ]);
        }
      });
    }
  }

  function selectChangeHandler(data) {
    setState((prevState) => ({ ...prevState, merchantId: data.value }));
  }

  const loadOptionsDebounce = debounce(loadOptions, 700);

  function showSuccessNotification() {
    SuccessToast({
      toastId: "merchantSettings",
    });
  }

  function showErrorNotification(errorMessage) {
    ErrorToast({
      message: errorMessage,
      toastId: "merchantSettings",
    });
  }

  async function updateMerchant() {
    const response = await changeUserMerchant(state.merchantId);

    if (response.status == 200) {
      showSuccessNotification();
      window.location.reload();
    } else {
      const responseJson = await response.json();
      showErrorNotification(responseJson.error);
    }
  }

  function resetWhatIsNewBadge() {
    setState((prevState) => ({
      ...prevState,
      whatIsBadgeConfirmation: !prevState.whatIsBadgeConfirmation,
    }));
  }

  async function confirmWhatIsNewBadge() {
    const response = await enableWhatIsNewBadge();
    if (response.status == 200) {
      showSuccessNotification();
      setState((prevState) => ({
        ...prevState,
        whatIsBadgeConfirmation: false,
      }));
      window.location.href = "/";
    }
  }

  return {
    selectChangeHandler,
    state,
    paywallState,
    toggleSettingsPaywall,
    checkoutV3State,
    toggleSettingsCheckoutV3,
    exchangeReturnsState,
    toggleSettingsExchangeReturns,
    customReturnsState,
    toggleSettingsCustomReturns,
    klaviyoIntegrationState,
    toggleSettingsKlaviyoIntegration,
    xentralIntegrationState,
    toggleSettingsXentralIntegration,
    peoplevoxIntegrationState,
    toggleSettingsPeoplevoxIntegration,
    onStoreExchangeState,
    toggleSettingsOnStoreExchange,
    createManualReturnState,
    toggleSettingsCreateManualReturnState,
    loadOptionsDebounce,
    updateMerchant,
    resetWhatIsNewBadge,
    confirmWhatIsNewBadge,
    onboardingAssistantVisibilityDays,
    updateOnboardingAssistantState,
    finecomIntegrationState,
    toggleSettingsFinecomIntegration,
  }
}
