import React from "react";
import Modal from "react-modal";

export const DeleteModal = ({ open, onRequestClose, t, onClick }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onRequestClose}
      style={{
        content: {
          maxWidth: "470px",
          maxHeight: "185px",
          margin: "auto",
          padding: "24px",
        },
        overlay: {
          background: "#00000080",
        },
      }}
    >
      <div>
        <div className="flex items-center mb-3">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="32"
              height="32"
              rx="16"
              fill="#EB5757"
              fillOpacity="0.1"
            />
            <path
              d="M10.75 11.75L11.5912 21.4233C11.681 22.4568 12.5462 23.25 13.5836 23.25H18.4164C19.4538 23.25 20.319 22.4568 20.4088 21.4233L21.25 11.75"
              stroke="#EB5757"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.75 11.5V10.75C13.75 9.64543 14.6454 8.75 15.75 8.75H16.25C17.3546 8.75 18.25 9.64543 18.25 10.75V11.5"
              stroke="#EB5757"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 11.75H23"
              stroke="#EB5757"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <p className="font-semibold text-base leading-6 text-gray-825 ml-3">
            {t.delete_heading}
          </p>
        </div>

        <p className="font-normal text-xs13 leading-4 text-gray-625 mb-5">
          {t.delete_description}
        </p>

        <div className="flex justify-end">
          <button
            className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625 focus:shadow-btn_blue focus:border-blue-625"
            onClick={onRequestClose}
          >
            {t.cancel}
          </button>

          <button
            className="fnot-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
            onClick={onClick}
          >
            {t.yes_delete}
          </button>
        </div>
      </div>
    </Modal>
  );
};
