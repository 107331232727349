import { useState } from "react";

import { addReturnAddress } from "../services/api";
import { getCountryList } from "../../util/getCountryList";

import { ErrorToast } from "../common/ErrorToast";
import { SuccessToast } from "../common/SuccessToast";

import translationEN from "../../translations/returnMethods/translationEN.json";
import translationDE from "../../translations/returnMethods/translationDE.json";

export const useReturnAddress = ({
  returnAddress,
  showEdit,
  lang,
  closeEditReturnAddressModal,
}) => {
  function getInitialState() {
    return {
      returnAddressAttributes: {
        id: returnAddress?.id || "",
        name: returnAddress?.name || "",
        name2: returnAddress?.name2 || "",
        street: returnAddress?.street || "",
        street_number: returnAddress?.street_number || "",
        zip: returnAddress?.zip || "",
        country: returnAddress?.country || "",
        city: returnAddress?.city || "",
        state: returnAddress?.state || "",
        default: true,
      },
      countryOptions: getCountryList(),
      isModelOpen: showEdit || false,
    };
  }

  const [state, setState] = useState(getInitialState());

  const t = lang === "en" ? translationEN : translationDE;

  function handleInputChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      returnAddressAttributes: {
        ...prevState.returnAddressAttributes,
        [name]: value,
      },
    }));
  }

  function closeEditModal() {
    closeEditReturnAddressModal(state.returnAddressAttributes);
  }

  function submitEditForm() {
    const params = {
      return_address_attributes: state.returnAddressAttributes,
    };
    saveReturnAddress(params);
  }

  async function saveReturnAddress(params) {
    const response = await addReturnAddress(params);
    if (response.status === 200 || response.status === 201) {
      response.json().then((responseJson) => {
        closeEditReturnAddressModal(responseJson);
        setState((prevState) => ({ ...prevState, isModelOpen: false }));
        SuccessToast({
          toastId: "returnAddressSuccess",
        });
      });
    } else {
      response.json().then((responseJson) => {
        ErrorToast({
          toastId: "customer_return",
          message: responseJson.error,
        });
      });
    }
  }
  return {
    state,
    t,
    handleInputChange,
    closeEditModal,
    submitEditForm,
  };
};
