import React, { useState } from "react";

export const useKlaviyoToggleState = ({
                                        initialValue,
                                        updateMerchantSettings,
                                        showSuccessNotification,
                                        showErrorNotification,
                                      }) => {
  const [klaviyoIntegrationState, setKlaviyoIntegrationState] =
    useState(initialValue)

  async function toggleSettingsKlaviyoIntegration() {
    const value = !klaviyoIntegrationState
    setKlaviyoIntegrationState(value)

    const response = await updateMerchantSettings({
      show_klaviyo_integration: value,
    })

    if(response.status == 200) {
      const { show_klaviyo_integration } = await response.json()
      setKlaviyoIntegrationState(show_klaviyo_integration)
      showSuccessNotification()
    } else {
      const { error } = await response.json()
      showErrorNotification(error)
    }
  }

  return {
    klaviyoIntegrationState,
    toggleSettingsKlaviyoIntegration,
  }
}
