import React from "react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ReactNotification from "react-notifications-component";
import { HexColorPicker } from "react-colorful";

import { Toggle } from "~dashboard/ui-kit/components/Toggle";
import { SuccessToast } from "~dashboard/components/common/SuccessToast";
import { ErrorToast } from "~dashboard/components/common/ErrorToast";
import { getXCSRFToken } from "~dashboard/util/getXCSRFToken";

import translationDE from "../../translations/returnPortals/translationDE.json";
import translationEN from "../../translations/returnPortals/translationEN.json";
import { InputLabel } from "../../ui-kit/components/InputLabel";
import { Input } from "../../ui-kit/components/Input";
import { TextArea } from "../../ui-kit/components/TextArea";

import "react-notifications-component/dist/theme.css";

class ReturnPortals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkout_view_setting: {
        id: this.props.checkout_view_setting.id,
        primary_color: this.props.checkout_view_setting.primary_color,
        secondary_color: this.props.checkout_view_setting.secondary_color,
        shop_url: this.props.checkout_view_setting.shop_url,
        custom_css: this.props.checkout_view_setting.custom_css,
        custom_script: this.props.checkout_view_setting.custom_script,
        iframe_whitelisted_url:
          this.props.checkout_view_setting.iframe_whitelisted_url,
        logo_attributes: {
          id: this.props.checkout_view_setting.logo?.id || "",
          image: this.props.checkout_view_setting.logo?.image_data || "",
        },
        favicon_attributes: {
          id: this.props.checkout_view_setting.favicon?.id || "",
          image: this.props.checkout_view_setting.favicon?.image_data || "",
        },
        background_image_attributes: {
          id: this.props.checkout_view_setting.background_image?.id || "",
          image:
            this.props.checkout_view_setting.background_image?.image_data || "",
        },
      },
      merchant_languages_attributes:
        this.props.current_merchant.merchant_languages_attributes,
      name: this.props.current_merchant.name,
      logoFileName: "",
      backgroundFileName: "",
      logoImageUrl:
        this.props.checkout_view_setting.logo?.image_url_thumbnail || "",
      backgroundImageUrl:
        this.props.checkout_view_setting.background_image
          ?.image_url_thumbnail || "",
      faviconImageUrl:
        this.props.checkout_view_setting.favicon?.image_url_thumbnail || "",
      faviconFileName: "",
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      portalLink: this.props.portal_link,
      primaryColorPicker: false,
      secondaryColorPicker: false,
    };
  }

  componentDidMount() {
    this.faviconUploader();
    this.logoUploader();
    this.backgroundImageUploader();
  }

  faviconUploader = () => {
    const uppyFavicon = new Uppy({
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 4 * 1024 * 1024,
        allowedFileTypes: ["image/jpeg", "image/jpg", "image/png"],
      },
      autoProceed: true,
    });

    const faviconFileInput = document.querySelector("#favicon-file-input");

    faviconFileInput.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          uppyFavicon.addFile({
            source: "file input",
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          ErrorToast({
            message: err.toString(),
            toastId: "fileUploadError",
          });
        }
      });
    });

    uppyFavicon.use(AwsS3, {
      companionUrl: "/",
    });

    uppyFavicon.on("upload-success", (file, response) => {
      const uploadedFileData = {
        id: file.meta["key"].match(/^cache\/(.+)/)[1],
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      };

      let url = URL.createObjectURL(file.data);
      this.setState({
        faviconFileName: file.name,
        faviconImageUrl: url,
        checkout_view_setting: {
          ...this.state.checkout_view_setting,
          favicon_attributes: {
            id: this.state.checkout_view_setting.favicon_attributes.id,
            image: JSON.stringify(uploadedFileData),
          },
        },
      });
    });

    uppyFavicon.on("file-removed", () => {
      faviconFileInput.value = null;
    });

    uppyFavicon.on("complete", () => {
      uppyFavicon.reset();
    });
  };

  logoUploader = () => {
    const uppyLogo = new Uppy({
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 4 * 1024 * 1024,
        allowedFileTypes: ["image/jpeg", "image/jpg", "image/png"],
      },
      autoProceed: true,
    });

    const LogoFileInput = document.querySelector("#logo-file-input");

    LogoFileInput.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          uppyLogo.addFile({
            source: "file input",
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          ErrorToast({
            message: err.toString(),
            toastId: "fileUploadError",
          });
        }
      });
    });

    uppyLogo.use(AwsS3, {
      companionUrl: "/",
    });

    uppyLogo.on("upload-success", (file, response) => {
      const uploadedFileData = {
        id: file.meta["key"].match(/^cache\/(.+)/)[1],
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      };

      let url = URL.createObjectURL(file.data);
      this.setState({
        logoFileName: file.name,
        logoImageUrl: url,
        checkout_view_setting: {
          ...this.state.checkout_view_setting,
          logo_attributes: {
            id: this.state.checkout_view_setting.logo_attributes.id,
            image: JSON.stringify(uploadedFileData),
          },
        },
      });
    });

    uppyLogo.on("file-removed", () => {
      LogoFileInput.value = null;
    });

    uppyLogo.on("complete", () => {
      uppyLogo.reset();
    });
  };
  backgroundImageUploader = () => {
    const uppyBackgroundImage = new Uppy({
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 4 * 1024 * 1024,
        allowedFileTypes: ["image/jpeg", "image/jpg", "image/png"],
      },
      autoProceed: true,
    });

    const backgroundFileInput = document.querySelector(
      "#background-file-input"
    );

    backgroundFileInput.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          uppyBackgroundImage.addFile({
            source: "file input",
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          ErrorToast({
            message: err.toString(),
            toastId: "fileUploadError",
          });
        }
      });
    });

    uppyBackgroundImage.use(AwsS3, {
      companionUrl: "/",
    });

    uppyBackgroundImage.on("upload-success", (file, response) => {
      const uploadedFileData = {
        id: file.meta["key"].match(/^cache\/(.+)/)[1],
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      };

      let url = URL.createObjectURL(file.data);
      this.setState({
        backgroundFileName: file.name,
        backgroundImageUrl: url,
        checkout_view_setting: {
          ...this.state.checkout_view_setting,
          background_image_attributes: {
            id: this.state.checkout_view_setting.background_image_attributes.id,
            image: JSON.stringify(uploadedFileData),
          },
        },
      });
    });

    uppyBackgroundImage.on("file-removed", () => {
      backgroundFileInput.value = null;
    });

    uppyBackgroundImage.on("complete", () => {
      uppyBackgroundImage.reset();
    });
  };

  handleInputChange = ({ name, value }) => {
    this.setState((prevState) => ({
      ...prevState,
      checkout_view_setting: {
        ...prevState.checkout_view_setting,
        [name]: value,
      },
    }));
  };

  handleLanguagePublishedCheckBoxChange = ({ index, value }) => {
    this.setState((prevState) => {
      const { merchant_languages_attributes } = prevState;
      merchant_languages_attributes[index]["published"] = value;
      return { merchant_languages_attributes };
    });
  };

  handleInputMerchantNameChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitEditForm = () => {
    const params = {
      merchant: {
        name: this.state.name,
        checkout_view_setting_attributes: this.state.checkout_view_setting,
        merchant_languages_attributes: this.state.merchant_languages_attributes,
      },
    };

    fetch("/settings", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.status === 200) {
        SuccessToast({
          toastId: "returnPortalSuccess",
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            message: responseJson.error,
            toastId: "returnPortalError",
          });
        });
      }
    });
  };

  showPrimaryColorPicker = () => {
    this.setState({
      primaryColorPicker: true,
    });
  };

  primaryColorComplete = (color) => {
    this.setState({
      checkout_view_setting: {
        ...this.state.checkout_view_setting,
        primary_color: color,
      },
    });
  };

  closePrimaryColorPicker = () => {
    this.setState({
      primaryColorPicker: false,
    });
  };

  showSecondaryColorPicker = () => {
    this.setState({
      secondaryColorPicker: true,
    });
  };

  secondaryColorComplete = (color) => {
    this.setState({
      checkout_view_setting: {
        ...this.state.checkout_view_setting,
        secondary_color: color,
      },
    });
  };

  closeSecondaryColorPicker = () => {
    this.setState({
      secondaryColorPicker: false,
    });
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />
        <div className="flex justify-between items-center px-8">
          <div className="font-medium text-xs leading-4">
            <p>
              <span className="text-gray-525">
                <a href="/settings">{this.state.t.settings}</a> /{" "}
              </span>
              <span className="text-gray-725 text-xs13">
                {this.state.t.portal_settings}
              </span>
            </p>
          </div>
          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>
              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>
        <div className="flex items-center justify-between border-t border-b mt-6 px-8 py-5">
          <div className="flex items-center">
            <h5 className="font-semibold text-base leading-6 text-gray-975">
              {this.state.t.portal_settings}
            </h5>
            <div className="ml-10 flex items-center">
              <p className="font-normal text-sm leading-5 text-gray-725">
                {this.props.current_merchant.subdomain}.returnsportal.online
              </p>
              <a href={this.state.portalLink} target="_blank">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-3 cursor-pointer"
                >
                  <path
                    d="M6.16602 3.16797H4.49935C3.76297 3.16797 3.16602 3.76492 3.16602 4.5013V11.5013C3.16602 12.2377 3.76297 12.8346 4.49935 12.8346H11.4993C12.2357 12.8346 12.8327 12.2377 12.8327 11.5013V9.83464"
                    stroke="#2F80ED"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.834 6.16797V3.16797H9.83398"
                    stroke="#2F80ED"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.6673 3.33203L7.83398 8.16537"
                    stroke="#2F80ED"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <button
            className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:bg-green-800 focus:shadow-btn_green"
            onClick={this.submitEditForm}
          >
            {this.state.t.save_changes}
          </button>
        </div>
        <div className="px-8 pt-6">
          <p className="font-semibold text-sm leading-5 text-gray-825">
            {this.state.t.company_details}
          </p>
          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {this.state.t.company_name}
            </p>
            <input
              className="w-68% bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="name"
              onChange={this.handleInputMerchantNameChange}
              defaultValue={this.state.name}
            ></input>
          </div>
          <div className="w-68% mt-4">
            <InputLabel>{this.state.t.shop_url}</InputLabel>
            <Input
              type="text"
              value={this.state.checkout_view_setting.shop_url}
              name="shop_url"
              onChange={(value) =>
                this.handleInputChange({ name: "shop_url", value })
              }
            />
          </div>
          <div className="mt-4 flex w-full items-center">
            <div className="relative w-1/3">
              <InputLabel>{this.state.t.primary_color}</InputLabel>
              <Input
                type="text"
                value={this.state.checkout_view_setting.primary_color}
                name="primary_color"
                onChange={(value) =>
                  this.handleInputChange({ name: "primary_color", value })
                }
              />
              <div className="absolute bottom-3 right-3">
                <div
                  className="h-5 w-5 rounded-full border cursor-pointer"
                  style={{
                    backgroundColor:
                      this.state.checkout_view_setting.primary_color,
                  }}
                  onClick={this.showPrimaryColorPicker}
                ></div>
                {this.state.primaryColorPicker && (
                  <div className="absolute z-10">
                    <div
                      className="fixed top-0 right-0 bottom-0 left-0"
                      onClick={this.closePrimaryColorPicker}
                    />
                    <HexColorPicker
                      color={this.state.checkout_view_setting.primary_color}
                      onChange={this.primaryColorComplete}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="relative w-1/3 ml-4">
              <InputLabel>{this.state.t.secondary_color}</InputLabel>
              <Input
                type="text"
                value={this.state.checkout_view_setting.secondary_color}
                name="secondary_color"
                onChange={(value) =>
                  this.handleInputChange({ name: "secondary_color", value })
                }
              />
              <div className="absolute bottom-3 right-3">
                <div
                  className="h-5 w-5 rounded-full border cursor-pointer"
                  style={{
                    backgroundColor:
                      this.state.checkout_view_setting.secondary_color,
                  }}
                  onClick={this.showSecondaryColorPicker}
                ></div>
                {this.state.secondaryColorPicker && (
                  <div className="absolute z-10">
                    <div
                      className="fixed top-0 right-0 bottom-0 left-0"
                      onClick={this.closeSecondaryColorPicker}
                    />
                    <HexColorPicker
                      color={this.state.checkout_view_setting.secondary_color}
                      onChange={this.secondaryColorComplete}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Custom CSS */}

          <div className="mt-4">
            <InputLabel className="font-medium text-xs leading-4 text-gray-725 mb-1 block">
              Custom css
            </InputLabel>
            <TextArea
              name="custom_css"
              height="h-56"
              className="w-68% bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              onChange={(value) =>
                this.handleInputChange({ name: "custom_css", value })
              }
              value={this.state.checkout_view_setting.custom_css}
            />
          </div>

          {/* Custom script */}

          <div className="mt-4">
            <InputLabel className="font-medium text-xs leading-4 text-gray-725 mb-1 block">
              Custom script
            </InputLabel>
            <TextArea
              name="custom_script"
              height="h-56"
              className="w-68% bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              onChange={(value) =>
                this.handleInputChange({ name: "custom_script", value })
              }
              value={this.state.checkout_view_setting.custom_script}
            />
          </div>

          {this.props.current_merchant.embedded_checkout_enabled && (
            <div className="w-68% mt-4">
              <InputLabel>{this.state.t.url_embedded_portal}</InputLabel>
              <Input
                type="text"
                value={this.state.checkout_view_setting.iframe_whitelisted_url}
                name="iframe_whitelisted_url"
                onChange={(value) =>
                  this.handleInputChange({
                    name: "iframe_whitelisted_url",
                    value,
                  })
                }
              />
            </div>
          )}
          <p className="font-medium text-xs leading-4 text-gray-725 mt-4">
            Logo
          </p>
          <div className="flex items-center mt-1">
            <div className="border border-r border-l border-t border-b border-gray-325 px-3 pt-2 pb-2 rounded-lg w-1/3">
              <div className="flex justify-between items-center">
                <p className="font-medium text-xs leading-4 text-gray-725">
                  {this.state.logoFileName}
                  {!this.state.logoFileName && (
                    <span>{this.state.t.click_on_logo}</span>
                  )}
                </p>
                <label
                  htmlFor="logo-file-input"
                  className="border border-r-2 border-l-2 border-t-2 border-b-2 border-blue-600 text-blue-600 px-3 py-2 rounded-lg cursor-pointer"
                >
                  {this.state.t.browse}
                </label>
                <input
                  type="file"
                  id="logo-file-input"
                  accept="image/jpeg, image/jpg, image/png"
                  className="hidden"
                ></input>
              </div>
            </div>
            <img
              id="return_method_logo"
              src={this.state.logoImageUrl}
              className="max-h-16 ml-10"
            />
          </div>
          <p className="font-medium text-xs leading-4 text-gray-725 mt-4">
            Favicon
          </p>
          <div className="flex items-center mt-1">
            <div className="border border-r border-l border-t border-b border-gray-325 px-3 pt-2 pb-2 rounded-lg w-1/3">
              <div className="flex justify-between items-center">
                <p className="font-medium text-xs leading-4 text-gray-725">
                  {this.state.faviconFileName}
                  {!this.state.faviconFileName && (
                    <span>{this.state.t.click_on_favicon}</span>
                  )}
                </p>
                <label
                  htmlFor="favicon-file-input"
                  className="border border-r-2 border-l-2 border-t-2 border-b-2 border-blue-600 text-blue-600 px-3 py-2 rounded-lg cursor-pointer"
                >
                  {this.state.t.browse}
                </label>
                <input
                  type="file"
                  id="favicon-file-input"
                  accept="image/jpeg, image/jpg, image/png"
                  className="hidden"
                  onChange={this.uploadFavicon}
                ></input>
              </div>
            </div>
            <img
              id="return_method_favicon"
              src={this.state.faviconImageUrl}
              className="max-h-16 ml-10"
            />
          </div>

          <div>
            <p className="font-medium text-xs leading-4 text-gray-725 mt-4">
              {this.state.t.background_image}
            </p>
            <div className="flex items-center mt-1">
              <div className="border border-r border-l border-t border-b border-gray-325 px-3 pt-2 pb-2 rounded-lg w-1/3">
                <div className="flex justify-between items-center">
                  <p className="font-medium text-xs leading-4 text-gray-725">
                    {this.state.backgroundFileName}
                    {!this.state.backgroundFileName && (
                      <span className="mr-1">
                        {this.state.t.click_on_background_image}
                      </span>
                    )}
                  </p>
                  <label
                    htmlFor="background-file-input"
                    className="border border-r-2 border-l-2 border-t-2 border-b-2 border-blue-600 text-blue-600 px-3 py-2 rounded-lg cursor-pointer"
                  >
                    {this.state.t.browse}
                  </label>
                  <input
                    type="file"
                    id="background-file-input"
                    accept="image/jpeg, image/jpg, image/png"
                    className="hidden"
                  ></input>
                </div>
              </div>
              <img
                id="return_method_logo"
                src={this.state.backgroundImageUrl}
                className="max-h-16 ml-10"
              />
            </div>
          </div>

          {/* Languages */}

          <div className="mt-6 mb-24 w-68% flex justify-between">
            <div>
              <p className="font-semibold text-sm leading-5 text-gray-825">
                {this.state.t.languages}
              </p>

              {this.state.merchant_languages_attributes.map(
                (language, index) => (
                  <Toggle
                    key={language.id}
                    className={index === 0 ? "mt-6" : "mt-4"}
                    onChange={(value) =>
                      this.handleLanguagePublishedCheckBoxChange({
                        index,
                        value,
                      })
                    }
                    checked={language.published} // Assuming published determines checked state
                    name={`language_${language.lang_code}`}
                    label={{ text: language.lang_name }}
                    id={`language_${language.lang_code}`}
                  />
                )
              )}
            </div>

            <div>
              <a
                className="flex items-center border border-gray-325 px-4 py-2 rounded-lg cursor-pointer focus:shadow-btn_blue focus:border-blue-625 focus:outline-none"
                href="/merchant_languages"
              >
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.750001 9.75L0.75 2.25C0.75 2.25 3.5 -1.07288e-06 7 2.25C10.5 4.5 13.25 2.25 13.25 2.25V9.75C13.25 9.75 10.5 12 7 9.75C3.5 7.5 0.750001 9.75 0.750001 9.75ZM0.750001 9.75L0.75 15.75"
                    stroke="#3F3F46"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="ml-2 font-semibold text-sm leading-5 text-gray-875">
                  {this.state.t.language_settings}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReturnPortals;
