import React from "react";

import { InputLabel } from "../../../../../../ui-kit/components/InputLabel";

export const ExchangeOption = ({ handleCheckBoxChange, id, value, label }) => {
  return (
    <div className="flex items-center w-1/2 mt-4">
      <input
        className="cursor-pointer h-6 w-6 rounded"
        type="checkbox"
        id={id}
        name={id}
        onChange={(e) =>
          handleCheckBoxChange({
            name: e.target.name,
            value: e.target.checked,
          })
        }
        checked={value}
      />
      <InputLabel
        htmlFor={id}
        cursorStyle="cursor-pointer"
        className={label.className}
      >
        {label.text}
      </InputLabel>
    </div>
  );
};
