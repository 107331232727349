import React from "react";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import "react-notifications-component/dist/theme.css";
import { CalenderIcon } from "../../../../../ui-kit/icons/CalenderIcon";

export const ReturnHistory = (props) => {
  const { activities, t, formatActivityTime } = props;

  return (
    activities.length > 0 && (
      <div className="-mt-28 mb-36">
        <Typography
          element="p"
          weight="semibold"
          size="base"
          height="6"
          color="gray-825"
        >
          {t.return_history}
        </Typography>
        {activities
          .sort(
            (activity1, activity2) =>
              new Date(activity2.created_at) - new Date(activity1.created_at)
          )
          .map((activity, index) => {
            return (
              <div
                className={`${
                  index === activities.length - 1 ? "" : "border-b"
                } py-4`}
                key={index}
              >
                <div className="mb-2">
                  <Typography
                    weight="semibold"
                    size="xs"
                    height="4"
                    color="gray-825"
                    className="mr-2"
                  >
                    {activity.user}
                  </Typography>
                  <Typography
                    weight="medium"
                    size="xs"
                    height="4"
                    color="gray-725"
                    dangerouslySetInnerHTML={{
                      __html: activity.event,
                    }}
                  ></Typography>
                  {activity.email_provider === "klaviyo" && (
                    <Typography
                      weight="medium"
                      size="xs"
                      height="4"
                      color="gray-500"
                      className="ml-2"
                    >
                      (via Klaviyo)
                    </Typography>
                  )}
                </div>
                <div className="flex items-center">
                  <CalenderIcon stroke="#71717A" />
                  <Typography
                    weight="medium"
                    size="xs"
                    height="4"
                    color="gray-725"
                    className="ml-1"
                  >
                    {formatActivityTime(activity.created_at)}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
    )
  );
};
