import React from "react";
import ReactNotification from "react-notifications-component";

import { Select } from "../../ui-kit/components/Select";
import Confirmation from "../confirmation_modal/confirmation_modal";
import { useUser } from "./user.hook";

import "react-notifications-component/dist/theme.css";

const EditUser = ({
  currentUser,
  userSignedin,
  current_password,
  password,
  unconfirmedEmail,
  deviseMappingConfirmable,
  pendingReconfirmation,
  canChangePassword,
  canUpdate,
  locales,
}) => {
  const {
    t,
    state,
    localeOptions,
    selectChangeHandler,
    inputChangeHandle,
    logOut,
    updateUser,
    confirmLogoutHandler,
  } = useUser({ currentUser, current_password, password, locales });

  const { user } = state;

  return (
    <div className="font-dm_sans mb-10 mr-6 mt-10 ml-10">
      <ReactNotification className="left-0 right-0 bottom-1" />
      <div className="mb-6 flex flex-col">
        <p className="font-medium text-2xl tracking-px_3 text-gray-950 leading-normal">
          {t.account}
        </p>

        <p className="font-medium text-sm tracking-px_3 text-gray-750 leading-normal">
          {t.updateAccountSettings}
        </p>
      </div>

      <div className="bg-white border-2 rounded box-border pl-6 pt-8 h-auto">
        <div className="w-full">
          <div className="lg:flex sm:mb-3">
            <div className="lg:w-1/3 mr-6">
              <label className="text-sm leading-4 text-gray-950">
                {t.firstName}
              </label>

              <div className="pt-2">
                <input
                  name="first_name"
                  className="w-full text-gray-750 border border-gray-750 rounded py-3 px-4 text-xs leading-4 items-center focus:outline-none capitalize"
                  defaultValue={user.first_name}
                  onChange={inputChangeHandle}
                />
              </div>
            </div>

            <div className="lg:w-1/3 lg:mt-0 sm:mt-3 mr-6">
              <label className="text-sm leading-4 text-gray-950">
                {t.lastName}
              </label>

              <div className="pt-2">
                <input
                  name="last_name"
                  className="w-full text-gray-750 border border-gray-750 rounded py-3 px-4 text-xs leading-4 items-center focus:outline-none capitalize"
                  defaultValue={user.last_name}
                  onChange={inputChangeHandle}
                />
              </div>
            </div>
          </div>

          <div className="lg:w-1/3 mb-3 mr-6">
            <label className="text-sm leading-4 text-gray-950">{t.email}</label>

            <div className="pt-2">
              <input
                name="email"
                className="w-full text-gray-750 border border-gray-750 rounded py-3 px-4 text-xs leading-4 items-center focus:outline-none"
                defaultValue={user.email}
                onChange={inputChangeHandle}
              />
            </div>

            {deviseMappingConfirmable && pendingReconfirmation && (
              <div>Currently waiting confirmation for: {unconfirmedEmail}</div>
            )}
          </div>

          {canChangePassword && (
            <div className="lg:flex mb-4">
              <div className="lg:w-1/3 mr-6">
                <label className="text-sm leading-4 text-gray-950">
                  {t.currentPassword}
                </label>

                <div className="lg:pt-2 md-s:pt-8">
                  <input
                    type="password"
                    name="current_password"
                    className="w-full text-gray-750 border border-gray-750 rounded py-3 px-4 text-xs leading-4 items-center focus:outline-none"
                    defaultValue={user.current_password}
                    onChange={inputChangeHandle}
                  />
                </div>
              </div>

              <div className="lg:w-1/2 lg:mt-0 sm:mt-3 mr-6">
                <label className="sm:mt-4 text-sm leading-4 text-gray-950">
                  {t.newPassword}
                </label>

                <div className="pt-2">
                  <input
                    type="password"
                    name="password"
                    className="w-full text-gray-750 border border-gray-750 rounded py-3 px-4 text-xs leading-4 items-center focus:outline-none"
                    defaultValue={user.password}
                    onChange={inputChangeHandle}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="lg:flex mb-6">
            <div className="lg:w-1/3 mr-6">
              <label className="text-sm leading-4 text-gray-950">
                {t.language}
              </label>

              <Select
                className="bg-white mt-2 box-border border-gray-750 rounded-lg text-xs px-4 leading-4 border focus:outline-none py-px px-px font-medium text-gray-750 w-full focus:border-blue-675 focus:border capitalize"
                options={localeOptions}
                onChange={(value) =>
                  selectChangeHandler({ name: "locale", value })
                }
                value={user.locale}
              />

              {deviseMappingConfirmable && pendingReconfirmation && (
                <div>
                  Currently waiting confirmation for: {unconfirmedEmail}
                </div>
              )}
            </div>
          </div>

          {canUpdate && (
            <div className="w-full mb-6">
              <button
                className="cursor-pointer focus:outline-none font-medium bg-blue-650 hover:bg-blue-700 text-sm tracking-px_4 leading-4 text-white py-4 px-8 rounded"
                onClick={updateUser}
              >
                {t.save}
              </button>
            </div>
          )}
        </div>

        {userSignedin && (
          <div className="mb-4">
            <button
              className="mt-4 text-red-600 hover:text-red-800 focus:outline-none"
              onClick={confirmLogoutHandler}
            >
              {t.logout}
            </button>
          </div>
        )}
      </div>

      <Confirmation
        isOpen={state.confirmLogout}
        onRequestClose={confirmLogoutHandler}
        confirmation={logOut}
        confirmationTitle="Are you sure?"
        cancelText="Cancel"
        confirmText="Confirm"
      />
    </div>
  );
};

export default EditUser;
