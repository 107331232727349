import React, { useState } from "react";

export const useInstantStoreCreditCaptureAuthorizedPaymentModal = ({
                                                                     onOkButtonClick,
                                                                   }) => {
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  const handleActionCallback = async (event) => {
    setActionButtonDisabled(true);
    await onOkButtonClick(event);
  }

  return {
    actionButtonDisabled,
    handleActionCallback,
  };
};
