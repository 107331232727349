import React from "react";
import cx from "classnames";
import moment from "moment";

export const FormatDate = (props) => {
  const {
    className = "",
    date,
    format
  } = props;

  return (
    <div className={cx(className, "font-normal text-xs13 leading-4 text-gray-625 table-cell py-8")}>
      {
        moment(date).isValid() ?
        moment(date).format(format) :
        date
      }
    </div>
  );
}
