import { useState } from "react";
import { isEmpty } from "lodash";
import EN from "./locales/EN.json";
import DE from "./locales/DE.json";
import {
  connectToFinecom,
  setFinecomIntegrationStatus,
} from "../../../services/api";

import { SuccessToast } from "../../../common/SuccessToast";
import { ErrorToast } from "../../../common/ErrorToast";

export const useFinecom = ({ currentUser, finecomIntegration }) => {
  const t = currentUser.locale === "en" ? EN : DE;
  const {
    id = "",
    username = "",
    password = "",
    warehouse = "",
    active = false,
    product_identifier = 'sku',
  } = finecomIntegration || {};
  const [connectBtnDisabled, setConnectBtnDisabled] = useState(true);
  const [state, setState] = useState(getInitialState());
  const [isConnected, setIsConnected] = useState(
    !isEmpty(state.integration.password)
  );

  const handleInputChange = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      integration: {
        ...prevState.integration,
        [name]: value,
      },
    }));
    setConnectBtnDisabled(false);
  };

  function getInitialState() {
    return {
      integration: {
        id,
        username,
        password,
        warehouse,
        active,
        productIdentifier: product_identifier,
      },
      finecomConnected: {
        isConnected: Boolean(password),
      },
      disableButton: false,
    };
  }

  async function connect() {
    const response = await connectToFinecom(state.integration);

    const responseJSON = await response.json();
    if (response.status === 200) {
      handleInputChange({
        name: "password",
        value: responseJSON.finecomIntegration.password,
      });
      setIsConnected(true);
      SuccessToast({
        toastId: "finecomIntegration",
      });
    } else {
      ErrorToast({
        message: responseJSON.error,
        toastId: "finecomIntegration",
      });
      setIsConnected(false);
    }
  }

  async function handleFinecomCheckBoxChange({ name, value }) {
    const response = await setFinecomIntegrationStatus({ active: value });

    const responseJSON = await response.json();
    if (response.status === 200) {
      setState((prevState) => ({
        ...prevState,
        integration: {
          ...prevState.integration,
          [name]: value,
        },
      }));
      SuccessToast({
        toastId: "finecomIntegration",
      });
    } else {
      ErrorToast({
        message: responseJSON.error,
        toastId: "finecomIntegration",
      });
    }
  }

  return {
    t,
    state,
    connectBtnDisabled,
    isConnected,
    handleInputChange,
    connect,
    handleFinecomCheckBoxChange,
  };
};
