import { useState } from "react";

export const useFinecomToggleState = ({
  initialValue,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [finecomIntegrationState, setFinecomIntegrationState] =
    useState(initialValue);

  async function toggleSettingsFinecomIntegration() {
    const value = !finecomIntegrationState;
    setFinecomIntegrationState(value);

    const response = await updateMerchantSettings({
      show_finecom_integration: value,
    });

    if (response.status == 200) {
      const { show_finecom_integration } = await response.json();
      setFinecomIntegrationState(show_finecom_integration);
      showSuccessNotification();
    } else {
      const { error } = await response.json();
      showErrorNotification(error);
    }
  }

  return {
    finecomIntegrationState,
    toggleSettingsFinecomIntegration,
  };
};
