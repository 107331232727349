import { useState } from "react";

export const useCreateManualReturnToggleState = ({
  initialValue,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [createManualReturnState, setCreateManualReturnState] =
    useState(initialValue);

  async function toggleSettingsCreateManualReturnState() {
    const value = !createManualReturnState;
    setCreateManualReturnState(value);

    const response = await updateMerchantSettings({
      create_return_button_enabled: value,
    });

    if (response.status === 200) {
      const { is_create_return_button_enabled } = await response.json();
      setCreateManualReturnState(is_create_return_button_enabled);
      showSuccessNotification();
    } else {
      setCreateManualReturnState(!value);
      const { error } = await response.json();
      showErrorNotification(error);
    }
  }

  return {
    createManualReturnState,
    toggleSettingsCreateManualReturnState,
  };
};
