import React from "react";

import { TrashIcon1 } from "../../../../../../../../ui-kit/icons/TrashIcon1";

import ItemTags from "./components/ItemTags";
import ValueType from "./components/ValueType";
import Value from "./components/Value";

import Draggable from "~dashboard/components/common/Draggable";

const Rule = ({
  index,
  itemTags,
  valueType,
  value,
  priority,
  updateRule,
  deleteRule,
  validateItemTag,
  t,
}) => {
  return (
    <>
      {index === 0 && (
        <div className="w-2/3 grid grid-rows-1 grid-cols-12 font-normal text-xs13 leading-4 text-gray-625 mb-3">
          <div className="col-span-2"></div>
          <div className="col-span-5 -ml-4 2xl:-ml-10">
            {t.partial_refund_product_tag}
          </div>
          <div className="col-span-3"></div>
          <div className="col-span-2 -ml-8">{t.partial_refund_value}</div>
        </div>
      )}

      <Draggable
        index={index}
        className="w-2/3 flex justify-start items-center mb-4"
      >
        {(dragHandleProps) => (
          <>
            <div className="flex justify-between items-center px-4 py-2 text-gray-525">
              <div
                {...dragHandleProps}
                className="w-6 h-6 bg-gray-100 rounded flex items-center justify-center hover:bg-blue-700 hover:text-white mr-4"
              >
                =
              </div>
              #{priority}
            </div>

            <div className="w-3/6 mr-5">
              <ItemTags
                index={index}
                value={itemTags}
                updateRule={updateRule}
                validateItemTag={validateItemTag}
              />
            </div>

            <div className="w-3/12 mr-5">
              <ValueType
                index={index}
                value={valueType}
                updateRule={updateRule}
                t={t}
              />
            </div>

            <div className="w-1/6 mr-5">
              <Value index={index} value={value} updateRule={updateRule} />
            </div>

            <div
              className="p-10px bg-gray-225 rounded"
              onClick={() => deleteRule(index)}
            >
              <TrashIcon1 />
            </div>
          </>
        )}
      </Draggable>
    </>
  );
};

export default Rule;
