export const useRefundTypeSelection = () => {
  const inputNames = [
    "refund_type_title",
    "store_credit_later_refund_title",
    "store_credit_bonus",
    "store_credit_percentage_bonus",
    "store_credit_description",
    "store_credit_refund_method_speed",
    "store_credit_refund_type_description",
    "original_refund_title",
    "original_refund_description",
    "subject_to_handling_and_shipping_fees",
    "instant_store_credit_refund_method_speed",
    "instant_store_credit_refund_type_description",
  ];

  return {
    inputNames,
  };
};
