import React from "react";
import cx from "classnames";

import { Typography } from "../../../../../../ui-kit/components/Typography/Typography";

export const CustomRefundViaOriginalPaymentMethod = ({
  giftCardMaxRefund,
  giftCardRefundAmount,
  nonGiftCardGatewayName,
  nonGiftCardMaxRefund,
  nonGiftCardRefundAmount,
  currency,
  currencySymbol,
  setGiftCardRefundAmount,
  setNonGiftCardRefundAmount,
  payPalRefundAmount,
  paypalMaxRefund,
  setPaypalRefundAmount,
  t,
}) => {
  const showGiftCardInput = () => giftCardMaxRefund > 0;

  return (
    <div className="border-b py-5">
      <div>
        {showGiftCardInput() && (
          <div className="w-full flex-column">
            <div className="flex items-center">
              <Typography
                element="p"
                weight="normal"
                size="xs13"
                height="4"
                color="gray-525"
                className="w-1/2 mb-1 pr-2"
              >
                {t.total_refund} (Gift Card)
              </Typography>

              <div className="w-1/2 flex items-center border box-border rounded-lg bg-white focus-within:border-blue-675 focus-within:border">
                <p className="pl-3 font-normal text-xs leading-4 text-gray-875">
                  {currencySymbol}
                </p>

                <input
                  type="number"
                  className="w-full rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3"
                  min="0.0"
                  max={giftCardMaxRefund}
                  step="0.01"
                  onChange={({ target: { value } }) =>
                    setGiftCardRefundAmount(value)
                  }
                  value={giftCardRefundAmount + ""}
                />
              </div>
            </div>

            <Typography
              element="p"
              weight="normal"
              size="xs13"
              height="4"
              color="gray-525"
              className="mt-3"
            >
              <span className="font-semibold">
                {new Intl.NumberFormat("en", {
                  style: "currency",
                  currency: currency,
                }).format(giftCardMaxRefund)}
              </span>{" "}
              {t.custom_refund_input_capture} (Gift Card)
            </Typography>
          </div>
        )}

        {nonGiftCardMaxRefund > 0 && (
          <div
            className={cx(
              { "mt-8": showGiftCardInput() },
              "w-full flex-column"
            )}
          >
            <div className="flex items-center">
              <Typography
                element="p"
                weight="normal"
                size="xs13"
                height="4"
                color="gray-525"
                className="w-1/2 mb-1 pr-2"
              >
                {t.total_refund} ({nonGiftCardGatewayName})
              </Typography>

              <div className="w-1/2 flex items-center border box-border rounded-lg bg-white focus-within:border-blue-675 focus-within:border">
                <p className="pl-3 font-normal text-xs leading-4 text-gray-875">
                  {currencySymbol}
                </p>

                <input
                  type="number"
                  className="w-full rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3"
                  min="0.0"
                  max={nonGiftCardMaxRefund}
                  step="0.01"
                  onChange={({ target: { value } }) =>
                    setNonGiftCardRefundAmount(value)
                  }
                  value={nonGiftCardRefundAmount + ""}
                />
              </div>
            </div>

            <Typography
              element="p"
              weight="normal"
              size="xs13"
              height="4"
              color="gray-525"
              className="mt-3"
            >
              <span className="font-semibold">
                {new Intl.NumberFormat("en", {
                  style: "currency",
                  currency: currency,
                }).format(nonGiftCardMaxRefund)}
              </span>{" "}
              {t.custom_refund_input_capture} ({nonGiftCardGatewayName})
            </Typography>
          </div>
        )}

        {paypalMaxRefund > 0 && (
          <div
            className={cx(
              "w-full flex-column mt-8"
            )}
          >
            <div className="flex items-center">
              <Typography
                element="p"
                weight="normal"
                size="xs13"
                height="4"
                color="gray-525"
                className="w-1/2 mb-1 pr-2"
              >
                {t.total_refund} (Paypal)
              </Typography>

              <div className="w-1/2 flex items-center border box-border rounded-lg bg-white focus-within:border-blue-675 focus-within:border">
                <p className="pl-3 font-normal text-xs leading-4 text-gray-875">
                  {currencySymbol}
                </p>

                <input
                  type="number"
                  className="w-full rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3"
                  min="0.0"
                  max={paypalMaxRefund}
                  step="0.01"
                  onChange={({ target: { value } }) =>
                    setPaypalRefundAmount(value)
                  }
                  value={payPalRefundAmount + ""}
                />
              </div>
            </div>

            <Typography
              element="p"
              weight="normal"
              size="xs13"
              height="4"
              color="gray-525"
              className="mt-3"
            >
              <span className="font-semibold">
                {new Intl.NumberFormat("en", {
                  style: "currency",
                  currency: currency,
                }).format(paypalMaxRefund)}
              </span>{" "}
              {t.custom_refund_input_capture} (Paypal)
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
