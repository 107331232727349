import React from "react";
import cx from "classnames";

export const Input = (props) => {
  const {
    value,
    type,
    name,
    placeholder,
    className = "",
    onChange,
    required
  } = props;

  return (
    <>
      <input
        value={value}
        type={type}
        name={name}
        placeholder={placeholder}
        className={cx(
          "w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875",
          "focus:outline-none focus:border focus:border-blue-675 rounded-lg box-border",
          "border border-gray-325 placeholder-gray-525",
          className
        )}
        onChange={event => onChange(event.target.value)}
        required={required}
      />
    </>
  );
}
