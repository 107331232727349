import React from "react";

import { DeleteLanguage } from "./components/DeleteLanguage";
import OtherSettingsSection from "./components/OtherSettingsSection";

export const OtherSettings = (props) => {
  return (
    <div className="mt-4 w-762px">
      <p className="font-semibold text-base leading-6 text-gray-975">
        {props.t.other_settings}
      </p>
      <OtherSettingsSection
        pageName={props.t.return_methods}
        url="/return_methods"
      />
      <OtherSettingsSection
        pageName={props.t.customer_notification}
        url="/email_templates"
      />
      <OtherSettingsSection
        pageName={props.t.return_reason}
        url="/return_reasons"
      />

      <DeleteLanguage
        selectedLanguage={props.selectedLanguage}
        t={props.t}
        tI18next={props.tI18next}
        submitDeleteRequest={props.submitDeleteRequest}
      />
    </div>
  );
};
