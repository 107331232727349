import React from "react";

type User = {
  first_name: string;
  last_name: string;
  email: string;
};

export type HeaderProps = {
  children: JSX.Element;
  lastColumnChildren?: JSX.Element;
} & Pick<LastColumnProps, "currentUser">;

type LastColumnProps = {
  children: JSX.Element;
  currentUser: User;
};

const Header = ({ children, lastColumnChildren, currentUser }: HeaderProps) => {
  return (
    <div className="flex justify-between items-center px-8 pb-3 border-b">
      {children}

      <LastColumn currentUser={currentUser}>{lastColumnChildren}</LastColumn>
    </div>
  );
};

const Column = ({ children }) => {
  return <div className="flex items-center">{children}</div>;
};

const LastColumn = ({ children, currentUser }: LastColumnProps) => {
  const { first_name, last_name, email } = currentUser;

  return (
    <div className="flex justify-between items-center">
      {children}

      <a className="focus:outline-none" href="/users/edit">
        <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
          {first_name + " "}
          {last_name}
        </p>

        <p className="font-normal text-xs leading-4 text-right text-gray-625">
          {email}
        </p>
      </a>
    </div>
  );
};

Header.Column = Column;

export default Header;
