import React from "react";

const SubHeader = ({ children }) => {
  return (
    <div className="flex justify-between items-center px-8 py-5 border-b">
      {children}
    </div>
  );
};

const Column = ({ children }) => {
  return <div className="flex items-center h-10">{children}</div>;
};

SubHeader.Column = Column;

export default SubHeader;
