import React from "react";

import { useValueType } from "./ValueType.hook";

import { Select } from "~dashboard/ui-kit/components/Select";

export default function ValueType({ index, value, updateRule, t }) {
  const { name, valueTypeOptions, handleSelect } = useValueType({
    index,
    updateRule,
    t,
  });

  return (
    <Select
      className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 focus:border-blue-675 focus:border"
      options={valueTypeOptions}
      onChange={(value) => handleSelect(name, value)}
      value={value}
    />
  );
}
