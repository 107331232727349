import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

export const BlockAndAllowSettings = ({
  t,
  handleTagifyInputs,
  handleTagifyInputIneligibleProductCategories,
  returnPolicy,
  currentMerchant,
}) => {
  const {
    ineligible_product_categories,
    allow_order_names,
    allow_customer_emails,
    eligible_order_tags,
    block_order_names,
    block_customer_emails,
    ineligible_customer_tags,
    ineligible_order_tags,
  } = returnPolicy;

  const { shop_backend } = currentMerchant;

  return (
    <>
      {/* Blocked items */}

      <div className="mt-8 px-8">
        <div className="border-b pb-8">
          <p className="font-semibold text-base leading-6 text-gray-825">
            {t.blocked_Items}
          </p>

          <Tags
            className="w-68% mt-4 focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
            name="ineligible_product_categories"
            onChange={handleTagifyInputIneligibleProductCategories}
            value={ineligible_product_categories}
          />

          <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
            {t.item_product}
          </p>
        </div>
      </div>

      {/* Allow list */}

      <div className="mt-8 px-8">
        <div className="border-b pb-8">
          <p className="font-semibold text-base leading-6 text-gray-825">
            {t.allow_list}
          </p>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.allow_order_name}
            </p>

            <Tags
              className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
              name="allow_order_names"
              onChange={handleTagifyInputs}
              value={allow_order_names}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.allow_order_name_description}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.allow_customer_emails}
            </p>

            <Tags
              className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
              name="allow_customer_emails"
              onChange={handleTagifyInputs}
              value={allow_customer_emails}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.allow_customer_emails_description}
            </p>
          </div>

          {shop_backend === "shopify_integration" && (
            <div className="mt-4">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.allow_order_return_period}
              </p>

              <Tags
                className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
                name="eligible_order_tags"
                onChange={handleTagifyInputs}
                value={eligible_order_tags}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.allow_order_return_period_description}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Block list */}

      <div className="mt-8 px-8">
        <div className="border-b pb-8">
          <p className="font-semibold text-base leading-6 text-gray-825">
            {t.block_list}
          </p>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.block_order_names}
            </p>

            <Tags
              className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
              name="block_order_names"
              onChange={handleTagifyInputs}
              value={block_order_names}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.block_order_names_description}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.block_customer_emails}
            </p>

            <Tags
              className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
              name="block_customer_emails"
              onChange={handleTagifyInputs}
              value={block_customer_emails}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.block_customer_emails_description}
            </p>
          </div>

          {shop_backend === "shopify_integration" && (
            <div className="mt-4">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.block_customer_tag}
              </p>

              <Tags
                className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
                name="ineligible_customer_tags"
                onChange={handleTagifyInputs}
                value={ineligible_customer_tags}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.blocked_customer_tags_description}
              </p>
            </div>
          )}

          {shop_backend === "shopify_integration" && (
            <div className="mt-4">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.block_order_tag}
              </p>

              <Tags
                className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
                name="ineligible_order_tags"
                onChange={handleTagifyInputs}
                value={ineligible_order_tags}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.blocked_order_tags_description}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
