import React from "react";

import { OrderRaw } from "../../Scayle.types";

type TableProps = {
  t: any;
  order: OrderRaw;
};

const Table = ({ t, order }: TableProps) => {
  const { order_name, order_date, customer, items_count } = order;

  return (
    <>
      <div className="flex items-center pb-4 border-b font-medium text-sm leading-4 text-gray-725 uppercase">
        <div className="w-1/4">{t("order")}</div>
        <div className="w-1/4">{t("date")}</div>
        <div className="w-1/4">{t("customer")}</div>
        <div className="w-1/4">{t("items")}</div>
      </div>

      <div className="flex items-center pt-4 pb-8 border-b text-sm">
        <div className="w-1/4 font-semibold text-gray-975">{order_name}</div>
        <div className="w-1/4 text-gray-725">{order_date}</div>
        <div className="w-1/4 font-semibold text-gray-975">{customer}</div>
        <div className="w-1/4 text-gray-725">{items_count}</div>
      </div>
    </>
  );
};

export default Table;
