import React from "react";
import ReactNotification from "react-notifications-component";
import { clamp } from "lodash";

import { TextArea } from "../../ui-kit/components/TextArea";
import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import translationEN from "../../translations/manageReturns/translationEN.json";
import translationDE from "../../translations/manageReturns/translationDE.json";

import "react-notifications-component/dist/theme.css";

class Refund extends React.Component {

  constructor(props) {
    super(props)

    this.merchant_return_method_cost = parseFloat(this.props.customer_return.merchant_return_method_cost || 0)
    this.max_shipping_refund = this.props.refund_shipping_cost ? this.props.shop_order_shipping_cost : 0
    this.credit_bonus = parseFloat(this.props.credit_bonus || 0)
    this.total_refund = parseFloat(this.props.refund_amount_without_shipping)
      + this.max_shipping_refund
      - this.merchant_return_method_cost
      - this.props.policy_fees
      + this.credit_bonus

    this.store_credit = this.props.store_credit_items.length > 0
    this.return_type_store_credit = 'store_credit'
    this.return_type_instant_store_credit = 'instant_store_credit'
    this.store_credit_return_types = [this.return_type_store_credit, this.return_type_instant_store_credit]
    this.is_instant_store_credit_item_present = this.store_credit &&
      this.props.store_credit_items[0].return_type === this.return_type_instant_store_credit

    this.state = {
      restock: this.props.shopify_integration_restock,
      customer_return: this.props.customer_return,
      refund_notes: "",
      credit_bonus: this.credit_bonus.toFixed(2),
      merchant_return_method_cost: this.merchant_return_method_cost,
      shop_order_shipping_cost: this.max_shipping_refund,
      include_refund: false,
      refund_amount_without_shipping: this.props.refund_amount_without_shipping,
      policy_fees: this.props.policy_fees,
      t: this.props.lang === 'en' ? translationEN : translationDE,
      refund_items: this.props.refund_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
        restock: this.props.shopify_integration_restock ? '1' : '0',
      })),
      exchange_items: this.props.exchange_items
        .filter(({ is_cancelled }) => !is_cancelled)
        .map(({ id, is_cancelled }) => ({
          id,
          is_cancelled,
          restock: this.props.shopify_integration_restock ? '1' : '0',
        })),
      custom_items: this.props.custom_items.map(({ id, is_cancelled }) => ({
        id, is_cancelled
      })),
      store_credit_items: this.props.store_credit_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
        restock: this.props.shopify_integration_restock ? '1' : '0',
      })),
      complete_btn_disable: false,
      total_refund: this.total_refund,
      total_original_amount: this.store_credit ?
        this.props.store_credit_items.reduce((sum, item) => {
          if (!item.is_cancelled && parseFloat(item.original_amount)) {
            return sum + parseFloat(item.original_amount) * parseInt(item.quantity)
          }

          return sum
        }, 0)
        :
        this.props.refund_items.reduce((sum, item) => {
          if (!item.is_cancelled && parseFloat(item.displayed_original_price)) {
            return sum + parseFloat(item.displayed_original_price) * parseInt(item.quantity)
          }

          return sum
        }, 0),
    }

    this.currency = this.props.customer_return.items[0]?.displayed_currency || this.props.customer_return.currency
    this.currency_symbol = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(0).replace(/\d/g, "").trim()
  }

  maxReturnFees = () => {
    return parseFloat(this.state.refund_amount_without_shipping) +
      parseFloat(this.state.shop_order_shipping_cost || 0) -
      parseFloat(this.state.policy_fees)
  }

  maxPolicyFees = () => {
    return parseFloat(this.state.refund_amount_without_shipping) +
    parseFloat(this.state.shop_order_shipping_cost || 0) -
    parseFloat(this.state.merchant_return_method_cost)
  }

  restockChckboxHandler = (item, index, e) => {
    if(item.return_type === 'refund') {
      let items = this.state.refund_items

      items[index].restock = e.target.checked ? '1' : '0'
      this.setState(
        {
          refund_items: items
        }
      )
    }
    else if(item.return_type === 'exchange' || item.return_type === 'shop_exchange') {
      let items = this.state.exchange_items

      items[index].restock = e.target.checked ? '1' : '0'
      this.setState(
        {
          exchange_items: items
        }
      )
    } else if(this.store_credit_return_types.includes(item.return_type)) {
      let items = this.state.store_credit_items
      items[index].restock = e.target.checked ? '1' : '0'
      this.setState(
        {
          store_credit_items: items
        }
      )
    }
  }

  inputChangeHandler = ({ target: { name, value } }) => {
    if (name === 'shop_order_shipping_cost') {
      const clampedValue = clamp(value, 0, this.max_shipping_refund)
      this.setState({ [name]: clampedValue })
      this.updateTotalRefundAmount({
        shipping_cost_refund: clampedValue,
      })
    } else if (name === 'merchant_return_method_cost') {
      const clampedValue = clamp(value, 0, this.maxReturnFees())
      this.setState({ [name]: clampedValue })
      this.updateTotalRefundAmount({
        return_fees: clampedValue,
      })
    }  else if (name === 'credit_bonus') {
      const clampedValue = clamp(value, 0, this.credit_bonus)
      this.setState({ [name]: clampedValue })
      this.updateTotalRefundAmount({
        credit_bonus: clampedValue,
      })
    } else if (name === 'policy_fees') {
      const clampedValue = clamp(value, 0, this.maxPolicyFees())
      this.setState({ [name]: clampedValue })
      this.updateTotalRefundAmount({
        policy_fees: clampedValue,
      })
    } else {
      this.setState({ [name]: value })
    }
  }

  updateTotalRefundAmount = ({
    shipping_cost_refund = this.state.shop_order_shipping_cost,
    return_fees = this.state.merchant_return_method_cost,
    policy_fees = this.state.policy_fees,
    credit_bonus = this.state.credit_bonus,
  }) => {
    const total_refund = parseFloat(this.state.refund_amount_without_shipping)
      + parseFloat(shipping_cost_refund || 0)
      - parseFloat(return_fees || 0)
      - parseFloat(policy_fees || 0)
      + parseFloat(credit_bonus || 0)

    this.setState({ total_refund: Math.max(total_refund, 0) })
  }

  completeShopwareReturn = () => {
    const validateInput = this.validateShopwareInput()
    if (validateInput) {
      this.setState({
        complete_btn_disable: true
      })
      let body;
      body = {
        shipping_amount_refunded: this.state.shop_order_shipping_cost.toString(),
        return_cost_charged: this.state.merchant_return_method_cost.toString(),
      }
      fetch(`/customer_returns/${this.state.customer_return.id}/refund/shopware_create`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify(body),
      }).then(response => {
        if(response.status === 200) {
          response.json().then(_responseJson => {
            SuccessToast({
              toastId: 'shopwareReturnCompleted',
            })
          })
          window.location.href = `/customer_returns/${this.state.customer_return.id}/edit`
        }
        else {
          this.setState({complete_btn_disable: false})

          response.json().then((responseJson) => {
            ErrorToast({
              message: responseJson.error,
              toastId: 'shopwareReturnFailed',
            })
          })
        }
      })
    }
  }

  completeReturn = () => {
    const validateInput = this.validateInput()
    if (validateInput) {
      this.setState({
        complete_btn_disable: true
      })
      let body;
      const items = this.store_credit ? this.state.store_credit_items : this.state.refund_items
      body = {
        items: items.filter(item => !item.is_cancelled),
        exchange_items: this.state.exchange_items.filter(item => !item.is_cancelled),
        note: this.state.refund_notes,
        return_shipping_amount: this.state.merchant_return_method_cost.toString(),
        order_shipping_amount: this.state.shop_order_shipping_cost.toString(),
        policy_fees: this.state.policy_fees.toString(),
        credit_bonus: this.state.credit_bonus.toString(),
      }

      fetch(`/customer_returns/${this.state.customer_return.id}/refund`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify(body),
      }).then(response => {
        if(response.status === 200) {
          window.location.href = `/customer_returns/${this.state.customer_return.id}/edit`
        } else {
          this.setState({complete_btn_disable: false})

          response.json().then((responseJson) => {
            ErrorToast({
              message: responseJson.error,
              toastId: 'shopifyReturnFailed',
            })
          })
        }
      })
    }
  }

  includeToRefund = () => {
    fetch(`/customer_returns/${this.state.customer_return.id}/refund?include_refund=true`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
    .then((response) => {
      if(response.status === 200) {
        response.json().then((responseJson) => {
          const refund_amount_without_shipping = responseJson.refund_amount_without_shipping

          const total_custom_refund_original_amount = this.store_credit ?
            this.props.custom_items.reduce((sum, item) => {
              if(!item.is_cancelled && parseFloat(item.original_amount)){
                return sum + parseFloat(item.original_amount) * parseFloat(item.quantity)
              }

              return sum
            }, 0)
            :
            this.props.custom_items.reduce((sum, item) => {
              if(!item.is_cancelled && parseFloat(item.displayed_original_price)){
                return sum + parseFloat(item.displayed_original_price) * parseFloat(item.quantity)
              }

              return sum
            }, 0)

          this.setState((prevState) => {
            let new_state = {
              include_refund: true,
              refund_amount_without_shipping,
              total_refund: parseFloat(prevState.total_refund) + parseFloat(refund_amount_without_shipping)
                - parseFloat(prevState.refund_amount_without_shipping),
              total_original_amount: parseFloat(prevState.total_original_amount + parseFloat(total_custom_refund_original_amount))
            }

            if (this.store_credit) {
              new_state = {
                ...new_state,
                store_credit_items: [...prevState.store_credit_items, ...prevState.custom_items] // custom items should always be added to the end of the list
              }
            } else {
              new_state = {
                ...new_state,
                refund_items: [...prevState.refund_items, ...prevState.custom_items] // custom items should always be added to the end of the list
              }
            }

            return new_state;
          })
        })
      }
      else {
        response.json().then(responseJson => {
          console.log(responseJson.error || response.status + " " + response.statusText);
        })
      }
    })
  }

  validateShopwareInput = () => {
    const state_shop_order_shipping_cost = parseFloat(this.state.shop_order_shipping_cost)
    const state_merchant_return_method_cost = this.state.merchant_return_method_cost
    const state_refund_amount_without_shipping = parseFloat(this.state.refund_amount_without_shipping)
    const state_refund_amount_with_order_shipping = state_refund_amount_without_shipping + state_shop_order_shipping_cost

    if ((state_shop_order_shipping_cost > this.max_shipping_refund) || (state_shop_order_shipping_cost < 0)) {
      ErrorToast({
        message: `Shipping amount must be between 0-${this.max_shipping_refund}`,
        toastId: 'shippingRefundError',
      })

      return false
    }
    else if((state_merchant_return_method_cost < 0) || (state_merchant_return_method_cost > state_refund_amount_with_order_shipping)) {
      ErrorToast({
        message: `Return fee must be between 0-${state_refund_amount_without_shipping + state_shop_order_shipping_cost}`,
        toastId: 'returnFeesError',
      })

      return false
    }
    return true
  }

  validateInput = () => {
    const state_shop_order_shipping_cost = parseFloat(this.state.shop_order_shipping_cost)
    const state_merchant_return_method_cost = this.state.merchant_return_method_cost
    const state_refund_amount_without_shipping = parseFloat(this.state.refund_amount_without_shipping)
    const state_refund_amount_with_order_shipping = state_refund_amount_without_shipping + state_shop_order_shipping_cost

    if (((state_shop_order_shipping_cost > this.max_shipping_refund) || (state_shop_order_shipping_cost < 0)) &&
      this.state.refund_items.length) {
      ErrorToast({
        message: `Shipping amount must be between 0-${this.max_shipping_refund}`,
        toastId: 'shippingRefundError',
      })

      return false
    }
    else if(((state_merchant_return_method_cost < 0) ||
      (state_merchant_return_method_cost > state_refund_amount_with_order_shipping)) && this.state.refund_items.length) {
      ErrorToast({
        message: `Return fee must be between 0-${state_refund_amount_without_shipping + state_shop_order_shipping_cost}`,
        toastId: 'returnFeesError',
      })

      return false
    }
    return true
  }

  isEqualDecimalNumbers = (a, b) => {
    return Math.abs(parseFloat(a) - parseFloat(b)) < Number.EPSILON
  }

  currencyPaddingRight = () => {
    switch (this.currency_symbol.length) {
      case 1:
        return "pl-5";
      case 3:
        return "pl-10";
      default:
        return "pl-5";
    }
  }

  isShowRestockOption = (item) => {
    return this.props.shop_backend == 'shopify_integration' &&
    !item.is_cancelled
  }
  render() {
    return(
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />
        <div className="flex justify-between items-center px-8 pb-3">
          <div className="font-inter font-medium text-xs leading-4 text-gray-525">
            <p><span className="text-gray-525"><a href="/customer_returns" className = "focus:outline-none" accessKey="E">{this.state.t.manage_return}</a> / </span><span className="text-gray-725 text-xs13"> { this.props.shop_backend == 'shopify_integration' ? this.props.customer_return.order_name : this.props.customer_return.order_number } </span></p>
          </div>
          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">{this.props.current_user.first_name + " "}{this.props.current_user.last_name}</p>
              <p className="font-normal text-xs leading-4 text-right text-gray-625">{this.props.current_user.email}</p>
            </a>
          </div>
        </div>
        <div className="border-t border-b px-8 font-semibold text-base leading-6 text-gray-825 py-5 border-gray-225">
          {
            this.state.refund_items.length > 0 || this.state.store_credit_items.length > 0
              ? this.state.t.refunded_items
              : this.state.custom_items.length > 0
                ? this.state.t.custom_items
                : this.state.exchange_items.length > 0
                  ? this.state.t.exchanged_items
                  : null
          }
        </div>
        <div className="flex">
          <div className="border-r w-67% min-h-screen">
            {/* TODO: Refactor Items listing, try to create a separate component and remove duplicate code */}
            {
              this.props.refund_items.map((item, index) => {
                  return (
                    <div className="pt-8 px-8 pr-6" key={index}>
                      <div className="border-b pb-8" key={index}>
                        <div className={`flex items-center justify-between ${item.is_cancelled ? "opacity-50" : ""}`}>
                          <div className="flex items-center">
                            <div className="flex items-center justify-center w-72px h-72px border rounded">
                              <img className="max-h-70px" src={item.item_picture_image_url} alt="img" />
                            </div>
                            <div className="ml-6">
                              <p className="font-semibold text-xs leading-4 text-gray-825"> { item.name } </p>
                              <div className="flex items-center mt-1">
                                <p className="font-medium text-xs leading-4 text-gray-725"> SKU </p>
                                <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> { item.sku } </p>
                                {
                                  item.ean &&
                                  <div className="flex">
                                    <p className="font-medium text-xs leading-4 text-gray-725 ml-6"> EAN </p>
                                    <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> {item.ean} </p>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center">
                            {
                              item.displayed_original_price && !this.isEqualDecimalNumbers(item.displayed_original_price, item.displayed_price) &&
                              <p className="font-medium text-xs leading-4 text-gray-825 line-through mr-3">
                                { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(parseFloat(item.displayed_original_price))}
                              </p>
                            }
                            {
                              <p className="font-medium text-xs leading-4 text-gray-825">
                                {new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.displayed_price)}
                              </p>
                            }

                            <svg width={17} height={16} className="mx-4" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 4.5L5 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5 4.5L12 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <p className="font-medium text-xs leading-4 text-gray-825">
                              {item.quantity}
                            </p>
                          </div>
                          {
                            <div>
                              <p className="font-semibold font-clash_grotesk text-lg leading-6 text-right">
                                { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.displayed_price * item.quantity)}
                              </p>
                            </div>
                          }

                        </div>
                        {
                          this.isShowRestockOption(item) &&
                          <div className="mt-3 flex items-center">
                            <input className="cursor-pointer border w-6 h-6 bg-white box-border rounded" type="checkbox" id={item.id}  onChange={(e) => this.restockChckboxHandler(item, index, e)} defaultChecked={this.state.restock}></input>
                            <label htmlFor={item.id} className="ml-3 font-medium text-xs leading-4 text-gray-825 cursor-pointer">{this.state.t.restock} ({item.quantity})</label>
                          </div>
                        }

                      </div>
                    </div>

                  )
                })
            }
            {
              this.props.store_credit_items.map((item, index) => {
                return (
                  <div className="pt-8 px-8 pr-6" key={index}>
                    <div className="border-b pb-8" key={index}>
                      <div className={`flex items-center justify-between ${item.is_cancelled ? "opacity-50" : ""}`}>
                        <div className="flex items-center">
                          <div className="flex items-center justify-center w-72px h-72px border rounded">
                            <img className="max-h-70px" src={item.item_picture_image_url} alt="img" />
                          </div>
                          <div className="ml-6">
                            <p className="font-semibold text-xs leading-4 text-gray-825"> { item.name } </p>
                            <div className="flex items-center mt-1">
                              <p className="font-medium text-xs leading-4 text-gray-725"> SKU </p>
                              <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> { item.sku } </p>
                              {
                                item.ean &&
                                <div className="flex">
                                  <p className="font-medium text-xs leading-4 text-gray-725 ml-6"> EAN </p>
                                  <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> {item.ean} </p>
                                </div>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center">
                          {
                            item.original_amount && !this.isEqualDecimalNumbers(item.original_amount, item.amount) &&
                            <p className="font-medium text-xs leading-4 text-gray-825 line-through mr-3">
                              { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(parseFloat(item.original_amount))}
                            </p>
                          }
                          {
                            <p className="font-medium text-xs leading-4 text-gray-825">
                              {new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.amount)}
                            </p>
                          }

                          <svg width={17} height={16} className="mx-4" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4.5L5 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 4.5L12 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                          <p className="font-medium text-xs leading-4 text-gray-825">
                            {item.quantity}
                          </p>
                        </div>
                        {
                          <div>
                            <p className="font-semibold font-clash_grotesk text-lg leading-6 text-right">
                              { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.amount * item.quantity)}
                            </p>
                          </div>
                        }

                      </div>
                      {
                        this.isShowRestockOption(item) &&
                        <div className="mt-3 flex items-center">
                          <input className="cursor-pointer border w-6 h-6 bg-white box-border rounded" type="checkbox" id={item.id}  onChange={(e) => this.restockChckboxHandler(item, index, e)} defaultChecked={this.state.restock}></input>
                          <label htmlFor={item.id} className="ml-3 font-medium text-xs leading-4 text-gray-825 cursor-pointer">{this.state.t.restock} ({item.quantity})</label>
                        </div>
                      }

                    </div>
                  </div>

                )
              })
            }
            {
              (this.props.custom_items.length > 0) &&
                <div>
                  {
                    ((this.state.refund_items.length > 0 || this.state.store_credit_items.length > 0) && !this.state.include_refund ) &&
                      <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                        {this.state.t.custom_items}
                      </div>
                  }
                  {
                    this.props.custom_items.map((item, index) => {
                        return (
                          <div className="pt-8 px-8 pr-6" key={index}>
                            <div className="border-b pb-8" key={index}>
                              <div className={`flex items-center justify-between ${item.is_cancelled ? "opacity-50" : ""}`}>
                                <div className="flex items-center">
                                  <div className="flex items-center justify-center w-72px h-72px border rounded">
                                    <img className="max-h-70px" src={item.item_picture_image_url} alt="img" />
                                  </div>
                                  <div className="ml-6">
                                    <p className="font-semibold text-xs leading-4 text-gray-825"> { item.name } </p>
                                    <div className="flex items-center mt-1">
                                      <p className="font-medium text-xs leading-4 text-gray-725"> SKU </p>
                                      <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> { item.sku } </p>
                                      {
                                        item.ean &&
                                        <div className="flex">
                                          <p className="font-medium text-xs leading-4 text-gray-725 ml-6"> EAN </p>
                                          <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> {item.ean} </p>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  {
                                    this.store_credit && this.state.include_refund ?
                                    item.original_amount && !this.isEqualDecimalNumbers(item.original_amount, item.amount) &&
                                    <p className="font-medium text-xs leading-4 text-gray-825 line-through mr-3">
                                      { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(parseFloat(item.original_amount))}
                                    </p>
                                    :
                                    item.displayed_original_price && !this.isEqualDecimalNumbers(item.displayed_original_price, item.displayed_price) &&
                                    <p className="font-medium text-xs leading-4 text-gray-825 line-through mr-3">
                                      { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(parseFloat(item.displayed_original_price))}
                                    </p>
                                  }
                                  {
                                    this.store_credit && this.state.include_refund ?
                                    <p className="font-medium text-xs leading-4 text-gray-825">
                                      {new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.amount)}
                                    </p>
                                    :
                                    <p className="font-medium text-xs leading-4 text-gray-825">
                                      {new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.displayed_price)}
                                    </p>
                                  }

                                  <svg width={17} height={16} className="mx-4" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4.5L5 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 4.5L12 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>

                                  <p className="font-medium text-xs leading-4 text-gray-825">{item.quantity}</p>
                                </div>
                                {
                                  this.store_credit && this.state.include_refund ?
                                  <div>
                                    <p className="font-semibold font-clash_grotesk text-lg leading-6 text-right">
                                      { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.amount * item.quantity)}
                                    </p>
                                  </div>
                                  :
                                  <div>
                                    <p className="font-semibold font-clash_grotesk text-lg leading-6 text-right">
                                      { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.displayed_price * item.quantity)}
                                    </p>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        )
                      })
                  }
                  {
                    (!this.state.include_refund && (this.state.refund_items.length > 0 || this.state.exchange_items.length > 0 || !this.is_instant_store_credit_item_present)) &&
                      <div className="px-8 mt-5">
                        <button className="w-full border bg-blue-675 rounded-lg text-white font-semibold text-xs leading-4 py-2 focus:outline-none" onClick={this.includeToRefund}>{this.state.t.include_to_refund}</button>
                      </div>
                  }
                </div>
            }
            {
              (this.props.exchange_items.length > 0) &&
                <div>
                  {
                    (this.state.refund_items.length > 0 || this.state.custom_items.length > 0 || this.state.store_credit_items.length > 0) &&
                      <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                      {this.state.t.exchanged_items}
                      </div>
                  }
                  {
                    this.props.exchange_items.map((item, index) => {
                        return (
                          <div className="pt-8 px-8 pr-6" key={index}>
                            <div className="border-b pb-8" key={index}>
                              <div className={`flex items-center justify-between ${item.is_cancelled ? "opacity-50" : ""}`}>
                                <div className="flex items-center">
                                  <div className="flex items-center justify-center w-72px h-72px border rounded">
                                    <img className="max-h-70px" src={item.item_picture_image_url} alt="img" />
                                  </div>
                                  <div className="ml-6">
                                    <p className="font-semibold text-xs leading-4 text-gray-825"> { item.name } </p>
                                    <div className="flex items-center mt-1">
                                      <p className="font-medium text-xs leading-4 text-gray-725"> SKU </p>
                                      <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> { item.sku } </p>
                                      {
                                        item.ean &&
                                        <div className="flex">
                                          <p className="font-medium text-xs leading-4 text-gray-725 ml-6"> EAN </p>
                                          <p className="font-normal text-xs leading-4 text-gray-625 ml-3"> {item.ean} </p>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="flex items-center">
                                  {
                                    item.displayed_original_price && !this.isEqualDecimalNumbers(item.displayed_original_price, item.displayed_price) &&
                                    <p className="font-medium text-xs leading-4 text-gray-825 line-through mr-3">
                                      { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(parseFloat(item.displayed_original_price))}
                                    </p>
                                  }

                                  <p className="font-medium text-xs leading-4 text-gray-825">
                                    {new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.displayed_price)}
                                  </p>

                                  <svg width={17} height={16} className="mx-4" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4.5L5 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 4.5L12 11.5" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>

                                  <p className="font-medium text-xs leading-4 text-gray-825">{item.quantity}</p>
                                </div>
                                {
                                  <div>
                                    <p className="font-semibold font-clash_grotesk text-lg leading-6 text-right">
                                      { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(item.displayed_price * item.quantity)}
                                    </p>
                                  </div>
                                }
                              </div>
                              {
                                this.isShowRestockOption(item) &&
                                <div className="mt-3 flex items-center">
                                  <input className="cursor-pointer border w-6 h-6 bg-white box-border rounded" type="checkbox" id={item.id} defaultChecked={this.state.restock} onChange={(e) => this.restockChckboxHandler(item, index, e)}></input>
                                  <label htmlFor={item.id} className="ml-3 font-medium text-xs leading-4 text-gray-825 cursor-pointer"> {this.state.t.restock} ({item.quantity})</label>
                                </div>
                              }
                            </div>
                          </div>
                        )
                      })
                  }
                </div>
            }
            { this.props.shop_backend === 'shopify_integration' &&
              <div className="mt-8 px-8 pr-6">
                <p className="font-semibold text-base leading-6 text-gray-825">
                  {this.state.t.refund_notes}
                </p>
                <TextArea
                  height="h-40px"
                  placeholder={this.state.t.refund_notes_placeholder}
                  name="refund_notes"
                  onChange={(value) =>
                    this.inputChangeHandler({ target: { name: "refund_notes", value }})
                  }
                  value={this.state.refund_notes}
                />
              </div>
            }
          </div>
          { this.props.shop_backend === 'shopify_integration' &&
            <div className="flex-grow pl-6 pt-8 pr-8">
              {
                (this.state.refund_items.length > 0 || this.state.store_credit_items.length > 0 || this.state.include_refund) &&
                  <div>
                    <div className="mb-5">
                      <p className="font-semibold text-base leading-6 text-gray-825">{this.state.t.summary}</p>
                    </div>
                    <div className="border-b pb-5">
                      {
                        this.state.customer_return.discount_codes &&
                        this.state.customer_return.discount_codes.split(',').map((code, index)=>(
                          <div className="w-full flex items-center mb-4" key={index}>
                            <p className="w-1/2 text-xs13 leading-4 text-gray-525">{this.state.t.discount_code}</p>
                            <div className="w-1/2 flex items-center">
                              <p className="font-medium text-xs13 leading-4 text-gray-825 mr-3">{code}</p>
                            </div>
                          </div>
                        ))
                      }
                      <div className="w-full flex items-center mb-4">
                        <p className="w-1/2 text-xs13 leading-4 text-gray-525">{this.state.t.total}</p>
                        <div className="w-1/2 flex items-center">
                          <p className="font-medium text-xs13 leading-4 text-gray-825 mr-3">{ new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(parseFloat(this.state.refund_amount_without_shipping))}</p>
                          {
                            (parseFloat(this.state.total_original_amount) > 0) && !this.isEqualDecimalNumbers(this.state.total_original_amount, this.state.refund_amount_without_shipping) &&
                            <p className="font-medium text-xs13 leading-4 text-gray-825 line-through">
                              { new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(this.state.total_original_amount)}
                            </p>
                          }
                        </div>
                      </div>

                      {/* Return Fees */}

                      <div className="w-full flex items-center mb-4">
                        <div className="w-1/2 flex items-center justify-between pr-1">
                           <p className="text-xs13 leading-4 text-gray-525"> {this.state.t.return_fees}</p>
                           <p className="font-medium text-xs13 leading-4 text-gray-625">-</p>
                        </div>
                        <div className="relative w-1/2 flex items-center">
                          <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">{this.currency_symbol}</p>
                          <input
                            type="number"
                            className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                            min="0.0"
                            max={this.maxReturnFees()}
                            step="0.01"
                            name="merchant_return_method_cost"
                            onChange={this.inputChangeHandler}
                            value={this.state.merchant_return_method_cost}
                          />
                        </div>
                      </div>

                      {
                        this.store_credit &&
                        <div className="w-full flex items-center mb-4">
                          <div className="w-1/2 flex items-center justify-between pr-1">
                            <p className="text-xs13 leading-4 text-gray-525">{this.state.t.credit_bonus}</p>
                            <p className="font-medium text-xs13 leading-4 text-gray-625">+</p>
                          </div>
                          <div className="relative w-1/2 flex items-center">
                            <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">{this.currency_symbol}</p>
                            <input
                              type="number"
                              className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                              name="credit_bonus"
                              max={this.credit_bonus}
                              step="0.01"
                              min="0.0"
                              onChange={this.inputChangeHandler}
                              value={this.state.credit_bonus}
                            />
                          </div>
                        </div>
                      }

                      {/* Policy Fees */}

                      {this.props.partial_refund_enabled &&
                        <div className="w-full flex items-center mb-4">
                          <div className="w-1/2 flex items-center justify-between pr-1">
                            <p className="text-xs13 leading-4 text-gray-525"> {this.state.t.policy_fees}</p>
                            <p className="font-medium text-xs13 leading-4 text-gray-625">-</p>
                          </div>
                          <div className="relative w-1/2 flex items-center">
                            <p
                              className="absolute left-3 font-normal text-xs leading-4 text-gray-875">{this.currency_symbol}</p>
                            <input
                              type="number"
                              className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                              min="0.0"
                              max={this.maxPolicyFees()}
                              step="0.01"
                              name="policy_fees"
                              onChange={this.inputChangeHandler}
                              value={this.state.policy_fees}
                            />
                          </div>
                        </div>
                      }

                      {
                        this.state.shop_order_shipping_cost > 0 &&
                        <div className="w-full flex items-center">
                          <div className="w-1/2 flex items-center justify-between pr-1">
                            <p className="text-xs13 leading-4 text-gray-525">{this.state.t.shipping_refund}</p>
                            <p className="font-medium text-xs13 leading-4 text-gray-625">+</p>
                          </div>
                          <div className="relative w-1/2 flex items-center">
                            <p className="absolute left-3 font-normal text-xs leading-4 text-gray-875">{this.currency_symbol}</p>
                            <input
                              type="number"
                              className={`${this.currencyPaddingRight()} w-full bg-white border box-border rounded-lg focus:outline-none not-italic font-normal text-xs leading-4 text-gray-875 py-2 pr-3 focus:border-blue-675 focus:border`}
                              min="0.0"
                              max={this.max_shipping_refund}
                              step="0.01"
                              name="shop_order_shipping_cost"
                              onChange={this.inputChangeHandler}
                              value={this.state.shop_order_shipping_cost}
                            />
                          </div>
                        </div>
                      }
                    </div>
                    <div className="border-b py-5">
                      <div className="w-full flex items-center">
                        <p className="w-1/2 text-xs13 leading-4 text-gray-525">{this.state.t.total_refund}</p>
                        <p className="w-1/2 font-clash_grotesk font-semibold text-lg leading-6 text-gray-825">{ new Intl.NumberFormat('en', { style: 'currency', currency: this.currency }).format(this.state.total_refund)}</p>
                      </div>
                    </div>

                    <div className="border-b py-5">
                      <div className="w-full flex items-center">
                        <p className="w-1/2 text-xs13 leading-4 text-gray-525">{this.state.t.refund_method}</p>
                          <p className="w-1/2 font-medium leading-4 text-xs13 text-gray-825">
                            {
                              this.store_credit
                                ? this.is_instant_store_credit_item_present
                                  ? this.state.t.instant_store_credit
                                  : this.state.t.store_credit
                                : this.state.t.original_payment_method
                            }
                          </p>
                      </div>
                    </div>
                  </div>
              }
              <div className="mt-5">
                <button className={`w-full border rounded-lg font-semibold text-xs leading-4 py-2 focus:outline-none bg-blue-675 text-white hover:bg-blue-625 focus:shadow-btn_blue ${this.state.complete_btn_disable ? "cursor-default" : " "}`} disabled={this.state.complete_btn_disable} onClick={this.completeReturn}>
                  {!this.state.complete_btn_disable &&
                    <span>
                      {this.state.t.complete}
                    </span>
                  }
                  {this.state.complete_btn_disable &&
                    <div className=" flex justify-center items-center">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                    </div>
                  }
                </button>
              </div>
            </div>
          }
          { this.props.shop_backend === 'shopware_integration' &&
            <div className="flex-grow pl-6 pt-9">
              <div>
                <div className="flex items center mb-6">
                  <p className="text-xs leading-4 flex items-center text-gray-525">{this.state.t.total}</p>
                  <p className="font-semibold text-lg leading-6 text-right text-gray-825 ml-5">
                    {
                      new Intl.NumberFormat('en', {
                        style: 'currency',
                        currency: this.state.customer_return.currency
                      }).format(parseFloat(this.state.customer_return.total_amount))
                    }
                  </p>
                </div>
                {
                  (this.state.shop_order_shipping_cost > 0) &&
                    <div className="mb-6 pr-8">
                      <p className="font-medium text-xs leading-4 flex items-center text-gray-725">{this.state.t.shipping_refund}</p>
                      <input
                        type="number"
                        className="bg-white border w-full box-border rounded-lg focus:outline-none font-medium text-sm leading-5 text-gray-525 py-3 px-4 mt-1"
                        min="0.0"
                        max={this.max_shipping_refund}
                        step="0.01"
                        name="shop_order_shipping_cost"
                        onChange={this.inputChangeHandler}
                        value={this.state.shop_order_shipping_cost}
                      />
                    </div>
                }
                <div className="pr-8 mb-6">
                    <p className="font-medium text-xs leading-4 flex items-center text-gray-725"> {this.state.t.return_fees} </p>
                    <input
                      type="number"
                      className="bg-white border w-full box-border rounded-lg focus:outline-none font-medium text-sm leading-5 text-gray-525 py-3 px-4 mt-1"
                      min="0.0"
                      max={parseFloat(this.state.refund_amount_without_shipping) + parseFloat(this.state.shop_order_shipping_cost)}
                      step="0.01"
                      name="merchant_return_method_cost"
                      onChange={this.inputChangeHandler}
                      value={this.state.merchant_return_method_cost}
                    />
                </div>
                <div className="flex items-center border-b pb-6">
                  <p className="text-xs leading-4 flex items-center text-gray-525">Total refund</p>
                  <p className="font-semibold text-lg leading-6 text-right text-gray-825 ml-5">{ new Intl.NumberFormat('en', { style: 'currency', currency: this.state.customer_return.currency }).format(parseFloat(this.state.customer_return.total_amount) - this.state.merchant_return_method_cost + parseFloat(this.state.shop_order_shipping_cost) )}</p>
                </div>
              </div>
              <div className="pr-8 mt-5">
                <button className={`w-full border rounded-lg font-semibold text-xs leading-4 py-2 focus:outline-none bg-blue-675 text-white hover:bg-blue-625 focus:border-blue-625 ${this.state.complete_btn_disable ? "cursor-default" : " "}`} disabled={this.state.complete_btn_disable} onClick={this.completeShopwareReturn}>
                  {!this.state.complete_btn_disable &&
                    <span>
                      {this.state.t.complete}
                    </span>
                  }
                  {this.state.complete_btn_disable &&
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                    </div>
                  }
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
export default Refund
