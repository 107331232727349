import React, { FC, useMemo } from "react";
import ReactSelect from "react-select";
import { isEmpty } from "lodash";
import { SelectOption } from "./Select.types";

type SelectProps = {
  options: SelectOption[];
  onChange: (value: string | SelectOption[]) => void;
  value: string;
  defaultOption?: SelectOption;
  isMulti?: boolean;
};

export const Select: FC<SelectProps> = ({
  options = [],
  onChange,
  defaultOption = null,
  value,
  isMulti = false,
  ...props
}) => {
  const selectedOption = useMemo(() => {
    if(isEmpty(value)) return;
    if (isMulti) {
      const values = value.split(",").map((v) => v.trim());
      return options.filter((option) => values.includes(option.value.trim()));
    }

    return options.find((option) => option.value.trim() === value.trim());
  }, [value, options, isMulti]);

  return (
    <ReactSelect
      {...props}
      defaultValue={defaultOption}
      options={options}
      onChange={(data) => onChange(isMulti ? data : data.value)}
      value={selectedOption}
      isMulti={isMulti}
    />
  );
};
