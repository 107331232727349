export const useSideBar = ({
  customer_return,
}) => {
  const isInstantStoreCreditCaptureAuthorizedAmountPopupEligible = () => {
    return customer_return.instant_store_credit_capture_authorized_amount_popup_eligible;
  };

  return {
    isInstantStoreCreditCaptureAuthorizedAmountPopupEligible,
  };
};
