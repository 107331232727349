import React, { useState } from "react";
import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import translationEN from "./locales/EN.json";
import translationDE from "./locales/DE.json";

export const useUser = ({
  currentUser,
  current_password,
  password,
  locales,
}) => {
  const t = currentUser.locale === "en" ? translationEN : translationDE;

  const { first_name, last_name, email, locale, merchant_id } = currentUser;

  const localeOptions = Object.entries(locales).map(([label, value]) => ({
    label,
    value,
  }));

  const getInitialState = () => {
    return {
      user: {
        first_name,
        last_name,
        email,
        locale,
        merchant_id,
        current_password,
        password,
      },
      confirmLogout: false,
    };
  };

  const [state, setState] = useState(getInitialState());

  const inputChangeHandle = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      user: { ...prevState.user, [e.target.name]: e.target.value },
    }));
  };

  const logOut = () => {
    fetch("/users/sign_out", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    }).then((response) => {
      window.location.href = "/";
    });
  };

  const selectChangeHandler = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      user: { ...prevState.user, [name]: value },
    }));
  };

  const dropBlankPassword = ({ current_password, password, ...props }) => {
    return {
      ...((current_password || password) && { current_password, password }),
      ...props,
    };
  };

  const updateUser = () => {
    const body = { user: dropBlankPassword(state.user) };
    fetch("/users", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 422) {
        response.json().then((responseJson) => {
          const errors = Object.keys(responseJson.errors).map((key) =>
            key.replace(/_/g, " ").concat(" ", responseJson.errors[key][0])
          );
          ErrorToast({
            message: errors.join(", "),
            toastId: "updateUser",
          });
        });
        setState((prevState) => ({
          ...prevState,
        }));
      } else {
        SuccessToast({ toastId: "updateUser" });
        setTimeout(() => {
          window.location.href = response.headers.get("Location") || "/";
        }, 1000);
      }
    });
  };

  function confirmLogoutHandler() {
    setState((prevState) => ({
      ...prevState,
      confirmLogout: !prevState.confirmLogout,
    }));
  }

  return {
    t,
    state,
    localeOptions,
    selectChangeHandler,
    inputChangeHandle,
    logOut,
    updateUser,
    confirmLogoutHandler,
  };
};
