import React from "react";
import SearchInput from "./SearchInput";

const CustomerReturnSearch = ({ value, onChange }) => (
  <SearchInput
    type="text"
    placeholder="Customer Return ID"
    value={value}
    onChange={onChange}
  />
);

export default CustomerReturnSearch;
