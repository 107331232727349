import React from "react";

const CustomReturnSection = ({ t, tI18next, handleInputChange, textBlock }) => {
  return (
    <div className="mt-4 border-b pb-4 w-762px">
      <p className="font-semibold text-base leading-6 text-gray-975">
        {t.custom_returns}
      </p>

      <p className="text-xs font-medium leading-4 text-gray-725 mt-1">
        {tI18next("custom_return_section_title_note")}
      </p>

      <div className="mt-4">
        <input
          placeholder={t.custom_button}
          className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
          type="text"
          name="custom_return_button"
          onChange={handleInputChange}
          value={textBlock.custom_return_button}
        />

        <div className="mt-4">
          <input
            placeholder={t.custom_button_description}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name="custom_button_description"
            onChange={handleInputChange}
            value={textBlock.custom_button_description}
          />
        </div>
      </div>
      <div className="block">
        <div className="mt-4">
          <input
            placeholder={t.custom_return_header}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name="custom_return_header"
            onChange={handleInputChange}
            value={textBlock.custom_return_header}
          />
        </div>
        <div className="mt-4">
          <input
            placeholder={t.custom_return_title}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name="custom_return_title"
            onChange={handleInputChange}
            value={textBlock.custom_return_title}
          />
        </div>
        <div className="mt-4">
          <input
            placeholder={t.custom_return_description}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name="custom_return_description"
            onChange={handleInputChange}
            value={textBlock.custom_return_description}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomReturnSection;
