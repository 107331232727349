import { useState } from "react";

export const useOnboardingAssistantState = ({
  initialValue,
  updateMerchantSettings,
  showErrorNotification,
}) => {
  const [onboardingAssistantVisibilityDays, setOnboardingAssistantState] =
    useState(initialValue);

  async function updateOnboardingAssistantState(newValue) {
    setOnboardingAssistantState(newValue);

    const response = await updateMerchantSettings({
      onboarding_assistant_visibility_days: newValue,
    });

    const responseJSON = await response.json();

    if (response.status === 200) {
      setOnboardingAssistantState(responseJSON.onboarding_assistant_visibility_days);
    } else {
      showErrorNotification(responseJSON.error);
      setOnboardingAssistantState(onboardingAssistantVisibilityDays);
    }
  }

  return {
    onboardingAssistantVisibilityDays,
    updateOnboardingAssistantState,
  };
}
