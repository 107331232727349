import React from "react";

class EmailData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      t: this.props.t,
    };
  }

  render() {
    return (
      <div>
        <div className="mb-4">
          <label className="block font-medium text-xs leading-4 text-gray-725 mb-1">
            {this.state.t.email_subject}
          </label>

          <input
            className="w-full focus:outline-none bg-white py-10px px-4 box-border rounded-lg border border-gray-325  focus:border focus:border-blue-675 font-medium text-sm leading-5 text-gray-875"
            name="subject"
            onChange={(e) =>
              this.props.translationInputChangeHandler(
                this.props.selectedTranslationIndex,
                "subject",
                e.target.value
              )
            }
            value={this.props.selectedTranslation.subject}
          />
        </div>

        <div>
          <label className="block font-medium text-xs leading-4 text-gray-725 mb-1">
            {this.state.t.email_body}
          </label>

          <textarea
            className="w-full bg-white border box-border border-gray-325 rounded-lg py-10px px-2 focus:outline-none focus:border-blue-675 focus:border font-medium text-sm leading-5 text-gray-875 shadow-textarea_shadow"
            rows="8"
            name="body"
            onChange={(e) =>
              this.props.translationInputChangeHandler(
                this.props.selectedTranslationIndex,
                "body",
                e.target.value
              )
            }
            value={this.props.selectedTranslation.body}
          />
        </div>
      </div>
    );
  }
}

export default EmailData;
