import React from "react";

export const DateArrowIcon = ({ rotate }) => {
  return (
    <svg
      className={`mr-1 ${rotate === "asc" ? "transform rotate-180" : ""}`}
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="focus:outline-none"
        d="M3.64645 8.35355C3.84171 8.54882 4.15829 8.54882 4.35355 8.35355L7.53553 5.17157C7.7308 4.97631 7.7308 4.65973 7.53553 4.46447C7.34027 4.2692 7.02369 4.2692 6.82843 4.46447L4 7.29289L1.17157 4.46447C0.976311 4.2692 0.659728 4.2692 0.464466 4.46447C0.269204 4.65973 0.269204 4.97631 0.464466 5.17157L3.64645 8.35355ZM3.5 0L3.5 8H4.5V0L3.5 0Z"
        fill="#6A758A"
      />
    </svg>
  );
};
