export const useInstantStoreCredit = () => {
  const inputNames = [
    "store_credit_instant_title",
    "original_payment_method",
    "store_credit_instant_description",
    "store_credit_later_title",
    "store_credit_later_description",
    "payment_authorization",
    "temporary_hold",
    "payment_authorization_foot_note",
    "payment_options",
    "payment_error_message",
    "receiving_refund",
    "receiving_store_credit",
  ];

  return {
    inputNames,
  };
};
