import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import Modal from "react-modal";
import moment from "moment";

import Table from "./table";
import CustomerReturnsExport from "./customer_returns_export";
import CustomerReturnsImportModal from "./customer_returns_import_modal";
import translationDE from "../../translations/manageReturns/translationDE.json";
import translationEN from "../../translations/manageReturns/translationEN.json";
import { ManageReturnIcon } from "../ui-kit/icons/ManageReturnIcon";
import { SearchIcon } from "../ui-kit/icons/SearchIcon";
import { FilterDateIcon } from "../ui-kit/icons/FilterDateIcon";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { NotificationIcon } from "../../ui-kit/icons/NotificationIcon";

class ManageReturns extends React.Component {
  constructor(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const limit = queryParams.get("limit");
    const type = queryParams.get("type");
    const search = queryParams.get("search");
    const startDate = queryParams.get("start_date");
    const endDate = queryParams.get("end_date");
    const orderBy = queryParams.get("order");
    const page = queryParams.get("page");
    const registeredBy = queryParams.get("registered_date");
    super(props);
    this.state = {
      customer_returns: this.props.returns_data.customer_returns,
      pagination: this.props.returns_data.pagination,
      selectedType: type || "",
      status: this.props.status,
      searchInputText: search || "",
      perPage: limit || this.props.returns_data.pagination.limit,
      page: page || this.props.returns_data.pagination.current_page,
      t: this.props.lang === "en" ? translationEN : translationDE,
      showExportModal: false,
      showImportModal: false,
      startDate: startDate ? new Date(startDate) : "",
      endDate: endDate ? new Date(endDate) : "",
      filterByDate: !!startDate,
      showfilterByDate: false,
      order: orderBy || "",
      clearAll: false,
      searchProcess: false,
      registeredDateOrder: registeredBy || "",
    };
    if (!limit) {
      sessionStorage.setItem("URL", "/customer_returns");
    }
  }

  componentDidUpdate(_prevprops, prevState) {
    const isStateChange =
      prevState.status !== this.state.status ||
      prevState.selectedType !== this.state.selectedType ||
      prevState.page !== this.state.page ||
      prevState.perPage !== this.state.perPage ||
      prevState.startDate != this.state.startDate ||
      prevState.order !== this.state.order ||
      prevState.clearAll !== this.state.clearAll ||
      prevState.searchProcess !== this.state.searchProcess ||
      prevState.registeredDateOrder !== this.state.registeredDateOrder;
    if (isStateChange) {
      this.fetchData();
    }
  }

  fetchData = () => {
    let str = "";
    str = this.state.searchInputText;
    if (this.state.searchInputText[0] == "#") {
      str = str.slice(1);
    }
    const {
      perPage,
      status,
      order,
      selectedType,
      page,
      startDate,
      endDate,
      registeredDateOrder,
    } = this.state;

    const queryParams = {
      limit: perPage,
      type: selectedType,
      status: status,
      order: order,
      page: page,
      search: str,
      start_date: startDate,
      end_date: endDate,
      registered_date: registeredDateOrder,
    };

    const url = `/customer_returns?${new URLSearchParams(
      queryParams
    ).toString()}`;
    window.history.pushState("Object", "title", url);
    sessionStorage.setItem("URL", url);
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          this.setState({
            customer_returns: responseJson.customer_returns,
            pagination: responseJson.pagination,
          });
        });
      } else {
        response.json().then((responseJson) => {
          console.log(
            responseJson.error || response.status + " " + response.statusText
          );
        });
      }
    });
  };

  search = (e) => {
    e.preventDefault();
    this.setState({ searchProcess: !this.state.searchProcess, page: "1" });
  };

  clearAll = () => {
    this.setState({
      selectedType: "",
      status: "",
      searchInputText: "",
      customer_returns: this.props.returns_data.customer_returns,
      startDate: "",
      endDate: "",
      perPage: "10",
      page: "1",
      filterByDate: false,
      order: "",
      clearAll: !this.state.clearAll,
      registeredDateOrder: "",
    });
  };

  changeType = (e) => {
    this.setState({ selectedType: e.target.value, page: "1" });
  };

  getReturnStatusData = (e) => {
    this.setState({ status: e.target.value, page: "1" });
  };

  searchInput = (e) => {
    this.setState({ searchInputText: e.target.value });
  };

  openCreateReturnPage = () => {
    window.location = "customer_returns/new";
  };

  openCreateReturnPageForManualReturn = () => {
    window.location = "customer_returns/new?manual_return=true";
  };

  changePerPageData = (data) => {
    this.setState({ perPage: data.value, page: "1" });
  };

  nextPage = () => {
    if (this.state.pagination.next_page) {
      this.setState({ page: this.state.pagination.next_page });
    }
  };

  previousPage = () => {
    if (this.state.pagination.previous_page) {
      this.setState({ page: this.state.pagination.previous_page });
    }
  };

  showExportModal = () => {
    this.setState({ showExportModal: true });
  };

  showImportModal = () => {
    this.setState({ showImportModal: true });
  };

  closeExportModal = () => {
    this.setState({ showExportModal: false });
  };

  closeImportModal = () => {
    this.setState({ showImportModal: false });
  };

  handleSelectDate = (ranges) => {
    this.setState({
      page: "1",
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      filterByDate: true,
      showfilterByDate: this.state.showfilterByDate ? false : true,
    });
  };

  filterByDateHandler = () => {
    this.setState({
      showfilterByDate: this.state.showfilterByDate ? false : true,
    });
  };

  sortByDate = () => {
    this.setState({
      order: this.state.order === "desc" || !this.state.order ? "asc" : "desc",
      page: "1",
      registeredDateOrder: "",
    });
  };

  sortByRegisteredDate = () => {
    this.setState({
      registeredDateOrder:
        this.state.registeredDateOrder === "desc" ||
        !this.state.registeredDateOrder
          ? "asc"
          : "desc",
      page: "1",
      order: "",
    });
  };

  isReturnTypeFilterEnabled = () => {
    return (
      this.props.current_merchant.show_exchange ||
      this.props.current_merchant.show_custom_return ||
      this.props.current_merchant.show_store_credit_option ||
      this.props.current_merchant.instant_store_credit_enabled ||
      this.props.current_merchant.show_trade_in_items
    );
  };

  render() {
    return (
      <div className="font-inter">
        {this.props.current_merchant.shop_backend === "shopify_integration" &&
          this.props.notification && (
            <div className="bg-blue-500 bg-opacity-10 rounded-lg px-2 py-6px ml-5 flex items-center mr-5 mb-5 text-left">
              <NotificationIcon />
              <div
                className="font-medium text-xs13 leading-4 text-blue-500 ml-1 font-inter"
                dangerouslySetInnerHTML={{
                  __html: this.props.notification.text,
                }}
              ></div>
            </div>
          )}
        {this.props.current_merchant.shop_backend === "shopify_integration" &&
          this.props.merchant_notification && (
            <div className="bg-orange-500 bg-opacity-10 rounded-lg px-2 py-6px ml-5 flex items-center mr-5 mb-5 text-left">
              <NotificationIcon color="#DC6601" />
              <div
                className="font-medium text-xs13 leading-4 text-orange-500 ml-1 font-inter"
                dangerouslySetInnerHTML={{
                  __html: this.props.merchant_notification.text,
                }}
              ></div>
            </div>
          )}
        <CustomerReturnsExport
          showExportModal={this.state.showExportModal}
          closeExportModal={this.closeExportModal}
          userEmail={this.props.current_user.email}
          lang={this.props.lang}
          scayleIntegration={this.props.is_scayle_integration}
        />

        <CustomerReturnsImportModal
          showImportModal={this.state.showImportModal}
          closeImportModal={this.closeImportModal}
          lang={this.props.lang}
        />

        <div className="flex justify-between items-center px-8 pb-3 border-b">
          <div className="font-medium text-xs leading-4 text-gray-725">
            {this.state.t.manage_return}
          </div>
          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>
              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>

        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="flex items-center">
            <ManageReturnIcon />
            <p className="font-semibold text-base leading-6 text-gray-975 ml-4">
              {this.state.t.manage_return}
            </p>
          </div>

          <div className="flex items-center">
            {this.props.can_export && (
              <button
                className="font-semibold text-sm leading-5 text-gray-875 focus:outline-none mr-8"
                onClick={this.showExportModal}
              >
                {this.state.t.export}
              </button>
            )}
            {this.props.current_merchant.import_enabled &&
              this.props.can_import && (
                <button
                  className="font-semibold text-sm leading-5 text-gray-875 focus:outline-none mr-8"
                  onClick={this.showImportModal}
                >
                  {this.state.t.import}
                </button>
              )}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {(this.props.is_shopify_integration ||
                this.props.is_scayle_integration) &&
                this.props.current_merchant.is_create_return_button_enabled &&
                this.props.can_create_manual_return && (
                  <div
                    className="bg-blue-600 rounded-lg py-2 px-4 cursor-pointer"
                    onClick={this.openCreateReturnPage}
                    style={{ marginRight: "10px" }} // Add margin-right for spacing
                  >
                    <p className="font-semibold text-sm leading-5 text-white">
                      {this.state.t.new_return}
                    </p>
                  </div>
                )}
              {this.props.current_merchant.is_create_return_button_enabled &&
                this.props.can_create_manual_return &&
                [
                  "STERNGLAS",
                  "Junglück",
                  "LUMIÉRE Design",
                  "onemate",
                  "VYBELLE",
                  "NEONA",
                  "OLA KALA",
                  "mokebo",
                  "Goldeimer",
                  "mokebo",
                  "Sandbox Store",
                ].includes(this.props.current_merchant.name) && (
                  <div
                    className="bg-blue-600 rounded-lg py-2 px-4 cursor-pointer"
                    onClick={this.openCreateReturnPageForManualReturn}
                  >
                    <p className="font-semibold text-sm leading-5 text-white">
                      {this.state.t.manual_return}
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>

        {this.props.can_see_filters && (
          <div className="flex items-center pl-8 pt-5">
            <div className="flex items-center w-1/3 pl-5 py-2 border border-gray-325 bg-white box-border rounded-lg">
              <SearchIcon />
              <form
                className="font-medium text-xs leading-4 text-gray-525 ml-2 w-full"
                onSubmit={this.search}
              >
                <input
                  accessKey="W"
                  className="outline-none w-full"
                  name="search_value"
                  type="text"
                  placeholder={this.state.t.search_for_a_return}
                  onChange={this.searchInput}
                  value={this.state.searchInputText}
                ></input>
              </form>
            </div>

            {this.isReturnTypeFilterEnabled() && (
              <div className="flex items-center border border-gray-325 px-4 h-10 py-2 w-1/6 bg-white box-border rounded-lg ml-4">
                <select
                  className="font-medium text-xs leading-4 text-gray-525 focus:outline-none w-full flex items-center"
                  onChange={this.changeType}
                  value={this.state.selectedType}
                >
                  <option className="outline-none bg-white" value="">
                    {this.state.t.type}
                  </option>
                  <option className="outline-none bg-white" value="refund">
                    {this.state.t.refund}
                  </option>
                  {this.props.current_merchant.show_exchange && (
                    <option className="outline-none bg-white" value="exchange">
                      {this.state.t.exchange}
                    </option>
                  )}
                  {this.props.current_merchant.shop_exchange_enabled && (
                    <option
                      className="outline-none bg-white"
                      value="shop_exchange"
                    >
                      {this.state.t.shop_exchange}
                    </option>
                  )}
                  {this.props.current_merchant.show_custom_return && (
                    <option className="outline-none bg-white" value="custom">
                      {this.state.t.custom}
                    </option>
                  )}
                  {this.props.current_merchant.show_store_credit_option && (
                    <option
                      className="outline-none bg-white"
                      value="store_credit"
                    >
                      {this.state.t.store_credit}
                    </option>
                  )}
                  {this.props.current_merchant.instant_store_credit_enabled && (
                    <option
                      className="outline-none bg-white"
                      value="instant_store_credit"
                    >
                      {this.state.t.instant_store_credit}
                    </option>
                  )}
                  {this.props.current_merchant.show_trade_in_items && (
                    <option className="outline-none bg-white" value="trade_in">
                      {this.state.t.trade_in}
                    </option>
                  )}
                </select>
              </div>
            )}

            <div className="flex items-center justify-between border border-gray-325 pl-4 pr-3 py-2 w-1/6 bg-white box-border rounded-lg ml-4">
              <p className="font-medium text-xs leading-4 text-gray-525">
                {this.state.filterByDate
                  ? moment(this.state.startDate).format("DD.MM.YYYY") +
                    "-" +
                    moment(this.state.endDate).format("DD.MM.YYYY")
                  : this.state.t.filter_by_date}
              </p>
              <FilterDateIcon filterByDateHandler={this.filterByDateHandler} />
            </div>

            <Modal
              isOpen={this.state.showfilterByDate}
              onRequestClose={this.filterByDateHandler}
              style={{
                content: {
                  maxWidth: "700px",
                  maxHeight: "400px",
                  margin: "auto",
                  padding: "0",
                },
              }}
            >
              <DateRangePicker
                ranges={[
                  {
                    startDate: this.state.startDate || new Date(),
                    endDate: this.state.endDate || new Date(),
                    key: "selection",
                  },
                ]}
                onChange={this.handleSelectDate}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                direction="vertical"
                scroll={{ enabled: true }}
              />
            </Modal>

            <button
              className="font-semibold text-xs leading-4 text-gray-725 ml-4 focus:outline-none"
              onClick={this.clearAll}
            >
              {this.state.t.clear_all}
            </button>
          </div>
        )}

        {this.props.can_see_status_scopes && (
          <div className="flex items-center bg-white border-b border-t pl-8 mt-5 mb-9 w-full">
            <Router>
              <Link
                to="/customer_returns"
                className={`${
                  !this.state.status
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                } focus:outline-none text-sm leading-5 mr-10`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value=""
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.all}
                </button>
              </Link>
              <Link
                to="/customer_returns?status=registered"
                className={`${
                  this.state.status === "registered"
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                } focus:outline-none text-sm leading-5 mr-10 w-max`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value="registered"
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.open}
                </button>
              </Link>
              <Link
                to="/customer_returns?status=received"
                className={`${
                  this.state.status === "received"
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                }  focus:outline-none text-sm leading-5 mr-10 w-max`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value="received"
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.received}
                </button>
              </Link>
              <Link
                to="/customer_returns?status=needs_review"
                className={`${
                  this.state.status === "needs_review"
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                } focus:outline-none text-sm leading-5 mr-10 w-max`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value="needs_review"
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.needs_review}
                </button>
              </Link>
              <Link
                to="/customer_returns?status=on_hold"
                className={`${
                  this.state.status === "on_hold"
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                } focus:outline-none text-sm leading-5 mr-10 w-max`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value="on_hold"
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.on_hold}
                </button>
              </Link>
              <Link
                to="/customer_returns?status=cancelled"
                className={`${
                  this.state.status === "cancelled"
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                } focus:outline-none text-sm leading-5 mr-10 w-max`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value="cancelled"
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.cancelled}
                </button>
              </Link>
              <Link
                to="/customer_returns?status=completed"
                className={`${
                  this.state.status === "completed"
                    ? "font-semibold text-gray-900 border-b-2 border-gray-825"
                    : "font-medium text-gray-625"
                } focus:outline-none text-sm leading-5 mr-10 w-max`}
              >
                <button
                  className="focus:outline-none py-4 px-2"
                  value="completed"
                  onClick={this.getReturnStatusData}
                >
                  {this.state.t.completed}
                </button>
              </Link>
            </Router>
          </div>
        )}

        <Table
          customer_returns={this.state.customer_returns}
          type={this.state.selectedType}
          status={this.state.status}
          perPage={this.state.perPage}
          changePerPageData={this.changePerPageData}
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          page={this.state.page}
          t={this.state.t}
          lang={this.props.lang}
          current_merchant={this.props.current_merchant}
          order={this.state.order}
          sortByDate={this.sortByDate}
          sortByRegisteredDate={this.sortByRegisteredDate}
          registeredDateOrder={this.state.registeredDateOrder}
          canSortReturns={this.props.can_sort_returns}
        />
      </div>
    );
  }
}
export default ManageReturns;
