import { useState } from "react";

import { connectWithShopify } from "../../services/api";
import translationEN from "../../../translations/integrations/translationEN.json";
import translationDE from "../../../translations/integrations/translationDE.json";

import { ErrorToast } from "~dashboard/components/common/ErrorToast";
import { SuccessToast } from "~dashboard/components/common/SuccessToast";

export const useShopify = ({
  current_user,
  shopify_integration,
  shopify_login_url,
}) => {
  const {
    id,
    shopify_domain,
    notify_customer_on_refund,
    restock,
    order_tag,
    exchange_order_tag,
    custom_order_tag,
    shopify_token,
    api_key,
    password,
    refund_reason,
    public_app: publicApp,
    inventory_location_id,
    show_deleted_variants_using_sku_enabled,
    store_credit_order_tag,
    store_credit_gift_order_tag,
    return_syncing_enabled,
  } = shopify_integration;

  function getInitialState() {
    return {
      shopifyIntegration: {
        id: id ? id : "",
        shopifyDomain: shopify_domain
          ? shopify_domain.split(".myshopify")[0]
          : "",
        shopifyToken: "",
        apiKey: "",
        password: "",
        notifyCustomerOnRefund: notify_customer_on_refund
          ? notify_customer_on_refund
          : false,
        restock: restock ? restock : false,
        orderTag: order_tag ? order_tag : "",
        exchangeOrderTag: exchange_order_tag ? exchange_order_tag : "",
        customOrderTag: custom_order_tag ? custom_order_tag : "",
        shopifyTokenFiltered: shopify_token,
        apiKeyFiltered: api_key,
        passwordFiltered: password,
        refundReason: refund_reason,
        inventoryLocationId: inventory_location_id,
        showDeletedVariantsUsingSkuEnabled:
          show_deleted_variants_using_sku_enabled,
        storeCreditOrderTag: store_credit_order_tag,
        storeCreditGiftOrderTag: store_credit_gift_order_tag,
        returnSyncingEnabled: return_syncing_enabled,
      },
      storeConnected: {
        connected: !!shopify_domain,
        show: !!shopify_domain,
      },
      disableButton: false,
    };
  }

  const t = current_user.locale === "en" ? translationEN : translationDE;
  const [state, setState] = useState(getInitialState());

  function handleShopifyInputChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      shopifyIntegration: {
        ...prevState.shopifyIntegration,
        [name]: value,
      },
    }));
  }

  function handleShopifyCheckBoxChange(e) {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      shopifyIntegration: {
        ...prevState.shopifyIntegration,
        [e.target.name]: e.target.checked,
      },
    }));
  }

  function handleTagifyInputs(e) {
    let value = "";
    e.detail.tagify.getCleanValue().map((tagify_value) => {
      value += `${tagify_value.value},`;
    });

    setState((prevState) => ({
      ...prevState,
      shopifyIntegration: {
        ...prevState.shopifyIntegration,
        [e.detail.tagify.DOM.originalInput.name]: value,
      },
    }));
  }

  async function connectShopify() {
    setState((prevState) => ({
      ...prevState,
      disableButton: true,
    }));

    let domain = state.shopifyIntegration.shopifyDomain.split(".myshopify")[0];
    domain = domain.replace(/\.+$/, "");

    const {
      id,
      shopifyToken: shopify_token,
      apiKey: api_key,
      password,
      notifyCustomerOnRefund: notify_customer_on_refund,
      restock,
      orderTag: order_tag,
      exchangeOrderTag: exchange_order_tag,
      customOrderTag: custom_order_tag,
      storeCreditOrderTag: store_credit_order_tag,
      storeCreditGiftOrderTag: store_credit_gift_order_tag,
      refundReason: refund_reason,
      inventoryLocationId: inventory_location_id,
      showDeletedVariantsUsingSkuEnabled:
        show_deleted_variants_using_sku_enabled,
      returnSyncingEnabled: return_syncing_enabled,
    } = state.shopifyIntegration;

    const body = {
      merchant: {
        shopify_integration_attributes: {
          id,
          shopify_domain: `${domain}.myshopify.com`,
          shopify_token,
          api_key,
          password,
          notify_customer_on_refund,
          restock,
          order_tag,
          exchange_order_tag,
          custom_order_tag,
          refund_reason,
          inventory_location_id,
          show_deleted_variants_using_sku_enabled,
          store_credit_order_tag,
          store_credit_gift_order_tag,
          return_syncing_enabled,
        },
      },
    };

    const response = await connectWithShopify(body);

    if (response.status === 200) {
      response.json().then((responseJson) => {
        if (responseJson.shopify_integration?.id) {
          setState((prevState) => ({
            ...prevState,
            shopifyIntegration: {
              ...prevState.shopifyIntegration,
              shopifyToken: "",
              shopifyTokenFiltered:
                responseJson.shopify_integration.shopify_token,
              apiKey: "",
              apiKeyFiltered: "FILTERED",
              password: "",
              passwordFiltered: "FILTERED",
              id: responseJson.shopify_integration.id,
            },
            storeConnected: {
              connected: true,
              show: true,
            },
          }));

          SuccessToast({
            toastId: "shopifySuccess",
          });
        } else if (responseJson.is_return_access_scope_missing) {
          window.open(
            responseJson.shopify_login_url || shopify_login_url,
            "_blank"
          );
        }
      });
    } else {
      response.json().then((responseJson) => {
        setState((prevState) => ({
          ...prevState,
          storeConnected: {
            connected: false,
            show: true,
          },
        }));
        ErrorToast({
          message: responseJson.error,
          toastId: "shopifyError",
        });
      });
    }

    setState((prevState) => ({
      ...prevState,
      disableButton: false,
    }));
  }

  return {
    state,
    t,
    handleShopifyInputChange,
    handleShopifyCheckBoxChange,
    handleTagifyInputs,
    connectShopify,
    publicApp,
  };
};
