import { useEffect } from "react";
import i18next, {
  loadStaticTranslationEN,
  loadStaticTranslationDE,
} from "../../components/locale/i18n"

export const loadTranslations = (
  locale,
  translationEN,
  translationDE,
) => {

  useEffect(() => {
    locale === "en" ? loadStaticTranslationEN(translationEN) : loadStaticTranslationDE(translationDE)
    i18next.changeLanguage(locale);
  }, []);
}
