import React from "react";
import Modal from "react-modal";

import { EditIcon2 } from "../../../../ui-kit/icons/EditIcon2";

export const UpdateRoleModal = ({ open, onRequestClose, t, onClick }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onRequestClose}
      style={{
        content: {
          maxWidth: "470px",
          maxHeight: "160px",
          margin: "auto",
          padding: "24px",
        },
        overlay: {
          background: "#00000080",
        },
      }}
    >
      <div>
        <div className="flex items-center mb-3">
          <EditIcon2 />

          <p className="font-semibold text-base leading-6 text-gray-825 ml-3">
            {t.update_heading}
          </p>
        </div>

        <p className="font-normal text-xs13 leading-4 text-gray-625 mb-5">
          {t.update_description}
        </p>

        <div className="flex justify-end">
          <button
            className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625 focus:shadow-btn_blue focus:border-blue-625"
            onClick={onRequestClose}
          >
            {t.cancel}
          </button>

          <button
            className="fnot-italic font-semibold text-sm leading-5 text-white bg-blue-700 rounded-lg py-2 px-4 ml-3 hover:bg-blue-625 focus:bg-blue-700 focus:outline-none"
            onClick={onClick}
          >
            {t.yes_change}
          </button>
        </div>
      </div>
    </Modal>
  );
};
