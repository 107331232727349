import React from "react";
import { TooltipIcon } from "../../../../ui-kit/icons/ToolTipIcon";

const ChecklistItem = ({
  link,
  linkText,
  description,
  tooltipLink,
  customClick,
}) => {
  const handleClick = () => {
    if (customClick) {
      customClick();
    } else {
      document.location = link;
    }
  };
  return (
    <li className="list-disc">
      <div className="border-b pb-2 gap-12 w-full pt-2">
        <div className="flex items-center gap-2 flex-1">
          <a
            className="font-inter font-medium text-xs13 text-gray-825 cursor-pointer"
            onClick={handleClick}
          >
            {linkText}
          </a>
          {tooltipLink !== "#" && <TooltipIcon url={tooltipLink} />}
        </div>
        <div className="flex items-center gap-16 flex-1">
          <p className="font-normal text-xs text-gray-525">{description}</p>
        </div>
      </div>
    </li>
  );
};

export default ChecklistItem;
