import { useState } from "react";

import { SuccessToast } from "../../common/SuccessToast";
import { ErrorToast } from "../../common/ErrorToast";

import { connectWithDhl } from "../../services/api";

import translationEN from "./translations/EN.json";
import translationDE from "./translations/DE.json";

export const useDhl = ({
  return_address,
  current_user,
  current_merchant,
  live_tracking_enabled,
  api_key,
}) => {
  const { dhl_username, dhl_receiver_id, note_attribute_name, dhl_password } =
    return_address || {};

  function getInitialState() {
    return {
      returnAddress: {
        dhl_username: return_address ? dhl_username : "",
        dhl_password: "",
        dhl_password_filtered: return_address ? dhl_password : "",
        dhl_receiver_id: return_address ? dhl_receiver_id : "",
        note_attribute_name: return_address ? note_attribute_name : "",
      },
      dhlConnected: {
        connected: !!dhl_password,
        show: !!dhl_password,
      },
      shopBackend: current_merchant.shop_backend,
      disableButton: false,
      shipmentStatusUpdate: current_merchant.shipment_status_update,
      liveTrackingEnabled: live_tracking_enabled,
      apiKey: api_key,
    };
  }

  const t = current_user.locale === "en" ? translationEN : translationDE;
  const [state, setState] = useState(getInitialState());

  function handleDhlIntegrationInputChange(e) {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        [e.target.name]: e.target.value,
      },
    }));
  }

  function handleApiKeyInputChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleCheckBoxChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleDropdownValue({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function connectDhl() {
    setState((prevState) => ({
      ...prevState,
      disableButton: true,
    }));

    const response = await connectWithDhl({
      return_address: state.returnAddress,
      shipment_status_update: state.shipmentStatusUpdate,
      dhl_live_tracking_enabled: state.liveTrackingEnabled,
      api_key: state.apiKey,
    });

    if (response.status === 200) {
      response.json().then((responseJson) => {
        setState((prevState) => ({
          ...prevState,
          disableButton: false,
          dhlConnected: {
            connected: true,
            show: true,
          },
          apiKey: responseJson.api_key,
        }));
        if (state.returnAddress.dhl_password) {
          setState((prevState) => ({
            ...prevState,
            returnAddress: {
              ...state.returnAddress,
              dhl_password: "",
              dhl_password_filtered: state.returnAddress.dhl_password,
            },
          }));
        }

        SuccessToast({ toastId: "DHLConnectionSuccess" });
      });
    } else {
      response.json().then((responseJson) => {
        setState((prevState) => ({
          ...prevState,
          dhlConnected: {
            connected: false,
            show: true,
          },
          disableButton: false,
        }));

        ErrorToast({
          toastId: "DHLConnectionFailure",
          message: responseJson.error,
        });
      });
    }
  }

  return {
    state,
    t,
    handleDhlIntegrationInputChange,
    connectDhl,
    handleCheckBoxChange,
    handleDropdownValue,
    handleApiKeyInputChange,
  };
};
