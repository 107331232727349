import React from "react";
import ReactNotification from "react-notifications-component";
import Tags from "@yaireo/tagify/dist/react.tagify";

import Header from "../../header/index";
import SubHeaderOld from "../../common/SubHeaderOld";
import { ShopifyLogoIcon } from "../../ui-kit/icons/shopify_logo";
import { InputLabel } from "../../../ui-kit/components/InputLabel";
import { Input } from "../../../ui-kit/components/Input";
import { Toggle } from "../../../ui-kit/components/Toggle";
import { Typography } from "../../../ui-kit/components/Typography/Typography";

import { useShopify } from "./shopify.hook";

import "react-notifications-component/dist/theme.css";

const Shopify = ({
  current_user,
  current_merchant,
  shopify_integration,
  shopify_login_url,
}) => {
  const {
    state,
    t,
    handleShopifyInputChange,
    handleShopifyCheckBoxChange,
    handleTagifyInputs,
    connectShopify,
    publicApp,
  } = useShopify({ shopify_integration, current_user, shopify_login_url });

  const { shopifyIntegration, storeConnected, disableButton } = state;

  const showPrivateAppFields =
    !shopifyIntegration.shopifyTokenFiltered &&
    shopify_integration.api_key &&
    shopify_integration.password;

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header
        current_user={current_user}
        text={"Shopify"}
        settings={t.settings}
        integrations={t.integrations}
      />
      <SubHeaderOld text={"Shopify"} />
      <div className="my-8 mx-8">
        <ShopifyLogoIcon />
        <div className="w-2/3">
          <div className="flex justify-between mt-8">
            <h1 className="font-semibold text-base leading-6 text-gray-825">
              {t.shopify_integration}
            </h1>
            <p
              className={`rounded-lg font-semibold text-sm leading-5 py-2 px-3 ${
                storeConnected.connected
                  ? "bg-teal-125 text-green-825"
                  : "text-red-525 bg-red-450"
              } ${storeConnected.show ? "" : "hidden"}`}
            >
              {storeConnected.connected ? t.connected : t.invalid_credentials}
            </p>
          </div>
          <div>
            <div className="mt-5 block">
              <InputLabel>{t.shop_name}</InputLabel>
              <div className="flex items-center">
                <div>
                  <Input
                    name="shopifyDomain"
                    onChange={(value) =>
                      handleShopifyInputChange({ name: "shopifyDomain", value })
                    }
                    value={shopifyIntegration.shopifyDomain}
                  />
                </div>
                <p className="font-medium text-sm leading-5 text-gray-625 ml-3">
                  .myshopify.com
                </p>
              </div>
            </div>

            {!publicApp && (
              <div className="flex items-center mt-4">
                <div className="w-full block">
                  <InputLabel>{t.admin_api_access_token}</InputLabel>
                  <Input
                    className="placeholder-gray-875"
                    name="shopifyToken"
                    onChange={(value) =>
                      handleShopifyInputChange({ name: "shopifyToken", value })
                    }
                    value={shopifyIntegration.shopifyToken}
                    placeholder={
                      shopifyIntegration.shopifyTokenFiltered ? "FILTERED" : ""
                    }
                  />
                </div>
              </div>
            )}

            {showPrivateAppFields && (
              <div className="flex items-center mt-4">
                <div className="w-1/2 block">
                  <InputLabel>{t.api_key_shopify}</InputLabel>
                  <Input
                    name="apiKey"
                    onChange={(value) =>
                      handleShopifyInputChange({ name: "apiKey", value })
                    }
                    value={shopifyIntegration.apiKey}
                    placeholder={
                      shopifyIntegration.apiKeyFiltered ? "FILTERED" : ""
                    }
                  />
                </div>
                <div className="w-1/2 ml-5 block">
                  <InputLabel>{t.password_shopify}</InputLabel>
                  <Input
                    name="password"
                    onChange={(value) =>
                      handleShopifyInputChange({ name: "password", value })
                    }
                    value={shopifyIntegration.password}
                    placeholder={
                      shopifyIntegration.passwordFiltered ? "FILTERED" : ""
                    }
                  />
                </div>
              </div>
            )}
            <div className="mt-4 block">
              <InputLabel>{t.order_tag}</InputLabel>
              <Tags
                className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                name="orderTag"
                onChange={handleTagifyInputs}
                value={shopifyIntegration.orderTag}
              ></Tags>
            </div>

            <div className="mt-4 block">
              <InputLabel>{t.reason_for_refund}</InputLabel>
              <div className="flex items-center">
                <Input
                  name="refundReason"
                  onChange={(value) =>
                    handleShopifyInputChange({ name: "refundReason", value })
                  }
                  value={shopifyIntegration.refundReason}
                />
              </div>
            </div>

            {current_merchant.show_exchange && (
              <div className="mt-4 block">
                <InputLabel>{t.exchange_order_tag}</InputLabel>
                <Tags
                  className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border font-medium text-sm pl-1 leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                  name="exchangeOrderTag"
                  onChange={handleTagifyInputs}
                  value={shopifyIntegration.exchangeOrderTag}
                />
              </div>
            )}
            {current_merchant.show_custom_return && (
              <div className="mt-4 block">
                <InputLabel>{t.custom_order_tag}</InputLabel>
                <Tags
                  className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border font-medium text-sm pl-1 leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                  name="customOrderTag"
                  onChange={handleTagifyInputs}
                  value={shopifyIntegration.customOrderTag}
                />
              </div>
            )}
            <div className="mt-4 block">
              <InputLabel>
                {t.store_credit_order_tag}
              </InputLabel>
              <Tags className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border font-medium text-sm pl-1 leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                name="storeCreditOrderTag"
                onChange={handleTagifyInputs}
                value={shopifyIntegration.storeCreditOrderTag}
              />
            </div>
            <div className="mt-4 block">
              <InputLabel>
                {t.store_credit_gift_order_tag}
              </InputLabel>
              <Tags className="w-full mt-1 focus:outline-none bg-white box-border rounded-lg border font-medium text-sm pl-1 leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
                name="storeCreditGiftOrderTag"
                onChange={handleTagifyInputs}
                value={shopifyIntegration.storeCreditGiftOrderTag}
              />
            </div>
            <div className="flex items-center mt-4">
              <div className="flex items-center w-1/2">
                <input
                  className="cursor-pointer h-6 w-6 rounded"
                  type="checkbox"
                  id="shopify_integration_notify_customer_on_refund"
                  name="notifyCustomerOnRefund"
                  onChange={handleShopifyCheckBoxChange}
                  checked={shopifyIntegration.notifyCustomerOnRefund}
                />
                <label
                  htmlFor="shopify_integration_notify_customer_on_refund"
                  className="ml-3 font-medium text-sm leading-5 text-gray-825 cursor-pointer"
                >
                  {t.notification_customers_refund}
                </label>
              </div>
              {
                <div className="flex items-center w-1/2 ml-8">
                  <input
                    className="cursor-pointer h-6 w-6"
                    type="checkbox"
                    id="shopify_integration_restock"
                    name="restock"
                    onChange={handleShopifyCheckBoxChange}
                    checked={shopifyIntegration.restock}
                  />
                  <label
                    htmlFor="shopify_integration_restock"
                    className="ml-3 font-medium text-sm leading-5 text-gray-825 cursor-pointer"
                  >
                    {t.restock_items}
                  </label>
                </div>
              }
            </div>
            <Toggle
              name="returnSyncingEnabled"
              className="mt-8 mb-6"
              onChange={(value) =>
                handleShopifyInputChange({
                  name: "returnSyncingEnabled",
                  value,
                })
              }
              checked={shopifyIntegration.returnSyncingEnabled}
              label={{
                text: t.sync_returns_data_with_shopify,
                className:
                  "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
              }}
            />
            {shopifyIntegration.restock && (
              <div className="w-1/2 block mt-5">
                <InputLabel>{t.inventory_location}</InputLabel>
                <Input
                  name="inventoryLocationId"
                  onChange={(value) =>
                    handleShopifyInputChange({
                      name: "inventoryLocationId",
                      value,
                    })
                  }
                  value={shopifyIntegration.inventoryLocationId}
                />
                <InputLabel>{t.location_additional_text}</InputLabel>
              </div>
            )}

            {/* Optimization */}

            <div className="pb-8 mt-8">
              <Typography
                element="p"
                weight="semibold"
                size="base"
                height="6"
                color="gray-825"
              >
                {t.optimization}
              </Typography>
              <Toggle
                id="showDeletedVariantsUsingSkuEnabled"
                name="showDeletedVariantsUsingSkuEnabled"
                className="mt-4"
                onChange={(value) =>
                  handleShopifyInputChange({
                    name: "showDeletedVariantsUsingSkuEnabled",
                    value,
                  })
                }
                checked={shopifyIntegration.showDeletedVariantsUsingSkuEnabled}
                label={{
                  text: t.title_show_deleted_variants_by_sku,
                  className:
                    "inline font-medium text-sm leading-5 text-gray-975 cursor-pointer",
                }}
              />
              <Typography
                element="p"
                weight="normal"
                size="xs13"
                height="4"
                color="gray-725"
                className="mt-2"
              >
                {t.description_for_show_deleted_variants}
              </Typography>
            </div>

            <button
              className={`bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue ${
                disableButton ? "cursor-default" : ""
              }`}
              disabled={disableButton}
              onClick={connectShopify}
            >
              {t.connect}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shopify;
