import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from "react-modal";
import ReactNotification from "react-notifications-component";

import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import { getCountryList } from "../../util/getCountryList";

import Form from "./form";

import translationEN from "../../translations/rules/translationEN.json";
import translationDE from "../../translations/rules/translationDE.json";

import "react-notifications-component/dist/theme.css";

Modal.setAppElement("#root");

class Rule extends React.Component {
  constructor(props) {
    super(props);

    this.grey_color = "#3F3F46";
    this.red_color = "#EB5757";
    this.blue_color = "#2F80ED";

    this.state = {
      priority: false,
      rules: this.props.rules.map((rule) => {
        return {
          ...rule,
          delete_color: this.grey_color,
          edit_color: this.grey_color,
          border_bottom_color: "transparent",
        };
      }),
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      addRule: false,
      showEditForm: false,
      showAll: true,
      rule: null,
      showDeleteModal: false,
      delete_rule_id: "",
      selected_rule_index: "",
      customAddressCountryOptions: getCountryList(),
      orderCountryOptions: getCountryList()
        .slice(1)
        .map((country) => ({ value: country.label, label: country.label })),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.priority !== this.state.priority &&
      this.state.priority === true
    ) {
      const finalbody = [];
      this.state.rules.map((i, index) => {
        let priorityWithId = {
          id: i.id,
          priority: index + 1,
        };
        finalbody.push(priorityWithId);
      });

      let body = {
        arr: finalbody,
      };

      fetch(`/change_rule_priority`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            rules: this.state.rules.map((i, index) => {
              return {
                ...i,
                priority: index + 1,
                delete_color: this.grey_color,
                edit_color: this.grey_color,
                border_bottom_color: "transparent",
              };
            }),
          });
        }
        this.setState({ priority: false });
      });
    }
  }

  changeShowEditform = (rule) => {
    this.setState({
      showEditForm: !this.state.showEditForm,
      rule: rule,
      showAll: !this.state.showAll,
    });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    this.reorder(result.source.index, result.destination.index);
  };

  reorder = (startIndex, endIndex) => {
    const result = this.state.rules;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    this.setState({
      rules: result.map((rule) => {
        return {
          ...rule,
          delete_color: this.grey_color,
          edit_color: this.grey_color,
          border_bottom_color: "transparent",
        };
      }),
      priority: true,
    });
  };

  submitEditForm = (id, body) => {
    fetch(`/rules/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const rules = this.state.rules;
          const index = rules.map((rule) => rule.id).indexOf(id);
          rules.splice(index, 1, responseJson);
          this.setState({
            showEditForm: false,
            rules: rules
              .sort((a, b) => a.priority - b.priority)
              .map((rule) => {
                return {
                  ...rule,
                  delete_color: this.grey_color,
                  edit_color: this.grey_color,
                  border_bottom_color: "transparent",
                };
              }),
            showAll: true,
          });

          SuccessToast({ toastId: "EditSuccess" });
        });
      } else {
        this.setState({
          showEditForm: true,
        });
        response.json().then((responseJson) => {
          ErrorToast({ toastId: "EditFailure", message: responseJson.error });
        });
      }
    });
  };

  deleteRule = (id, index) => {
    fetch(`/rules/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    }).then((response) => {
      if (response.status === 200) {
        const arr = this.state.rules;
        arr.splice(index, 1);
        this.setState({
          rules: arr.map((rule) => {
            return {
              ...rule,
              delete_color: this.grey_color,
              edit_color: this.grey_color,
              border_bottom_color: "transparent",
            };
          }),
        });

        SuccessToast({ toastId: "DeleteSuccess", message: "Deleted." });
      }
      this.closeDeleteModal();
    });
  };

  addRule = () => {
    this.setState({
      addRule: !this.state.addRule,
      showAll: !this.state.showAll,
    });
  };

  submitCreateForm = (body) => {
    fetch("/rules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const rules = this.state.rules;
          rules.push(responseJson);
          this.setState({
            rules: rules.map((rule) => {
              return {
                ...rule,
                delete_color: this.grey_color,
                edit_color: this.grey_color,
                border_bottom_color: "transparent",
              };
            }),
            addRule: false,
            showAll: true,
          });

          SuccessToast({
            toastId: "CreateSuccess",
            message: "Rule was successfully created.",
          });
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({ toastId: "CreateFailure", message: responseJson.error });
        });
      }
    });
  };

  onMouseOver = (rule, index) => {
    const rules = this.state.rules;
    rule.delete_color = this.red_color;
    rules.splice(index, 1, rule);
    this.setState({ rules: rules });
  };

  onMouseOut = (rule, index) => {
    const rules = this.state.rules;
    rule.delete_color = this.grey_color;
    rules.splice(index, 1, rule);
    this.setState({ rules: rules });
  };

  onMouseOverEdit = (rule, index) => {
    const rules = this.state.rules;
    rule.edit_color = this.blue_color;
    rules.splice(index, 1, rule);
    this.setState({ rules: rules });
  };

  onMouseOutEdit = (rule, index) => {
    const rules = this.state.rules;
    rule.edit_color = this.grey_color;
    rules.splice(index, 1, rule);
    this.setState({ rules: rules });
  };

  onMouseOverDragIcon = (rule, index) => {
    const rules = this.state.rules;
    rule.border_bottom_color = this.blue_color;
    rules.splice(index, 1, rule);
    this.setState({ rules: rules });
  };

  onMouseOutDragIcon = (rule, index) => {
    const rules = this.state.rules;
    rule.border_bottom_color = "transparent";
    rules.splice(index, 1, rule);
    this.setState({ rules: rules });
  };

  ordinal = (number) => {
    const english_ordinal_rules = new Intl.PluralRules("en", {
      type: "ordinal",
    });

    const suffixes = {
      one: "st",
      two: "nd",
      few: "rd",
      other: "th",
    };
    const suffix = suffixes[english_ordinal_rules.select(number)];
    return number + suffix;
  };

  showDeleteModal = (id, index) => {
    this.setState({
      showDeleteModal: true,
      delete_rule_id: id,
      selected_rule_index: index,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      delete_rule_id: "",
      selected_rule_index: "",
    });
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />
        {this.state.showAll && (
          <div>
            <div className="flex justify-between items-center px-8 mb-3">
              <div className="font-medium text-xs leading-4">
                <p className="text-gray-725 text-xs13">{this.state.t.rules}</p>
              </div>
              <div className="font-inter">
                <a className="focus:outline-none" href="/users/edit">
                  <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                    {this.props.current_user.first_name + " "}
                    {this.props.current_user.last_name}
                  </p>
                  <p className="font-normal text-xs leading-4 text-right text-gray-625">
                    {this.props.current_user.email}
                  </p>
                </a>
              </div>
            </div>
            <div className="flex items-center justify-between border-t border-b  px-8 py-5">
              <div className="flex items-center">
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  className="mr-4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.666 17.4999V13.3333"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.166 13.3333H19.166"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.33398 17.5001V11.6667"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.833984 11.6667H5.83398"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 17.5V10"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 6.66675H12.5"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.666 10V2.5"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 6.66667V2.5"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.33398 8.33333V2.5"
                    stroke="#27272A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <h5 className="font-semibold text-base leading-6 text-gray-975">
                  {this.state.t.rules}
                </h5>
              </div>
              <button
                className="bg-blue-675 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border hover:bg-blue-625 focus:outline-none focus:border-blue-625"
                onClick={this.addRule}
              >
                + {this.state.t.new_rule}
              </button>
            </div>
            <div className="font-medium text-xs leading-4 text-gray-525 px-8">
              <div className="flex justify-between tracking-px_64 uppercase border-b pb-2 pt-4">
                <div className="w-1/12">{this.state.t.priority}</div>
                <div className="w-1/3 pl-2">{this.state.t.category}</div>
                <div className="w-1/2 text-right">ACTION</div>
              </div>
            </div>
            <div className="px-8">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.rules.map((rule, index) => (
                        <Draggable
                          key={rule.id}
                          draggableId={`${rule.id}`}
                          index={index}
                          id={rule.id}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="flex justify-between items-center py-4 px-4 hover:bg-gray-100 hover-container"
                                key={index}
                                style={{
                                  borderBottom: `2px solid ${rule.border_bottom_color}`,
                                  paddingBottom: "14px",
                                }}
                              >
                                <div className="w-1/12 font-medium text-sm leading-5 text-gray-925 flex items-center">
                                  <div
                                    {...provided.dragHandleProps}
                                    className="w-6 h-6 bg-gray-100 rounded flex items-center justify-center flex-col rounded text-gray-525 hover:bg-blue-700 hover:text-white mr-4"
                                    onMouseOver={() =>
                                      this.onMouseOverDragIcon(rule, index)
                                    }
                                    onMouseOut={() =>
                                      this.onMouseOutDragIcon(rule, index)
                                    }
                                  >
                                    =
                                  </div>
                                  {rule.priority && this.ordinal(rule.priority)}
                                </div>
                                <div className="w-1/3 font-medium text-sm leading-5 text-gray-925">
                                  {rule.name}
                                </div>
                                <div className="w-1/2">
                                  <div className="flex float-right display-flex hidden">
                                    <div
                                      className="mr-6"
                                      onClick={() =>
                                        this.changeShowEditform(rule)
                                      }
                                      onMouseOver={() =>
                                        this.onMouseOverEdit(rule, index)
                                      }
                                      onMouseOut={() =>
                                        this.onMouseOutEdit(rule, index)
                                      }
                                    >
                                      <svg
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="cursor-pointer"
                                      >
                                        <path
                                          d="M8.54963 2.78125H2.6777C2.23274 2.78125 1.80601 2.95801 1.49139 3.27264C1.17676 3.58726 1 4.01399 1 4.45895V16.2028C1 16.6478 1.17676 17.0745 1.49139 17.3891C1.80601 17.7038 2.23274 17.8805 2.6777 17.8805H14.4216C14.8665 17.8805 15.2932 17.7038 15.6079 17.3891C15.9225 17.0745 16.0993 16.6478 16.0993 16.2028V10.3309"
                                          stroke={rule.edit_color}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14.8414 1.52119C15.1751 1.18748 15.6277 1 16.0996 1C16.5716 1 17.0242 1.18748 17.3579 1.52119C17.6916 1.85491 17.8791 2.30752 17.8791 2.77947C17.8791 3.25141 17.6916 3.70402 17.3579 4.03774L9.38884 12.0068L6.03345 12.8456L6.8723 9.49025L14.8414 1.52119Z"
                                          stroke={rule.edit_color}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      onClick={() =>
                                        this.showDeleteModal(rule.id, index)
                                      }
                                      onMouseOver={() =>
                                        this.onMouseOver(rule, index)
                                      }
                                      onMouseOut={() =>
                                        this.onMouseOut(rule, index)
                                      }
                                    >
                                      <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="cursor-pointer"
                                      >
                                        <path
                                          d="M0.899902 4.60156H2.6999H17.0999"
                                          stroke={rule.delete_color}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M5.39995 4.6V2.8C5.39995 2.32261 5.58959 1.86477 5.92716 1.52721C6.26472 1.18964 6.72256 1 7.19995 1H10.8C11.2773 1 11.7352 1.18964 12.0727 1.52721C12.4103 1.86477 12.6 2.32261 12.6 2.8V4.6M15.3 4.6V17.2C15.3 17.6774 15.1103 18.1352 14.7727 18.4728C14.4352 18.8104 13.9773 19 13.5 19H4.49995C4.02256 19 3.56472 18.8104 3.22716 18.4728C2.88959 18.1352 2.69995 17.6774 2.69995 17.2V4.6H15.3Z"
                                          stroke={rule.delete_color}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.19995 9.10156V14.5016"
                                          stroke={rule.delete_color}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10.7998 9.10156V14.5016"
                                          stroke={rule.delete_color}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        )}
        {this.state.addRule && (
          <Form
            fields={this.props.fields}
            active_methods={this.props.active_methods}
            submitCreateForm={this.submitCreateForm}
            t={this.state.t}
            current_merchant={this.props.current_merchant}
            order_country_field={this.props.order_country_field}
            return_type_field={this.props.return_type_field}
            return_reason_field={this.props.return_reason_field}
            customer_risk_level_field={this.props.customer_risk_level_field}
            ruleLength={this.state.rules.length}
            addRuleHandler={this.addRule}
            current_user={this.props.current_user}
            number_fields={this.props.number_fields}
            number_predicates={this.props.number_predicates}
            collection_fields={this.props.collection_fields}
            collection_predicates={this.props.collection_predicates}
            return_types={this.props.return_types}
            risk_levels={this.props.risk_levels}
            reasons={this.props.reasons}
            customAddressCountryOptions={this.state.customAddressCountryOptions}
            orderCountryOptions={this.state.orderCountryOptions}
          />
        )}
        {this.state.showEditForm && (
          <Form
            rule={this.state.rule}
            fields={this.props.fields}
            active_methods={this.props.active_methods}
            submitEditForm={this.submitEditForm}
            t={this.state.t}
            deleteRule={this.deleteRule}
            showEditForm={this.state.showEditForm}
            current_user={this.props.current_user}
            changeShowEditform={this.changeShowEditform}
            current_merchant={this.props.current_merchant}
            order_country_field={this.props.order_country_field}
            return_type_field={this.props.return_type_field}
            return_reason_field={this.props.return_reason_field}
            customer_risk_level_field={this.props.customer_risk_level_field}
            rules={this.state.rules}
            number_fields={this.props.number_fields}
            number_predicates={this.props.number_predicates}
            collection_fields={this.props.collection_fields}
            collection_predicates={this.props.collection_predicates}
            return_types={this.props.return_types}
            risk_levels={this.props.risk_levels}
            reasons={this.props.reasons}
            customAddressCountryOptions={this.state.customAddressCountryOptions}
            orderCountryOptions={this.state.orderCountryOptions}
          />
        )}
        <Modal
          isOpen={this.state.showDeleteModal}
          onRequestClose={this.closeDeleteModal}
          style={{
            content: {
              maxWidth: "470px",
              maxHeight: "185px",
              margin: "auto",
              padding: "24px",
            },
          }}
        >
          <div>
            <div className="flex items-center mb-3">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="32"
                  height="32"
                  rx="16"
                  fill="#EB5757"
                  fillOpacity="0.1"
                />
                <path
                  d="M10.75 11.75L11.5912 21.4233C11.681 22.4568 12.5462 23.25 13.5836 23.25H18.4164C19.4538 23.25 20.319 22.4568 20.4088 21.4233L21.25 11.75"
                  stroke="#EB5757"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.75 11.5V10.75C13.75 9.64543 14.6454 8.75 15.75 8.75H16.25C17.3546 8.75 18.25 9.64543 18.25 10.75V11.5"
                  stroke="#EB5757"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 11.75H23"
                  stroke="#EB5757"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="font-semibold text-base leading-6 text-gray-825 ml-3">
                {this.state.t.delete_return_reason}
              </p>
            </div>
            <p className="font-normal text-xs13 leading-4 text-gray-625 mb-5">
              {this.state.t.are_you_sure}
            </p>
            <div className="flex justify-end">
              <button
                className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625 focus:shadow-btn_blue focus:border-blue-625"
                onClick={this.closeDeleteModal}
              >
                {this.state.t.cancel}
              </button>
              <button
                className="fnot-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
                onClick={() =>
                  this.deleteRule(
                    this.state.delete_rule_id,
                    this.state.selected_rule_index
                  )
                }
              >
                {this.state.t.yes_delete}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Rule;
