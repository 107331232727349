import React, { useState, useEffect } from "react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";

import ReactNotification, { store } from "react-notifications-component";
import translationDE from "../../translations/returnMethods/translationDE.json";
import translationEN from "../../translations/returnMethods/translationEN.json";
import Notification from "../notification/index";

const Form = (props) => {
  const [languages, setLanguages] = useState(props.initial_languages);
  const languageFields = props.language_fields_object;

  const initialState = {
    id: props.return_method.id,
    image_url: props.return_method.image_url,
    is_activated: props.return_method.is_activated,
    cost: props.return_method.cost,
    return_method_picture_attributes: {},
    collect_plus_tracking_account_code:
      props.return_method.collect_plus_tracking_account_code,
    collect_plus_label_service_code:
      props.return_method.collect_plus_label_service_code,
  };

  Object.values(languageFields).forEach((fields) => {
    fields.forEach(
      (field) => (initialState[field] = props.return_method[field])
    );
  });

  const [returnMethod, setReturnMethod] = useState(initialState);

  const [hideCost, setHideCost] = useState(props.return_method.cost == 0);
  const [showCost, setShowCost] = useState(props.return_method.cost != 0);
  const [fileName, setFileName] = useState("");
  const [notificationId, setNotificationId] = useState(0);

  const t = props.lang === "en" ? translationEN : translationDE;

  useEffect(() => {
    const uppy = new Uppy({
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 0.2 * 1024 * 1024,
        allowedFileTypes: ["image/jpeg", "image/jpg", "image/png"],
      },
      autoProceed: true,
    });

    const fileInput = document.querySelector("#file-input");

    const handleChange = (event) => {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          uppy.addFile({
            source: "file input",
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          const errorMessage = err.isRestriction
            ? "Image is not Uploaded"
            : `Error: ${err}`;
          console.error(err);
          store.addNotification({
            id: notificationId,
            container: "bottom-center",
            animationIn: ["animated", "bounceIn"],
            animationOut: ["animated", "bounceOut"],
            content: <Notification error_message={errorMessage} />,
            width: 762,
            dismiss: {
              duration: 5000,
              showIcon: true,
            },
          });
        }
      });
    };

    fileInput.addEventListener("change", handleChange);

    uppy.use(AwsS3, {
      companionUrl: "/",
    });

    uppy.on("upload-success", (file, response) => {
      const uploadedFileData = {
        id: file.meta["key"].match(/^cache\/(.+)/)[1],
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      };
      let url = URL.createObjectURL(file.data);
      setFileName(file.name);
      setReturnMethod((prevReturnMethod) => ({
        ...prevReturnMethod,
        image_url: url,
        return_method_picture_attributes: {
          image: JSON.stringify(uploadedFileData),
        },
      }));
    });

    uppy.on("file-removed", () => {
      fileInput.value = null;
    });

    uppy.on("complete", () => {
      uppy.reset();
    });

    return () => {
      fileInput.removeEventListener("change", handleChange);
    };
  }, []);

  const handleCheckBoxChange = (e) => {
    setReturnMethod({
      ...returnMethod,
      [e.target.name]: e.target.checked,
    });
  };

  const handleInputChange = (e) => {
    setReturnMethod({
      ...returnMethod,
      [e.target.name]: e.target.value,
    });
  };

  const handleShowCost = () => {
    setHideCost(false);
    setShowCost(true);
  };

  const handleHideCost = () => {
    setHideCost(true);
    setShowCost(false);
    setReturnMethod({
      ...returnMethod,
      cost: 0,
    });
  };

  const submitEditForm = () => {
    const params = {
      return_method_attributes: returnMethod,
    };

    fetch(`/return_methods/${returnMethod.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseJson) => {
            setNotificationId(notificationId + 1);
            store.addNotification({
              id: notificationId,
              container: "bottom-center",
              animationIn: ["animated", "bounceIn"],
              animationOut: ["animated", "bounceOut"],
              content: <Notification success_message="Saved successfully!" />,
              width: 762,
              dismiss: {
                duration: 2000,
                showIcon: true,
              },
            });
          });
          window.location.href = "/return_methods";
        } else {
          setNotificationId(notificationId + 1);
          response.json().then((responseJson) => {
            store.addNotification({
              id: notificationId,
              container: "bottom-center",
              animationIn: ["animated", "bounceIn"],
              animationOut: ["animated", "bounceOut"],
              content: <Notification error_message={responseJson.error} />,
              width: 762,
              dismiss: {
                duration: 5000,
                showIcon: true,
              },
            });
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);

    // Update the languages state based on the selected language
    const updatedLanguages = Object.keys(languages).reduce((acc, key) => {
      acc[key] = key === language;
      return acc;
    }, {});

    setLanguages(updatedLanguages);
  };

  // Function to render language buttons dynamically based on the languages state
  const renderLanguageButtons = () => {
    return Object.keys(languages).map((language) => (
      <button
        key={language}
        className={`${
          languages[language]
            ? "border-b-2 inline-block border-gray-825 font-semibold text-gray-825"
            : "font-medium text-gray-625"
        } ml-8 w-max pb-2 font-inter text-sm leading-5 focus:outline-none`}
        onClick={() => handleLanguageChange(language)}
      >
        {language}
      </button>
    ));
  };

  const getCapitalizedLabel = (field) => {
    const labelIdentifier = field.split("_")[0];
    return labelIdentifier.charAt(0).toUpperCase() + labelIdentifier.slice(1);
  };

  // Function to render different language sections
  const renderLanguageSections = () => {
    return languageFields[selectedLanguage].map((field) => (
      <div
        key={field}
        className={languages[selectedLanguage] ? "ml-8" : "hidden"}
      >
        <div className="mb-5">
          {field.includes("cost_message") && showCost && (
            <div className="mb-5">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.cost_massage || field}
              </p>
              <input
                className="w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
                type="text"
                placeholder={t.cost_placeholder}
                name={field}
                onChange={handleInputChange}
                defaultValue={returnMethod[field]}
              />
            </div>
          )}
          {!field.includes("cost_message") && !field.includes("note") && (
            <div>
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {getCapitalizedLabel(field)}
              </p>
              <input
                className="w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
                type="text"
                placeholder={t[`${field}_placeholder`]}
                name={field}
                onChange={handleInputChange}
                defaultValue={returnMethod[field]}
              />
            </div>
          )}
          {field.includes("note") && (
            <div>
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.note} ({t.supports}{" "}
                <a
                  href="https://shopify.github.io/liquid/basics/introduction/"
                  target="_blank"
                  className="text-blue-600 cursor-pointer"
                >
                  {t.liquid}
                </a>{" "}
                {t.syntax})
              </p>
              <textarea
                className="w-3/4 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-16"
                name={field}
                rows="10"
                onChange={handleInputChange}
                defaultValue={returnMethod[field]}
              ></textarea>
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <div className="flex justify-between items-center px-8">
        <div className="font-medium text-xs leading-4">
          <p>
            <span className="text-gray-525">
              <a href="/settings">{t.settings}</a> /{" "}
            </span>
            <span className="text-gray-525">
              <a href="/return_methods">{t.return_method}</a> /{" "}
            </span>
            <span className="text-gray-725 text-xs13">{returnMethod.name}</span>
          </p>
        </div>
        <div className="font-inter">
          <a className="focus:outline-none" href="/users/edit">
            <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
              {props.current_user.first_name + " "}
              {props.current_user.last_name}
            </p>
            <p className="font-normal text-xs leading-4 text-right text-gray-625">
              {props.current_user.email}
            </p>
          </a>
        </div>
      </div>
      <div className="flex items-center justify-between border-t border-b mt-6 px-8 py-5">
        <div>
          <h5 className="font-semibold text-base leading-6 text-gray-975">
            {t.edit_return_method}
          </h5>
        </div>
        <div className="flex items-center">
          <div className="relative inline-block w-12 mr-5 align-middle select-none transition duration-200 ease-in">
            <input
              id="returns-method-toggle"
              name="is_activated"
              onChange={handleCheckBoxChange}
              defaultChecked={props.return_method.is_activated}
              type="checkbox"
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            />
            <label
              htmlFor="returns-method-toggle"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            />
          </div>
          <button
            className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
            onClick={submitEditForm}
          >
            {t.save_changes}
          </button>
        </div>
      </div>
      <div className="ml-8">
        <p className="font-semibold text-sm leading-5 text-gray-975 mt-5">
          {t.common_details}
        </p>
        <p className="font-medium text-xs leading-4 text-gray-725 mt-5">
          {t.custom_logo}
        </p>
        <div className="flex items-center mt-1">
          <div className="border border-r border-l border-t border-b border-gray-325 px-3 pt-2 pb-2 rounded-lg w-1/4">
            <div className="flex justify-between items-center">
              <p className="font-medium text-xs leading-4 text-gray-725">
                {fileName}
                {fileName && <span> {t.uploaded} </span>}
              </p>
              <label
                htmlFor="file-input"
                className="border border-r-2 border-l-2 border-t-2 border-b-2 border-blue-600 text-blue-600 px-3 py-2 rounded-lg cursor-pointer"
              >
                Browse
              </label>
              <input
                type="file"
                id="file-input"
                accept="image/jpeg, image/jpg, image/png"
                className="hidden"
              ></input>
            </div>
          </div>
          <img
            id="return_method_logo"
            src={returnMethod.image_url}
            className="max-h-16 ml-10"
          />
        </div>

        {props.paid_return_enabled && (
          <>
            <p className="font-medium text-xs leading-4 text-gray-725 mt-4 mb-2">
              {t.offer_free}
            </p>
            <div className="flex">
              <div className="flex items-center">
                <input
                  id="free_return_yes"
                  className="w-6 h-6 cursor-pointer"
                  type="radio"
                  value={hideCost}
                  name="show-hide"
                  defaultChecked={hideCost}
                  onChange={handleHideCost}
                />
                <label
                  htmlFor="free_return_yes"
                  className="font-medium text-sm leading-5 text-black ml-3 cursor-pointer"
                >
                  {t.yes}
                </label>
              </div>
              <div className="flex items-center ml-6">
                <input
                  id="free_return_no"
                  className="w-6 h-6 cursor-pointer"
                  type="radio"
                  value={showCost}
                  name="show-hide"
                  defaultChecked={showCost}
                  onChange={handleShowCost}
                />
                <label
                  htmlFor="free_return_no"
                  className="font-medium text-sm leading-5 text-black ml-3 cursor-pointer"
                >
                  {t.no}
                </label>
              </div>
            </div>
          </>
        )}

        {showCost && (
          <div className="mt-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.cost}
            </p>
            <input
              className="w-4/12 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 pr-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
              type="number"
              name="cost"
              onChange={handleInputChange}
              defaultValue={returnMethod.cost}
            ></input>
          </div>
        )}

        {props.return_method.is_collect_plus && (
          <div className="mt-8 w-3/4">
            <span className="font-semibold text-sm leading-5 text-gray-975 mt-5">
              Collect+
            </span>

            <div className="mt-5 flex flex-row justify-between">
              <div className="w-1/2">
                <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                  Tracking account code
                </p>
                <input
                  className="w-11/12 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
                  type="text"
                  placeholder=""
                  name="collect_plus_tracking_account_code"
                  onChange={handleInputChange}
                  defaultValue={returnMethod.collect_plus_tracking_account_code}
                  maxLength="3"
                />
              </div>

              <div className="w-1/2">
                <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                  Label service code
                </p>
                <input
                  className="w-11/12 bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-3 pl-4 focus:outline-none focus:border-blue-675 focus:border mb-2"
                  type="text"
                  placeholder=""
                  name="collect_plus_label_service_code"
                  onChange={handleInputChange}
                  defaultValue={returnMethod.collect_plus_label_service_code}
                  maxLength="9"
                />
              </div>
            </div>
          </div>
        )}

        <p className="font-semibold text-sm leading-5 text-gray-975 mt-8 ">
          {t.localized_details}
        </p>
      </div>
      <div className="border-b mb-7 mt-5">{renderLanguageButtons()}</div>
      {renderLanguageSections()}
    </div>
  );
};

export default Form;
