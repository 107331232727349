import React from "react";
import ReactNotification from "react-notifications-component";

import { SuccessToast } from "../../../common/SuccessToast";
import { ErrorToast } from "../../../common/ErrorToast";

import translationEN from "../../../../translations/manageReturns/translationEN.json";
import translationDE from "../../../../translations/manageReturns/translationDE.json";

import { Item } from "./components/Item";

import "react-notifications-component/dist/theme.css";

class Scayle extends React.Component {
  constructor(props) {
    super(props);

    this.total_refund = parseFloat(this.props.refund_amount_without_shipping);
    this.currency =
      this.props.customer_return.items[0]?.displayed_currency ||
      this.props.customer_return.currency;

    this.state = {
      customer_return: this.props.customer_return,
      include_refund: false,
      refund_amount_without_shipping: this.props.refund_amount_without_shipping,
      t: this.props.lang === "en" ? translationEN : translationDE,
      refund_items: this.props.refund_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
        restock: this.props.scayle_integration_restock,
      })),
      custom_items: this.props.custom_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
      })),
      exchange_items: this.props.exchange_items.map(({ id, is_cancelled }) => ({
        id,
        is_cancelled,
        restock: this.props.scayle_integration_restock,
      })),
      complete_btn_disable: false,
      total_refund: this.total_refund,
      total_original_amount: this.props.refund_items.reduce((sum, item) => {
        if (!item.is_cancelled && parseFloat(item.displayed_original_price)) {
          return (
            sum +
            parseFloat(item.displayed_original_price) * parseInt(item.quantity)
          );
        }

        return sum;
      }, 0),
    };
  }

  completeReturn = () => {
    this.setState({
      complete_btn_disable: true,
    });

    let body = {
      items: this.state.refund_items
        .filter((item) => !item.is_cancelled)
        .map((item) => ({ ...item, restock: item.restock ? "1" : "0" })),
      exchange_items: this.state.exchange_items
        .filter((item) => !item.is_cancelled)
        .map((item) => ({ ...item, restock: item.restock ? "1" : "0" })),
    };

    fetch(`/customer_returns/${this.state.customer_return.id}/refund`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((_responseJson) => {
          SuccessToast({
            toastId: "returnCompleted",
          });
        });
        window.location.href = `/customer_returns/${this.state.customer_return.id}/edit`;
      } else {
        this.setState({ complete_btn_disable: false });

        response.json().then((responseJson) => {
          ErrorToast({
            message: responseJson.error,
            toastId: "returnFailed",
          });
        });
      }
    });
  };

  includeToRefund = () => {
    fetch(
      `/customer_returns/${this.state.customer_return.id}/refund?include_refund=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          const refund_amount_without_shipping =
            responseJson.refund_amount_without_shipping;

          const total_custom_refund_original_amount =
            this.props.custom_items.reduce((sum, item) => {
              if (
                !item.is_cancelled &&
                parseFloat(item.displayed_original_price)
              ) {
                return (
                  sum +
                  parseFloat(item.displayed_original_price) *
                    parseFloat(item.quantity)
                );
              }

              return sum;
            }, 0);

          this.setState((prevState) => {
            let new_state = {
              include_refund: true,
              refund_amount_without_shipping,
              total_refund:
                parseFloat(prevState.total_refund) +
                parseFloat(refund_amount_without_shipping) -
                parseFloat(prevState.refund_amount_without_shipping),
              total_original_amount: parseFloat(
                prevState.total_original_amount +
                  parseFloat(total_custom_refund_original_amount)
              ),
            };

            new_state = {
              ...new_state,
              refund_items: [
                ...prevState.refund_items,
                ...prevState.custom_items,
              ], // custom items should always be added to the end of the list
            };

            return new_state;
          });
        });
      } else {
        response.json().then((responseJson) => {
          console.log(
            responseJson.error || response.status + " " + response.statusText
          );
        });
      }
    });
  };

  isShowRestockOption = (item) => {
    return !item.is_cancelled && !item.is_restocked;
  };

  restockCheckboxHandler = (item, index, e) => {
    const checked = e.target.checked;

    if (item.return_type === "refund") {
      let items = this.state.refund_items;
      items[index].restock = checked;
      this.setState({ refund_items: items });
    } else if (item.return_type === "exchange") {
      let items = this.state.exchange_items;
      items[index].restock = checked;
      this.setState({ exchange_items: items });
    }
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />

        <div className="flex justify-between items-center px-8 pb-3">
          <div className="font-inter font-medium text-xs leading-4 text-gray-525">
            <p>
              <span className="text-gray-525">
                <a
                  href="/customer_returns"
                  className="focus:outline-none"
                  accessKey="E"
                >
                  {this.state.t.manage_return}
                </a>{" "}
                /{" "}
              </span>

              <span className="text-gray-725 text-xs13">
                {this.props.customer_return.order_number}
              </span>
            </p>
          </div>

          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>

              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>

        <div className="border-t border-b px-8 font-semibold text-base leading-6 text-gray-825 py-5 border-gray-225">
          {this.state.refund_items.length > 0
            ? this.state.t.refunded_items
            : this.state.custom_items.length > 0
            ? this.state.t.custom_items
            : this.state.exchange_items.length > 0
            ? this.state.t.exchanged_items
            : null}
        </div>

        <div className="flex">
          <div className="border-r w-67% min-h-screen">
            {this.props.refund_items.map((item, index) => {
              return (
                <Item
                  key={index}
                  item={item}
                  index={index}
                  t={this.state.t}
                  currency={this.currency}
                  isShowRestockOption={this.isShowRestockOption(item)}
                  restock={this.state.refund_items[index].restock}
                  restockCheckboxHandler={this.restockCheckboxHandler}
                />
              );
            })}

            {this.props.custom_items.length > 0 && (
              <div>
                {this.state.refund_items.length > 0 &&
                  !this.state.include_refund && (
                    <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                      {this.state.t.custom_items}
                    </div>
                  )}

                {this.props.custom_items.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      item={item}
                      t={this.state.t}
                      currency={this.currency}
                    />
                  );
                })}

                {!this.state.include_refund &&
                  (this.state.refund_items.length > 0 ||
                    this.state.exchange_items.length > 0) && (
                    <div className="px-8 mt-5">
                      <button
                        className="w-full border bg-blue-675 rounded-lg text-white font-semibold text-xs leading-4 py-2 focus:outline-none"
                        onClick={this.includeToRefund}
                      >
                        {this.state.t.include_to_refund}
                      </button>
                    </div>
                  )}
              </div>
            )}

            {this.props.exchange_items.length > 0 && (
              <div>
                {(this.state.refund_items.length > 0 ||
                  this.state.custom_items.length > 0) && (
                  <div className="border-b font-semibold pl-8 mt-8 text-base leading-6 text-gray-825 pb-5 border-gray-225 w-full">
                    {this.state.t.exchanged_items}
                  </div>
                )}

                {this.props.exchange_items.map((item, index) => {
                  return (
                    <Item
                      key={index}
                      item={item}
                      index={index}
                      t={this.state.t}
                      currency={this.currency}
                      isExchangeType
                      isShowRestockOption={this.isShowRestockOption(item)}
                      restock={this.state.exchange_items[index].restock}
                      restockCheckboxHandler={this.restockCheckboxHandler}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="flex-grow pl-6 pt-9">
            <div>
              <div className="w-full flex items-center border-b pb-6">
                <p className="w-1/2 text-xs leading-4 text-gray-525">
                  Total refund
                </p>

                <p className="w-1/2 font-semibold text-lg leading-4 text-gray-825">
                  {new Intl.NumberFormat("en", {
                    style: "currency",
                    currency: this.state.customer_return.currency,
                  }).format(this.state.total_refund)}
                </p>
              </div>
            </div>

            <div className="pr-8 mt-5">
              <button
                className={`w-full border rounded-lg font-semibold text-xs leading-4 py-2 focus:outline-none bg-blue-675 text-white hover:bg-blue-625 focus:border-blue-625 ${
                  this.state.complete_btn_disable ? "cursor-default" : " "
                }`}
                disabled={this.state.complete_btn_disable}
                onClick={this.completeReturn}
              >
                {!this.state.complete_btn_disable && (
                  <span>{this.state.t.complete}</span>
                )}

                {this.state.complete_btn_disable && (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Scayle;
