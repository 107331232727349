import React from "react";
import Modal from "react-modal";
import ReactNotification from "react-notifications-component";

import Header from "../header/index";
import { RedDotIcon } from "../ui-kit/icons/red_dot";
import { GreenDotIcon } from "../ui-kit/icons/green_dot";
import { DeleteIcon } from "../ui-kit/icons/delete_icon";
import { TrashIcon1 } from "../../ui-kit/icons/TrashIcon1";
import { EditIcon2 } from "../../ui-kit/icons/EditIcon2";

import Form from "./Form";
import { useTradeInItems } from "./index.hook";

import "react-notifications-component/dist/theme.css";

Modal.setAppElement("#root");

const TradeInItems = ({ tradeInItems, currentUser }) => {
  const {
    state,
    t,
    onMouseOver,
    onMouseOut,
    onMouseOverEdit,
    onMouseOutEdit,
    showDeleteModal,
    closeDeleteModal,
    deleteItem,
    createItem,
    editItem,
    submitAddForm,
    submitEditForm,
  } = useTradeInItems({ tradeInItems, currentUser });
  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      {state.showAll && (
        <div>
          <Header
            current_user={currentUser}
            text={t.tradeInItems}
            settings={t.settings}
          />
          <div className="flex items-center border-t border-b mt-6 px-8 py-5">
            <div className="w-3/5">
              <h5 className="font-semibold text-base leading-6 text-gray-975">
                {t.tradeInItems}
              </h5>
            </div>
            <div className="w-1/2 flex items-center justify-end">
              <button
                className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                onClick={createItem}
              >
                + {t.tradeInItems}
              </button>
            </div>
          </div>
          <div className="mx-8 mt-6 font-medium text-xs leading-4 text-gray-525 border-b pb-2">
            <div className="ml-4 mr-4 flex justify-between tracking-px_64 uppercase">
              <div className="w-1/3">Name</div>
              <div className="w-1/2">SKU</div>
              <div className="w-1/2">{t.returnLimit}</div>
              <div className="w-1/3">Status</div>
              <div className="w-1/6 text-right">Actions</div>
            </div>
          </div>

          {state.tradeInItems.map((item, index) => (
            <div className="mx-8 mt-4" key={index}>
              <div className="flex justify-between tracking-px_64 items-center px-4 pt-4 pb-4 hover:bg-gray-100 hover-container">
                <div className="w-1/3 font-medium text-sm leading-5 tesxt-gray-825">
                  {item.name}
                </div>
                <div className="w-1/2 font-medium text-sm leading-5 text-gray-625">
                  {item.sku}
                </div>
                <div className="w-1/2 font-medium text-sm leading-5 text-gray-625">
                  {item.returnLimit}
                </div>
                <div className="w-1/3 font-medium text-sm leading-5 text-gray-625">
                  <div className="bg-gray-225 flex items-center w-min rounded-lg py-1 pr-2">
                    <div className="mx-2">
                      {item.isActive ? <GreenDotIcon /> : <RedDotIcon />}
                    </div>
                    <div className="font-medium text-xs leading-4">
                      {item.isActive ? t.active : t.inactive}
                    </div>
                  </div>
                </div>
                <div className="w-1/6">
                  <div className="flex float-right display-flex hidden">
                    <div
                      className="mr-6"
                      onMouseOver={() => onMouseOverEdit(item, index)}
                      onMouseOut={() => onMouseOutEdit(item, index)}
                      onClick={() => editItem(item)}
                    >
                      <EditIcon2 color={item.editColor} />
                    </div>
                    <div
                      onClick={() => showDeleteModal(item.id, index)}
                      onMouseOver={() => onMouseOver(item, index)}
                      onMouseOut={() => onMouseOut(item, index)}
                    >
                      <TrashIcon1 color={item.deleteColor} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {state.createItem && (
        <Form currentUser={currentUser} t={t} submitAddForm={submitAddForm} />
      )}

      {state.editItem && (
        <Form
          currentUser={currentUser}
          t={t}
          propTradeInItem={state.selectedItem}
          submitEditForm={submitEditForm}
          tradeInItemImage={state.selectedItem.tradeInItemImage}
        />
      )}

      <Modal
        isOpen={state.showDeleteModal}
        onRequestClose={closeDeleteModal}
        style={{
          content: {
            maxWidth: "470px",
            maxHeight: "185px",
            margin: "auto",
            padding: "24px",
          },
        }}
      >
        <div>
          <div className="flex items-center mb-3">
            <DeleteIcon />
            <p className="font-semibold text-base leading-6 text-gray-825 ml-3">
              {t.deleteItem}
            </p>
          </div>
          <p className="font-normal text-xs13 leading-4 text-gray-625 mb-5">
            {t.areYouSure}
          </p>
          <div className="flex justify-end">
            <button
              className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625 focus:shadow-btn_blue focus:border-blue-625"
              onClick={closeDeleteModal}
            >
              {t.cancel}
            </button>
            <button
              className="fnot-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
              onClick={deleteItem}
            >
              {t.yesDelete}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TradeInItems;
