import React from "react";
import Header from "../header/index";
import SubHeaderOld from "../common/SubHeaderOld";
import IntegrationCard from "./integration_card";
import { DhlLogoIcon } from "../ui-kit/icons/dhl_logo";
import { Logo8returnsIcon } from "../ui-kit/icons/logo_8returns";
import { ShopifyLogoIcon } from "../ui-kit/icons/shopify_logo";
import { KlaviyoLogoIcon } from "../ui-kit/icons/klaviyo_logo";
import { ShopwareLogoIcon } from "../ui-kit/icons/shopware_logo";
import { CollectPlusLogo } from "../ui-kit/icons/CollectPlusLogo";
import { XentralLogo } from "../ui-kit/icons/XentralLogo";
import { PeoplevoxLogo } from "../../ui-kit/icons/PeoplevoxLogo";
import { FinecomLogo } from "./components/Finecom/ui-kit/icons/FinecomLogo"

import translationEN from "../../translations/integrations/translationEN.json";
import translationDE from "../../translations/integrations/translationDE.json";

const Integrations = ({
  current_user,
  current_merchant,
  show_collect_plus: showCollectPlus,
}) => {
  const t = current_user.locale === "en" ? translationEN : translationDE;
  const integrations = [
    {
      svg: <Logo8returnsIcon />,
      url: "/integrations/public_api",
      heading: "8returns API",
      text: t.return_api,
      show: true,
    },
    {
      svg: <ShopifyLogoIcon />,
      url: "/integrations/shopify",
      heading: "Shopify",
      text: t.shopify_api,
      show: current_merchant.shop_backend === "shopify_integration",
    },
    {
      svg: <ShopwareLogoIcon />,
      url: "/integrations/shopware",
      heading: "Shopware",
      text: t.shopware_api,
      show: current_merchant.shop_backend === "shopware_integration",
    },
    {
      svg: <DhlLogoIcon />,
      url: "/integrations/dhl",
      heading: "DHL",
      text: t.dhl_api,
      show: true,
    },
    {
      svg: <KlaviyoLogoIcon />,
      url: "/integrations/klaviyo",
      heading: "Klaviyo",
      text: t.klaviyo_api,
      show: current_merchant.show_klaviyo_integration,
    },
    {
      svg: <CollectPlusLogo />,
      url: "/integrations/collect_plus",
      heading: "Collect+",
      text: t.manage_collect_plus_integration,
      show: showCollectPlus,
    },
    {
      svg: <XentralLogo />,
      url: "/integrations/xentral",
      heading: "Xentral",
      text: t.xentral_api,
      show: current_merchant.show_xentral_integration,
    },
    {
      svg: <PeoplevoxLogo />,
      url: "/integrations/peoplevox",
      heading: "Peoplevox",
      text: t.peoplevox_api,
      show: current_merchant.peoplevox_integration_enabled,
    },
    {
      svg: <FinecomLogo />,
      url: "/integrations/finecom",
      heading: t.finecom_integration,
      text: t.finecom_api,
      show: current_merchant.show_finecom_integration,
    },
  ]

  return (
    <div className="font-inter">
      <Header
        current_user={current_user}
        text={t.integrations}
        settings={t.settings}
      />
      <SubHeaderOld text={t.integrations} />
      <div className="flex flex-wrap pl-8 pt-3">
        {integrations
          .filter((i) => i.show)
          .map((i, index) => (
            <IntegrationCard
              svg={i.svg}
              url={i.url}
              heading={i.heading}
              text={i.text}
              key={index}
            />
          ))}
      </div>
    </div>
  );
};

export default Integrations;
