import React from "react"

export const ImageUploader = ({itemImage, fileName, placeHolderName, browse, imageFileInput,imageUrl}) => {
  return (
    <>
      <p className="font-medium text-xs leading-4 text-gray-725 mt-4">{itemImage}</p>
      <div className="flex items-center mt-1">
        <div className="border border-r border-l border-t border-b border-gray-325 px-3 pt-2 pb-2 rounded-lg w-1/3">
          <div className = "flex justify-between items-center">
            <p className="font-medium text-xs leading-4 text-gray-725">
              {fileName}
              { !fileName &&
                <span>{placeHolderName}</span>
              } 
            </p>
            <label htmlFor={imageFileInput} className="border border-r-2 border-l-2 border-t-2 border-b-2 border-blue-600 text-blue-600 px-3 py-2 rounded-lg cursor-pointer" >
              {browse}
            </label>
            <input type="file" id={imageFileInput} accept="image/jpeg, image/jpg, image/png" className="hidden"></input>
          </div>
        </div>
        <img
          src={imageUrl} 
          className="max-h-16 ml-10"
        />
      </div>
    </>
  )
}
