import React from "react";
import { isEmpty, sumBy } from "lodash";

export const Item = ({
  item,
  index,
  t,
  currency,
  isExchangeType,
  isShowRestockOption,
  restock,
  restockCheckboxHandler,
}) => {
  const isEqualDecimalNumbers = (a, b) => {
    return Math.abs(parseFloat(a) - parseFloat(b)) < Number.EPSILON;
  };

  const {
    id,
    is_cancelled,
    item_picture_image_url,
    name,
    sku,
    ean,
    displayed_original_price,
    displayed_price,
    quantity,
    item_condition_static_name,
    item_condition_custom_name,
  } = item;

  const originalPrice = () => {
    if (!isEmpty(item.sub_items)) {
      return sumBy(item.sub_items, (subItem) =>
        parseFloat(subItem.displayed_original_price)
      );
    }

    return displayed_original_price;
  };

  const price = () => {
    if (!isEmpty(item.sub_items)) {
      return sumBy(item.sub_items, (subItem) =>
        parseFloat(subItem.displayed_price)
      );
    }

    return displayed_price;
  };

  return (
    <div className="pt-8 px-8 pr-6">
      <div className="border-b pb-8">
        <div
          className={`flex items-center justify-between ${
            is_cancelled ? "opacity-50" : ""
          }`}
        >
          <div className="w-3/5 flex items-center">
            <div className="flex items-center justify-center w-72px h-72px border rounded">
              <img
                className="max-h-70px"
                src={item_picture_image_url}
                alt="img"
              />
            </div>

            <div className="ml-6">
              <p className="font-semibold text-xs leading-4 text-gray-825">
                {name}
              </p>

              <div className="flex items-center mt-1">
                <p className="font-medium text-xs leading-4 text-gray-725">
                  SKU
                </p>

                <p className="font-normal text-xs leading-4 text-gray-625 ml-3">
                  {sku}
                </p>

                {ean && (
                  <div className="flex">
                    <p className="font-medium text-xs leading-4 text-gray-725 ml-6">
                      EAN
                    </p>
                    <p className="font-normal text-xs leading-4 text-gray-625 ml-3">
                      {ean}
                    </p>
                  </div>
                )}

                <div className="flex">
                  <p className="font-medium text-xs leading-4 text-gray-725 ml-6">
                    {item_condition_custom_name === item_condition_static_name
                      ? `${t.condition} ${item_condition_custom_name}`
                      : item_condition_custom_name.substring(0, 11)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-1/5 flex justify-center">
            {originalPrice() &&
              !isEqualDecimalNumbers(originalPrice(), price()) && (
                <p className="font-medium text-xs leading-4 text-gray-825 line-through mr-3">
                  {new Intl.NumberFormat("en", {
                    style: "currency",
                    currency,
                  }).format(parseFloat(originalPrice()))}
                </p>
              )}

            <p className="font-medium text-xs leading-4 text-gray-825">
              {new Intl.NumberFormat("en", {
                style: "currency",
                currency,
              }).format(price())}
            </p>

            <svg
              width={17}
              height={16}
              className="mx-4"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4.5L5 11.5"
                stroke="#A1A1AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 4.5L12 11.5"
                stroke="#A1A1AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p className="font-medium text-xs leading-4 text-gray-825">
              {quantity}
            </p>
          </div>

          <div className="w-1/5 font-semibold font-clash_grotesk text-lg leading-6 text-right">
            {new Intl.NumberFormat("en", {
              style: "currency",
              currency,
            }).format(isExchangeType ? 0 : price() * quantity)}
          </div>
        </div>

        {isShowRestockOption && (
          <div className="mt-3 flex items-center">
            <input
              className="cursor-pointer border w-6 h-6 bg-white box-border rounded"
              type="checkbox"
              id={id}
              checked={restock}
              onChange={(e) => restockCheckboxHandler(item, index, e)}
            />

            <label
              htmlFor={id}
              className="ml-3 font-medium text-xs leading-4 text-gray-825 cursor-pointer"
            >
              {t.restock_in_scayle} ({quantity})
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
