import React from "react";
import Select from "react-select";

import { FormatDate } from "../../ui-kit/components/FormatDate";
import { DateArrowIcon } from "../ui-kit/icons/DateArrowIcon";
import { LeftArrowIcon } from "../ui-kit/icons/LeftArrowIcon";
import { RightArrowIcon } from "../ui-kit/icons/RightArrowIcon";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.statusBackgroundColor = {
      registered: "bg-orange-425",
      received: "bg-yellow-650",
      needs_review: "bg-blue-425",
      completed: "bg-green-550",
      cancelled: "bg-red-425",
      on_hold: "bg-purple-550",
    };

    this.statusTextColor = {
      registered: "text-orange-450",
      received: "text-yellow-950",
      needs_review: "text-blue-675",
      completed: "text-green-750",
      cancelled: "text-red-750",
      on_hold: "text-purple-625",
    };

    this.statusTranslation = {
      registered: this.props.t.open,
      received: this.props.t.received,
      needs_review: this.props.t.needs_review,
      completed: this.props.t.completed,
      cancelled: this.props.t.cancelled,
      on_hold: this.props.t.on_hold,
    };

    this.date = {
      received: "arrived_date",
      needs_review: "inspected_date",
      on_hold: "hold_date",
      cancelled: "cancelled_date",
      completed: "refunded_date",
      registered: "registered_date",
    };

    sessionStorage.setItem("order_by_date", this.props.order);
  }

  render() {
    const returnPerPageOption = [
      {
        value: "10",
        label: "10",
      },
      {
        value: "20",
        label: "20",
      },
      {
        value: "30",
        label: "30",
      },
      {
        value: "40",
        label: "40",
      },
      {
        value: "50",
        label: "50",
      },
    ];
    const returnPerPageOptionDefaultValue = {
      value: this.props.perPage,
      label: this.props.perPage,
    };
    const customStyles = {
      control: (base) => ({
        ...base,
        minHeight: 34,
      }),
    };

    const returns = this.props.customer_returns.map((r) => {
      return {
        ...r,
        statusDate: r[this.date[this.props.status]] || r.registered_date,
      };
    });

    return (
      <div>
        <div className="px-8 py-1">
          <div className="table border-collapse w-full">
            <div className="w-full">
              <div className="font-medium text-xs leading-4 text-gray-525 uppercase tracking-px_64 table-row">
                <div className="text-left pb-2 w-7% pr-4 table-cell">
                  {this.props.t.order}
                </div>
                {this.props.status && (
                  <div className="text-left w-12% pb-2 pr-4 table-cell">
                    <button
                      className="flex items-center w-max focus:outline-none"
                      onClick={this.props.sortByDate}
                    >
                      <DateArrowIcon rotate={this.props.order} />
                      <p>{this.props.t.date}</p>
                    </button>
                  </div>
                )}
                <div className="text-left w-9% pb-2 pr-4 table-cell">
                  {this.props.canSortReturns ? (
                    <button
                      className="flex items-center w-max focus:outline-none"
                      onClick={this.props.sortByRegisteredDate}
                    >
                      <DateArrowIcon rotate={this.props.registeredDateOrder} />
                      <p>{this.props.t.registered_date}</p>
                    </button>
                  ) : (
                    <div className="flex items-center w-max focus:outline-none">
                      <p>{this.props.t.registered_date}</p>
                    </div>
                  )}
                </div>

                <div className="text-left w-1/5 pb-2 pr-4 table-cell">
                  {this.props.t.customer}
                </div>
                <div className="text-left w-9% pb-2 table-cell">
                  {this.props.t.status}
                </div>
                <div className="text-left w-9% pb-2 pl-20 table-cell">
                  {this.props.t.items}
                </div>
                <div className="text-left w-12% pb-2 pl-8 table-cell">
                  {this.props.t.refund}
                </div>
                <div className="text-left w-1/4 pb-2 pl-7 table-cell">
                  {this.props.t.type}
                </div>
              </div>

              {returns.map((customer_return, index) => {
                return (
                  <a
                    href={`/customer_returns/${customer_return.id}/edit`}
                    className="border-t focus:outline-none cursor-pointer table-row"
                    key={index}
                  >
                    <div className="font-medium text-xs13 leading-4 text-gray-725 py-6 table-cell">
                      {customer_return.order_name ||
                        customer_return.order_number}
                    </div>

                    {this.props.status && (
                      <FormatDate
                        date={customer_return.statusDate}
                        format="DD MMM, YYYY"
                        className="w-11%"
                      />
                    )}

                    <FormatDate
                      date={customer_return.registered_date}
                      format="DD MMM, YYYY"
                    />

                    <div className="font-medium text-xs13 leading-4 text-gray-725 py-6 table-cell">
                      {customer_return.customer?.full_name}
                    </div>

                    <div className="table-cell py-6">
                      <div
                        className={`w-max ${
                          this.statusBackgroundColor[customer_return.status]
                        } rounded-full font-medium text-xs13 leading-4 py-1 px-2 ${
                          this.statusTextColor[customer_return.status]
                        }`}
                      >
                        {this.statusTranslation[customer_return.status]}
                      </div>
                    </div>

                    <div className="font-normal text-xs13 leading-4 text-gray-625 pl-20 py-6 table-cell">
                      {customer_return.quantity}
                    </div>

                    <div className="font-semibold text-xs13 leading-4 text-gray-825 pl-8 py-6 table-cell">
                      {new Intl.NumberFormat("en", {
                        style: "currency",
                        currency: customer_return.currency,
                      }).format(customer_return.total_amount)}
                    </div>

                    <div className="flex items-center pl-7 py-6 flex-wrap">
                      {[
                        ...new Set(
                          customer_return.items.map((item) => item.return_type)
                        ),
                      ].map((type, index) => (
                        <div
                          className="w-max bg-gray-225 py-1 px-2 rounded-lg font-medium text-xs13 leading-4 text-gray-725 mr-2 mt-2"
                          key={index}
                        >
                          <span>{this.props.t[type]}</span>
                        </div>
                      ))}
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-4 mb-5 flex justify-center items-center">
          <div className="mr-8 ml-auto">
            <button
              className="mr-4 ml-5 focus:outline-none"
              onClick={this.props.previousPage}
            >
              <LeftArrowIcon />
            </button>
            <button
              className="ml-2 outline-none focus:outline-none"
              onClick={this.props.nextPage}
            >
              <RightArrowIcon />
            </button>
          </div>
          <div className="flex items-center ml-auto mr-28">
            <div className="font-medium text-xs leading-4 text-gray-625">
              {this.props.t.row_per_page}
            </div>
            <Select
              className="focus:outline-none rounded-lg w-20 ml-2"
              options={returnPerPageOption}
              onChange={(data) => this.props.changePerPageData(data)}
              value={returnPerPageOptionDefaultValue}
              menuPlacement="top"
              styles={customStyles}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
