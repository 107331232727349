import React from "react";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from "react-notifications-component";
import Notification from './index'

class Authentication extends React.Component  {

  componentDidMount = () => {
    if(this.props.alert || this.props.notice) {
      store.addNotification({
        id: 'notification',
        container: "bottom-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        content: <Notification success_message={this.props.notice} error_message={this.props.alert}/>,
        width: 762,
        dismiss: {
          duration: 5000,
          showIcon: true,
        }
      });
    }
  }

  render() {
    return(
      <div>
        <ReactNotification className="left-0 right-0 m-auto"/>
      </div>
    )
  }

}

export default Authentication;
