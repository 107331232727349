import {
  IntegrationResponse,
  IntegrationResponseRaw,
} from "./Peoplevox.types";

export const transformIntegrationResponse = (
  responseData: IntegrationResponseRaw
): IntegrationResponse => {
  const {
    id = "",
    base_url = "",
    client_id = "",
    username = "",
    password = "",
    sales_order_number_prefix = "",
    sales_order_number_suffix = "",
    enabled = false,
  } = responseData || {};

  return {
    id,
    baseUrl: base_url,
    clientId: client_id,
    username,
    password,
    salesOrderNumberPrefix: sales_order_number_prefix,
    salesOrderNumberSuffix: sales_order_number_suffix,
    enabled,
  };
}
