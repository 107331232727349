import React from "react";

import { useItemTags } from "./ItemTags.hook";

import { TagsInput } from "~dashboard/ui-kit/components/TagsInput";

export default function ItemTags({
  index,
  value,
  updateRule,
  validateItemTag,
}) {
  const { name, handleTagsInput } = useItemTags({
    index,
    updateRule,
    validateItemTag,
  });

  return (
    <TagsInput
      className="focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
      name={name}
      value={value}
      onChange={(values) => handleTagsInput(name, values)}
    />
  );
}
