export const useValue = ({ index, updateRule }) => {
  const name = "value";

  const handleInput = (name, value) => {
    updateRule({ index, [name]: value });
  };

  return {
    name,
    handleInput,
  };
};
