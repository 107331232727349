import React from "react";
import moment from "moment";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import { TimeLineMarkIcon } from "../../../../../ui-kit/icons/TimeLineMarkIcon";
import { CompletedLineIcon } from "../../../../../ui-kit/icons/CompletedLineIcon";
import { UncompletedLineIcon } from "../../../../../ui-kit/icons/UncompletedLineIcon";
export const ReturnStatus = (props) => {
  const {
    registered_date,
    arrived_date,
    inspected_date,
    refunded_date,
    is_arrived,
    is_inspected,
    is_refunded,
    t,
  } = props;

  return (
    <div className="flex items-center mt-6 justify-between">
      <div className="flex">
        <TimeLineMarkIcon />
        <div className="ml-3">
          <Typography
            element="p"
            weight="semibold"
            size="xs13"
            height="4"
            color="gray-825"
          >
            {t.registered}
          </Typography>
          <Typography
            element="p"
            weight="normal"
            size="xs"
            height="4"
            color="gray-525"
          >
            {moment(registered_date).fromNow()}
          </Typography>
        </div>
      </div>
      <CompletedLineIcon />
      <div className="flex items-center">
        {is_arrived ? (
          <TimeLineMarkIcon />
        ) : (
          <p className="w-6 h-6 flex items-center justify-center bg-blue-675 rounded-80 font-semibold text-xs leading-4 text-white">
            2
          </p>
        )}

        <div className="ml-3">
          <Typography
            element="p"
            weight="medium"
            size="xs13"
            height="4"
            color="gray-825"
          >
            {t.received}
          </Typography>
          {is_arrived && (
            <Typography
              element="p"
              weight="normal"
              size="xs"
              height="4"
              color="gray-525"
            >
              {moment(arrived_date).fromNow()}
            </Typography>
          )}
        </div>
      </div>
      {is_arrived ? <CompletedLineIcon /> : <UncompletedLineIcon />}
      <div className="flex items-center">
        {is_inspected ? (
          <TimeLineMarkIcon />
        ) : (
          <p
            className={`w-6 h-6 flex items-center justify-center rounded-80 border font-semibold text-xs leading-4 ${
              is_arrived ? "text-white bg-blue-675" : "text-gray-525"
            }`}
          >
            3
          </p>
        )}

        <div className="ml-3">
          <Typography
            element="p"
            weight="medium"
            size="xs13"
            height="4"
            color={`${is_arrived ? "gray-825" : "gray-525"}`}
          >
            {t.inspected}
          </Typography>
          {is_inspected && (
            <Typography
              element="p"
              weight="normal"
              size="xs"
              height="4"
              color="gray-525"
            >
              {moment(inspected_date).fromNow()}
            </Typography>
          )}
        </div>
      </div>

      {is_inspected ? <CompletedLineIcon /> : <UncompletedLineIcon />}

      <div className="flex items-center">
        {is_refunded ? (
          <TimeLineMarkIcon />
        ) : (
          <p
            className={`w-6 h-6 flex items-center justify-center rounded-80 border font-semibold text-xs leading-4 ${
              is_inspected ? "text-white bg-blue-675" : "text-gray-525"
            }`}
          >
            4
          </p>
        )}
        <div className="ml-3">
          <Typography
            element="p"
            weight="medium"
            size="xs13"
            height="4"
            color={`${is_arrived ? "gray-825" : "gray-525"}`}
          >
            {t.completed}
          </Typography>
          {is_refunded && (
            <Typography
              element="p"
              weight="normal"
              size="xs"
              height="4"
              color="gray-525"
            >
              {moment(refunded_date).fromNow()}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
