import React from "react";
import { useCheckoutV3ToggleState } from "../useCheckoutV3ToggleState";
import { useExchangeReturnsToggleState } from "../useExchangeReturnsToggleState";
import { useCustomReturnsToggleState } from "../useCustomReturnsToggleState";
import { useKlaviyoToggleState } from "../useKlaviyoToggleState";
import { useXentralToggleState } from "../useXentralToggleState";
import { usePeoplevoxToggleState } from "../usePeoplevoxToggleState";
import { useOnStoreExchangeToggleState } from "../useOnStoreExchangeToggleState";
import { usePaywallToggleState } from "../usePaywallToggleState";
import { useCreateManualReturnToggleState } from "../useCreateManualReturnToggleState";
import { useOnboardingAssistantState } from "../useOnboardingAssistantState";
import { useFinecomToggleState } from "../useFinecomToggleState/useFinecomToggleState";

export const useMerchantSettingStates = ({
  currentMerchant,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const { paywallState, toggleSettingsPaywall } = usePaywallToggleState({
    initialValue: currentMerchant.subscription,
    updateMerchantSettings,
    showSuccessNotification,
    showErrorNotification,
  });

  const { checkoutV3State, toggleSettingsCheckoutV3 } =
    useCheckoutV3ToggleState({
      initialValue: currentMerchant.show_checkout_v3,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const { exchangeReturnsState, toggleSettingsExchangeReturns } =
    useExchangeReturnsToggleState({
      initialValue: currentMerchant.show_exchange,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const { customReturnsState, toggleSettingsCustomReturns } =
    useCustomReturnsToggleState({
      initialValue: currentMerchant.show_custom_return,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const { klaviyoIntegrationState, toggleSettingsKlaviyoIntegration } =
    useKlaviyoToggleState({
      initialValue: currentMerchant.show_klaviyo_integration,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const { xentralIntegrationState, toggleSettingsXentralIntegration } =
    useXentralToggleState({
      initialValue: currentMerchant.show_xentral_integration,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const { peoplevoxIntegrationState, toggleSettingsPeoplevoxIntegration } =
    usePeoplevoxToggleState({
      initialValue: currentMerchant.peoplevox_integration_enabled,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const { onStoreExchangeState, toggleSettingsOnStoreExchange } =
    useOnStoreExchangeToggleState({
      initialValue: currentMerchant.shop_exchange_enabled,
      updateMerchantSettings,
      showSuccessNotification,
      showErrorNotification,
    });

  const {
    createManualReturnState,
    toggleSettingsCreateManualReturnState,
  } = useCreateManualReturnToggleState({
    initialValue: currentMerchant.is_create_return_button_enabled,
    updateMerchantSettings,
    showSuccessNotification,
    showErrorNotification,
  });

  const {
    onboardingAssistantVisibilityDays,
    updateOnboardingAssistantState,
  } = useOnboardingAssistantState({
    initialValue: currentMerchant.onboarding_assistant_visibility_days,
    updateMerchantSettings,
    showErrorNotification
  })
  
  const {
    finecomIntegrationState,
    toggleSettingsFinecomIntegration,
  } = useFinecomToggleState({
    initialValue: currentMerchant.show_finecom_integration,
    updateMerchantSettings,
    showSuccessNotification,
    showErrorNotification
  })

  return {
    paywallState,
    toggleSettingsPaywall,
    checkoutV3State,
    toggleSettingsCheckoutV3,
    exchangeReturnsState,
    toggleSettingsExchangeReturns,
    customReturnsState,
    toggleSettingsCustomReturns,
    klaviyoIntegrationState,
    toggleSettingsKlaviyoIntegration,
    xentralIntegrationState,
    toggleSettingsXentralIntegration,
    peoplevoxIntegrationState,
    toggleSettingsPeoplevoxIntegration,
    onStoreExchangeState,
    toggleSettingsOnStoreExchange,
    createManualReturnState,
    toggleSettingsCreateManualReturnState,
    onboardingAssistantVisibilityDays,
    updateOnboardingAssistantState,
    finecomIntegrationState,
    toggleSettingsFinecomIntegration,
  }
}
