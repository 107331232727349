import React from "react";

import { useItemSelection } from "./ItemSelection.hook";

export const ItemSelection = (props) => {
  const { inputNames } = useItemSelection();

  return (
    <div className="mt-4 border-b pb-4 w-762px">
      <p className="font-semibold text-base leading-6 text-gray-975">
        {props.t.item_selection}
      </p>
      <div className="mt-4">
        <input
          placeholder={props.t.item_selection_title}
          className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
          name="item_selection_title"
          onChange={props.onChange}
          value={props.textBlock.item_selection_title}
        />
      </div>
      <div className="mt-4">
        <input
          placeholder={props.t.return_type_title}
          className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
          name="return_type_title"
          onChange={props.onChange}
          value={props.textBlock.return_type_title}
        />
      </div>
      {/* We hided because it's not being used in checkout V3 anywhere.
          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {this.props.t.description}
            </p>

            <textarea
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="item_selection_text"
              onChange={this.handleTextBlockInputChange}
              value={this.props.text_block.item_selection_text}
            />
          </div> */}
      <div className="mt-4">
        <input
          placeholder={props.t.refund_button}
          className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
          type="text"
          name="refund_button"
          onChange={props.onChange}
          value={props.textBlock.refund_button}
        />
      </div>
      {props.showExchange && (
        <div className="mt-4">
          <input
            placeholder={props.t.exchange_button}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name="exchange_button"
            onChange={props.onChange}
            value={props.textBlock.exchange_button}
          />
        </div>
      )}
      <div className="block">
        <div className="mt-4">
          <input
            placeholder={props.t.refund_button_description}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name="refund_button_description"
            onChange={props.onChange}
            value={props.textBlock.refund_button_description}
          />
        </div>

        {props.showExchange && (
          <div className="mt-4">
            <input
              placeholder={props.t.exchange_button_description}
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="exchange_button_description"
              onChange={props.onChange}
              value={props.textBlock.exchange_button_description}
            />
          </div>
        )}
      </div>
      {inputNames.map((inputName, index) => (
        <div key={index} className="mt-4">
          <input
            placeholder={props.t[inputName]}
            className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
            type="text"
            name={inputName}
            onChange={props.onChange}
            value={props.textBlock[inputName]}
          />
        </div>
      ))}

      {props.showLocalizedItemDetails && (
        <div>
          <div className="mt-4">
            <input
              placeholder={props.t.item_details_1}
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="item_details_1"
              onChange={props.onChange}
              value={props.textBlock.item_details_1}
            />
          </div>

          <div className="mt-4">
            <input
              placeholder={props.t.item_details_2}
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="item_details_2"
              onChange={props.onChange}
              value={props.textBlock.item_details_2}
            />
          </div>
        </div>
      )}
    </div>
  );
};
