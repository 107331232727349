import { useState } from "react";

import { ErrorToast } from "../../../common/ErrorToast";
import { SuccessToast } from "../../../common/SuccessToast";
import { addReturnMethod } from "../../../services/api";
import translationDE from "../../../../translations/returnMethods/translationDE.json";
import translationEN from "../../../../translations/returnMethods/translationEN.json";

export const useReturnMethod = ({
  showAddReturnMethodModal,
  closeAddReturnMethodModal,
  returnMethodTemplates,
  returnIntegrationMethods,
  lang,
}) => {
  function getInitialState() {
    const carrier = returnIntegrationMethods[0].value;
    const template = returnMethodTemplates[carrier][0].value;
    return {
      returnMethod: {
        carrier,
        template,
      },
      integrationOptions: returnIntegrationMethods,
      templateOptions: returnMethodTemplates[carrier],
      isModalOpen: showAddReturnMethodModal || false,
    };
  }

  const [state, setState] = useState(getInitialState());
  const t = lang === "en" ? translationEN : translationDE;

  function closeModal() {
    closeAddReturnMethodModal();
  }

  function handleInputChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      templateOptions: returnMethodTemplates[value],
      returnMethod: {
        ...prevState.returnMethod,
        [name]: value,
        template: returnMethodTemplates[value][0].value,
      },
    }));
  }

  function handleTemplateChange({ name, value }) {
    setState((prevState) => ({
      ...prevState,
      returnMethod: {
        ...prevState.returnMethod,
        [name]: value,
      },
    }));
  }

  function submitForm() {
    const params = state.returnMethod;
    saveReturnMethod(params);
  }

  async function saveReturnMethod(params) {
    const response = await addReturnMethod(params);
    const responseJson = await response.json();
    if (response.status === 200 || response.status === 201) {
      closeModal();
      setState((prevState) => ({ ...prevState, isModalOpen: false }));
      SuccessToast({
        toastId: "add_return_success",
      });
      window.location.href = "/return_methods";
    } else {
      ErrorToast({
        toastId: "add_return_failed",
        message: responseJson.error,
      });
    }
  }

  return {
    state,
    t,
    handleInputChange,
    handleTemplateChange,
    closeModal,
    submitForm,
  };
};
