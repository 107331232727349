export const useLoginPage = (props) => {
  const inputNames = [
    "login_header",
    "login_instructions",
    "login_placeholder_email",
    "login_placeholder_order_number",
    "login_overview_header",
    "login_overview_text_1",
    "login_overview_text_2",
    "login_overview_text_3",
    "climate",
    "start",
    "returns_policy",
    "imprint",
    "imprint_url",
    "privacy_policy",
    "privacy_policy_url",
    "order_not_found",
  ];

  const t = {
    ...props.t,
    ...(props.defaultImprintUrl && { imprint_url: props.defaultImprintUrl }),
    ...(props.defaultPrivacyPolicyUrl && {
      privacy_policy_url: props.defaultPrivacyPolicyUrl,
    }),
  };

  return {
    inputNames,
    t,
  };
};
