import React from "react";
import cx from "classnames";

import { AppLayout } from "../common/AppLayout";
import SubHeaderOld from "../../../common/SubHeaderOld";
import { InputLabel } from "../../../../ui-kit/components/InputLabel";
import { Input } from "../../../../ui-kit/components/Input";
import { Toggle } from "../../../../ui-kit/components/Toggle";
import { FinecomLogo } from "./ui-kit/icons/FinecomLogo";
import { useFinecom } from "./Finecom.hook";
import { Typography } from "../../../../ui-kit/components/Typography/Typography";
import { Select } from "../../../../ui-kit/components/Select";

const Finecom = ({ currentUser, finecomIntegration }) => {
  const {
    t,
    connectBtnDisabled,
    isConnected,
    state: {
      integration: { username, password, warehouse, active, productIdentifier },
    },
    handleInputChange,
    connect,
    handleFinecomCheckBoxChange,
  } = useFinecom({
    currentUser,
    finecomIntegration,
  });

  const productIdentifierOptions = [
    {
      value: "sku",
      label: "SKU",
    },
    {
      value: "ean",
      label: "EAN",
    },
  ];

  return (
    <AppLayout
      currentUser={currentUser}
      settings={t.settings}
      integrations={t.integrations}
      headerText="Finecom"
      renderSubHeader={
        <SubHeaderOld
          text="Finecom"
          renderToggle={
            isConnected && (
              <Toggle
                name="active"
                className="mt-4"
                onChange={(value) =>
                  handleFinecomCheckBoxChange({ name: "active", value })
                }
                checked={active}
                label={{
                  text: t.active,
                  className:
                    "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
                }}
              />
            )
          }
        />
      }
    >
      <FinecomLogo />

      <div className="w-2/3 pt-8">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-base leading-6 text-gray-825">
            {t.finecom_integration}
          </h1>

          {isConnected && (
            <p
              className={cx(
                "rounded-lg font-semibold text-sm leading-5 py-2 px-3",
                "bg-teal-125 text-green-825"
              )}
            >
              {t.connected}
            </p>
          )}
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <InputLabel>{t.username}</InputLabel>

            <Input
              name="username"
              value={username}
              onChange={(value) =>
                handleInputChange({ name: "username", value })
              }
            />
          </div>

          <div className="w-1/2 ml-5 block">
            <InputLabel>{t.password}</InputLabel>

            <Input
              name="password"
              value={password}
              onChange={(value) =>
                handleInputChange({ name: "password", value })
              }
            />
          </div>
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <InputLabel>{t.warehouse}</InputLabel>

            <Input
              name="warehouse"
              value={warehouse}
              onChange={(value) =>
                handleInputChange({ name: "warehouse", value })
              }
            />
          </div>
          <div className="w-1/2 ml-5 block">
            <div className="w">
              <Typography
                element="p"
                weight="medium"
                size="xs"
                height="4"
                color="gray-725"
                className=" mt-2 mb-1"
              >
                {t.productIdentifier}
              </Typography>
              <Select
                className="w-full border box-border border-gray-325 font-medium text-sm leading-5 text-gray-875 rounded-lg px-1 focus:outline-none focus:border-blue-675 focus:border"
                options={productIdentifierOptions}
                onChange={(e) =>
                  handleInputChange({ name: "productIdentifier", value: e })
                }
                value={productIdentifier}
              />
            </div>
          </div>
        </div>

        <button
          className={cx(
            "bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5",
            "text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white",
            "focus:shadow-btn_blue",
            { "cursor-default": connectBtnDisabled }
          )}
          disabled={connectBtnDisabled}
          onClick={connect}
        >
          {t.connect}
        </button>
      </div>
    </AppLayout>
  );
};

export default Finecom;
