import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

import { Toggle } from "../../../../ui-kit/components/Toggle";

export const RefundSettings = ({
  t,
  currentMerchant,
  returnPolicy,
  handleInputChange,
  handleTagifyInputs,
  handleCheckBoxChange,
  blockedItemsForRefundDisplayable,
}) => {
  const {
    email,
    withdrawal_period,
    // refund_period,
    policy_url,
    imprint_url,
    privacy_policy_url,
    refund_shipping_cost,
    refund_ineligible_product_categories,
    expected_refund,
  } = returnPolicy;

  return (
    <div className="mt-8 px-8">
      <div className="border-b pb-8">
        <p className="font-semibold text-base leading-6 text-gray-825">
          Details
        </p>

        <div className="mt-4 flex items-center">
          <div className="w-1/3">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              Email
            </p>

            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="email"
              onChange={handleInputChange}
              value={email}
            />
          </div>

          <div className="w-1/3 ml-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.return_window}
            </p>

            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="number"
              min="0"
              name="withdrawal_period"
              onChange={handleInputChange}
              value={withdrawal_period}
            />
          </div>
        </div>

        <div className="mt-4 flex items-center">
          {/*<div className="w-1/3">*/}
          {/*  <p className="font-medium text-xs leading-4 text-gray-725 mb-1">*/}
          {/*    {t.refund_period}*/}
          {/*  </p>*/}

          {/*  <input*/}
          {/*    className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"*/}
          {/*    type="number"*/}
          {/*    name="refund_period"*/}
          {/*    min="0"*/}
          {/*    onChange={handleInputChange}*/}
          {/*    value={refund_period}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="w-1/3">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.return_policy_url}
            </p>

            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="policy_url"
              onChange={handleInputChange}
              value={policy_url || ""}
            />
          </div>

          <div className="w-1/3 ml-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.refund_shipping_cost}
            </p>

            <select
              className="w-full border box-border border-gray-325 font-medium text-sm leading-5 text-gray-875 rounded-lg py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              name="refund_shipping_cost"
              onChange={handleInputChange}
              value={refund_shipping_cost}
            >
              <option value="refund">{t.refund}</option>
              <option value="no_refund">{t.no_refund}</option>
              {currentMerchant.shop_backend === "shopify_integration" && (
                <option value="if_all_items_returned">{t.if_all_items}</option>
              )}
            </select>
          </div>
        </div>

        <div className="mt-4 flex items-center">
          <div className="w-1/3">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.imprint_url}
            </p>

            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="imprint_url"
              onChange={handleInputChange}
              value={imprint_url}
            />
          </div>

          <div className="w-1/3 ml-5">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.privacy_policy_url}
            </p>

            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="privacy_policy_url"
              onChange={handleInputChange}
              value={privacy_policy_url}
            />
          </div>
        </div>

        {blockedItemsForRefundDisplayable &&
          (currentMerchant.show_exchange ||
            currentMerchant.show_custom_return) && (
            <div className="mt-4">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.refund_ineligible_product}
              </p>

              <Tags
                className="w-68% focus:outline-none bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:border focus:border-blue-675 border-gray-325"
                name="refund_ineligible_product_categories"
                onChange={handleTagifyInputs}
                value={refund_ineligible_product_categories}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.blocked_product_tags}
              </p>
            </div>
          )}

        <Toggle
          id="expected_refund"
          name="expected_refund"
          className="w-1/3 mt-4"
          onChange={handleCheckBoxChange}
          checked={expected_refund}
          label={{
            text: t.expected_refund,
            className:
              "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
          }}
        />
      </div>
    </div>
  );
};
