import React from "react";

export const LeftArrowIcon = () => {
  return (
    <svg
      width={5}
      height={8}
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 0.75L0.75 4L4.25 7.25"
        stroke="#18181B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
