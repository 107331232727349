import React from "react";
import Modal from "react-modal";

import { ReturnAddressEditIcon } from "../../ui-kit/icons/ReturnAddressEditIcon";
import { Typography } from "../../ui-kit/components/Typography/Typography";
import { Input } from "../../ui-kit/components/Input/Input";

import { useReturnAddress } from "./index.hook";

Modal.setAppElement("#root");

const ReturnAddress = (props) => {
  const {
    showEdit,
    closeEditReturnAddressModal,
    returnAddress,
    lang,
    cancelButton,
  } = props;

  const { state, t, handleInputChange, closeEditModal, submitEditForm } =
    useReturnAddress({
      showEdit,
      closeEditReturnAddressModal,
      returnAddress,
      lang,
    });

  const { isModelOpen, returnAddressAttributes, countryOptions } = state;
  return (
    <Modal
      isOpen={isModelOpen}
      onRequestClose={closeEditModal}
      style={{
        content: {
          maxWidth: "830px",
          maxHeight: "560px",
          margin: "auto",
          padding: "32px",
        },
      }}
    >
      <form
        className="font-intel"
        onSubmit={(event) => {
          event.preventDefault();
          submitEditForm();
        }}
      >
        <div className="flex items-center mb-8">
          <ReturnAddressEditIcon />
          <Typography
            element="p"
            weight="semibold"
            size="base"
            height="6"
            color="gray-975"
          >
            {t.edit_return_address}
          </Typography>
        </div>
        <Typography
          element="p"
          weight="semibold"
          size="base"
          height="6"
          color="gray-825"
        >
          Details
        </Typography>
        <div className="flex mt-6">
          <div className="w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.address}
            </Typography>
            <Input
              type="text"
              value={returnAddressAttributes.name}
              name="name"
              onChange={(value) => handleInputChange({ name: "name", value })}
            />
          </div>
          <div className="ml-5 w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.address_suffix}
            </Typography>
            <Input
              type="text"
              value={returnAddressAttributes.name2}
              name="name2"
              onChange={(value) => handleInputChange({ name: "name2", value })}
            />
          </div>
        </div>
        <div className="flex mt-6">
          <div className="w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.street}
            </Typography>
            <Input
              type="text"
              value={returnAddressAttributes.street}
              name="street"
              onChange={(value) => handleInputChange({ name: "street", value })}
              required
            />
          </div>
          <div className="ml-5 w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.street_number}
            </Typography>
            <Input
              name="street_number"
              value={returnAddressAttributes.street_number}
              onChange={(value) =>
                handleInputChange({ name: "street_number", value })
              }
              required
            />
          </div>
        </div>
        <div className="flex mt-6">
          <div className="w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.zip}
            </Typography>
            <Input
              name="zip"
              value={returnAddressAttributes.zip}
              onChange={(value) => handleInputChange({ name: "zip", value })}
              required
            />
          </div>
          <div className="ml-5 w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.city}
            </Typography>
            <Input
              name="city"
              value={returnAddressAttributes.city}
              onChange={(value) => handleInputChange({ name: "city", value })}
              required
            />
          </div>
          <div className="ml-5 w-1/2">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
            >
              {t.state}
            </Typography>
            <Input
              name="state"
              value={returnAddressAttributes.state}
              onChange={(value) => handleInputChange({ name: "state", value })}
            />
            <Typography
              element="p"
              weight="normal"
              size="xs"
              height="4"
              color="gray-600"
            >
              {t.state_caption}
            </Typography>
          </div>
        </div>
        <div className="w-1/2">
          <Typography
            element="p"
            weight="medium"
            size="xs"
            height="4"
            color="gray-725"
            className=" mt-6"
          >
            {t.country}
          </Typography>
          <select
            className="border w-372 box-border  border-gray-325 rounded outline-none px-4 py-3 font-normal text-xs leading-5 flex items-center text-gray-750 focus:bg-white focus:border-blue-675 focus:border mt-1"
            placeholder="country"
            name="country"
            onChange={(e) =>
              handleInputChange({ name: "country", value: e.target.value })
            }
            defaultValue={returnAddressAttributes.country}
            required
          >
            {countryOptions.map((i, index) => (
              <option className="text-gray-750" value={i.value} key={index}>
                {i.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mt-8">
          {cancelButton && (
            <button
              className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625"
              onClick={closeEditModal}
            >
              {t.cancel}
            </button>
          )}
          <button
            className=" bg-green-800 font-semibold text-sm leading-5 text-white rounded-lg py-2 px-4 ml-3 focus:outline-none text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
            type="submit"
          >
            {t.save_changes}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReturnAddress;
