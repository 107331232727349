import React from "react";
import ReactNotification from "react-notifications-component";

import translationEN from "../../translations/policySettings/translationEN.json";
import translationDE from "../../translations/policySettings/translationDE.json";

import { PolicySettingsIcon } from "../../ui-kit/icons/PolicySettingsIcon";

import Header from "../header";
import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import { RefundSettings } from "./components/RefundSettings";
import PartialRefundSettings from "./components/PartialRefundSettings";
import { ItemConditions } from "./components/ItemConditions";
import { BlockAndAllowSettings } from "./components/BlockAndAllowSettings";
import { WorkflowAutomation } from "./components/WorkflowAutomation";
import { ExchangeSettings } from "./components/ExchangeSettings";
import { CustomReturnSettings } from "./components/CustomReturnSettings";
import TradeInItemSettings from "./components/TradeInItemSettings";

import { PartialRefundValueType } from "./components/PartialRefundSettings/components/RulesList/components/Rule/Rule.types";

import {
  transformTagifyJSONToCommaSeparatedString,
  transformCommaSeparatedStringToTagifyJSON,
} from "~dashboard/util/transformTagifyString";

import "react-notifications-component/dist/theme.css";

class PolicySettings extends React.Component {
  constructor(props) {
    super(props);

    this.partialRefundRulePositionField = "priority";

    this.state = {
      return_policy: this.props.return_policy,
      merchant: {
        cancel_customer_return_days:
          this.props.current_merchant.cancel_customer_return_days,
        show_item_conditions: this.props.current_merchant.show_item_conditions,
      },
      tradeInItemSetting: {
        id: this.props.current_merchant.trade_in_item_setting?.id,
        eligibleCountries:
          this.props.current_merchant.trade_in_item_setting
            ?.eligible_countries ?? "",
      },
      workflow_automation: this.props.workflow_automation,
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      statusOptions: [
        {
          value: "is_inspected",
          label: "Inspected",
        },
        {
          value: "is_arrived",
          label: "Arrived",
        },
      ],
      onSpecificDay:
        this.props.workflow_automation.completion_automation_period === "day",
      AfterNoOfDays:
        this.props.workflow_automation.completion_automation_period ===
        "status_age",
      partialRefundRules: this.props.partial_refund_rules.map(
        ({
          id,
          item_tags: itemTags,
          value_type: valueType,
          value,
          priority,
        }) => ({
          id,
          itemTags: transformCommaSeparatedStringToTagifyJSON(itemTags),
          valueType,
          value,
          priority,
        })
      ),
      deletedPartialRefundRules: [],
      itemConditions: this.props.item_conditions,
      deletedItemConditions: [],
    };
  }

  setPartialRefundRules = (rules) => {
    this.setState({ partialRefundRules: rules });
  };

  addPartialRefundRule = () => {
    this.setState(({ partialRefundRules: rules }) => ({
      partialRefundRules: [
        ...rules,
        {
          id: "",
          itemTags: "",
          valueType: PartialRefundValueType.Percentage,
          value: 100.0,
          [this.partialRefundRulePositionField]: rules.length + 1,
        },
      ],
    }));
  };

  updatePartialRefundRule = ({ index, ...props }) => {
    this.setState(({ partialRefundRules: rules }) => ({
      partialRefundRules: rules.map((rule, currentIndex) => {
        if (currentIndex == index) {
          return {
            ...rule,
            ...props,
            id: rule.id,
          };
        }

        return rule;
      }),
    }));
  };

  deletePartialRefundRule = (index) => {
    const deletedRule = this.state.partialRefundRules[index];

    if (deletedRule.id) {
      this.setState(({ deletedPartialRefundRules: deletedRules }) => ({
        deletedPartialRefundRules: [...deletedRules, deletedRule],
      }));
    }

    this.setState(({ partialRefundRules: rules }) => ({
      partialRefundRules: rules
        .filter((rule, currentIndex) => currentIndex !== index)
        .map((rule, currentIndex) => ({
          ...rule,
          [this.partialRefundRulePositionField]: currentIndex + 1,
        })),
    }));
  };

  validatePartialRefundRuleItemTag = (input, index) => {
    const errors = [];

    let prevTagRuleIndex = -1;
    const isDuplicateTag = this.state.partialRefundRules
      .filter((rule, currentIndex) => currentIndex !== index)
      .some(({ itemTags }, currentIndex) => {
        const tags = transformTagifyJSONToCommaSeparatedString(itemTags);
        if (tags.split(",").includes(input)) {
          prevTagRuleIndex =
            currentIndex >= index ? currentIndex + 1 : currentIndex;
          return true;
        }
      });

    if (isDuplicateTag) {
      errors.push(`Tag already exists on Rule #${prevTagRuleIndex + 1}`);
    }

    return errors;
  };

  handleInputChange = (e) => {
    this.setState({
      return_policy: {
        ...this.state.return_policy,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCheckBoxChange = ({ name, value }) => {
    this.setState({
      return_policy: {
        ...this.state.return_policy,
        [name]: value,
      },
    });
  };

  handleCheckBoxChangeForWorkflowAutomation = (e) => {
    this.setState({
      workflow_automation: {
        ...this.state.workflow_automation,
        [e.target.name]: e.target.checked,
      },
    });
  };

  handleInputChangeForWorkflowAutomation = (e) => {
    this.setState({
      workflow_automation: {
        ...this.state.workflow_automation,
        [e.target.name]: e.target.value,
      },
    });
  };

  addItemCondition = () => {
    const codePointA = 65;

    this.setState(({ itemConditions }) => {
      const staticName = String.fromCodePoint(
        itemConditions.length + codePointA
      );

      return {
        itemConditions: [
          ...itemConditions,
          {
            id: "",
            static_name: staticName,
            custom_name: staticName,
          },
        ],
      };
    });
  };

  updateItemCondition = ({ index, ...props }) => {
    this.setState(({ itemConditions }) => ({
      itemConditions: itemConditions.map((condition, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...condition,
            ...props,
            id: condition.id,
          };
        }

        return condition;
      }),
    }));
  };

  deleteItemCondition = (index) => {
    const deletedCondition = this.state.itemConditions[index];

    if (deletedCondition.id) {
      this.setState(({ deletedItemConditions }) => ({
        deletedItemConditions: [...deletedItemConditions, deletedCondition],
      }));
    }

    this.setState(({ itemConditions }) => ({
      itemConditions: itemConditions.filter(
        (condition, currentIndex) => currentIndex !== index
      ),
    }));
  };

  handelDropDownValue = (data, name) => {
    this.setState({
      workflow_automation: {
        ...this.state.workflow_automation,
        [name]: data.value,
      },
    });
  };

  enableOnSpecificWeekdayOption = () => {
    this.setState({
      workflow_automation: {
        ...this.state.workflow_automation,
        completion_automation_period: "day",
      },
      onSpecificDay: true,
      AfterNoOfDays: false,
    });
  };

  disableOnSpecificWeekdayOption = () => {
    this.setState({
      workflow_automation: {
        ...this.state.workflow_automation,
        completion_automation_period: "status_age",
      },
      onSpecificDay: false,
      AfterNoOfDays: true,
    });
  };

  handleInputMerchantChange = (e) => {
    this.setState({
      merchant: {
        ...this.state.merchant,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleToggleMerchantChange = ({ name, value }) => {
    this.setState({
      merchant: {
        ...this.state.merchant,
        [name]: value,
      },
    });
  };

  handleTagifyInputs = (e) => {
    this.setState((prevState) => ({
      return_policy: {
        ...prevState.return_policy,
        [e.detail.tagify.DOM.originalInput.name]: e.detail.tagify
          .getCleanValue()
          .map((x) => x.value)
          .join(","),
      },
    }));
  };

  handleTagifyInputIneligibleProductCategories = (e) => {
    this.setState((prevState) => ({
      return_policy: {
        ...prevState.return_policy,
        ineligible_product_categories: e.detail.tagify
          .getCleanValue()
          .map((x) => this.removeWhitespacesAroundSeparator(x.value, ":"))
          .join(","),
      },
    }));
  };

  removeWhitespacesAroundSeparator = (str, separator) => {
    return str
      .split(separator)
      .map((x) => x.trim())
      .join(separator);
  };

  handleTagifyInputsForExchangeReturnPolicy = (e) => {
    this.setState((prevState) => ({
      return_policy: {
        ...prevState.return_policy,
        exchange_setting: {
          ...prevState.return_policy.exchange_setting,
          [e.detail.tagify.DOM.originalInput.name]: e.detail.tagify
            .getCleanValue()
            .map((x) => x.value)
            .join(","),
        },
      },
    }));
  };

  returnPolicyRequestParams = ({
    exchange_setting: exchange_setting_attributes,
    ...scalar_params
  }) => ({
    ...scalar_params,
    exchange_setting_attributes,
  });

  submitEditForm = async () => {
    const { cancel_customer_return_days, show_item_conditions } =
      this.state.merchant;

    const { id: tradeInItemSettingId, eligibleCountries: eligible_countries } =
      this.state.tradeInItemSetting;

    const params = {
      merchant: {
        cancel_customer_return_days,
        show_item_conditions,
        return_policy_attributes: this.returnPolicyRequestParams(
          this.state.return_policy
        ),
        workflow_automation_attributes: this.state.workflow_automation,
        trade_in_item_setting_attributes: {
          id: tradeInItemSettingId,
          eligible_countries,
        },
        partial_refund_rules_attributes: this.state.partialRefundRules
          .map(({ id, itemTags, valueType: value_type, value, priority }) => ({
            id,
            item_tags: transformTagifyJSONToCommaSeparatedString(itemTags),
            value_type,
            value,
            priority,
          }))
          .concat(
            this.state.deletedPartialRefundRules.map((deletedRule) => ({
              ...deletedRule,
              _destroy: 1,
            }))
          ),
        item_conditions_attributes: this.state.itemConditions.concat(
          this.state.deletedItemConditions.map((deletedCondition) => ({
            ...deletedCondition,
            _destroy: 1,
          }))
        ),
      },
    };

    const response = await fetch("/policy_settings", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(params),
    });

    if (response.status === 200) {
      SuccessToast({
        toastId: "updatePolicySettings",
      });

      this.setState({
        deletedPartialRefundRules: [],
        deletedItemConditions: [],
      });
    } else {
      const responseJson = await response.json();
      ErrorToast({
        toastId: "updatePolicySettings",
        message: responseJson.error,
      });
    }
  };

  setTradeInItemEligibleCountries = (value) => {
    this.setState((prevState) => ({
      tradeInItemSetting: {
        ...prevState.tradeInItemSetting,
        eligibleCountries: value,
      },
    }));
  };

  render() {
    return (
      <div className="font-inter pb-10">
        <ReactNotification className="left-0 right-0 m-auto" />

        <Header
          current_user={this.props.current_user}
          text={this.state.t.policy_settings}
        />

        <div className="flex items-center justify-between border-t border-b mt-6 px-8 py-5">
          <div className="flex items-center">
            <PolicySettingsIcon />

            <h5 className="font-semibold text-lg leading-5 text-gray-975 ml-5">
              {this.state.t.policy_settings}
            </h5>
          </div>

          <button
            className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:bg-green-800 focus:shadow-btn_green"
            onClick={this.submitEditForm}
          >
            {this.state.t.save_changes}
          </button>
        </div>

        {/* Refund Settings */}

        <RefundSettings
          t={this.state.t}
          currentMerchant={this.props.current_merchant}
          returnPolicy={this.state.return_policy}
          handleInputChange={this.handleInputChange}
          handleTagifyInputs={this.handleTagifyInputs}
          handleCheckBoxChange={(value) =>
            this.handleCheckBoxChange({ name: "expected_refund", value })
          }
          blockedItemsForRefundDisplayable={
            this.props.blocked_items_for_refund_displayable
          }
        />

        {/* Partial refund */}

        {this.props.partial_refund_displayable && (
          <PartialRefundSettings
            rules={this.state.partialRefundRules}
            setRules={this.setPartialRefundRules}
            addRule={this.addPartialRefundRule}
            updateRule={this.updatePartialRefundRule}
            deleteRule={this.deletePartialRefundRule}
            validateItemTag={this.validatePartialRefundRuleItemTag}
            positionField={this.partialRefundRulePositionField}
            t={this.state.t}
          />
        )}

        {/* Item conditions */}

        <ItemConditions
          t={this.state.t}
          itemConditionsState={this.state.merchant.show_item_conditions}
          handleToggleMerchantChange={this.handleToggleMerchantChange}
          itemConditions={this.state.itemConditions}
          addItemCondition={this.addItemCondition}
          updateItemCondition={this.updateItemCondition}
          deleteItemCondition={this.deleteItemCondition}
        />

        {/* Block & Allow Settings */}

        {this.props.block_and_allow_list_displayable && (
          <BlockAndAllowSettings
            t={this.state.t}
            handleTagifyInputs={this.handleTagifyInputs}
            handleTagifyInputIneligibleProductCategories={
              this.handleTagifyInputIneligibleProductCategories
            }
            returnPolicy={this.state.return_policy}
            currentMerchant={this.props.current_merchant}
          />
        )}

        {/* Workflow Automation */}

        <WorkflowAutomation
          t={this.state.t}
          handleInputMerchantChange={this.handleInputMerchantChange}
          handleInputChange={this.handleInputChangeForWorkflowAutomation}
          handleCheckBoxChange={this.handleCheckBoxChangeForWorkflowAutomation}
          handelDropDownValue={this.handelDropDownValue}
          disableOnSpecificWeekdayOption={this.disableOnSpecificWeekdayOption}
          enableOnSpecificWeekdayOption={this.enableOnSpecificWeekdayOption}
          afterNoOfDays={this.state.AfterNoOfDays}
          onSpecificDay={this.state.onSpecificDay}
          merchant={this.state.merchant}
          state={this.state.workflow_automation}
        />

        {/* Exchanges */}

        {this.props.current_merchant.show_exchange && (
          <ExchangeSettings
            returnPolicy={this.state.return_policy}
            t={this.state.t}
            handleInputChange={this.handleInputChange}
            handleCheckBoxChange={this.handleCheckBoxChange}
            handleTagifyInputs={this.handleTagifyInputs}
            handleTagifyInputsForExchangeReturnPolicy={
              this.handleTagifyInputsForExchangeReturnPolicy
            }
          />
        )}

        {/* Custom  */}

        {this.props.current_merchant.show_custom_return && (
          <CustomReturnSettings
            returnPolicy={this.state.return_policy}
            t={this.state.t}
            handleCheckBoxChange={this.handleCheckBoxChange}
            handleInputChange={this.handleInputChange}
            handleTagifyInputs={this.handleTagifyInputs}
            customMissingTranslationsLangs={
              this.props.custom_missing_translations_langs
            }
            blockedItemsForCustomReturnDisplayable={
              this.props.blocked_items_for_custom_return_displayable
            }
          />
        )}

        {/* Trade-in items */}

        {this.props.current_merchant.show_trade_in_items && (
          <TradeInItemSettings
            eligibleCountries={this.state.tradeInItemSetting.eligibleCountries}
            setEligibleCountries={this.setTradeInItemEligibleCountries}
            t={this.state.t}
          />
        )}
      </div>
    );
  }
}

export default PolicySettings;
