import React, { Component } from "react";

import ReactNotification, { store } from "react-notifications-component";

import Modal from "react-modal";

import ReturnAddress from "../ReturnAddress";
import translationDE from "../../translations/returnMethods/translationDE.json";
import translationEN from "../../translations/returnMethods/translationEN.json";

import AddReturnMethod from "./components/AddReturnMethod";
import Notification from "../notification/index";

class ReturnMethods extends Component {
  constructor(props) {
    super(props);
    this.grey_color = "#3F3F46";
    this.red_color = "#EB5757";
    this.blue_color = "#2F80ED";

    this.state = {
      returnMethods: this.props.return_methods.map((return_method) => {
        return {
          ...return_method,
          delete_color: this.grey_color,
          edit_color: this.grey_color,
        };
      }),
      editReturnAddress: false,
      addReturnMethod: false,
      returnAddress: this.props.return_address,
      notificationId: 0,
      showDeleteModal: false,
      deleteReturnMethodId: "",
      selectedDeleteMethod: "",
      t: this.props.lang === "en" ? translationEN : translationDE,
      returnMethodTemplates: this.props.return_method_templates,
      returnIntegrationMethods: this.props.return_integration_methods,
    };
  }

  showEditReturnAddressModal = () => {
    this.setState({
      editReturnAddress: true,
    });
  };

  showAddReturnMethodModal = () => {
    this.setState({
      addReturnMethod: true,
    });
  };

  closeEditReturnAddressModal = (return_address) => {
    this.setState({
      editReturnAddress: false,
      returnAddress: return_address,
    });
  };

  closeAddReturnMethodModal = () => {
    this.setState({
      addReturnMethod: false,
    });
  };

  editReturnMethod = (return_method) => {
    window.location.href = `/return_methods/${return_method.id}/edit`;
  };

  showDeleteModal = (id, index) => {
    this.setState({
      showDeleteModal: true,
      deleteReturnMethodId: id,
      selectedDeleteMethod: index,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      deleteReturnMethodId: "",
      selectedDeleteMethod: "",
    });
  };

  deleteReturnMethod = () => {
    fetch(`/return_methods/${this.state.deleteReturnMethodId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    }).then((response) => {
      if (response.status === 200) {
        let return_methods = this.state.returnMethods;
        return_methods.splice(this.state.selectedDeleteMethod, 1);
        this.setState({
          returnMethods: return_methods,
          showDeleteModal: false,
          deleteReturnMethodId: "",
          selectedDeleteMethod: "",
          notification_id: this.state.notificationId + 1,
        });
        store.addNotification({
          id: this.state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: <Notification success_message="Deleted." />,
          width: 762,
          dismiss: {
            duration: 2000,
            showIcon: true,
          },
        });
      } else {
        response.json().then((responseJson) => {
          store.addNotification({
            id: this.state.notificationId,
            container: "bottom-center",
            animationIn: ["animated", "bounceIn"],
            animationOut: ["animated", "bounceOut"],
            content: <Notification error_message={responseJson.error} />,
            width: 762,
            dismiss: {
              duration: 5000,
              showIcon: true,
            },
          });
        });
      }
    });
  };
  onMouseOver = (return_method, index) => {
    let return_methods = this.state.returnMethods;
    return_method.delete_color = this.red_color;
    return_methods.splice(index, 1, return_method);
    this.setState({ returnMethods: return_methods });
  };

  onMouseOut = (return_method, index) => {
    let return_methods = this.state.returnMethods;
    return_method.delete_color = this.grey_color;
    return_methods.splice(index, 1, return_method);
    this.setState({ returnMethods: return_methods });
  };

  onMouseOverEdit = (return_method, index) => {
    const return_methods = this.state.returnMethods;
    return_method.edit_color = this.blue_color;
    return_methods.splice(index, 1, return_method);
    this.setState({ returnMethods: return_methods });
  };

  onMouseOutEdit = (return_method, index) => {
    const return_methods = this.state.returnMethods;
    return_method.edit_color = this.grey_color;
    return_methods.splice(index, 1, return_method);
    this.setState({ return_reasons: return_methods });
  };

  render() {
    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />
        <div>
          <div className="flex justify-between items-center px-8">
            <div className="font-medium text-xs leading-4">
              <p>
                <span className="text-gray-525">
                  <a href="/settings">{this.state.t.settings}</a> /{" "}
                </span>
                <span className="text-gray-725 text-xs13">
                  {this.state.t.return_method}
                </span>
              </p>
            </div>
            <div className="font-inter">
              <a className="focus:outline-none" href="/users/edit">
                <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                  {this.props.current_user.first_name + " "}
                  {this.props.current_user.last_name}
                </p>
                <p className="font-normal text-xs leading-4 text-right text-gray-625">
                  {this.props.current_user.email}
                </p>
              </a>
            </div>
          </div>

          <div className="flex items-center justify-between border-t border-b mt-6 px-8 py-5">
            <div>
              <h5 className="font-semibold text-base leading-6 text-gray-975">
                {this.state.t.return_method}
              </h5>
            </div>
            <div className="w-1/2 flex items-center justify-end">
              <div className="mx-4">
                <button
                  className="bg-white flex items-center rounded-lg px-4 py-2 text-gray-875 text-sm leading-5 border border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625"
                  onClick={this.showEditReturnAddressModal}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M0.75 15.2498L5 14.2498L14.2929 4.95695C14.6834 4.56643 14.6834 3.93327 14.2929 3.54274L12.4571 1.70696C12.0666 1.31643 11.4334 1.31643 11.0429 1.70696L1.75 10.9998L0.75 15.2498Z"
                      stroke="#3F3F46"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {this.state.t.edit_return_address}
                </button>
              </div>
              <button
                className="bg-blue-700 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white focus:outline-none "
                onClick={this.showAddReturnMethodModal}
              >
                {this.state.t.add_return_method_button}
              </button>
            </div>
          </div>
          <div className="ml-8 mt-6 font-medium text-xs leading-4 text-gray-525 mr-8 border-b pb-2">
            <div className="ml-4 mr-4 flex justify-between tracking-px_64">
              <div className="w-1/5">NAME</div>
              <div className="w-3/5">{this.state.t.description}</div>
              <div className="w-1/5">STATUS</div>
              <div className="w-1/5 text-right">Action</div>
            </div>
          </div>
          {this.state.returnMethods.map((return_method, index) => (
            <div className="mx-8 mt-4" key={index}>
              <div className="flex items-center px-4 pt-4 pb-4 hover:bg-gray-100 hover-container">
                <div className="w-1/5 font-medium text-sm leading-5 tesxt-gray-825">
                  {return_method.merchant_return_method_translations[0].name}
                </div>
                <div className="w-3/5 font-medium text-sm leading-5 text-gray-625">
                  {
                    return_method.merchant_return_method_translations[0]
                      .description
                  }
                </div>
                <div className="w-1/5 font-medium text-sm leading-5 text-gray-625">
                  {return_method.is_activated && (
                    <div className="bg-gray-225 flex items-center w-5/12 rounded-lg pl-3 pr-2 py-1">
                      <div className="mr-2">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#27AE60" />
                        </svg>
                      </div>
                      <div className="font-medium text-xs leading-4">
                        {this.state.t.active}
                      </div>
                    </div>
                  )}
                  {!return_method.is_activated && (
                    <div className="bg-gray-225 flex items-center w-20 rounded-lg px-2 py-1">
                      <div className="mr-2">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="4" cy="4" r="4" fill="#EB5757" />
                        </svg>
                      </div>
                      <div className="font-medium text-xs leading-4">
                        {this.state.t.inactive}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-1/5">
                  <div className="flex float-right display-flex hidden">
                    <div
                      className="mr-6"
                      onClick={() => this.editReturnMethod(return_method)}
                      onMouseOver={() =>
                        this.onMouseOverEdit(return_method, index)
                      }
                      onMouseOut={() =>
                        this.onMouseOutEdit(return_method, index)
                      }
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                      >
                        <path
                          d="M8.54963 2.78125H2.6777C2.23274 2.78125 1.80601 2.95801 1.49139 3.27264C1.17676 3.58726 1 4.01399 1 4.45895V16.2028C1 16.6478 1.17676 17.0745 1.49139 17.3891C1.80601 17.7038 2.23274 17.8805 2.6777 17.8805H14.4216C14.8665 17.8805 15.2932 17.7038 15.6079 17.3891C15.9225 17.0745 16.0993 16.6478 16.0993 16.2028V10.3309"
                          stroke={return_method.edit_color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.8414 1.52119C15.1751 1.18748 15.6277 1 16.0996 1C16.5716 1 17.0242 1.18748 17.3579 1.52119C17.6916 1.85491 17.8791 2.30752 17.8791 2.77947C17.8791 3.25141 17.6916 3.70402 17.3579 4.03774L9.38884 12.0068L6.03345 12.8456L6.8723 9.49025L14.8414 1.52119Z"
                          stroke={return_method.edit_color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={() =>
                        this.showDeleteModal(return_method.id, index)
                      }
                      onMouseOver={() => this.onMouseOver(return_method, index)}
                      onMouseOut={() => this.onMouseOut(return_method, index)}
                    >
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                      >
                        <path
                          d="M0.899902 4.60156H2.6999H17.0999"
                          stroke={return_method.delete_color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.39995 4.6V2.8C5.39995 2.32261 5.58959 1.86477 5.92716 1.52721C6.26472 1.18964 6.72256 1 7.19995 1H10.8C11.2773 1 11.7352 1.18964 12.0727 1.52721C12.4103 1.86477 12.6 2.32261 12.6 2.8V4.6M15.3 4.6V17.2C15.3 17.6774 15.1103 18.1352 14.7727 18.4728C14.4352 18.8104 13.9773 19 13.5 19H4.49995C4.02256 19 3.56472 18.8104 3.22716 18.4728C2.88959 18.1352 2.69995 17.6774 2.69995 17.2V4.6H15.3Z"
                          stroke={return_method.delete_color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.19995 9.10156V14.5016"
                          stroke={return_method.delete_color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.7998 9.10156V14.5016"
                          stroke={return_method.delete_color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {this.state.editReturnAddress && (
          <ReturnAddress
            showEdit={this.state.editReturnAddress}
            closeEditReturnAddressModal={this.closeEditReturnAddressModal}
            returnAddress={this.state.returnAddress}
            lang={this.props.lang}
            cancelButton
          />
        )}
        {this.state.addReturnMethod && (
          <AddReturnMethod
            showAddReturnMethodModal={this.state.addReturnMethod}
            closeAddReturnMethodModal={this.closeAddReturnMethodModal}
            returnMethodTemplates={this.state.returnMethodTemplates}
            returnIntegrationMethods={this.state.returnIntegrationMethods}
            lang={this.props.lang}
            cancelButton
          />
        )}
        <Modal
          isOpen={this.state.showDeleteModal}
          onRequestClose={this.closeDeleteModal}
          style={{
            content: {
              maxWidth: "470px",
              maxHeight: "185px",
              margin: "auto",
              padding: "24px",
            },
          }}
        >
          <div>
            <div className="flex items-center mb-3">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="32"
                  height="32"
                  rx="16"
                  fill="#EB5757"
                  fillOpacity="0.1"
                />
                <path
                  d="M10.75 11.75L11.5912 21.4233C11.681 22.4568 12.5462 23.25 13.5836 23.25H18.4164C19.4538 23.25 20.319 22.4568 20.4088 21.4233L21.25 11.75"
                  stroke="#EB5757"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.75 11.5V10.75C13.75 9.64543 14.6454 8.75 15.75 8.75H16.25C17.3546 8.75 18.25 9.64543 18.25 10.75V11.5"
                  stroke="#EB5757"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 11.75H23"
                  stroke="#EB5757"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="font-semibold text-base leading-6 text-gray-825 ml-3">
                {this.state.t.delete_method}
              </p>
            </div>
            <p className="font-normal text-xs13 leading-4 text-gray-625 mb-5">
              {this.state.t.delete_text}
            </p>
            <div className="flex justify-end">
              <button
                className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:shadow-btn_blue focus:border-blue-625"
                onClick={this.closeDeleteModal}
              >
                {this.state.t.cancel}
              </button>
              <button
                className="fnot-italic font-semibold text-sm leading-5 text-white bg-red-525 rounded-lg py-2 px-4 ml-3 focus:outline-none hover:bg-red-800 focus:bg-red-525 focus:shadow-btn_red"
                onClick={this.deleteReturnMethod}
              >
                {this.state.t.yes_delete}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ReturnMethods;
