import React from "react";

export const useTradeInItemSettings = ({
  eligibleCountries,
  setEligibleCountries,
}) => {
  const changeCountry = (nodes) => {
    const value = nodes.map(node => node.value).join(",")
    setEligibleCountries(value)
  }

  const eligibleCountriesCurrentValue = eligibleCountries
    ? eligibleCountries.split(",").map(countryIso2 => ({ value: countryIso2, label: countryIso2 }))
    : [];

  return {
    changeCountry,
    eligibleCountriesCurrentValue,
  };
}
