import React from "react";

export function ItemFieldDropdown({
  name,
  option,
  options,
  onChange,
  isDisabled,
  className,
}) {
  return (
    <select
      name={name}
      className={`${className} w-110px ml-5 text-xs text-gray-825 border box-border rounded-80 border-gray-225 px-3 py-1 ${
        isDisabled ? "opacity-50" : ""
      }`}
      value={option}
      onChange={onChange}
      disabled={isDisabled}
    >
      {options.map(({ value, label }, index) => (
        <option key={index} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}
