import React, { useState } from "react";

import {
  getPaywallFromSubscription,
  getSubscriptionFromPaywall,
} from "./usePaywallToggleState.util";

export const usePaywallToggleState = ({
  initialValue,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [paywallState, setPaywallState] = useState(getPaywallFromSubscription(initialValue));

  async function toggleSettingsPaywall() {
    const value = !paywallState;
    setPaywallState(value);

    const response = await updateMerchantSettings({ subscription: getSubscriptionFromPaywall(value) });

    if (response.status === 200) {
      const { subscription } = await response.json();
      setPaywallState(getPaywallFromSubscription(subscription));
      showSuccessNotification();
    } else {
      const { error } = await response.json();
      showErrorNotification(error);
    }
  }

  return {
    paywallState,
    toggleSettingsPaywall,
  };
}
