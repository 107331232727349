import React from "react";

const SearchInput = ({
  type,
  placeholder,
  value,
  onChange,
}) => (<div className="flex items-center mt-5 pl-5 py-2 border border-gray-325 bg-white box-border rounded-lg ml-4">
    <input
      className="outline-none ml-2 w-full"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>);

export default SearchInput;
