import React from "react";
import cx from "classnames";
import { random } from "lodash";

import { InputLabel } from "../InputLabel";
import { ToggleWithoutLabel } from "../ToggleWithoutLabel";

export type LabelProps = {
  text: string;
  className: string;
};

export type ToggleProps = {
  id?: string;
  name: string;
  className?: string;
  onChange: (value) => void;
  checked: boolean;
  label: LabelProps;
};

export function Toggle({
  name,
  className,
  id = `toggle-${random(1000)}`,
  onChange,
  checked,
  label,
}: ToggleProps) {
  return (
    <div className={cx("flex items-center", className)}>
      <ToggleWithoutLabel
        name={name}
        id={id}
        onChange={onChange}
        checked={checked}
      />

      {label && (
        <InputLabel
          htmlFor={id}
          cursorStyle="cursor-pointer"
          className={label.className}
        >
          {label.text}
        </InputLabel>
      )}
    </div>
  );
}
