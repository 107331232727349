import React from "react";
import Select from "react-select";

export const WorkflowAutomation = ({
  t,
  handleInputMerchantChange,
  handleInputChange,
  handleCheckBoxChange,
  handelDropDownValue,
  disableOnSpecificWeekdayOption,
  enableOnSpecificWeekdayOption,
  afterNoOfDays,
  onSpecificDay,
  merchant,
  state,
}) => {
  const {
    automate_completion,
    complete_after_days,
    complete_since_event,
    complete_on_weekday,
  } = state;

  const statusOptions = [
    {
      value: "is_inspected",
      label: "Inspected",
    },
    {
      value: "is_arrived",
      label: "Arrived",
    },
  ];

  const findStatusOption = statusOptions.find(
    ({ value }) => value === complete_since_event
  );

  const statusOptionsDefaultValue = {
    value: complete_since_event,
    label: findStatusOption.label,
  };

  const dayNamesArray = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const dayNamesOptions = dayNamesArray.map((weekday) => ({
    value: weekday,
    label: t[weekday],
  }));

  const dayNamesOptionsDefaultValue = {
    value: complete_on_weekday,
    label: t[complete_on_weekday],
  };

  return (
    <div className="mt-8 px-8">
      <div className="border-b pb-8">
        <p className="font-semibold text-base leading-6 text-gray-825">
          {t.workflow}
        </p>

        <div className="mt-4 flex">
          <div className="w-1/3">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.cancel_return_days}
            </p>

            <input
              className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="number"
              min="0"
              name="cancel_customer_return_days"
              onChange={handleInputMerchantChange}
              value={merchant.cancel_customer_return_days}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.registered_status}
            </p>
          </div>
        </div>

        <div className="mt-4 flex">
          <div className="flex items-center">
            <div className="relative inline-block min-w-40 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                type="checkbox"
                name="automate_completion"
                id="automate_completion"
                onChange={handleCheckBoxChange}
                checked={automate_completion}
              />

              <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
            </div>

            <label
              htmlFor="automate_completion"
              className="inline font-medium text-sm leading-5 text-gray-825 cursor-pointer"
            >
              {t.workflow_automation_completion_text1}
            </label>
          </div>
        </div>

        {automate_completion ? (
          <div>
            <div className="mt-2">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.workflow_automation_completion_text3}
              </p>

              <div className="flex">
                <div className="w-1/3">
                  <div className="flex">
                    <Select
                      className="w-full bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 focus:border-blue-675 focus:border capitalize"
                      options={statusOptions}
                      onChange={(data) =>
                        handelDropDownValue(data, "complete_since_event")
                      }
                      value={statusOptionsDefaultValue}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex">
                <div className="flex">
                  <div className="flex">
                    <input
                      id="after_no_of_days"
                      className="w-6 h-6 cursor-pointer"
                      type="radio"
                      value={afterNoOfDays}
                      name="time_and_no_of_day"
                      checked={afterNoOfDays}
                      onChange={disableOnSpecificWeekdayOption}
                    />

                    <label
                      htmlFor="after_no_of_days"
                      className="font-medium text-sm leading-5 text-black ml-3 cursor-pointer"
                    >
                      {t.after_no_of_days}
                    </label>
                  </div>

                  <div className="flex ml-6">
                    <input
                      id="on_specific_day"
                      className="w-6 h-6 cursor-pointer"
                      type="radio"
                      value={onSpecificDay}
                      name="time_and_no_of_day"
                      checked={onSpecificDay}
                      onChange={enableOnSpecificWeekdayOption}
                    />

                    <label
                      htmlFor="on_specific_day"
                      className="font-medium text-sm leading-5 text-black ml-3 cursor-pointer"
                    >
                      {t.on_specific_day}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {onSpecificDay && (
              <div className="mt-4">
                <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                  <span
                    data-tooltip-target="tooltip-light"
                    data-tooltip-style="light"
                    data-tooltip-placement="top"
                  >
                    {t.complete_return_every}

                    <span className="group cursor-pointer relative inline-block text-center">
                      <svg
                        className="inline-block cursor-pointer"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z"
                          fill="#2F80ED"
                        />

                        <path
                          d="M12 13V15"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />

                        <path
                          d="M12.5 9C12.5 9.27614 12.2761 9.5 12 9.5C11.7239 9.5 11.5 9.27614 11.5 9C11.5 8.72386 11.7239 8.5 12 8.5C12.2761 8.5 12.5 8.72386 12.5 9Z"
                          stroke="white"
                        />
                      </svg>

                      <span className="opacity-0 w-60 bg-white  border box-border border-gray-325 text-gray-875 text-left text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-7 px-3 pointer-events-none">
                        {t.tool_tip_text}
                      </span>
                    </span>
                  </span>
                </p>

                <div className="flex items-center">
                  <div className="w-1/3">
                    <div className="flex">
                      <Select
                        className="w-full bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 focus:border-blue-675 focus:border capitalize"
                        options={dayNamesOptions}
                        onChange={(data) =>
                          handelDropDownValue(data, "complete_on_weekday")
                        }
                        value={dayNamesOptionsDefaultValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {afterNoOfDays && (
              <div className="w-1/3 mt-4">
                <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                  {t.workflow_automation_completion_text2}
                </p>

                <input
                  className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                  type="number"
                  min="0"
                  name="complete_after_days"
                  id="complete_after_days"
                  onChange={handleInputChange}
                  value={complete_after_days}
                />
                <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.workflow_automation_sync_time}
            </p>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
