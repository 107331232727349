import React from "react";

export const ShopNowIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5383 11.3402H5.52539C5.39244 11.3405 5.26336 11.2954 5.15937 11.2126C5.05537 11.1298 4.98264 11.014 4.95313 10.8844L3.60391 5.00625C3.58417 4.92039 3.58406 4.83117 3.60356 4.74526C3.62307 4.65934 3.66171 4.57893 3.71659 4.51001C3.77147 4.44108 3.84119 4.38542 3.92056 4.34717C3.99993 4.30892 4.0869 4.28906 4.175 4.28906H14.8887C14.9769 4.28888 15.064 4.30861 15.1435 4.34679C15.223 4.38497 15.2929 4.4406 15.3479 4.50955C15.4029 4.57849 15.4416 4.65897 15.4612 4.74497C15.4808 4.83098 15.4807 4.92029 15.4609 5.00625L14.1094 10.8844C14.0799 11.0138 14.0074 11.1294 13.9036 11.2122C13.7998 11.2951 13.671 11.3402 13.5383 11.3402ZM5.9918 10.1684H13.0719L14.1527 5.46211H4.91055L5.9918 10.1684Z" fill="#18181B"/>
      <path d="M6.67187 16.5766C6.22779 16.5766 5.79368 16.4449 5.42444 16.1982C5.0552 15.9514 4.76742 15.6008 4.59747 15.1905C4.42753 14.7802 4.38307 14.3288 4.4697 13.8932C4.55634 13.4577 4.77018 13.0576 5.0842 12.7436C5.39821 12.4296 5.79829 12.2157 6.23383 12.1291C6.66938 12.0424 7.12084 12.0869 7.53112 12.2569C7.94139 12.4268 8.29206 12.7146 8.53878 13.0838C8.7855 13.4531 8.91719 13.8872 8.91719 14.3313C8.91657 14.9266 8.67981 15.4973 8.25886 15.9182C7.83792 16.3392 7.26718 16.5759 6.67187 16.5766ZM6.67187 13.2582C6.45955 13.2582 6.252 13.3212 6.07546 13.4391C5.89893 13.5571 5.76134 13.7248 5.68011 13.9209C5.59887 14.1171 5.57764 14.333 5.61909 14.5412C5.66054 14.7494 5.76281 14.9407 5.91298 15.0908C6.06314 15.2409 6.25444 15.3431 6.4627 15.3845C6.67095 15.4259 6.88679 15.4046 7.08293 15.3233C7.27907 15.242 7.44669 15.1043 7.56459 14.9277C7.6825 14.7511 7.74539 14.5436 7.74531 14.3313C7.745 14.0467 7.63179 13.7739 7.43054 13.5727C7.22929 13.3715 6.95643 13.2584 6.67187 13.2582Z" fill="#18181B"/>
      <path d="M12.3914 16.5766C11.9473 16.5766 11.5132 16.4449 11.144 16.1982C10.7747 15.9514 10.4869 15.6008 10.317 15.1905C10.1471 14.7802 10.1026 14.3288 10.1892 13.8932C10.2759 13.4577 10.4897 13.0576 10.8037 12.7436C11.1177 12.4296 11.5178 12.2157 11.9534 12.1291C12.3889 12.0424 12.8404 12.0869 13.2506 12.2569C13.6609 12.4268 14.0116 12.7146 14.2583 13.0838C14.505 13.4531 14.6367 13.8872 14.6367 14.3313C14.6361 14.9266 14.3993 15.4973 13.9784 15.9182C13.5574 16.3392 12.9867 16.5759 12.3914 16.5766ZM12.3914 13.2582C12.1791 13.2582 11.9715 13.3212 11.795 13.4391C11.6185 13.5571 11.4809 13.7248 11.3996 13.9209C11.3184 14.1171 11.2972 14.333 11.3386 14.5412C11.3801 14.7494 11.4823 14.9407 11.6325 15.0908C11.7827 15.2409 11.974 15.3431 12.1822 15.3845C12.3905 15.4259 12.6063 15.4046 12.8025 15.3233C12.9986 15.242 13.1662 15.1043 13.2841 14.9277C13.402 14.7511 13.4649 14.5436 13.4648 14.3313C13.4645 14.0467 13.3513 13.7739 13.1501 13.5727C12.9488 13.3715 12.676 13.2584 12.3914 13.2582Z" fill="#18181B"/>
      <path d="M4.175 5.46445C4.05201 5.46444 3.93214 5.42573 3.83237 5.3538C3.73261 5.28186 3.65801 5.18036 3.61914 5.06367L2.61484 2.04688H0.761719C0.606318 2.04688 0.457283 1.98514 0.347398 1.87526C0.237514 1.76537 0.175781 1.61634 0.175781 1.46094C0.175781 1.30554 0.237514 1.1565 0.347398 1.04662C0.457283 0.936733 0.606318 0.875 0.761719 0.875H3.03906C3.16206 0.875011 3.28193 0.913725 3.38169 0.985657C3.48146 1.05759 3.55606 1.15909 3.59492 1.27578L4.73281 4.69336C4.76215 4.78143 4.77013 4.87521 4.75612 4.96697C4.74211 5.05873 4.70649 5.14585 4.65221 5.22115C4.59793 5.29646 4.52653 5.35778 4.4439 5.40008C4.36128 5.44238 4.26978 5.46445 4.17695 5.46445H4.175Z" fill="#18181B"/>
    </svg>
  );
};
