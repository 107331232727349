import { useState } from "react";

import {
  changePeoplevoxStatus,
  connectToPeoplevox
} from "../../../services/api";

import { SuccessToast } from "../../../common/SuccessToast";
import { ErrorToast } from "../../../common/ErrorToast";

import { transformIntegrationResponse } from "./Peoplevox.util";

import EN from "./locales/EN.json";
import DE from "./locales/DE.json";

export const usePeoplevox = ({
  currentUser,
  peoplevoxIntegration,
}) => {
  const t = currentUser.locale === "en" ? EN : DE;

  const [state, setState] = useState(
    transformIntegrationResponse(peoplevoxIntegration)
  );

  const [connectBtnDisabled, setConnectBtnDisabled] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [isConnected, setIsConnected] = useState(Boolean(state.password));

  const handleInputChange = ({ name, value }) => {
    setState(prevState => ({ ...prevState, [name]: value }));

    if (name === "password") setPasswordChanged(true);
  };

  async function changeStatus({ name, value }) {
    handleInputChange({ name, value });

    const response = await changePeoplevoxStatus({
      id: state.id,
      enabled: value,
    });
    const responseJSON = await response.json();

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        enabled: responseJSON.enabled,
      }));

      SuccessToast({
        toastId: "peoplevoxIntegration",
      });
    } else {
      setState(prevState => ({
        ...prevState,
        enabled: !value,
      }));

      ErrorToast({
        message: responseJSON.error,
        toastId: "peoplevoxIntegration",
      });
    }
  }

  async function connect() {
    setConnectBtnDisabled(true);

    const { password, ...stateWithoutPassword } = state;
    const response = await connectToPeoplevox({
      ...stateWithoutPassword,
      ...(passwordChanged && { password }),
    });

    const responseJSON = await response.json();

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        ...transformIntegrationResponse(responseJSON.peoplevox_integration),
      }));
      setPasswordChanged(false);
      setIsConnected(true);

      SuccessToast({
        toastId: "peoplevoxIntegration",
      });
    } else {
      ErrorToast({
        message: responseJSON.error,
        toastId: "peoplevoxIntegration",
      });
    }

    setConnectBtnDisabled(false);
  }

  return {
    state,
    t,
    handleInputChange,
    connectBtnDisabled,
    connect,
    isConnected,
    changeStatus,
  };
}
