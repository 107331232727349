import React, { useState } from "react";
import { saveCollectPlusReturnAddressSettings } from "../../../services/api";
import { store } from "react-notifications-component"
import Notification from "../../../notification";
import translationEN from "../../../../translations/integrations/translationEN.json";
import translationDE from "../../../../translations/integrations/translationDE.json";

export const useCollectPlus = ({
  currentUser,
  returnAddress,
}) => {
  const {
    collect_plus_route_depot_number: collectPlusRouteDepotNumber,
    collect_plus_depot_short_name: collectPlusDepotShortName,
  } = returnAddress;

  function getInitialState() {
    return {
      returnAddress: {
        collectPlusRouteDepotNumber,
        collectPlusDepotShortName,
      },
      disableButton: false,
      notificationId: 0,
    }
  }

  const t = currentUser.locale === "en" ? translationEN : translationDE

  const [state, setState] = useState(getInitialState())

  function handleInputChange(e) {
    e.persist()

    setState((prevState) => ({
      ...prevState,
      returnAddress: {
        ...prevState.returnAddress,
        [e.target.name]: e.target.value,
      },
    }))
  }

  async function saveCollectPlusSettings() {
    setState(prevState => ({ ...prevState, disableButton: true }))

    const response = await saveCollectPlusReturnAddressSettings(state.returnAddress)

    setState(prevState => ({
      ...prevState,
      disableButton: false,
      notificationId: prevState.notificationId + 1,
    }))

    if(response.status == 200) {
      store.addNotification({
        id: state.notificationId,
        container: "bottom-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        content: <Notification success_message="Saved successfully!"/>,
        width: 762,
        dismiss: {
          duration: 2000,
          showIcon: true
        }
      })
    } else {
      const responseJson = await response.json()

      store.addNotification({
        id: state.notificationId,
        container: "bottom-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        content: <Notification error_message={responseJson.error}/>,
        width: 762,
        dismiss: {
          duration: 5000,
          showIcon: true
        }
      })
    }
  }

  return {
    t,
    state,
    handleInputChange,
    saveCollectPlusSettings,
  }
}
