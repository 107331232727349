import { useState, useEffect } from "react";
import { useUppy } from "../../hooks/useUppy/useUppy"; 

export const useForm = ({ propTradeInItem, submitAddForm, submitEditForm, tradeInItemImage }) => {
  function getInitialState() {
    return {
      sku: propTradeInItem ? propTradeInItem.sku : "",
      name: propTradeInItem ? propTradeInItem.name : "",
      returnLimit: propTradeInItem ? propTradeInItem.returnLimit : "",
      isActive: propTradeInItem ? propTradeInItem.isActive : false,
      trade_in_item_image_attributes: {
        id: tradeInItemImage?.id || "",
        image: tradeInItemImage?.image_data || ""
      },
      fileName: "",
      imageUrl: tradeInItemImage?.image_url_thumbnail || "",
    };
  }

  const [tradeInItem, setTradeInItem] = useState(getInitialState());
  const inputId = "#image-file-input"
  const maxNumberOfFiles = 1 
  const maxFileSize = (4 * 1024 * 1024)
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png']
  const allowMultipleUploads = true
  const autoProceed = true

  useEffect(() => {
    useUppy({
      inputId, 
      setUppyState, 
      maxNumberOfFiles,
      maxFileSize, 
      allowedFileTypes,
      allowMultipleUploads,
      autoProceed,
    })
  }, []);

  function handleInputChange(e) {
    e.persist();
    setTradeInItem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function handleCheckBoxChange(e) {
    e.persist();
    setTradeInItem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  }

  function createItem() {
    submitAddForm(tradeInItem);
  }

  function updateItem() {
    submitEditForm(tradeInItem);
  }

  function setUppyState(fileName, imageUrl, uploadedFileData){
  setTradeInItem((prevState) => ({
    ...prevState,
    fileName: fileName,
    imageUrl: imageUrl,
    trade_in_item_image_attributes: {
      id: tradeInItem.trade_in_item_image_attributes.id,
      image: JSON.stringify(uploadedFileData),
    }
  }));
}

  return {
    tradeInItem,
    handleCheckBoxChange,
    handleInputChange,
    createItem,
    updateItem,
  };
};
