import React, { FC } from "react";

type SubHeaderProps = {
  text: string;
  Icon?: FC<any>;
  renderToggle?: FC<any>;
};

const SubHeaderOld = ({ text, Icon, renderToggle }: SubHeaderProps) => {
  return (
    <div className="font-inter">
      <div className="flex items-center justify-between border-t border-b border-gray-225 mt-3 px-8 py-7">
        <div className="flex items-center">
          {Icon && (
            <div className="mr-4">
              <Icon />
            </div>
          )}
          <h1 className="font-semibold text-base leading-6 text-gray-975">
            {text}
          </h1>
        </div>

        {renderToggle}
      </div>
    </div>
  );
};

export default SubHeaderOld;
