import React from "react";

import { TrashIcon1 } from "../../../../ui-kit/icons/TrashIcon1";
import { Toggle } from "../../../../ui-kit/components/Toggle";

export const ItemConditions = ({
  t,
  itemConditionsState,
  handleToggleMerchantChange,
  itemConditions,
  addItemCondition,
  updateItemCondition,
  deleteItemCondition,
}) => {
  return (
    <div className="mt-8 px-8">
      <div className="border-b pb-8">
        <p className="font-semibold text-base leading-6 text-gray-825 mb-4">
          {t.item_conditions}
        </p>

        <Toggle
          id="show_item_conditions"
          name="show_item_conditions"
          className="w-1/3 mb-4"
          onChange={(value) =>
            handleToggleMerchantChange({ name: "show_item_conditions", value })
          }
          checked={itemConditionsState}
          label={{
            text: t.show_item_conditions,
            className:
              "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
          }}
        />

        {itemConditionsState && (
          <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
            {t.item_conditions_text}
          </p>
        )}

        {itemConditionsState &&
          itemConditions.map(({ static_name, custom_name }, index) => (
            <div
              key={index}
              className="w-1/3 flex justify-start items-center mb-4"
            >
              <div className="mr-4 opacity-50">{static_name}</div>

              <div className="w-10/12 mr-5">
                <input
                  className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                  name="custom_name"
                  value={custom_name}
                  onChange={({ target: { value } }) =>
                    updateItemCondition({ index, custom_name: value })
                  }
                />
              </div>

              <div
                className="p-10px bg-gray-225 rounded"
                onClick={() => deleteItemCondition(index)}
              >
                <TrashIcon1 />
              </div>
            </div>
          ))}

        {itemConditionsState && (
          <button
            className="bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue"
            onClick={addItemCondition}
          >
            + {t.add_condition}
          </button>
        )}
      </div>
    </div>
  );
};
