import React, { useState } from "react";
import { isEmpty } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from "react-modal";
import countryList from "react-select-country-list";
import Select from "react-select";

import AppLayout from "../common/AppLayout";
import Header from "../common/Header";
import SubHeader from "../common/SubHeader";

import { Input } from "../../ui-kit/components/Input";
import { Toggle } from "../../ui-kit/components/Toggle";
import { Typography } from "../../ui-kit/components/Typography/Typography";
import { ShopNowIcon } from "../../ui-kit/icons/ShopNowIcon";
import { TrashIcon1 } from "../../ui-kit/icons/TrashIcon1";

import { useShopNowSettings } from "./index.hook";

const ShopNowSettings = ({
  current_user,
  shop_now_settings,
  payment_integration_route,
}) => {
  const {
    state,
    t,
    translations,
    updateShopNowSettings,
    saveSettings,
    isSaveSettingsInProgress,
  } = useShopNowSettings({
    current_user,
    shop_now_settings,
    payment_integration_route,
  });

  const {
    shop_via_returns_portal,
    active_paypal_checkout,
    shop_now_navigation_settings,
    removed_navigation_items,
    showNavigationItemModal,
    eligible_countries,
  } = state.shop_now_settings;

  const options = [
    {
      value: "DE",
      label: "Germany",
    },
    {
      value: "GB",
      label: "United Kingdom",
    },
    {
      value: "AT",
      label: "Austria",
    },
    {
      value: "FR",
      label: "France",
    },
    ...countryList().getData(),
  ];
  const countryDefaultValue = eligible_countries
    ? eligible_countries.split(",").map((p) => ({
        value: p,
        label: p,
      }))
    : [];

  const changeCountry = (e) => {
    const value = e.map((p) => p.value).join(",");
    updateShopNowSettings({
      name: "eligible_countries",
      value: value,
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    reorder(result.source.index, result.destination.index);
  };

  const reorder = (startIndex, endIndex) => {
    const navigationItemsList = shop_now_navigation_settings;
    const [removed] = navigationItemsList.splice(startIndex, 1);
    navigationItemsList.splice(endIndex, 0, removed);
    navigationItemsList.map((item, index) => {
      item.priority = index + 1;
    });
    updateShopNowSettings({
      name: "shop_now_navigation_settings",
      value: navigationItemsList,
    });
  };

  const deleteNavigationItem = (navigationItem, index) => {
    const updatedRemovedNavigationItems = [
      ...removed_navigation_items,
      {
        ...navigationItem,
        _destroy: 1,
      },
    ];

    const updatedShopNowNavigationSettings = [
      ...shop_now_navigation_settings.slice(0, index),
      ...shop_now_navigation_settings.slice(index + 1),
    ].map((item, index) => ({
      ...item,
      order: index,
      priority: index + 1,
    }));

    updateShopNowSettings({
      name: "shop_now_navigation_settings",
      value: updatedShopNowNavigationSettings,
    });

    updateShopNowSettings({
      name: "removed_navigation_items",
      value: updatedRemovedNavigationItems,
    });
  };

  const updateCollectionName = (newCollectionName, index) => {
    const navigationItemsList = shop_now_navigation_settings;
    navigationItemsList[index].collection_name = newCollectionName;
    updateShopNowSettings({
      name: "shop_now_navigation_settings",
      value: navigationItemsList,
    });
  };

  const showModal = () => {
    updateShopNowSettings({
      name: "showNavigationItemModal",
      value: true,
    });
  };

  const cancelAddNavigationModal = () => {
    updateShopNowSettings({
      name: "showNavigationItemModal",
      value: false,
    });
  };

  const [nameInput, setNameInput] = useState("");
  const [handleInput, sethandleInput] = useState("");

  const addNavigationItem = (name, handle) => {
    const navigationItemsList = shop_now_navigation_settings;
    const priority = navigationItemsList.length + 1;
    const navigationItem = {
      id: "",
      priority: priority,
      collection_name: name,
      collection_slug: handle,
    };
    navigationItemsList.splice(priority, 0, navigationItem);
    saveSettings().then(({ success }) => {
      if (success) {
        sethandleInput("");
        setNameInput("");
        updateShopNowSettings({
          name: "showNavigationItemModal",
          value: false,
        });
      } else {
        navigationItemsList.splice(shop_now_navigation_settings.length - 1, 1);
      }
    });
  };

  return (
    <AppLayout translations={translations} language={current_user.locale}>
      <Header currentUser={current_user}>
        <Header.Column>
          <div className="font-medium text-xs leading-4 text-gray-725">
            {t("shop_now")}
          </div>
        </Header.Column>
      </Header>

      <SubHeader>
        <SubHeader.Column>
          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="mr-4">
                <ShopNowIcon />
              </div>
              <h1 className="font-semibold text-base leading-6 text-gray-975">
                {t("shop_now")}
              </h1>
            </div>
          </div>
        </SubHeader.Column>

        <SubHeader.Column>
          <button
            className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
            onClick={saveSettings}
          >
            {"Save"}
          </button>
        </SubHeader.Column>
      </SubHeader>

      <div className="mt-8 ml-10 pb-8 w-2/3">
        <Typography
          element="div"
          weight="semibold"
          size="lg"
          height="5"
          color="gray-975"
          className=" mt-10"
        >
          {t("main_header")}
        </Typography>
        <Typography
          element="div"
          weight="normal"
          size="sm"
          height="5"
          color="gray-625"
          className="mt-3"
        >
          {t("sub_header")}
        </Typography>

        <div className="flex mt-4">
          <div className="w-1/2">
            <Toggle
              name="shop_via_returns_portal"
              className="mt-4"
              onChange={(value) =>
                updateShopNowSettings({
                  name: "shop_via_returns_portal",
                  value,
                })
              }
              checked={shop_via_returns_portal}
              label={{
                text: t("shop_via_return_portal"),
                className:
                  "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
              }}
            />
          </div>
        </div>

        <div className="flex mt-4">
          <div className="w-3/4">
            <Toggle
              name="active_paypal_checkout"
              className="mt-4"
              onChange={(value) =>
                updateShopNowSettings({
                  name: "active_paypal_checkout",
                  value,
                })
              }
              checked={active_paypal_checkout}
              label={{
                text: t("active_paypal_checkout"),
                className:
                  "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
              }}
            />
          </div>
        </div>

        {/* <div className="flex mt-4"> */}
        {/*   <div className="w-1/2"> */}
        {/*     <InputLabel>{t("exchange_period")}</InputLabel> */}
        {/*     <Input */}
        {/*       name="exchange_period" */}
        {/*       onChange={(value) => */}
        {/*         updateShopNowSettings({ name: "exchange_period", value }) */}
        {/*       } */}
        {/*       value={exchange_period} */}
        {/*     /> */}
        {/*     <InputLabel>{t("exchange_period_description")}</InputLabel> */}
        {/*   </div> */}
        {/* <div className="w-1/2 ml-5">
          <InputLabel>{t("minimum_inventory")}</InputLabel>
          <Input
            name="minimum_inventory"
            onChange={(value) =>
              updateShopNowSettings({ name: "minimum_inventory", value })
            }
            value={minimum_inventory}
          />
          <InputLabel className="font-normal block text-xs leading-4 text-gray-725">
            {t("minimum_inventory_description")}
          </InputLabel>
        </div> */}
        {/* </div> */}
        {/* <div className="flex mt-4">
          <div className="w-1/2">
            <InputLabel>{t("bonus_percentage")}</InputLabel>
            <Input
              name="bonus_percentage"
              onChange={(value) =>
                updateShopNowSettings({ name: "bonus_percentage", value })
              }
              value={bonus_percentage}
            />
            <InputLabel>{t("bonus_percentage_description")}</InputLabel>
          </div>
        </div> */}
        <div className="mt-5">
          <p className="font-medium text-sm leading-4 text-gray-825 mb-1">
            {t("countries_shop_now_option")}
          </p>

          <Select
            className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
            onChange={(e) => changeCountry(e)}
            placeholder={"Country"}
            options={options}
            value={countryDefaultValue}
            isClearable={false}
            isMulti
          />

          <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
            {t("eligible_countries")}
          </p>
        </div>
        {!isEmpty(shop_now_navigation_settings) && (
          <div>
            <Typography
              element="div"
              weight="semibold"
              size="lg"
              height="5"
              color="gray-975"
              className=" mt-10"
            >
              {t("navigation_settings")}
            </Typography>
            <div className="font-medium text-xs leading-4 text-gray-525">
              <div className="flex justify-between tracking-px_64 uppercase border-b pb-2 pt-4">
                <div className="w-1/4">{t("priority")}</div>
                <div className="w-1/4 text-center">{t("name")}</div>
                <div className="w-1/4 text-center">{t("handle")}</div>
                <div className="w-1/4 text-center">{t("action")}</div>
              </div>

              <div className="ml-2 mt-4">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {shop_now_navigation_settings.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                            id={item.id}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div
                                  className="flex flex-row justify-between items-center pl-2 mr-10 pr-4 pt-4 hover:bg-gray-100 hover-container"
                                  key={index}
                                >
                                  <div className="w-1/4 font-medium text-sm leading-5 text-gray-625 flex items-center">
                                    <div
                                      {...provided.dragHandleProps}
                                      className="w-6 h-6 bg-gray-100 rounded flex items-center justify-center flex-col text-gray-525 hover:bg-blue-700 hover:text-white"
                                    >
                                      =
                                    </div>

                                    {item.priority && (
                                      <div className="ml-4">
                                        #{item.priority}
                                      </div>
                                    )}
                                  </div>

                                  <div className="w-1/3 font-medium text-sm leading-5 tesxt-gray-825 text-center">
                                    <Input
                                      name="collection_name"
                                      onChange={(value) =>
                                        updateCollectionName(value, index)
                                      }
                                      value={item.collection_name}
                                    />
                                  </div>

                                  <div className="w-1/4 font-medium text-sm leading-5 text-gray-625 pl-1 text-center">
                                    {item.collection_slug}
                                  </div>

                                  <div className="w-1/4">
                                    <div className="flex float-right display-flex hidden">
                                      <div
                                        onClick={() =>
                                          deleteNavigationItem(item, index)
                                        }
                                      >
                                        <TrashIcon1 />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <button
                  className="bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue"
                  onClick={showModal}
                >
                  + {t("add_navigation_item")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={showNavigationItemModal}
        style={{
          content: {
            maxWidth: "439px",
            maxHeight: "360px",
            margin: "auto",
            padding: "16px",
          },
        }}
      >
        <form
          className="font-intel"
          onSubmit={(event) => {
            event.preventDefault();
            addNavigationItem(nameInput, handleInput);
          }}
        >
          <div className="w">
            <Typography
              element="p"
              weight="bold"
              size="sm"
              height="4"
              color="gray-725"
              className=" mt-6"
            >
              {t("add_navigation_item")}
            </Typography>
          </div>

          <div className="w">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
              className=" mt-6"
            >
              {t("name")}
            </Typography>

            <Input
              name="name"
              onChange={(value) => setNameInput(value)}
              value={nameInput}
              placeholder="New Arrivals"
              required
            />
          </div>

          <div className="w">
            <Typography
              element="p"
              weight="medium"
              size="xs"
              height="4"
              color="gray-725"
              className=" mt-6"
            >
              {t("handle")}
            </Typography>

            <Input
              name="handle"
              onChange={(value) => sethandleInput(value)}
              value={handleInput}
              placeholder="new-products"
              required
            />
            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t("handle_description")}
            </p>
          </div>

          <div className="flex justify-end mt-8">
            <button
              className="font-semibold text-sm leading-5 text-gray-875 py-2 px-4 border box-border rounded-lg border-gray-325 hover:border-gray-425 focus:outline-none focus:border-blue-625"
              onClick={cancelAddNavigationModal}
            >
              {t("cancel")}
            </button>

            <button
              className={`font-semibold text-sm leading-5 rounded-lg py-2 px-4 ml-3 focus:outline-none border focus:outline-none text-white ${
                isSaveSettingsInProgress
                  ? "bg-gray-225"
                  : "bg-green-800 border-green-800 hover:bg-green-825 focus:border-green-825"
              }`}
              type="submit"
              disabled={isSaveSettingsInProgress}
            >
              {t("save")}
            </button>
          </div>
        </form>
      </Modal>

      <a href={t("set_up_link")} target="_blank" rel="noreferrer">
        <Typography
          element="div"
          weight="semibold"
          size="sm"
          height="5"
          color="gray-975"
          className="ml-10 underline"
        >
          {t("how_to_set_up")}
        </Typography>
      </a>
    </AppLayout>
  );
};

export default ShopNowSettings;
