import { useState } from "react";

export const usePeoplevoxToggleState = ({
  initialValue,
  updateMerchantSettings,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [peoplevoxIntegrationState, setPeoplevoxIntegrationState] =
    useState(initialValue);

  async function toggleSettingsPeoplevoxIntegration() {
    const value = !peoplevoxIntegrationState;
    setPeoplevoxIntegrationState(value);

    const response = await updateMerchantSettings({
      peoplevox_integration_enabled: value,
    });

    const responseJSON = await response.json();

    if (response.status === 200) {
      setPeoplevoxIntegrationState(responseJSON.peoplevox_integration_enabled);
      showSuccessNotification();
    } else {
      setPeoplevoxIntegrationState(!value);
      showErrorNotification(responseJSON.error);
    }
  }

  return {
    peoplevoxIntegrationState,
    toggleSettingsPeoplevoxIntegration,
  };
}
