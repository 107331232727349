import React from "react";
import cx from "classnames";

import { AppLayout } from "../common/AppLayout";
import SubHeaderOld from "../../../common/SubHeaderOld";
import { usePeoplevox } from "./Peoplevox.hook";

import { PeoplevoxLogo } from "../../../../ui-kit/icons/PeoplevoxLogo";
import { InputLabel } from "../../../../ui-kit/components/InputLabel";
import { Input } from "../../../../ui-kit/components/Input";
import { Toggle } from "../../../../ui-kit/components/Toggle";

import { IntegrationResponse } from "./Peoplevox.types";

export type PeoplevoxProps = {
  currentUser: any;
  peoplevoxIntegration: IntegrationResponse;
};

export default function Peoplevox({
  currentUser,
  peoplevoxIntegration,
}: PeoplevoxProps) {
  const {
    t,
    state: {
      enabled,
      baseUrl,
      clientId,
      username,
      password,
      salesOrderNumberPrefix,
      salesOrderNumberSuffix,
    },
    handleInputChange,
    connectBtnDisabled,
    connect,
    isConnected,
    changeStatus,
  } = usePeoplevox({
    currentUser,
    peoplevoxIntegration,
  });

  return (
    <AppLayout
      currentUser={currentUser}
      settings={t.settings}
      integrations={t.integrations}
      headerText="Peoplevox"
      renderSubHeader={
        <SubHeaderOld
          text="Peoplevox"
          renderToggle={
            isConnected && (
              <Toggle
                name="enabled"
                className="mt-4"
                onChange={(value) => changeStatus({ name: "enabled", value })}
                checked={enabled}
                label={{
                  text: t.enabled,
                  className:
                    "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
                }}
              />
            )
          }
        />
      }
    >
      <PeoplevoxLogo />

      <div className="w-2/3 pt-8">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-base leading-6 text-gray-825">
            {t.peoplevox_integration}
          </h1>

          {isConnected && (
            <p
              className={cx(
                "rounded-lg font-semibold text-sm leading-5 py-2 px-3",
                "bg-teal-125 text-green-825"
              )}
            >
              {t.connected}
            </p>
          )}
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <InputLabel>{t.base_url}</InputLabel>

            <Input
              name="baseUrl"
              value={baseUrl}
              onChange={(value) =>
                handleInputChange({ name: "baseUrl", value })
              }
              placeholder="https://qac.peoplevox.net/"
            />
          </div>

          <div className="w-1/2 ml-5 block">
            <InputLabel>{t.client_id}</InputLabel>

            <Input
              name="clientId"
              value={clientId}
              onChange={(value) =>
                handleInputChange({ name: "clientId", value })
              }
              placeholder="abcDe1234"
            />
          </div>
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <InputLabel>{t.username}</InputLabel>

            <Input
              name="username"
              value={username}
              onChange={(value) =>
                handleInputChange({ name: "username", value })
              }
            />
          </div>

          <div className="w-1/2 ml-5 block">
            <InputLabel>{t.password}</InputLabel>

            <Input
              name="password"
              value={password}
              onChange={(value) =>
                handleInputChange({ name: "password", value })
              }
            />
          </div>
        </div>

        <div className="flex items-center mt-5">
          <div className="w-1/2 block">
            <InputLabel>{t.sales_order_number_prefix}</InputLabel>

            <Input
              name="salesOrderNumberPrefix"
              value={salesOrderNumberPrefix}
              onChange={(value) =>
                handleInputChange({ name: "salesOrderNumberPrefix", value })
              }
            />
          </div>

          <div className="w-1/2 ml-5 block">
            <InputLabel>{t.sales_order_number_suffix}</InputLabel>

            <Input
              name="salesOrderNumberSuffix"
              value={salesOrderNumberSuffix}
              onChange={(value) =>
                handleInputChange({ name: "salesOrderNumberSuffix", value })
              }
            />
          </div>
        </div>

        <button
          className={cx(
            "bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5",
            "text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white",
            "focus:shadow-btn_blue",
            { "cursor-default": connectBtnDisabled }
          )}
          disabled={connectBtnDisabled}
          onClick={connect}
        >
          {t.connect}
        </button>
      </div>
    </AppLayout>
  );
}
