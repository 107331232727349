import React, { FC, useEffect } from "react";
import ReactNotification from "react-notifications-component";
import i18next from "i18next";
import { isEmpty } from "lodash";

import {
  loadStaticTranslations,
  LocaleCodes,
  TranslationsMap,
} from "../../../common/i18n";

type AppLayoutProps = {
  translations?: TranslationsMap;
  language?: LocaleCodes;
};

const AppLayout: FC<AppLayoutProps> = ({
  children,
  translations,
  language,
}) => {
  useEffect(() => {
    if (!isEmpty(translations)) loadStaticTranslations(translations);
  }, []);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  return (
    <div className="font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />

      {children}
    </div>
  );
};

export default AppLayout;
