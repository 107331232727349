import React from "react";

import { KlaviyoLogoIcon } from "../../ui-kit/icons/klaviyo_logo";
import { Toggle } from "../../../ui-kit/components/Toggle";

import AppLayout from "../../common/AppLayout";
import Header from "../../common/Header";
import SubHeader from "../../common/SubHeader";

import { useKlaviyo } from "./klaviyo.hook";

const Klaviyo = ({ current_user, klaviyo_integration }) => {
  const {
    state,
    t,
    translations,
    handleKlaviyoInputChange,
    handleKlaviyoCheckBoxChange,
    connectKlaviyo,
  } = useKlaviyo({ klaviyo_integration, current_user });

  const { klaviyoIntegration, klaviyoConnected, disableButton } = state;

  return (
    <AppLayout translations={translations} language={current_user.locale}>
      <Header currentUser={current_user}>
        <Header.Column>
          <div className="font-medium text-xs leading-4">
            <p>
              <span className="text-gray-525">
                <a href="/settings">{t("settings")}</a> /{" "}
              </span>

              <span className="text-gray-525">
                <a href="/integrations">{t("integrations")}</a> /{" "}
              </span>

              <span className="text-gray-725 text-xs13">Klaviyo</span>
            </p>
          </div>
        </Header.Column>
      </Header>

      <SubHeader>
        <SubHeader.Column>
          <h1 className="font-semibold text-base leading-6 text-gray-975">
            Klaviyo
          </h1>
        </SubHeader.Column>

        <SubHeader.Column>
          <div className="flex flex-col items-end">
            {klaviyoConnected.connected && (
              <Toggle
                name="active"
                onChange={(value) =>
                  handleKlaviyoCheckBoxChange({ name: "active", value })
                }
                checked={klaviyoIntegration.active}
                label={{
                  text: t("active"),
                  className:
                    "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
                }}
              />
            )}

            <div
              className="font-normal text-sm leading-5 text-gray-625"
              dangerouslySetInnerHTML={{
                __html: t("klaviyo_activation_capture", {
                  notification_path: "/email_templates",
                }),
              }}
            ></div>
          </div>
        </SubHeader.Column>
      </SubHeader>

      <div className="mt-8 mx-8">
        <KlaviyoLogoIcon />
        <div className="w-2/3 mt-8">
          <div className="flex justify-between items-center">
            <h1 className="font-semibold text-base leading-6 text-gray-825">
              Klaviyo Integration
            </h1>
            <p
              className={`rounded-lg font-semibold text-sm leading-5 py-2 px-3 ${
                klaviyoConnected.connected
                  ? "bg-teal-125 text-green-825"
                  : "text-red-525 bg-red-450"
              } ${klaviyoConnected.show ? "" : "hidden"}`}
            >
              {klaviyoConnected.connected
                ? t("connected")
                : t("invalid_credentials")}
            </p>
          </div>
          <div className="flex items-center mt-5">
            <div className="w-1/2 block ml-2">
              <label className="font-medium text-xs leading-4 text-gray-725">
                Private key
              </label>
              <input
                className="w-full mt-1 py-9px px-4 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 placeholder-gray-875 rounded-lg box-border border border-gray-325"
                name="private_api_key"
                placeholder={
                  klaviyoIntegration.private_api_key_filtered ? "FILTERED" : ""
                }
                onChange={handleKlaviyoInputChange}
                value={klaviyoIntegration.private_api_key}
              />
            </div>
          </div>
          <button
            className={`bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue ${
              disableButton ? "cursor-default" : ""
            }`}
            disabled={disableButton}
            onClick={connectKlaviyo}
          >
            {t("connect")}
          </button>
        </div>
      </div>
    </AppLayout>
  );
};

export default Klaviyo;
