import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(BrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['staticTranslations'],
    defaultNS: 'staticTranslations',
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

export function loadStaticTranslationEN(translationEN) {
  i18n.addResources('en', 'staticTranslations', translationEN);
}

export function loadStaticTranslationDE(translationDE) {
  i18n.addResources('de', 'staticTranslations', translationDE);
}

export default i18n;
