import React from "react";

import { Typography } from "../../../../../ui-kit/components/Typography/Typography";

import { useShowReturnItem } from "./ShowReturnItem.hook";
import { TopLevelItem } from "./components/TopLevelItem";

import "react-notifications-component/dist/theme.css";

export const ShowReturnItem = ({
  isScayleIntegration,
  customer_return,
  items,
  isItemConditionDisplayable,
  itemConditions,
  itemReturnTypes,
  t,
  currency,
  is_refunded,
  is_cancelled,
  showImageModal,
  hideMarkItemAsMissing,
  markStatus,
  showInstantStoreCreditCaptureAuthorizedPaymentModal,
  canCancel,
  isCompletionInProgress,
}) => {
  const {
    isInstantStoreCreditCaptureAuthorizedAmountPopupEligible,
    conditionOptions,
    returnTypeOptions,
    shouldDisableItemInput,
    items_count,
  } = useShowReturnItem({
    t,
    customer_return,
    items,
    itemConditions,
    itemReturnTypes,
    isCompletionInProgress,
  });

  return (
    <div className="border-t pt-5 mt-29px">
      <Typography
        element="p"
        weight="semibold"
        size="base"
        height="6"
        color="gray-825"
      >
        {t.items} ({items_count})
      </Typography>

      {items.map((item, index) => (
        <div className="border-b py-8" key={index}>
          <TopLevelItem
            t={t}
            item={item}
            showImageModal={showImageModal}
            isScayleIntegration={isScayleIntegration}
            returnTypeOptions={returnTypeOptions}
            markStatus={markStatus}
            shouldDisableItemInput={shouldDisableItemInput}
            isItemConditionDisplayable={isItemConditionDisplayable}
            conditionOptions={conditionOptions}
            currency={currency}
            canCancel={canCancel}
            hideMarkItemAsMissing={hideMarkItemAsMissing}
            onClickMarkAsMissingHandler={(e) =>
              isInstantStoreCreditCaptureAuthorizedAmountPopupEligible(item.id)
                ? showInstantStoreCreditCaptureAuthorizedPaymentModal({
                    actionName: "mark_as_missing",
                    actionCallback: (event) => markStatus(event, item.id),
                  })
                : markStatus(e, item.id)
            }
            isCancelled={is_cancelled}
            isCompleted={is_refunded}
          />
        </div>
      ))}
    </div>
  );
};
