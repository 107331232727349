import React from "react";

export const CommentIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99967 15.2077C12.9163 15.2077 16.0413 13.7493 16.0413 9.99935C16.0413 6.24935 12.9163 4.79102 9.99967 4.79102C7.08301 4.79102 3.95801 6.24935 3.95801 9.99935C3.95801 10.8575 4.12167 11.5957 4.40903 12.2238C4.58816 12.6153 4.68864 13.0471 4.60929 13.4703L4.3908 14.6356C4.28279 15.2116 4.7874 15.7162 5.36343 15.6082L8.03624 15.1071C8.24634 15.0677 8.46173 15.0711 8.67285 15.1046C9.11219 15.1743 9.55832 15.2077 9.99967 15.2077Z"
        stroke="#52525B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91634 10.0007C7.91634 10.2308 7.72979 10.4173 7.49967 10.4173C7.26956 10.4173 7.08301 10.2308 7.08301 10.0007C7.08301 9.77053 7.26956 9.58398 7.49967 9.58398C7.72979 9.58398 7.91634 9.77053 7.91634 10.0007Z"
        stroke="#52525B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4163 10.0007C10.4163 10.2308 10.2298 10.4173 9.99967 10.4173C9.76956 10.4173 9.58301 10.2308 9.58301 10.0007C9.58301 9.77053 9.76956 9.58398 9.99967 9.58398C10.2298 9.58398 10.4163 9.77053 10.4163 10.0007Z"
        stroke="#52525B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9163 10.0007C12.9163 10.2308 12.7298 10.4173 12.4997 10.4173C12.2696 10.4173 12.083 10.2308 12.083 10.0007C12.083 9.77053 12.2696 9.58398 12.4997 9.58398C12.7298 9.58398 12.9163 9.77053 12.9163 10.0007Z"
        stroke="#52525B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
