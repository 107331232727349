import i18next from "i18next";
import { initReactI18next } from "react-i18next";

enum LocaleCode {
  en = "en",
  de = "de",
}

export type LocaleCodes = keyof typeof LocaleCode;

const supportedLanguages: LocaleCodes[] = [LocaleCode.en, LocaleCode.de];

i18next.use(initReactI18next).init({
  ns: ["static"],
  defaultNS: "static",
  fallbackLng: LocaleCode.en,
  load: "languageOnly",
  debug: false,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: supportedLanguages,
});

export type TranslationsMap = {
  en: any;
  de: any;
};

export const loadStaticTranslations = (translations: TranslationsMap) =>
  supportedLanguages.forEach((language) =>
    loadStaticTranslationsByLocaleCode(language, translations[language])
  );

const loadStaticTranslationsByLocaleCode = (
  locale: LocaleCodes,
  translationJSON: any
) => i18next.addResourceBundle(locale, "static", translationJSON);
