import React from "react";
import SearchInput from "./SearchInput";

const EmailAndInvoiceSearch = ({ email, invoiceNumber, onChange }) => (
  <>
    <SearchInput
      type="text"
      placeholder="Order Number"
      value={invoiceNumber}
      onChange={(e) =>
        onChange({
          email,
          invoiceNumber: e.target.value,
        })
      }
    />
  </>
);

export default EmailAndInvoiceSearch;
