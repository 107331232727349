import React, { FC } from "react";
import cx from "classnames";
import { TypographyElement } from "./Typography.types";

export type TypographyProps = {
  weight?: string;
  size?: string;
  height?: string;
  color?: string;
  element?: TypographyElement;
  className?: string;
};

export const Typography: FC<TypographyProps> = ({
  children,
  size,
  weight,
  element: TypographyComponent = "span",
  className,
  height,
  color,
  ...props
}) => {
  return (
    <TypographyComponent
      className={cx(
        className,
        `font-${weight} text-${size} leading-${height} text-${color}`
      )}
      {...props}
    >
      {children}
    </TypographyComponent>
  );
};
