import React, { useState } from "react"
import { store } from "react-notifications-component"
import Notification from "../../notification/index"
import { generateApiKey } from "../../services/api"
import translationEN from "../../../translations/integrations/translationEN.json"
import translationDE from "../../../translations/integrations/translationDE.json"

export const usePublicApiKey = ({
  api_setting,
  current_user
}) => {

  const {
    api_key,
    password_digest
  } = api_setting

  function getInitialState() {
    return {
      api_key: api_key,
      password: password_digest ? "FILTERED" : "",
      notificationId: 0,
      disableButton: false
    }
  }

  const t = current_user.locale === "en" ? translationEN : translationDE
  const [state, setState] = useState(getInitialState())

  function copyApiKey() {
    var copy = document.getElementsByName("api_key")
    copy[0].select()
    document.execCommand("copy")
  }

  async function generatekey() {
    setState((prevState) => ({
      ...prevState,
      disableButton: true
    }))
    const response = await generateApiKey()
    if(response.status === 200) {
      response.json().then(responseJson => {
        store.addNotification({
          id: state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: <Notification
            success_message="This is the only time that the password can be viewed or downloaded. You cannot recover it later. However, you can create new password at any time."
           />,
          width: 762,
          dismiss: {
            duration: 0,
            showIcon: true
          }
        })

        setState((prevState) => ({
          ...prevState,
          api_key: responseJson.api_key,
          password:responseJson.password,
          notificationId: state.notificationId + 1,
          disableButton: false
        }))
      })
    } else {
      setState((prevState) => ({
        ...prevState,
        disableButton: false
      }))
      response.json().then(responseJson => {
        store.addNotification({
          id: state.notificationId,
          container: "bottom-center",
          animationIn: ["animated", "bounceIn"],
          animationOut: ["animated", "bounceOut"],
          content: <Notification error_message={responseJson.error}/>,
          width: 762,
          dismiss: {
            duration: 5000,
            showIcon: true
          }
        })
      })
    }
  }

  return {
    state,
    t,
    copyApiKey,
    generatekey
  }
}
